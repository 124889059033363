// Geral
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

// Ações
import * as list from "actions/list";
import { fetchList, changeStatus, deleteRecord, openPaymentModal } from "actions/compras";

// Componentes
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card";
import CardBody from "components/Card/CardBody";
import RightPanel from "components/RightPanel";
import DynamicTable from "components/DynamicTable";
import Button from "components/CustomButton";
import Pesquisa from "components/Pesquisa";
import ListHeader from "components/ListHeader";
import Launch from "@material-ui/icons/Launch";

// Ícones
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import Done from "@material-ui/icons/Done";

// Formulário
import Form from "./form";
import Payment from "./payment";

const view = "compras";

export default function Compras({ params }) {
    useEffect(() => {
        list.start(view, "IdCompra", fetchList, changeStatus, deleteRecord);
    }, []);

    const listData = useSelector(state => state.list[view]);

    const form = listData
        ? (
            <Form
                changeStatus={listData.changeStatus}
                deleteRecord={listData.delete}
                afterSave={() => list.afterSaveSuccess(view)}
                afterChangeStatusSuccess={() => list.afterChangeStatusSuccess(view)}
                afterDeleteSuccess={() => list.afterDeleteSuccess(view)}
                id={params ? params.id : null}
            />
        ) : null;

    const payments = (
        <Payment
            id={params ? params.id : null}
        />
    );

    return (
        <GridContainer>
            {listData && (
                <>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <ListHeader
                                title="Compras"
                                add="Nova Compra"
                                legend="Compras da empresa"
                                view={view}
                            />
                            <CardBody>
                                <Pesquisa view={view} />
                                <DynamicTable
                                    view={view}
                                    {...listData.tableProps}
                                    columns={[
                                        {
                                            Header: "Data/Hora",
                                            accessor: "DataHoraDisplay"
                                        },
                                        {
                                            Header: "Empresa",
                                            accessor: "NomeEmpresa"
                                        },
                                        {
                                            Header: "Plano",
                                            accessor: "NomePlano"
                                        },
                                        {
                                            Header: "Valor",
                                            accessor: "Valor"
                                        },
                                        {
                                            Header: "Valor Pago",
                                            accessor: "Valorpago"
                                        },
                                        {
                                            Header: "Status",
                                            accessor: "Status",
                                            Cell: props => {
                                                const status = {
                                                    '0': 'Não Informado',
                                                    '1': 'Completo',
                                                    '2': 'Aguardando Pagamento', 
                                                    '3': 'Cancelado'
                                                }
                                                return status[props.value] || status['0']
                                            }
                                        },
                                        {
                                            Header: "Faturada?",
                                            accessor: "StatusFaturamento",
                                            maxWidth: 100,
                                            Cell: props => {
                                                if (props.value === 1) {
                                                    return "SIM";
                                                }
                                                return "NÃO";
                                            }
                                        },
                                        {
                                            Header: "Pagamento",
                                            maxWidth: 100,
                                            style: {
                                                padding: 0,
                                                textAlign: "center"
                                            },
                                            Cell: props => parseInt(props.original.Status) !== 1 ? (
                                                <Button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        openPaymentModal(props.original[listData.idField]);
                                                    }}
                                                    color="info"
                                                    link
                                                    size="sm"
                                                    round
                                                    justIcon
                                                    title="Pagamento"
                                                >
                                                    <MonetizationOn />
                                                </Button>
                                            ) : (
                                                <Button
                                                    color="success"
                                                    link
                                                    size="sm"
                                                    round
                                                    justIcon
                                                    title="Pago"
                                                >
                                                    <Done />
                                                </Button>
                                            )
                                        },
                                        {
                                            Header: "",
                                            maxWidth: 50,
                                            style: {
                                                padding: 0
                                            },
                                            Cell: props => (
                                                <Button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        list.openEditModal(props.original[listData.idField], view);
                                                    }}
                                                    color="primary"
                                                    link
                                                    size="sm"
                                                    round
                                                    justIcon
                                                    title="Ver / Editar"
                                                >
                                                    <Launch />
                                                </Button>
                                            )
                                        }
                                    ]}
                                    onFetchData={(state, instance) => list.requestData(view)}
                                    onPageSizeChange={(pageSize, pageIndex) => list.onPageSizeChange(pageSize, pageIndex, view)}
                                    onPageChange={(pageIndex) => list.onPageChange(pageIndex, view)}
                                    onSortedChange={(newSorted, column, shiftKey) => list.onSortedChange(newSorted, column, shiftKey, view)}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>

                    <RightPanel root={listData.root} isPaneOpen={params && params.action === "new"} title="Nova Compra" path="new">
                        {form}
                    </RightPanel>
                    <RightPanel root={listData.root} isPaneOpen={params && params.action === "edit"} title="Visualização de Compra" path="edit">
                        {form}
                    </RightPanel>
                    <RightPanel root={listData.root} isPaneOpen={params && params.action === "payment"} title="Efetuar Pagamento" path="payment">
                        {payments}
                    </RightPanel>
                </>
            )}
        </GridContainer>
    );
}
