/* Geral / Bibliotecas */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";

/* Ícones */
import Save from "@material-ui/icons/Save";
import Delete from '@material-ui/icons/Delete';
import AddIcon from "@material-ui/icons/Add";

/* Métodos / Ações */
import { saveProfissionais } from "actions/membros";
import { loadOptions as loadOptionsEmpresas } from "actions/empresas";

/* Componentes */
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import FormInput from "components/FormInput";
import Button from "components/CustomButton";
import { Container } from "assets/jss/doSul-dashboard";
import Loading from "components/Loading";
import FormSelect from "components/FormSelect";
import DynamicTable from "components/DynamicTable";
import ListHeader from "components/ListHeader";
import CardBody from "components/Card/CardBody";
import Card from "components/Card";

const Model = {
    IdMembro: null,
    Profissao: "",
    Ocupacao: "",
    Especialidade: "",
    Vinculos: []
};

export default function MembrosProfissionaisForm({
    id, form, formData
}) {
    const [tmpEmpresa, setTmpEmpresa] = useState(null);
    const [tmpCargo, setTmpCargo] = useState('');
    const permissions = useSelector(state => state.permissions);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(form.setAfterSave(() => { }));
        dispatch(form.setSave(saveProfissionais));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // É chamado toda vez que muda o ID
        form.setModel(Model);
        if (id) {
            form.fetchData(id);
        }
    }, [id, form]);

    if (formData.loading) {
        return <Loading />;
    }

    const disabled = permissions && permissions[formData.root] < 2;

    const handleSubmit = (values, actions) => {
        let vinculosProfissionais = [];
        for (let i = 0; i < values.Vinculos.length; i++) {
            vinculosProfissionais.push({
                empresa: parseInt(values.Vinculos[i].empresa),
                Cargo: values.Vinculos[i].cargo
            });
        }

        values.vinculosProfissionais = vinculosProfissionais;
        formData.save(values, actions, formData.afterSave);
    };

    return (
        <Container>
            <Formik
                initialValues={formData.model}
                onSubmit={handleSubmit}
                render={FormikProps => (
                    <Form onSubmit={FormikProps.handleSubmit}>
                        <GridContainer>
                            {(FormikProps.values.Tipo === "E") &&
                                <p>Membro do tipo Empresa, nada para alterar aqui.</p>
                            }
                            {(FormikProps.values.Tipo !== "E") &&
                                <>
                                    <GridItem xs={12}>
                                        <FormInput
                                            field={{
                                                name: "Profissao",
                                                disabled,
                                                multiline: true,
                                                rows: 3
                                            }}
                                            form={FormikProps}
                                            label="Profissão"
                                        />
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <FormInput
                                            field={{
                                                name: "Ocupacao",
                                                disabled,
                                                multiline: true,
                                                rows: 3
                                            }}
                                            form={FormikProps}
                                            label="Ocupação"
                                        />
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <FormInput
                                            field={{
                                                name: "Especialidade",
                                                disabled,
                                                multiline: true,
                                                rows: 3
                                            }}
                                            form={FormikProps}
                                            label="Especialidade"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card>
                                            <ListHeader
                                                title="Vínculos Profissionais"
                                                add={false}
                                                legend={false}
                                                view={'membros'}
                                            />
                                            <Container style={{ width: '100%' }}>
                                                <GridContainer alignContent="space-between" alignItems="flex-end">
                                                    <GridItem xs>
                                                        <FormSelect
                                                            field={{
                                                                name: "empresa",
                                                                placeholder: "Digite para localizar...",
                                                                isSearchable: true,
                                                                loadOptions: loadOptionsEmpresas,
                                                                getOptionLabel: (option) => `${option.Nome}`,
                                                                loadingMessage: () => "Carregando...",
                                                                noOptionsMessage: (input) => (input.inputValue === "" ? "Digite para localizar..." : "Nenhum resultado encontrado"),
                                                                value: tmpEmpresa,
                                                                onChange: (value) => {
                                                                    if (!value || FormikProps.values.Vinculos.filter((val, idx) => val.empresa === value.IdEmpresa).length === 0)
                                                                        setTmpEmpresa(value);
                                                                    else
                                                                        setTmpEmpresa(null);
                                                                }
                                                            }}
                                                            form={FormikProps}
                                                            async
                                                            label="Adicionar vínculo empresarial"
                                                        />
                                                    </GridItem>
                                                </GridContainer>
                                                <GridContainer alignContent="space-between" alignItems="flex-end">
                                                    <GridItem xs>
                                                        <FormInput
                                                            field={{
                                                                name: "Cargo",
                                                                disabled,
                                                                value: tmpCargo,
                                                                onChange: (evt) => {
                                                                    setTmpCargo(evt.target.value);
                                                                }
                                                            }}
                                                            form={FormikProps}
                                                            label="Cargo"
                                                        />
                                                    </GridItem>
                                                    <GridItem xs="auto">
                                                        <Button disabled={!tmpEmpresa || !tmpCargo} color="success" type="button" onClick={() => {
                                                            let tmpEmpresas = FormikProps.values.Vinculos;
                                                            let cargo = tmpCargo;
                                                            if (tmpEmpresa) {
                                                                tmpEmpresas.push({
                                                                    empresa: tmpEmpresa.IdEmpresa,
                                                                    nomeEmpresa: tmpEmpresa.Nome,
                                                                    cargo
                                                                });
                                                                setTmpEmpresa(null);
                                                                setTmpCargo('');
                                                            }
                                                            FormikProps.setFieldValue('Vinculos', tmpEmpresas);
                                                        }}>
                                                            <AddIcon />
                                                        </Button>
                                                    </GridItem>
                                                </GridContainer>
                                            </Container>
                                            <CardBody>
                                                <DynamicTable
                                                    sortable={false}
                                                    showPagination={false}
                                                    defaultPageSize={9999}
                                                    data={FormikProps.values.Vinculos}
                                                    style={{ margin: "-0.9375rem -20px", marginTop: "10px" }}
                                                    columns={[
                                                        {
                                                            Header: "Empresa",
                                                            accessor: "nomeEmpresa"
                                                        },
                                                        {
                                                            Header: "Cargo",
                                                            accessor: "cargo"
                                                        },
                                                        {
                                                            Header: "Opções",
                                                            acessor: "nomeLoja",
                                                            maxWidth: 120,
                                                            style: {
                                                                padding: 0,
                                                                display: 'flex',
                                                                justifyContent: 'center'
                                                            },
                                                            Cell: props => {
                                                                return <>
                                                                    <Button onClick={() => {
                                                                        let tmpEmpresas = FormikProps.values.Vinculos.filter((val, idx) => idx !== props.index);
                                                                        FormikProps.setFieldValue('Vinculos', tmpEmpresas);
                                                                    }} color="danger" link size="sm" round justIcon title="Remover"><Delete></Delete></Button>
                                                                </>
                                                            }
                                                        }
                                                    ]}
                                                />
                                            </CardBody>
                                        </Card>
                                    </GridItem>

                                    <GridItem xs={12}>
                                        <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                                            {!disabled && (
                                                <Button color="success" type="submit" disabled={FormikProps.isSubmitting}>
                                                    <Save />
                                                    {" "}
                                                    Salvar
                                                </Button>
                                            )}
                                        </div>
                                    </GridItem>
                                </>
                            }
                        </GridContainer>

                    </Form>
                )
                }
            />
        </Container >
    );
}

MembrosProfissionaisForm.propTypes = {
    id: PropTypes.any,
    form: PropTypes.any,
    formData: PropTypes.any
};