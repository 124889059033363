// Geral
import React, { useState, useRef, useEffect } from "react";
import { useRoutes, useRedirect, usePath } from "hookrouter";
import { useSelector } from "react-redux";
import Unauthorized from "views/403";
import NotFound from "views/404";
import Loading from "views/Loading";
import { Theme } from "assets/jss/doSul-dashboard";

// Scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// Componentes principais
import Navbar from "components/Navbars";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";

// Rotas
import routes from "routes.js";
// import Preferencias from "views/Preferencias";

// Estilos
import { Container } from "assets/jss/doSul-dashboard";
import { Content, Wrapper, MainPanel } from "./styles";

// Imagens
import logo from "assets/img/logo.png";

let ps;

const switchRoutes = (permissions = {}) => {
  const tmpRoutes = {
    // preferencias: () => <Preferencias />
  };
  routes.map((prop, key) => {
    if (prop.type && prop.type === "group") {
      return prop.childrens.map((innerProp, innerKey) => {
        let path = innerProp.path.replace(/\/:{1}.{0,}$/g, "");

        let currPath = "";
        let pathSplit = innerProp.path.split("/");
        for (let i = 0; i < pathSplit.length; i++) {
          currPath = i > 0 ? currPath + "/" + pathSplit[i] : pathSplit[i];

          let route =
            path === "home" || (permissions[path] && permissions[path] > 0) ? innerProp.component : permissions[path] ? () => <Unauthorized /> : () => <Loading />;
          tmpRoutes[currPath] = route;
          tmpRoutes[currPath + "/"] = route;
        }
        return null;
      });
    } else {
      let path = prop.path.replace(/\/:{1}.{0,}$/g, "");

      let currPath = "";
      let pathSplit = prop.path.split("/");
      for (let i = 0; i < pathSplit.length; i++) {
        currPath = i > 0 ? currPath + "/" + pathSplit[i] : pathSplit[i];

        let route = path === "home" || (permissions[path] && permissions[path] > 0) ? prop.component : permissions[path] ? () => <Unauthorized /> : () => <Loading />;
        tmpRoutes[currPath] = route;
        tmpRoutes[currPath + "/"] = route;
      }
      return null;
    }
  });

  return tmpRoutes;
};

export default function Dashboard({ classes, ...rest }) {
  const image = Theme.menuBackground;
  const path = usePath();

  useRedirect("/", "/home");

  const permissions = useSelector(state => state.permissions);

  const routeResult = useRoutes(switchRoutes(permissions));

  const [stimage] = useState(image);
  const [color] = useState("primary");
  const [mobileOpen, setMobileOpen] = useState(false);

  const mainPanel = useRef(null);

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
    }
    window.addEventListener("resize", resizeFunction);

    return () => {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, []);

  useEffect(() => {
    mainPanel.current.scrollTop = 0;
    if (mobileOpen) {
      setMobileOpen(false);
    }
    // eslint-disable-next-line
  }, [path]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (
    <Wrapper>
      <Sidebar routes={routes} logo={logo} image={stimage} handleDrawerToggle={handleDrawerToggle} open={mobileOpen} color={color} {...rest} />
      <MainPanel ref={mainPanel}>
        <>
          <Navbar routes={routes} handleDrawerToggle={handleDrawerToggle} {...rest} />
          <Content>
            <Container>{routeResult || <NotFound />}</Container>
          </Content>
          <Footer />
        </>
      </MainPanel>
    </Wrapper>
  );
}