/* Geral */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';

// Componentes Material
import Hidden from "@material-ui/core/Hidden";

// Componentes
import AdminNavbarLinks from "components/Navbars/NavbarLinks.jsx";

/* Estilo */
import { CustomList } from 'assets/jss/doSul-dashboard';
import { NavLinkItem, CustomListItem, CustomIcon, DynamicIcon, CustomListItemText, Logo, LogoImage, Img, Background, DrawerPaper, SidebarWrapper } from './styles';

class Sidebar extends Component {
    constructor() {
        super();

        this.state = {
            opened_id: null
        }
    }
    // Verifica se a rota está ativa
    activeRoute = (routeName) => {
        return window.location.href.indexOf(routeName) > -1 ? true : false;
    }

    render() {
        const { logo, image, routes } = this.props;
        var links = (
            <CustomList>
                {routes.map((prop, key) => {
                    if (prop.type && prop.type === 'group') {
                        let id = prop.id;
                        let opened_id = this.state.opened_id;
                        let permitidos = prop.childrens.filter((innerProp) => {
                            let path = innerProp.path.replace(/\/:{1}.{0,}$/g, '');
                            if (this.activeRoute(path) && id !== opened_id) {
                                opened_id = id
                            }
                            return ((this.props.permissions && this.props.permissions[path] > 0) || path === 'home')
                        });

                        if (permitidos.length === 0) return null;

                        let list = [<CustomListItem onClick={() => {
                            if (opened_id === id) {
                                this.setState({
                                    opened_id: null
                                })
                            } else {
                                this.setState({
                                    opened_id: id
                                })
                            }
                        }} key="-1" parentlist="true" active={(opened_id === id).toString()} button>
                            {typeof prop.icon === "string" ? (
                                <CustomIcon>
                                    {prop.icon}
                                </CustomIcon>
                            ) : (
                                    <DynamicIcon component={<prop.icon></prop.icon>} />
                                )}
                            <CustomListItemText
                                primary={prop.name}
                                disableTypography={true}
                            />
                        </CustomListItem>];
                        if (id === opened_id) {
                            prop.childrens.map((innerProp, innerKey) => {
                                let path = innerProp.path.replace(/\/:{1}.{0,}$/g, '');
                                if ((this.props.permissions && this.props.permissions[path] > 0) || path === 'home') {
                                    list.push(<NavLinkItem
                                        href={`${path}`}
                                        key={innerKey}
                                    ><CustomListItem sublist="true" active={this.activeRoute(path).toString()} button>
                                            {typeof innerProp.icon === "string" ? (
                                                <CustomIcon>
                                                    {innerProp.icon}
                                                </CustomIcon>
                                            ) : (
                                                    <DynamicIcon component={<innerProp.icon></innerProp.icon>} />
                                                )}
                                            <CustomListItemText
                                                primary={innerProp.name}
                                                disableTypography={true}
                                            />
                                        </CustomListItem>
                                    </NavLinkItem>);
                                }
                                return innerProp;
                            });
                        }
                        return list;
                    } else {
                        let path = prop.path.replace(/\/:{1}.{0,}$/g, '');
                        if ((this.props.permissions && this.props.permissions[path] > 0) || path === 'home') {
                            return (
                                <NavLinkItem
                                    href={`${path}`}
                                    key={key}
                                >
                                    <CustomListItem onClick={() => { this.setState({ opened_id: null }) }} active={this.activeRoute(path).toString()} button>
                                        {typeof prop.icon === "string" ? (
                                            <CustomIcon>
                                                {prop.icon}
                                            </CustomIcon>
                                        ) : (
                                                <DynamicIcon component={<prop.icon></prop.icon>} />
                                            )}
                                        <CustomListItemText
                                            primary={prop.name}
                                            disableTypography={true}
                                        />
                                    </CustomListItem>
                                </NavLinkItem>
                            );
                        } else {
                            return null;
                        }
                    }
                })}
                {(!this.props.permissions || Object.keys(this.props.permissions).length === 0) &&
                    <p style={{ color: 'white', textAlign: "center" }}><small>Carregando suas permissões ...</small></p>
                }
            </CustomList>
        );
        var brand = (
            <Logo>
                <LogoImage>
                    <Img src={logo} alt="Logo" />
                </LogoImage>
            </Logo>
        );
        return (
            <div>
                <Hidden mdUp implementation="css">
                    <DrawerPaper
                        variant="temporary"
                        anchor={"right"}
                        open={this.props.open}
                        onClose={this.props.handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}
                    >
                        {brand}
                        <SidebarWrapper>
                            <AdminNavbarLinks />
                            {links}
                        </SidebarWrapper>
                        {image !== undefined ? (
                            <Background
                                style={{ backgroundImage: "url(" + image + ")" }}
                            />
                        ) : null}
                    </DrawerPaper>
                </Hidden>
                <Hidden smDown implementation="css">
                    <DrawerPaper
                        anchor={"left"}
                        variant="permanent"
                        open
                    >
                        {brand}
                        <SidebarWrapper>{links}</SidebarWrapper>
                        {image !== undefined ? (
                            <Background
                                style={{ backgroundImage: "url(" + image + ")" }}
                            />
                        ) : null}
                    </DrawerPaper>
                </Hidden>
            </div>
        );
    }
};

Sidebar.propTypes = {
    handleDrawerToggle: PropTypes.func,
    logo: PropTypes.string,
    image: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    open: PropTypes.bool,

    permissions: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => ({
    permissions: store.permissions
});

export default connect(mapStateToProps)(Sidebar);
