import axios from "axios";
import React from "react";

import store from "helpers/store";
import { navigate } from "hookrouter";
import objToQueryString from "helpers/objToQueryString";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DEFAULT_TOAST_POSITION, DEFAULT_TOAST_TIMEOUT } from "helpers/consts";
import Button from "components/CustomButton";
import {
    defaultFetchList,
    defaultChangeStatus,
    defaultDelete,
    defaultSave,
    defaultFetch,
    defaultLoadOptions,
} from "./default";
import { setParentId } from "./list";

const model = "lojas";
const idField = "IdLoja";

const MySwal = withReactContent(Swal);

export const fetchList = (reqData, resolve, reject, dispatch) =>
    defaultFetchList(model, reqData, resolve, reject, dispatch);
export const fetch = (id, callback) => defaultFetch(model, id, callback);
export const changeStatus = (id, status, afterChange, afterError) =>
    defaultChangeStatus(model, id, status, afterChange, afterError);
export const deleteRecord = (id, afterDelete, afterError) =>
    defaultDelete(model, id, afterDelete, afterError);
export const save = (data, actions, afterSave, afterError) =>
    defaultSave(model, idField, data, false, actions, afterSave, afterError);

export const loadOptions = (inputValue) =>
    defaultLoadOptions(inputValue, model, ["IdLoja", "Nome"]);

export const openMembrosModal = (id = null) => {
    const state = store.getState();
    if (state.list.lojas.root && id) {
        store.dispatch(setParentId(btoa(id), "membroLoja"));
        navigate(`/${state.list.lojas.root}/membros/${btoa(id)}`);
    }
};

export const fetchListMembros = (reqData, resolve, reject, dispatch) => {
    const state = store.getState().list.membroLoja;

    const ignoreNulls = true;

    axios
        .get(
            `${model}/membros/${state.parentId}${objToQueryString(
                reqData,
                ignoreNulls
            )}`
        )
        .then((res) => {
            resolve(res.data);
        })
        .catch((err) => {
            let erro = { message: "Não foi possível obter os dados" };
            if (err.response) {
                erro = err.response.data;
            }
            reject(erro.message);
        });
};

export const addMembroLoja = (id, afterChange, afterError) => {
    const state = store.getState().list.membroLoja;

    axios
        .post(`${model}/membros/${state.parentId}/add`, {
            id: btoa(id),
        })
        .then((res) => {
            MySwal.fire({
                toast: true,
                position: DEFAULT_TOAST_POSITION,
                showConfirmButton: false,
                timer: DEFAULT_TOAST_TIMEOUT,
                type: "success",
                title: "Membro incluído com sucesso",
            });

            if (afterChange) afterChange();
        })
        .catch((err) => {
            if (err.response && err.response.data) {
                MySwal.fire({
                    type: "error",
                    title: `Não foi possível incluir o membro. ${err.response.data.message}`,
                    toast: true,
                    position: DEFAULT_TOAST_POSITION,
                    showConfirmButton: false,
                    timer: DEFAULT_TOAST_TIMEOUT,
                });
                if (afterError) afterError();
            }
        });
};

export const changeStatusMembros = (
    id,
    status = 0,
    afterChange,
    afterError
) => {
    const state = store.getState().list.membroLoja;

    axios
        .post(`${model}/membros/${state.parentId}/change-status/${btoa(id)}`, {
            status,
        })
        .then((res) => {
            MySwal.fire({
                toast: true,
                position: DEFAULT_TOAST_POSITION,
                showConfirmButton: false,
                timer: DEFAULT_TOAST_TIMEOUT,
                type: "success",
                title: "Status alterado com sucesso",
            });

            if (afterChange) afterChange();
        })
        .catch((err) => {
            if (err.response && err.response.data) {
                MySwal.fire({
                    type: "error",
                    title: `Não foi possível alterar o status. ${err.response.data.message}`,
                    toast: true,
                    position: DEFAULT_TOAST_POSITION,
                    showConfirmButton: false,
                    timer: DEFAULT_TOAST_TIMEOUT,
                });
                if (afterError) afterError();
            }
        });
};

export const deleteRecordMembros = (id, afterDelete, afterError) => {
    const state = store.getState().list.membroLoja;

    MySwal.fire({
        title: "",
        type: "warning",
        html:
            "Deseja realmente excluir o registro? <br>Essa ação não pode ser revertida!",
        showCancelButton: true,
        confirmButtonText: (
            <Button
                component="span"
                size="lg"
                color="danger"
                title="Sim, excluir"
            >
                Sim, excluir
            </Button>
        ),
        cancelButtonText: (
            <Button component="span" size="lg" color="white" title="Cancelar">
                Cancelar
            </Button>
        ),
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            axios
                .delete(`${model}/membros/${state.parentId}/${btoa(id)}`)
                .then((res) => {
                    MySwal.fire({
                        toast: true,
                        position: DEFAULT_TOAST_POSITION,
                        showConfirmButton: false,
                        timer: DEFAULT_TOAST_TIMEOUT,
                        type: "success",
                        title: "Registro excluído com sucesso",
                    });

                    if (afterDelete) afterDelete();
                })
                .catch((err) => {
                    if (err.response && err.response.data) {
                        MySwal.fire({
                            type: "error",
                            title: `Não foi possível excluir o registro. ${err.response.data.message}`,
                            toast: true,
                            position: DEFAULT_TOAST_POSITION,
                            showConfirmButton: false,
                            timer: DEFAULT_TOAST_TIMEOUT,
                        });
                        if (afterError) afterError();
                    }
                });
        }
    });
};
