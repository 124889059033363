import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import FormSelect from "components/FormSelect";

export default function CustomSelect ({ optionsList, form, estado, name }) {
   
    const selecionado = useMemo(() => {
        let sel = optionsList.estados.filter((val) => 
            (val.IdEstado === estado || val.IdEstado === form.values.Estado)
            || (val.Nome === estado || val.Nome === form.values.EstadoNome)
        );

		if (sel && sel.length > 0) return sel[0];

		return null;
	}, [estado]);

    const loadOptions = useCallback((search) => {
        return new Promise((resolve, reject) => {
            const filteredStates = optionsList.estados.filter((data) => {
                if (data.Nome === undefined) {
                    return false;
                }

                if (!search) return true;

                return new RegExp(search, 'gi').test(data.Nome) || new RegExp(search, 'gi').test(`${data.IdEstado}${data.Nome}`);
            })
            resolve(filteredStates);
        });
    }, []);

    async function setFieldValues(value) {
        (value) ? resetaCampos(value.IdEstado, `${value.Nome}` || '') : resetaCampos(null, null);
    }

    function resetaCampos(field1, field2) {
        form.setFieldValue(`${name}`, field2);
        form.setFieldValue(`estado`, field1)
        form.setFieldValue(`Estado`, field1)
        form.setFieldValue(`EstadoNome`, field2)
    }
        
    return (
        <FormSelect
            field={{
                name: `${name}Codigo`,
                placeholder: "Digite para localizar...",
                isSearchable: true,
                isClearable: false,
                defaultOptions: true,
                loadOptions,
                getOptionLabel: (option) => `${option.Nome}`,
                loadingMessage: () => "Carregando...",
                noOptionsMessage: (input) => input.inputValue === "" ? "Digite para localizar" : "Nenhum resultado encontrado",
                value: selecionado,
                onChange: (value) => setFieldValues(value),
            }}
            form={form}
            async
            label="Estado *"
        />
    );
}


CustomSelect.propTypes = {
	estado: PropTypes.string,
	form: PropTypes.object.isRequired,
};

CustomSelect.defaultProps = {
	estado: null,
	name: "EstadoForm",
};
