import React, { useEffect, useState } from "react";
import FormInput from "components/FormInput";
import CustomSelect from "./select";
import axios from "axios";

export default function EstadoForm({ estado, pais, nacionalidade, form }) {
    
    const [options, setOptions] = useState(null);

    useEffect(() => {
        if (pais > 0) {
            async function fetchEstados() {
                let response = await axios.get('estados', {params: {pais} });
                
                (response.status === 200 && (pais === 1058 || pais === 840))
                    ? setOptions({ estados: response.data })
                    : setOptions(null);
            }
            
            fetchEstados()
        } else {
            setOptions(null);
        }
      }, [pais]);


	return (
        <>
            {
                options ? (
                    <CustomSelect
                        optionsList={options}
                        form={form}
                        estado={estado}
                    />
                ) : (
                    <FormInput
                        field={{ name: "Estado" }}
                        form={form}
                        label="Estado *"
                    />
                )
            }
        </>
	);
}