import React from "react";
import PropTypes from "prop-types";
import ImagesUploader from "@dosul/ds-react-images-uploader";
import "@dosul/ds-react-images-uploader/styles.css";
import "@dosul/ds-react-images-uploader/font.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { JWT_TOKEN, DEFAULT_TOAST_POSITION, DEFAULT_TOAST_TIMEOUT } from "helpers/consts";
import { Container, AlertSize } from "./styles";

import axios from "axios";

import { Theme } from "assets/jss/doSul-dashboard";

const MySwal = withReactContent(Swal);

export default function ImageUpload({ url = axios.defaults.baseURL + `media/`, afterLoad = null, multiple = false, ...props }) {
    return (
        <Container>
            <AlertSize>Tamanho maximo de upload: 3MB</AlertSize>
        <ImagesUploader
            {...props}
            url={url}
            headers={{
                Authorization: 'Bearer ' + localStorage.getItem(JWT_TOKEN)
            }}
            multiple={multiple}
            label={props.label ? props.label : multiple ? "Selecione as imagens" : "Selecione uma imagem"}
            max={10}
            color={Theme.grayColor[3]}
            borderColor={Theme.primaryColor[0]}
            disabledColor={Theme.grayColor[0]}
            disabledBorderColor={Theme.grayColor[0]}
            onLoadEnd={(err, response) => {
                if (err && err.message) {
                    MySwal.fire({
                        type: "error",
                        title: "Não foi possível fazer o upload. " + err.message,
                        toast: true,
                        position: DEFAULT_TOAST_POSITION,
                        showConfirmButton: false,
                        timer: DEFAULT_TOAST_TIMEOUT
                    });
                } else {
                    if (afterLoad)
                        afterLoad(response);
                }
            }}
            styles={{
                label: {
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    textAlign: 'center'
                },
                container: {
                    textAlign: multiple ? 'left' : 'center'
                }
            }}
        />
        </Container>
    );
}

ImageUpload.propTypes = {
    multiple: PropTypes.bool,
    optimisticPreviews: PropTypes.bool,
    url: PropTypes.string,
    dataName: PropTypes.string,
    label: PropTypes.string,
    afterLoad: PropTypes.func
};
