import React from 'react'
import { useTranslation } from 'react-i18next';

import { BrasilFlag, EuaFlag } from 'assets/img/flags/index'
import Flag from './flag'
import './i18n.css';

const FlagsContainer = () => {
    const { i18n } = useTranslation();

    function handleChangeLanguage(language) {
        i18n.changeLanguage(language)
        window.location.reload()
    }

    const selectedLanguage = i18n.language
    
    return (
        <div className="flags-container">
            <Flag
                image={BrasilFlag}
                isSelected={selectedLanguage === 'pt'}
                onClick={() => handleChangeLanguage('pt')}
            />
            <Flag
                image={EuaFlag}
                isSelected={selectedLanguage === 'en'}
                onClick={() => handleChangeLanguage('en')}
            />
        </div>
    )
}

export default FlagsContainer