import React from "react";
import PropTypes from "prop-types";

// Estilo
import { CustomCardHeader } from "./styles"

function CardHeader({ ...props }) {
  const {
    children,
    ...rest
  } = props;
  return (
    <CustomCardHeader {...rest}>
      {children}
    </CustomCardHeader>
  );
}

CardHeader.propTypes = {
  plain: PropTypes.bool,
  stats: PropTypes.bool,
  icon: PropTypes.bool,
  children: PropTypes.node
};

export default CardHeader;
