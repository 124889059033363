import React, { Component } from 'react'
import MaskedInput from 'react-text-mask'
import CepValidation from "helpers/validation/Cep";

export default class Cep extends Component {
    constructor() {
        super();

        let anyNum = /[0-9]/;
        this.state = {
            value: null,
            mask: [anyNum, anyNum, anyNum, anyNum, anyNum, '-', anyNum, anyNum, anyNum],
            lastValue: null
        }
    }

    render() {
        let props = {}
        Object.keys(this.props).map((idx) => { // Não pode-se colocar funções customizadas
            if (idx !== 'inputRef' && idx !== 'callbackCep')
                props[idx] = this.props[idx]

            return null
        })
        return (
            <MaskedInput guide={true} mask={this.state.mask} {...props} onKeyUp={() => {
                if (this.props.callbackCep && props.value && this.state.lastValue !== props.value && CepValidation(props.value)) {
                    this.setState({ lastValue: props.value });
                    this.props.callbackCep(props.value);
                }
            }} />
        )
    }
}