import types from 'types/auth'
import isEmpty from 'helpers/validation/isEmpty';

const initialState = {
    isAuthenticated: false,
    user: {
        nome: '',
        foto: '',
    }
}

export default function(state = initialState, action ) {
    switch(action.type) {
        case types.SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            }
        default: 
            return state;
    }
}