import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    Nome: Yup.string().required("Por favor digite o Nome"),
    Tipo: Yup.string().required("Por favor selecione o Tipo"),
    Telefone: Yup.string().required("Por favor digite o Telefone"),
    Cim: Yup.string().when("Tipo", (Tipo, field) => (Tipo === "I" ? field.required("Por favor digite o Cim") : field)),
    Grau: Yup.string().when("Tipo", (Tipo, field) => (Tipo === "I" ? field.required("Por favor digite o Grau") : field)),
    loja: Yup.string().when("Tipo", (Tipo, field) => (Tipo === "I" ? field.required("Por favor selecione a Loja") : field.nullable())),
    responsavel: Yup.string().when("Tipo", (Tipo, field) => (Tipo !== "I" && Tipo !== "E" ? field.required("Por favor selecione o Responsável") : field)),
    Email: Yup.string().required("Por favor digite o e-mail").email("Por favor digite um e-mail válido"),
    Senha: Yup.string().when("IdMembro", (IdMembro, field) => (IdMembro ? field : field.required("Por favor digite a senha"))),
    ConfirmaSenha: Yup.string().oneOf([Yup.ref("Senha"), null, ""], "Por favor digite a confirmação de senha")
});

export default validationSchema;
