/* eslint-disable indent */
/* eslint-disable no-multi-spaces */
/* eslint-disable radix */
/* eslint-disable no-cond-assign */
/* eslint-disable no-param-reassign */
import types from "types/list";
import {
  requestData,
  onPageChange,
  onPageSizeChange,
  onSortedChange,
  openEditModal
} from "actions/list";

const initialTableProps = {
  edit: openEditModal,
  style: { margin: "-0.9375rem -20px", marginTop: "10px" },
  onPageChange,
  onPageSizeChange,
  onSortedChange,
  onFetchData: (state, instance) => requestData(),
  page: false,
  pageSize: false,
  sorted: [],
  idField: null,
  loading: true,
  data: [],
  pages: 1,
};

const initialState = {
  root: null,
  parentId: null,
  listFetch: null,
  delete: null,
  idField: null,
  changeStatus: null,
  loading: true,
  results: [],
  total: 0,
  pg: false,
  pgSize: false,
  o: false,
  s: false,
  t: false,
  sorted: [],
  extraFilter: {},
  paginacao: {
    total: 0,
    paginas: 1,
    pg: 1
  },
  tableProps: { ...initialTableProps }
};

export default function (state = {}, action) {
  if (action.view === undefined) return state;

  if (state[action.view] === undefined) state[action.view] = initialState;
  if (state[action.view].root) {
    if (state[action.view].pg === false) {
      state[action.view].pg = parseInt(localStorage.getItem(`pg_${state[action.view].root}`)) || 0;
    }
    if (state[action.view].pgSize === false) {
      state[action.view].pgSize = parseInt(localStorage.getItem(`pgSize_${state[action.view].root}`))
        || 10;
    }
    if (state[action.view].o === false) {
      state[action.view].o = localStorage.getItem(`o_${state[action.view].root}`) || null;
    }
    if (state[action.view].s === false) {
      state[action.view].s = localStorage.getItem(`s_${state[action.view].root}`) || "";
    }
    if (state[action.view].t === false) {
      state[action.view].t = localStorage.getItem(`t_${state[action.view].root}`) || null;
    }

    if (state[action.view].tableProps.page === false) {
      state[action.view].tableProps.page = parseInt(localStorage.getItem(`pg_${state[action.view].root}`)) || 0;
    }
    if (state[action.view].tableProps.pageSize === false) {
      state[action.view].tableProps.pageSize = parseInt(localStorage.getItem(`pgSize_${state[action.view].root}`))
        || 10;
    }
    if (state[action.view].tableProps.sorted.length === 0) {
      const o = localStorage.getItem(`o_${state[action.view].root}`) || null;
      const t = localStorage.getItem(`t_${state[action.view].root}`) || null;
      if (o && t) {
        state[action.view].tableProps.sorted = [
          {
            id: o,
            desc: t === "desc"
          }
        ];
      }
    }
  }
  switch (action.type) {
    case types.SET_LIST_ROOT:
      return {
        ...state,
        [action.view]: { ...state[action.view], root: action.payload }
      };
    case types.SET_PARENT_ID:
      return {
        ...state,
        [action.view]: { ...state[action.view], parentId: action.payload }
      };
    case types.SET_LIST_EXTRA_FILTER:
      return {
        ...state,
        [action.view]: { ...state[action.view], extraFilter: action.payload }
      };
    case types.SET_LIST_CHANGE_STATUS:
      return {
        ...state,
        [action.view]: { ...state[action.view], changeStatus: action.payload }
      };
    case types.SET_LIST_FETCH:
      return {
        ...state,
        [action.view]: { ...state[action.view], listFetch: action.payload }
      };
    case types.SET_LIST_DELETE:
      return {
        ...state,
        [action.view]: { ...state[action.view], delete: action.payload }
      };
    case types.SET_LIST_IDFIELD:
      return {
        ...state,
        [action.view]: {
          ...state[action.view],
          idField: action.payload,
          tableProps: {
            ...state[action.view].tableProps,
            idField: action.payload
          }
        }
      };
    case types.SET_LIST_LOADING:
      return {
        ...state,
        [action.view]: {
          ...state[action.view],
          loading: action.payload,
          tableProps: {
            ...state[action.view].tableProps,
            loading: action.payload
          }
        }
      };
    case types.SET_LIST_RESULTS:
      return {
        ...state,
        [action.view]: {
          ...state[action.view],
          results: action.payload,
          tableProps: { ...state[action.view].tableProps, data: action.payload }
        }
      };
    case types.SET_LIST_TOTAL:
      return {
        ...state,
        [action.view]: { ...state[action.view], total: action.payload }
      };
    case types.SET_LIST_PG:
      return {
        ...state,
        [action.view]: {
          ...state[action.view],
          pg: action.payload,
          tableProps: { ...state[action.view].tableProps, page: action.payload }
        }
      };
    case types.SET_LIST_PGSIZE:
      return {
        ...state,
        [action.view]: {
          ...state[action.view],
          pgSize: action.payload,
          tableProps: {
            ...state[action.view].tableProps,
            pageSize: action.payload
          }
        }
      };
    case types.SET_LIST_O:
      return {
        ...state,
        [action.view]: { ...state[action.view], o: action.payload }
      };
    case types.SET_LIST_S:
      return {
        ...state,
        [action.view]: { ...state[action.view], s: action.payload }
      };
    case types.SET_LIST_T:
      return {
        ...state,
        [action.view]: { ...state[action.view], t: action.payload }
      };
    case types.SET_LIST_SORTED:
      return {
        ...state,
        [action.view]: {
          ...state[action.view],
          sorted: action.payload,
          tableProps: {
            ...state[action.view].tableProps,
            sorted: action.payload
          }
        }
      };
    case types.SET_LIST_PAGINACAO:
      return {
        ...state,
        [action.view]: {
          ...state[action.view],
          paginacao: action.payload,
          tableProps: {
            ...state[action.view].tableProps,
            pages: action.payload.paginas
          }
        }
      };
    default:
      return state;
  }
}
