import styled from 'styled-components';

import AppBar from "@material-ui/core/AppBar";
import Button from "components/CustomButton";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from "@material-ui/core/MenuItem";
import Poppers from "@material-ui/core/Popper";

import { Theme, primaryBoxShadow } from 'assets/jss/doSul-dashboard';

export const CustomAppBar = styled(AppBar)`
    background-color: transparent!important
    box-shadow: none!important
    border-bottom: 0!important
    margin-bottom: 0!important
    position: absolute!important
    width: 100%!important
    padding-top: 10px!important
    z-index: 1029!important
    color: ${Theme.grayColor[7]}!important
    border: 0!important
    border-radius: 3px!important
    padding: 10px 0!important
    transition: all 150ms ease 0s!important
    min-height: 50px!important
    display: block!important
`

export const CustomContainer = styled(Toolbar)`
    padding-right: 15px
    padding-left: 15px
    margin-left: auto
    margin-right: auto
    min-height: 50px
`

export const Flex = styled.div`
    flex: 1
`

export const Title = styled(Button)`
    letter-spacing: unset!important
    line-height: 30px!important
    font-size: 18px!important
    border-radius: 3px!important
    text-transform: none!important
    color: inherit!important
    font-weight: 500
    margin: 0!important
    &:hover, &:focus {
        background: transparent!important
    }
`

export const Manager = styled.div`
    @media (max-width: ${Theme.breakpointMd}) {
        width: 100%
    }
    display: inline-block
`

export const ButtonLink = styled(Button)`
    @media (max-width: ${Theme.breakpointLg}) {
        display: flex
        margin-left: 30px
        width: auto
    }
    @media (max-width: ${Theme.breakpointMd}) {
        display: flex!important
        margin: 10px 15px 0!important
        width: -webkit-fill-available!important

        & img {
            height: 30px!important
            margin-right: 15px!important
            margin-left: -15px!important
        }
        & svg {
            width: 24px!important
            height: 30px!important
            margin-right: 15px!important
            margin-left: -15px!important
        }
        & .fab,& .fas,& .far,& .fal,& .material-icons {
            font-size: 24px!important
            line-height: 30px!important
            width: 24px!important
            height: 50px!important
        }
        & > span {
            justify-content: flex-start!important
            width: 100%!important
        }
    }
`

export const NotificationsAmount = styled.span`
    z-index: 4
    @media (min-width: ${Theme.breakpointMd}) {
        position: absolute
        top: 2px
        border: 1px solid ${Theme.whiteColor};
        right: 4px
        font-size: 9px
        font-weight: 600;
        background: ${Theme.dangerColor[0]};
        color: ${Theme.whiteColor};
        min-width: 16px
        height: 16px
        border-radius: 10px
        text-align: center
        line-height: 16px
        vertical-align: middle
        display: block
    }
    @media (max-width: ${Theme.breakpointMd}) {
        font-size: 14px
        margin-right: 8px
    }
`

export const LinkText = styled.p`
    z-index: 4
    font-size: 14px
    margin: 0px
`

export const DropdownItem = styled(MenuItem)`
    font-size: 13px!important
    padding: 10px 20px!important
    margin: 0 5px!important
    border-radius: 2px!important
    -webkit-transition: all 150ms linear!important
    -moz-transition: all 150ms linear!important
    -o-transition: all 150ms linear!important
    -ms-transition: all 150ms linear!important
    transition: all 150ms linear!important
    display: block!important
    clear: both!important
    font-weight: 400!important
    line-height: 1.42857143!important
    color: ${Theme.grayColor[8]}!important
    white-space: nowrap!important
    height: unset!important
    min-height: unset!important
    &:hover {
        background-color: ${Theme.primaryColor[0]}!important
        color: ${Theme.whiteColor}!important
        box-shadow: ${primaryBoxShadow}!important
    }
`

export const PopperNav = styled(Poppers)`
    pointer-events: ${(props) => props.open ? "unset" : "none"};

    @media (max-width: ${Theme.breakpointMd}) {
        position: static !important
        left: unset !important
        top: unset !important
        transform: none !important
        will-change: unset !important
        width: 90%;
        transform: translateX(5%)!important;
        & > div: {
          box-shadow: none !important
          margin-left: 0rem
          margin-right: 0rem
          transition: none !important
          margin-top: 0px !important
          margin-bottom: 0px !important
          padding: 0px !important
          background-color: transparent !important
          & ul li: {
            color: ${Theme.whiteColor} +  !important
            margin: 10px 15px 0!important
            padding: 10px 15px !important
            &:hover: {
              background-color: hsla(0,0%,78%,.2)
              boxShadow: none
            }
          }
        }
      }
`