// Geral
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

// Ações
import * as list from "actions/list";
import { fetchListCurtidores } from "actions/publicacoes";

// Componentes
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card";
import CardBody from "components/Card/CardBody";
import DynamicTable from "components/DynamicTable";
import { Container } from "assets/jss/doSul-dashboard";

const view = "curtidaPublicacao";

export default function PublicacaoListCurtidas({ id }) {
    useEffect(() => {
        list.start(view, "IdPublicacao", fetchListCurtidores, () => { }, () => { }, id);
    }, [id]);

    const listData = useSelector(state => state.list[view]);

    return (
        <Container>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            {listData && (
                                <>
                                    <DynamicTable
                                        {...listData.tableProps}
                                        columns={[
                                            {
                                                Header: " ",
                                                accessor: "mFoto",
                                                maxWidth: 80,
                                                Cell: props => props.original.mFoto ? <img
                                                    src={props.original.mFoto}
                                                    alt={props.original.mNome}
                                                    title={props.original.mNome}
                                                    style={{ display: 'block', maxWidth: '100%' }} /> : null
                                            },
                                            {
                                                Header: "Membro",
                                                accessor: "mNome"
                                            },
                                            {
                                                Header: "Data/Hora",
                                                accessor: "DataHoraDisplay"
                                            }
                                        ]}
                                        clickable={false}
                                        view={view}
                                        onFetchData={(state, instance) => list.requestData(view)}
                                        onPageSizeChange={(pageSize, pageIndex) => list.onPageSizeChange(pageSize, pageIndex, view)}
                                        onPageChange={(pageIndex) => list.onPageChange(pageIndex, view)}
                                        onSortedChange={(newSorted, column, shiftKey) => list.onSortedChange(newSorted, column, shiftKey, view)}
                                    />

                                </>
                            )}
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </Container>
    );
}
