const types = {
    SET_VIEW: "SET_VIEW",
    CLEAR_LIST: "CLEAR_LIST",
    SET_PARENT_ID: "SET_PARENT_ID",
    SET_LIST_ROOT: "SET_LIST_ROOT",
    SET_LIST_CHANGE_STATUS: "SET_LIST_CHANGE_STATUS",
    SET_LIST_FETCH: "SET_LIST_FETCH",
    SET_LIST_DELETE: "SET_LIST_DELETE",
    SET_LIST_IDFIELD: "SET_LIST_IDFIELD",
    SET_LIST_LOADING: "SET_LIST_LOADING",
    SET_LIST_RESULTS: "SET_LIST_RESULTS",
    SET_LIST_TOTAL: "SET_LIST_TOTAL",
    SET_LIST_PG: "SET_LIST_PG",
    SET_LIST_PGSIZE: "SET_LIST_PGSIZE",
    SET_LIST_O: "SET_LIST_O",
    SET_LIST_S: "SET_LIST_S",
    SET_LIST_T: "SET_LIST_T",
    SET_LIST_SORTED: "SET_LIST_SORTED",
    SET_LIST_PAGINACAO: "SET_LIST_PAGINACAO",
    SET_LIST_EXTRA_FILTER: "SET_LIST_EXTRA_FILTER"
};

export default types;
