import styled from 'styled-components';
import { Theme } from 'assets/jss/doSul-dashboard';

let drawerWidth = 260

export const Wrapper = styled.div`
    position: relative
    top: 0
    height: 100vh
`

export const MainPanel = styled.div`
    overflow: auto;
    position: relative;
    float: right;
    transition: all 300ms linear!important;
    max-height: 100%;
    width: 100%;
    overflow-scrolling: touch;

    @media (min-width: ${Theme.breakpointMd}) {
        width: calc(100% - ${drawerWidth}px);
    }
`

export const Content = styled.div`
    margin-top: 70px
    padding: 30px 15px
    min-height: calc(100vh - 139px)
`