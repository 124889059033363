/* Geral / Bibliotecas */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import styled from "styled-components";
import ReactHtmlParser from 'react-html-parser';
import { getQueryParams } from "hookrouter";

/* Ícones */
import Done from "@material-ui/icons/Done";
import Block from "@material-ui/icons/Block";
import Delete from "@material-ui/icons/Delete";
import ChatBubble from '@material-ui/icons/ChatBubble';
import Favorite from '@material-ui/icons/Favorite';
import VisibilityIcon from '@material-ui/icons/Visibility';

/* Métodos / Ações */
import * as form from "actions/form";
import { fetch, save } from "actions/publicacoes";

/* Componentes */
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButton";
import { Container } from "assets/jss/doSul-dashboard";
import Loading from "components/Loading";
import RightPanel from "components/RightPanel";
import PublicacaoListCurtidas from "./listCurtidas";
import PublicacaoListComentarios from "./listComentarios";

/* Validação */
import validationSchema from "./validation";

/* Estilos */
const CustomList = styled(List)`
    li{ padding: 0px;}
`;

const Model = {
    IdPublicacao: null,
    membro: null,
    DataHora: "",
    Texto: "",
    Status: 1
};

export default function PublicacoesForm({
    id, changeStatus, deleteRecord, afterSave, afterChangeStatusSuccess, afterDeleteSuccess, action
}) {
    const queryParams = getQueryParams()

    const [modalCurtidasOpen, setModalCurtidasOpen] = useState(false);
    const [modalComentariosOpen, setModalComentariosOpen] = useState((queryParams && queryParams.denuncia) ? true : false);
    const formData = useSelector(state => state.form);
    const permissions = useSelector(state => state.permissions);

    const dispatch = useDispatch();

    const afterFetch = (data) => {
        const model = data.result;
        if (model.Membro) model.membro = btoa(model.Membro);
        // etc
        dispatch(form.setModel(model));
        dispatch(form.setLoading(false));
    }; // Sobrescrever se precisar

    useEffect(() => {
        form.start("publicacoes", "IdPublicacao", id, Model, fetch, save, changeStatus, deleteRecord, afterSave, afterChangeStatusSuccess, afterDeleteSuccess, afterFetch);
        return form.stop;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // É chamado toda vez que muda o ID
        form.setModel(Model);
        if (id) {
            form.fetchData(id);
        }
    }, [id]);

    const handleSubmit = (values, actions) => {
        formData.save(values, actions, formData.afterSave);
    };

    if (formData.loading) {
        return <Loading />;
    }

    const disabled = permissions && permissions[formData.root] < 2;

    return (
        <Container>
            <Formik
                validationSchema={validationSchema}
                initialValues={formData.model}
                onSubmit={handleSubmit}
                render={FormikProps => (
                    <Form onSubmit={FormikProps.handleSubmit}>
                        <GridContainer style={{ paddingTop: '20px' }}>
                            <GridItem xs={12} lg={4}>
                                <Button
                                    color="danger"
                                    type="button"
                                    right
                                    onClick={() => {
                                        setModalCurtidasOpen(true);
                                    }}
                                >
                                    {FormikProps.values.Curtidas}&nbsp;&nbsp;
                                    <Favorite />
                                    {" "}
                                    Curtidas
                                </Button>
                            </GridItem>

                            <GridItem xs={12} lg={4}>
                                <Button
                                    color="info"
                                    type="button"
                                    right
                                    onClick={() => {
                                        setModalComentariosOpen(true);
                                    }}
                                >
                                    {FormikProps.values.Comentarios}&nbsp;&nbsp;
                                    <ChatBubble />
                                    {" "}
                                    Comentários
                                </Button>
                            </GridItem>

                            <GridItem xs={12} lg={4}>
                                <Button
                                    color="white"
                                    type="button"
                                    right
                                    onClick={() => {
                                    }}
                                >
                                    {FormikProps.values.Visualizacoes}&nbsp;&nbsp;
                                    <VisibilityIcon />
                                    {" "}
                                    Visualizações
                                </Button>
                            </GridItem>

                            <GridItem xs={12} lg={12}>
                                <CustomList subheader={<li />}>
                                    <li>
                                        <ul>
                                            <ListSubheader>Responsável</ListSubheader>
                                            <ListItem>
                                                <ListItemText primary={<>{FormikProps.values.Responsavel}&nbsp;&nbsp;&nbsp;<small>({FormikProps.values.TipoDescricao})</small></>} />
                                            </ListItem>
                                            <Divider />
                                        </ul>
                                    </li>
                                </CustomList>
                            </GridItem>

                            <GridItem xs={12} lg={12}>
                                <CustomList subheader={<li />}>
                                    <li>
                                        <ul>
                                            <ListSubheader>Texto</ListSubheader>
                                            <ListItem>
                                                <ListItemText>{ReactHtmlParser(FormikProps.values.Texto)}</ListItemText>
                                            </ListItem>
                                            <Divider />
                                        </ul>
                                    </li>
                                </CustomList>
                            </GridItem>

                            <GridItem xs={12} lg={12}>
                                <CustomList subheader={<li />}>
                                    <li>
                                        <ul>
                                            <ListSubheader>Localização</ListSubheader>
                                            <ListItem>
                                                <ListItemText>{FormikProps.values.Localizacao ? FormikProps.values.Localizacao : 'Não informada'}</ListItemText>
                                            </ListItem>
                                            <Divider />
                                        </ul>
                                    </li>
                                </CustomList>
                            </GridItem>

                            <GridItem xs={12} lg={12}>
                                <CustomList subheader={<li />}>
                                    <li>
                                        <ul>
                                            <ListSubheader>Arquivos</ListSubheader>
                                            <ListItem>
                                                {FormikProps.values.Arquivos.length > 0 &&
                                                    FormikProps.values.Arquivos.map((val, idx) => {
                                                        // Pode-se verificar o val.mime quando tiver vídeos
                                                        return <img title="Imagem Publicação" alt="Imagem Publicação" style={{ maxWidth: '50%' }} key={idx} src={val.url} />;
                                                    })
                                                }
                                                {FormikProps.values.Arquivos.length === 0 &&
                                                    <span>Nenhum arquivo inserido</span>
                                                }
                                            </ListItem>
                                            <Divider />
                                        </ul>
                                    </li>
                                </CustomList>
                            </GridItem>

                            <GridItem xs={12}>
                                <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                                    {!disabled && formData.model[formData.idField] && (
                                        <Button color="danger" type="button" right onClick={form.handleDelete}>
                                            <Delete />
                                            {" "}
                                            Excluir
                                        </Button>
                                    )}
                                    {!disabled && formData.model[formData.idField] && formData.model.Status === 0 && (
                                        <Button
                                            color="info"
                                            type="button"
                                            right
                                            onClick={() => {
                                                form.handleChangeStatus(1);
                                            }}
                                        >
                                            <Done />
                                            {" "}
                                            Ativar
                                        </Button>
                                    )}
                                    {!disabled && formData.model[formData.idField] && formData.model.Status === 1 && (
                                        <Button
                                            color="warning"
                                            type="button"
                                            right
                                            onClick={() => {
                                                form.handleChangeStatus(0);
                                            }}
                                        >
                                            <Block />
                                            {" "}
                                            Bloquear
                                        </Button>
                                    )}
                                </div>
                            </GridItem>
                        </GridContainer>

                        <RightPanel onClose={() => { setModalCurtidasOpen(false); }} isPaneOpen={modalCurtidasOpen} title="Quem curtiu a publicação" path="curtidas">
                            <PublicacaoListCurtidas id={btoa(FormikProps.values.IdPublicacao)} />
                        </RightPanel>

                        <RightPanel onClose={() => { setModalComentariosOpen(false); }} isPaneOpen={modalComentariosOpen} title="Quem comentou na publicação" path="comentarios">
                            <PublicacaoListComentarios id={btoa(FormikProps.values.IdPublicacao)} />
                        </RightPanel>

                    </Form>
                )}
            />
        </Container>
    );
}

PublicacoesForm.propTypes = {
    id: PropTypes.any,
    afterSave: PropTypes.func,
    changeStatus: PropTypes.func,
    deleteRecord: PropTypes.func,
    afterChangeStatusSuccess: PropTypes.func,
    afterDeleteSuccess: PropTypes.func
};
