/* Geral / Bibliotecas */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";

import isValid from "date-fns/isValid";
import FNSformat from "date-fns/format";
import ptBRLocale from "date-fns/locale/pt-BR";

import { JWT_TOKEN } from "helpers/consts";
import jwt_decode from "jwt-decode";

/* Ícones */
import Save from "@material-ui/icons/Save";
import Done from "@material-ui/icons/Done";
import Block from "@material-ui/icons/Block";
import Delete from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";

/* Métodos / Ações */
import {
    savePessoal,
    loadOptionsIrmaos as loadOptionsMembros,
} from "actions/membros";

import { loadCidades } from "actions/cidadesNew";
import PaisForm from "components/CustomInput/Pais";
import EstadoForm from "components/CustomInput/Estado";
import { getCoordinates } from "actions/coordinates";

/* Componentes */
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import FormInput from "components/FormInput";
import Button from "components/CustomButton";
import FormSelect from "components/FormSelect";
import DatePicker from "components/DatePicker";
import { Container } from "assets/jss/doSul-dashboard";
import Loading from "components/Loading";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Card from "components/Card";
import ListHeader from "components/ListHeader";
import CardBody from "components/Card/CardBody";
import DynamicTable from "components/DynamicTable";
import PaisTelefone from "components/CustomInput/PaisTelefone";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

/* Validação */
import validationSchema from "./validationPessoal";

const Model = {
    IdMembro: null,
    Nome: "",
    Email: "",
    Senha: "",
    ConfirmaSenha: "",
    TelefonePais: "",
    TelefonePaisCodigo: "",
    Telefone: "",
    Telefone2Pais: "",
    Telefone2PaisCodigo: "",
    Telefone2: "",
    Tipo: "I",
    Status: 1,
    Sexo: "M",
    PermiteLocalizar: 0,
    DataNascimento: null,
    Site: "",
    Facebook: "",
    Instagram: "",
    Linkedin: "",
    // Endereco: "",
    // Numero: "",
    // Bairro: "",
    // Cep: "",
    // cidade: null,
    /**/
    Endereco: "",
    Numero: null,
    Complemento: "",
    Bairro: "",
    Cep: "",
    pais: "",
    Pais: "",
    PaisForm: "",
    PaisNome: "",
    estado: "",
    Estado: "",
    EstadoForm: "",
    EstadoNome: "",
    cidade: "",
    Cidade: "",
    CidadeForm: "",
    CidadeNome: "",
    /**/
    Complemento: "",
    Escolaridade: [],
};

export default function MembrosPessoalForm({
    id,
    form,
    formData,
    setDisplayDadosMaconicos,
}) {
    const [tmpInstituicao, setTmpInstituicao] = useState("");
    const [tmpCurso, setTmpCurso] = useState("");

    const permissions = useSelector((state) => state.permissions);
    const [labelCep, setLabelCep] = useState('CEP');

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(form.setAfterSave(() => {}));
        dispatch(form.setSave(savePessoal));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // É chamado toda vez que muda o ID
        form.setModel(Model);
        if (id) {
            form.fetchData(id);
        }
    }, [id, form]);

    const token = jwt_decode(localStorage.getItem(JWT_TOKEN));

    if (formData.loading) {
        return <Loading />;
    }

    const disabled = permissions && permissions[formData.root] < 2;

    const handleSubmit = (values, actions) => {
        if (values.Pais && values.pais === undefined) values.pais = values.Pais;
        if (values.Estado && values.estado === undefined) values.estado = values.Estado;
        if (values.Cidade && values.cidade === undefined) values.cidade = btoa(values.Cidade);
        if (typeof values.cidade === 'number') values.cidade = btoa(values.cidade);
        
        formData.save(values, actions, formData.afterSave);
    };

    /* Recupera as coordenadas por meio do endereco informado */
    const MySwal = withReactContent(Swal)
    async function fnGetCoordinates(props) {
        let { Endereco, Bairro, EstadoNome, CidadeNome, PaisNome } = props.values;
        
        if (Endereco.length > 0 && Bairro.length > 0 && EstadoNome.length > 0 && CidadeNome.length > 0 && PaisNome.length > 0) {
            MySwal.fire({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 1000,
                type: 'info',
                title: 'Aguarde um instante'
            })

            const response = await getCoordinates(props);
            const { lat, lng } = response.results[0].geometry.location;
    
            props.setFieldValue(`Latitude`, lat.toFixed(8));
            props.setFieldValue(`Longitude`, lng.toFixed(8));
        } else {
            MySwal.fire({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 3000,
                type: 'warning',
                title: 'Preencha o logradouro completo primeiro'
            })
        }
    }

    return (
        <Container>
            <Formik
                validationSchema={validationSchema}
                initialValues={formData.model}
                onSubmit={handleSubmit}
                render={(FormikProps) => (
                    <Form onSubmit={FormikProps.handleSubmit}>
                        <GridContainer>
                            <GridItem xs={12} lg={12}>
                                <FormInput
                                    field={{
                                        name: "Nome",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Nome *"
                                />
                            </GridItem>

                            {token.platform === "dashboard" && (
                                <GridItem xs={12} lg={6}>
                                    <FormSelect
                                        field={{
                                            isSearchable: false,
                                            name: "Tipo",
                                            disabled,
                                            options: getTipoOptions(token),
                                            value: getTipoValue(
                                                FormikProps.values.Tipo
                                            ),
                                            onChange: (value) => {
                                                FormikProps.setFieldValue(
                                                    "Tipo",
                                                    value.value
                                                );
                                                setDisplayDadosMaconicos(
                                                    value.value === "I"
                                                );
                                            },
                                        }}
                                        form={FormikProps}
                                        async={false}
                                        label="Tipo *"
                                    />
                                </GridItem>
                            )}

                            {FormikProps.values.Tipo !== "E" && (
                                <GridItem xs={12} lg={6}>
                                    <DatePicker
                                        field={{
                                            name: "DataNascimento",
                                            disabled,
                                        }}
                                        value={
                                            FormikProps.values.DataNascimento
                                                ? new Date(
                                                      FormikProps.values.DataNascimento
                                                  )
                                                : null
                                        }
                                        onChange={function (value) {
                                            FormikProps.setFieldValue(
                                                "DataNascimento",
                                                isValid(value)
                                                    ? FNSformat(
                                                          value,
                                                          "yyyy-MM-dd 00:00:00",
                                                          { locale: ptBRLocale }
                                                      )
                                                    : null
                                            );
                                        }}
                                        form={FormikProps}
                                        label="Data de Nascimento *"
                                    />
                                </GridItem>
                            )}

                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "Telefone",
                                        disabled,
                                        startAdornment: (
                                            <PaisTelefone
                                                pais={
                                                    FormikProps.values
                                                        .TelefonePais
                                                }
                                                codigo={
                                                    FormikProps.values
                                                        .TelefonePaisCodigo
                                                }
                                                form={FormikProps}
                                            />
                                        ),
                                    }}
                                    form={FormikProps}
                                    label="Telefone *"
                                />
                            </GridItem>
                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "Telefone2",
                                        disabled,
                                        startAdornment: (
                                            <PaisTelefone
                                                pais={
                                                    FormikProps.values
                                                        .Telefone2Pais
                                                }
                                                codigo={
                                                    FormikProps.values
                                                        .TelefonePais2Codigo
                                                }
                                                form={FormikProps}
                                                name="Telefone2"
                                            />
                                        ),
                                    }}
                                    form={FormikProps}
                                    label="Telefone 2 *"
                                />
                            </GridItem>

                            {FormikProps.values.Tipo === "S" && (
                                <GridItem xs={12} lg={12}>
                                    <FormSelect
                                        field={{
                                            isSearchable: false,
                                            name: "Sexo",
                                            disabled,
                                            options: getSexoOptions(),
                                            value: getSexoValue(
                                                FormikProps.values.Sexo
                                            ),
                                            onChange: (value) => {
                                                FormikProps.setFieldValue(
                                                    "Sexo",
                                                    value.value
                                                );
                                            },
                                        }}
                                        form={FormikProps}
                                        async={false}
                                        label="Sexo *"
                                    />
                                </GridItem>
                            )}

                            <GridItem xs={12} lg={12}>
                                <FormInput
                                    field={{
                                        name: "Site",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Site"
                                />
                            </GridItem>

                            <GridItem xs={12}>
                                <FormInput
                                    field={{
                                        name: "Facebook",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Facebook"
                                />
                            </GridItem>

                            <GridItem xs={12}>
                                <FormInput
                                    field={{
                                        name: "Instagram",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Instagram"
                                />
                            </GridItem>

                            <GridItem xs={12}>
                                <FormInput
                                    field={{
                                        name: "Linkedin",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Linkedin"
                                />
                            </GridItem>

                            {FormikProps.values.Tipo !== "E" && (
                                <GridItem xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    FormikProps.values
                                                        .PermiteLocalizar === 1
                                                }
                                                onChange={function (event) {
                                                    FormikProps.setFieldValue(
                                                        "PermiteLocalizar",
                                                        event.target.checked
                                                            ? 1
                                                            : 0
                                                    );
                                                }}
                                                value="1"
                                                color="primary"
                                            />
                                        }
                                        label="Permite Localizar"
                                    />
                                    <div>
                                        <small>
                                            Caso não permita ser Localizado,
                                            apenas o País, Estado e Cidade será
                                            mostrado no aplicativo
                                        </small>
                                    </div>
                                </GridItem>
                            )}

<GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "Cep",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label={labelCep}
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <PaisForm
                                    pais={FormikProps.values.PaisForm}
                                    form={FormikProps}
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <EstadoForm
                                    estado={FormikProps.values.EstadoForm}
                                    pais={FormikProps.values.Pais}
                                    form={FormikProps}
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                {
                                    (FormikProps.values.Pais === 1058 || FormikProps.values.Pais === 840) && +FormikProps.values.Estado > 0
                                    ? 
                                    <FormSelect
                                        field={{
                                            name: "cidade",
                                            placeholder: "Digite para localizar...",
                                            isSearchable: true,
                                            loadOptions: (cidade) => {
                                                return loadCidades({ cidade, estado: FormikProps.values.Estado, pais: FormikProps.values.Pais })
                                            },
                                            getOptionLabel: (option) =>
                                                `${option.Nome}${option.NomeEstado ? ` - ${option.NomeEstado}` : ""}`,
                                            loadingMessage: () => "Carregando...",
                                            noOptionsMessage: (input) =>
                                                input.inputValue === "" ? "Digite para localizar..." : "Nenhum resultado encontrado",
                                            disabled,
                                            value: FormikProps.values.cidade
                                                ? { IdCidade: FormikProps.values.cidade, Nome: FormikProps.values.CidadeNome }
                                                : null,
                                            onChange: (value) => {
                                                if (value) {
                                                    if (value.IdCidade) {
                                                        FormikProps.setFieldValue("cidade", btoa(value.IdCidade) ); 
                                                        FormikProps.setFieldValue("Cidade", btoa(value.IdCidade) ); 
                                                    }
                                                    FormikProps.setFieldValue("NomeCidade", value.Nome);
                                                    FormikProps.setFieldValue("CidadeNome", value.Nome);
                                                    FormikProps.setFieldValue("CidadeForm", value.Nome);
                                                } else {
                                                    FormikProps.setFieldValue("cidade", "");
                                                    FormikProps.setFieldValue("Cidade", "");
                                                    FormikProps.setFieldValue("NomeCidade", "");
                                                    FormikProps.setFieldValue("CidadeForm", "");
                                                }
                                            },
                                        }}
                                        form={FormikProps}
                                        async
                                        label="Cidade *"
                                    />
                                    :
                                    <FormInput
                                        field={{
                                            name: "CidadeNome",
                                            disabled,
                                        }}
                                        form={FormikProps}
                                        label="Cidade *"
                                    />
                                }
                            </GridItem>

                            <GridItem xs={12} lg={8}>
                                <FormInput
                                    field={{
                                        name: "Endereco",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Endereco *"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={4}>
                                <FormInput
                                    field={{
                                        name: "Numero",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Número"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "Complemento",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Complemento"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "Bairro",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Bairro *"
                                />
                            </GridItem>

                            {FormikProps.values.Tipo !== "I" &&
                                FormikProps.values.Tipo !== "E" &&
                                token.platform === "dashboard" && (
                                    <GridItem xs={12}>
                                        <FormSelect
                                            field={{
                                                name: "responsavel",
                                                placeholder:
                                                    "Digite para localizar...",
                                                isSearchable: true,
                                                defaultOptions: true,
                                                loadOptions: loadOptionsMembros,
                                                getOptionLabel: (option) =>
                                                    `${option.Nome}`,
                                                loadingMessage: () =>
                                                    "Carregando...",
                                                noOptionsMessage: (input) =>
                                                    input.inputValue === ""
                                                        ? "Digite para localizar..."
                                                        : "Nenhum resultado encontrado",
                                                disabled,
                                                value: FormikProps.values
                                                    .responsavel
                                                    ? {
                                                          IdMembro:
                                                              FormikProps.values
                                                                  .responsavel,
                                                          Nome:
                                                              FormikProps.values
                                                                  .nomeResponsavel,
                                                      }
                                                    : null,
                                                onChange: (value) => {
                                                    if (value) {
                                                        if (value.IdMembro) {
                                                            FormikProps.setFieldValue(
                                                                "responsavel",
                                                                btoa(
                                                                    value.IdMembro
                                                                )
                                                            );
                                                        }
                                                        FormikProps.setFieldValue(
                                                            "nomeResponsavel",
                                                            value.Nome
                                                        );
                                                    } else {
                                                        FormikProps.setFieldValue(
                                                            "responsavel",
                                                            null
                                                        );
                                                    }
                                                },
                                            }}
                                            form={FormikProps}
                                            async
                                            label="Responsável *"
                                        />
                                    </GridItem>
                                )}

                            <GridItem xs={12} lg={4}>
                                <FormInput
                                    field={{
                                        name: "Latitude",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Latitude"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={4}>
                                <FormInput
                                    field={{
                                        name: "Longitude",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Longitude"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={4}>
                                <Button
                                    color="info"
                                    type="button"
                                    left
                                    onClick={() => fnGetCoordinates(FormikProps)}
                                >
                                    Pegar coordenadas
                                </Button>
                            </GridItem>

                            <GridItem xs={12}>
                                <FormInput
                                    field={{
                                        name: "Email",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="E-mail *"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "Senha",
                                        type: "password",
                                        autoComplete: "new-password",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label={
                                        formData.model[formData.idField]
                                            ? "Senha"
                                            : "Senha *"
                                    }
                                />
                            </GridItem>

                            {FormikProps.values.senha !== "" && (
                                <GridItem xs={12} lg={6}>
                                    <FormInput
                                        field={{
                                            name: "ConfirmaSenha",
                                            type: "password",
                                            autoComplete: "new-password",
                                            disabled,
                                        }}
                                        form={FormikProps}
                                        label="Confirme a senha *"
                                    />
                                </GridItem>
                            )}

                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <ListHeader
                                        title="Escolaridade"
                                        add={false}
                                        legend={false}
                                        view={"membros"}
                                    />
                                    <Container style={{ width: "100%" }}>
                                        <GridContainer
                                            alignContent="space-between"
                                            alignItems="flex-end"
                                        >
                                            <GridItem xs>
                                                <FormInput
                                                    field={{
                                                        name: "Instituicao",
                                                        disabled,
                                                        value: tmpInstituicao,
                                                        onChange: (evt) => {
                                                            setTmpInstituicao(
                                                                evt.target.value
                                                            );
                                                        },
                                                    }}
                                                    form={FormikProps}
                                                    label="Instituicao"
                                                />
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer
                                            alignContent="space-between"
                                            alignItems="flex-end"
                                        >
                                            <GridItem xs>
                                                <FormInput
                                                    field={{
                                                        name: "Curso",
                                                        disabled,
                                                        value: tmpCurso,
                                                        onChange: (evt) => {
                                                            setTmpCurso(
                                                                evt.target.value
                                                            );
                                                        },
                                                    }}
                                                    form={FormikProps}
                                                    label="Curso"
                                                />
                                            </GridItem>
                                            <GridItem xs="auto">
                                                <Button
                                                    disabled={
                                                        !tmpInstituicao ||
                                                        !tmpCurso
                                                    }
                                                    color="success"
                                                    type="button"
                                                    onClick={() => {
                                                        let tmpEscolaridade =
                                                            FormikProps.values
                                                                .Escolaridade;
                                                        let Instituicao = tmpInstituicao;
                                                        let Curso = tmpCurso;
                                                        if (
                                                            tmpInstituicao &&
                                                            tmpCurso
                                                        ) {
                                                            tmpEscolaridade.push(
                                                                {
                                                                    Instituicao,
                                                                    Curso,
                                                                }
                                                            );
                                                            setTmpInstituicao(
                                                                ""
                                                            );
                                                            setTmpCurso("");
                                                        }
                                                        FormikProps.setFieldValue(
                                                            "Escolaridade",
                                                            tmpEscolaridade
                                                        );
                                                    }}
                                                >
                                                    <AddIcon />
                                                </Button>
                                            </GridItem>
                                        </GridContainer>
                                    </Container>
                                    <CardBody>
                                        <DynamicTable
                                            sortable={false}
                                            showPagination={false}
                                            defaultPageSize={9999}
                                            data={
                                                FormikProps.values.Escolaridade
                                            }
                                            style={{
                                                margin: "-0.9375rem -20px",
                                                marginTop: "10px",
                                            }}
                                            columns={[
                                                {
                                                    Header: "Instituicao",
                                                    accessor: "Instituicao",
                                                },
                                                {
                                                    Header: "Curso",
                                                    accessor: "Curso",
                                                },
                                                {
                                                    Header: "Opções",
                                                    acessor: "nomeLoja",
                                                    maxWidth: 120,
                                                    style: {
                                                        padding: 0,
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                    },
                                                    Cell: (props) => {
                                                        return (
                                                            <>
                                                                <Button
                                                                    onClick={() => {
                                                                        let tmpEscolaridade = FormikProps.values.Escolaridade.filter(
                                                                            (
                                                                                val,
                                                                                idx
                                                                            ) =>
                                                                                idx !==
                                                                                props.index
                                                                        );
                                                                        FormikProps.setFieldValue(
                                                                            "Escolaridade",
                                                                            tmpEscolaridade
                                                                        );
                                                                    }}
                                                                    color="danger"
                                                                    link
                                                                    size="sm"
                                                                    round
                                                                    justIcon
                                                                    title="Remover"
                                                                >
                                                                    <Delete></Delete>
                                                                </Button>
                                                            </>
                                                        );
                                                    },
                                                },
                                            ]}
                                        />
                                    </CardBody>
                                </Card>
                            </GridItem>

                            <GridItem xs={12}>
                                <div
                                    style={{
                                        paddingTop: "40px",
                                        paddingBottom: "40px",
                                    }}
                                >
                                    {!disabled && (
                                        <Button
                                            color="success"
                                            type="submit"
                                            disabled={FormikProps.isSubmitting}
                                        >
                                            <Save /> Salvar
                                        </Button>
                                    )}
                                    {!disabled &&
                                        formData.model[formData.idField] &&
                                        token.platform === "dashboard" && (
                                            <Button
                                                color="danger"
                                                type="button"
                                                right
                                                onClick={form.handleDelete}
                                            >
                                                <Delete /> Excluir
                                            </Button>
                                        )}
                                    {!disabled &&
                                        formData.model[formData.idField] &&
                                        formData.model.Status === 0 &&
                                        token.platform === "dashboard" && (
                                            <Button
                                                color="info"
                                                type="button"
                                                right
                                                onClick={() => {
                                                    form.handleChangeStatus(1);
                                                }}
                                            >
                                                <Done /> Ativar
                                            </Button>
                                        )}
                                    {!disabled &&
                                        formData.model[formData.idField] &&
                                        formData.model.Status === 1 &&
                                        token.platform === "dashboard" && (
                                            <Button
                                                color="warning"
                                                type="button"
                                                right
                                                onClick={() => {
                                                    form.handleChangeStatus(0);
                                                }}
                                            >
                                                <Block /> Bloquear
                                            </Button>
                                        )}
                                </div>
                            </GridItem>
                        </GridContainer>
                    </Form>
                )}
            />
        </Container>
    );
}

MembrosPessoalForm.propTypes = {
    id: PropTypes.any,
    form: PropTypes.any,
    formData: PropTypes.any,
};

const tipoOptions = [
    { value: "C", label: "Cunhada" },
    { value: "S", label: "Sobrinho(a)" },
];

const getTipoOptions = (token) => {
    if (token.platform === "dashboard" && tipoOptions.length < 3) {
        tipoOptions.push({ value: "I", label: "Irmão" });
    }
    if (token.platform === "dashboard" && tipoOptions.length < 4) {
        tipoOptions.push({ value: "E", label: "Empresa (Não Maçonico)" });
    }
    return tipoOptions;
};

const getTipoValue = (value = "") => {
    const index = tipoOptions.findIndex((opt) => opt.value === value);
    return tipoOptions[index] ? tipoOptions[index] : "";
};

const sexoOptions = [
    { value: "M", label: "Masculino" },
    { value: "F", label: "Feminino" },
];

const getSexoOptions = () => {
    return sexoOptions;
};

const getSexoValue = (value = "") => {
    const index = sexoOptions.findIndex((opt) => opt.value === value);
    return sexoOptions[index] ? sexoOptions[index] : "";
};
