/* eslint-disable max-len */
import {
    defaultFetchList,
    defaultChangeStatus,
    defaultDelete,
    defaultSave,
    defaultFetch
} from "./default";

const model = "agenda";
const idField = "IdAgenda";

export const fetchList = (reqData, resolve, reject, dispatch) => defaultFetchList(model, reqData, resolve, reject, dispatch);
export const fetch = (id, callback) => defaultFetch(model, id, callback);
export const changeStatus = (id, status, afterChange, afterError) => defaultChangeStatus(model, id, status, afterChange, afterError);
export const deleteRecord = (id, afterDelete, afterError) => defaultDelete(model, id, afterDelete, afterError);
export const save = (data, actions, afterSave, afterError) => defaultSave(model, idField, data, true, actions, afterSave, afterError);
