export const JWT_TOKEN = "b86a9358f7afdce8d08f5e886dd61e34";

export const URL_NEW = "new";
export const URL_EDIT = "edit";

export const DEFAULT_TOAST_POSITION = "bottom-end";
export const DEFAULT_TOAST_TIMEOUT = 3000;

export const PAYPAL_SANBOX_TOKEN = "ASYPnTb8SLCTKBsV8whqjQDKVjXLeZ-aiiIgmAGOOSOBMWgivooWnpZDmTknIH2CvRJlIoXZsMXICDek";
export const PAYPAL_LIVE_TOKEN = "Aa7YXVUUgNSsj4LmnZAOXjqTjWWFGwSagILWfghU_Sws--di6fIA7C03EdSpqwwrZ58CAY6hYXkwy-7A";
export const PAYPAL_PRODUCTION = false;

export const URL_VIEW_STATISTIC = "viewStatistic";