import types from "types/form";

const initialState = {
    id: null,
    root: null,
    idField: null,
    formFetch: null,
    afterFetch: null,
    save: null,
    afterSave: null,
    delete: null,
    afterDeleteSuccess: null,
    changeStatus: null,
    afterChangeStatusSuccess: null,
    loading: true,
    model: {}
};

export default function(state = initialState, action) {
    switch (action.type) {
        case types.CLEAR_FORM:
            return initialState;
        case types.SET_FORM_ID:
            return { ...state, id: action.payload };
        case types.SET_FORM_ROOT:
            return { ...state, root: action.payload };
        case types.SET_FORM_SAVE:
            return { ...state, save: action.payload };
        case types.SET_FORM_AFTER_SAVE:
            return { ...state, afterSave: action.payload };
        case types.SET_FORM_CHANGE_STATUS:
            return { ...state, changeStatus: action.payload };
        case types.SET_FORM_AFTER_CHANGE_STATUS_SUCCESS:
            return { ...state, afterChangeStatusSuccess: action.payload };
        case types.SET_FORM_FETCH:
            return { ...state, formFetch: action.payload };
        case types.SET_FORM_AFTER_FETCH:
            return { ...state, afterFetch: action.payload };
        case types.SET_FORM_DELETE:
            return { ...state, delete: action.payload };
        case types.SET_FORM_AFTER_DELETE_SUCCESS:
            return { ...state, afterDeleteSuccess: action.payload };
        case types.SET_FORM_IDFIELD:
            return { ...state, idField: action.payload };
        case types.SET_FORM_LOADING:
            return { ...state, loading: action.payload };
        case types.SET_FORM_MODEL:
            return { ...state, model: action.payload };
        default:
            return state;
    }
}
