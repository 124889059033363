import React from 'react'
import ChartistGraph from 'react-chartist'

// Componentes
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card";

import './graph.css';

const GenderGraph = ({ title, dataGraph }) => {

    let labels = [];
    let series = [];

    if(typeof dataGraph[0] !== undefined) {
        /* Recebe o primeiro valor do array informado */
        dataGraph = dataGraph[0];
    
        /* Recupera o parametro recebido */
        Object.keys(dataGraph).map((key, index) => {
            /* Exclui o label total e exclui os registros que são iguais a zero */
            if(key !== 'Total' && Number(dataGraph[key]) > 0) {
                labels.push(key)
                series.push(Number(dataGraph[key]))
            }

            return true;
        })    
    }

    var chartData = {
        labels,
        series
    }
    
    let chartOptions = {
        height: 150
    }

    /* Renderiza para o usuário uma mensagem de que não há resultados disponíveis para serem visualizados */
    let missing = (Number(dataGraph.Total) === 0) ? <GridItem lg={12}>Essa pesquisa retornou sem registros para o período informado</GridItem> : null;

    return (
        <>
            <GridContainer style={{ justifyContent: 'center', marginTop: '20px' }}>
                <h4>{ title }</h4>
            </GridContainer>
            <Card>
                <GridContainer style={{ textAlign: 'center' }}>
                {
                    dataGraph.Total > 0 && Object.keys(dataGraph).map((key, index) => 
                        (<GridItem key={index} xs={12} sm={4} md={4}>
                            {key} 
                            <h5>{dataGraph[key]}</h5>
                        </GridItem>)
                    )
                }
                { missing }
                </GridContainer>
                { 
                    dataGraph.Total > 0 && <ChartistGraph data={chartData} type={'Pie'} options={chartOptions} /> 
                }
            </Card>
        </>
    )
}

export default GenderGraph