import React from 'react'
import ChartistGraph from 'react-chartist'

// Componentes
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card";

import './graph.css';

const LocationGraph = ({ title, dataGraph }) => {

    let alive = 0;
    let labels = [];
    let series = [[], []];

    /* Recupera o parametro recebido */
    Object.keys(dataGraph).map((key, index) => {
        let masc = Number(dataGraph[key].masc);
        let fem = Number(dataGraph[key].fem);

        /* Exclui o label total e exclui os registros que são iguais a zero */
        if(masc > 0 || fem > 0) {
            let local = `${dataGraph[key].cidade} (${dataGraph[key].estado}${dataGraph[key].pais !== 'Brasil' ? ` ${dataGraph[key].pais} ` : ``})`
            labels.push(local);
            
            series[0].push({ meta: "", value: fem})
            series[1].push({ meta: "", value: masc})

            alive++
        }

        return true
    })
    
    var chartData = { labels, series }
    
    let chartOptions = {}

    if(alive > 0) {
        chartOptions = {  
            axisX: {
                onlyInteger: true, 
            },
            reverseData: true,
            horizontalBars: true,
            axisY: {
                offset: 200,
            },
            low: 1,
            referenceValue: 10,
            chartPadding: {
                top: 15,
                right: 75,
                bottom: 5,
                left: 0
            },
            seriesBarDistance: 0,
            plugins: [
                
            ]
        }
    }


    /* Renderiza para o usuário uma mensagem de que não há resultados disponíveis para serem visualizados */
    let missing = (alive === 0) ? <GridItem lg={12}>Essa pesquisa retornou sem registros para o período informado</GridItem> : null;

    return (
        <>
            <GridContainer style={{ justifyContent: 'center' }}>
                <h4>{ title }</h4>
                <GridItem className="legendas">
                    <section>
                        <div className="dot dot__masc"></div>
                        Masculino
                    </section>
                    <section>
                        <div className="dot dot__fem"></div>
                        Feminino
                    </section>
                </GridItem>
            </GridContainer>
            <Card>
                <GridContainer style={{ textAlign: 'center' }}>
                    { 
                        alive > 0 && <ChartistGraph className="ct-chart" data={chartData} type={'Bar'} options={chartOptions} style={{ marginBottom: '10px' }} /> 
                    }

                    { missing }
                </GridContainer>

                <GridContainer style={{ justifyContent: 'center' }}>
                    {
                        Object.keys(dataGraph).map((key, index) => {
                            let masc = Number(dataGraph[key].masc);
                            let fem = Number(dataGraph[key].fem);

                            let local = `${dataGraph[key].cidade} (${dataGraph[key].estado}${dataGraph[key].pais !== 'Brasil' ? ` ${dataGraph[key].pais} ` : ``})`

                            if(masc > 0 || fem > 0) {
                                return (
                                    <GridItem key={index} className="legendas_localidade">
                                        <section>{local}</section>
                                        <section>
                                            <div className="dot dot__masc"></div>
                                            {masc}
                                        </section>
                                        <section>
                                            <div className="dot dot__fem"></div>
                                            {fem}
                                        </section>
                                    </GridItem>
                                )
                            } else {
                                return ''
                            }
                        })
                    }
                </GridContainer>
            </Card>
        </>
    )
}

export default LocationGraph