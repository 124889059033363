/* eslint-disable indent */
import React from "react";
import ReactDOM from "react-dom";
import jwt_decode from "jwt-decode";
import { Provider } from "react-redux";
import { useRoutes, setBasepath } from "hookrouter";

// Helpers
import store from "helpers/store";
import setAuthToken from "helpers/setAuthToken";
import setAxiosDefaults from "helpers/setAxiosDefaults";
import refreshToken from "helpers/refresh";
import { setCurrentUser, logoutUser } from "actions/auth";
import { JWT_TOKEN } from "helpers/consts";

// Componentes
import Admin from "layouts/Admin/";
import Login from "views/Login";

/* Translations */
import './i18nextInit';

import "./assets/css/material-dashboard-react.css?v=1.7.0";

const BASE_URL = process.env.NODE_ENV === "development" ? "http://192.168.0.36:8080/" : "https://api.fraternalhug.com/"

setAxiosDefaults(BASE_URL);

const canView = refreshToken(
  () => {
    const token = localStorage.getItem(JWT_TOKEN);
    setAuthToken(token);
    const decoded = jwt_decode(token);
    store.dispatch(setCurrentUser(decoded));

    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      store.dispatch(logoutUser());
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
  },
  () => {
    if (window.location.pathname !== `${process.env.PUBLIC_URL}/login` && window.location.pathname !== `${process.env.PUBLIC_URL}/login/adm`) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
  }
);

// Define em variável caso precise interromper
window.refreshTokenInterval = setInterval(() => {
  refreshToken();
}, 120000);

function Main() {
  const routes = {};

  if (!canView) {
    routes["/login"] = () => <Login />;
    routes["/login/adm"] = () => <Login adm />;
  } else {
    routes["/*"] = () => <Admin />;
  }

  const routeResult = useRoutes(routes);
  return routeResult;
}

setBasepath("/mason-web/backoffice");

ReactDOM.render(
  <Provider store={store}>
    <Main />
  </Provider>,
  document.getElementById("root")
);
