import * as Yup from 'yup'; 

const validationSchema = Yup.object().shape({
    DataHoraInicio: Yup.date('Digite um data válida').required('Por favor digite a Data/Hora de Início'),
    DataHoraFim: Yup.date('Digite um data válida').required('Por favor digite a Data/Hora de Fim'),
    Titulo: Yup.string().required('Por favor digite o titulo'),
    Descricao: Yup.string().required('Por favor digite a Descrição'), 
    Endereco: Yup.string().required('Por favor digite o Endereco'), 
    Numero: Yup.string().required('Por favor digite o Numero'), 
    // Complemento: Yup.string().required('Por favor digite o Complemento'), 
    Bairro: Yup.string().required('Por favor digite o Bairro'),  
    // Cep: Yup.string().required('Por favor digite o CEP'),
    cidade: Yup.string().required('Por favor procure a Cidade'),
});

export default validationSchema;