import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import Grid from "@material-ui/core/Grid";

function GridContainer(props) {
  const { classes, style, children, ...rest } = props;
  return (
    <Grid container {...rest} style={{
        margin: "0 -15px",
        width: "unset",
        ...style
    }}>
      {children}
    </Grid>
  );
}

GridContainer.propTypes = {
  children: PropTypes.node
};

export default GridContainer;
