import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    Codigo: Yup.string().required("Por favor digite o código"),
    Titulo: Yup.string().required("Por favor digite o título"),
    DataInicio: Yup.date('Digite um data válida').required('Por favor digite a data de Início'),
    DataFinal: Yup.date('Digite um data válida').required('Por favor digite a data de Fim'),
    Desconto: Yup.number("Por favor digite um valor válido").transform((o, v) => parseFloat(v.replace(/,/g, ''))).required("Por favor digite o valor"),
    Quantidade: Yup.number("Por favor digite a quantidade").required("Por favor digite a quantidade")
});

export default validationSchema;
