import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import countries from "world-countries";
import FormSelect from "components/FormSelect";
import axios from "axios";

export default function PaisForm({ pais, form, name, setLabelCep = null}) {
    
    const selecionado = useMemo(() => {
        if (pais === null) {
            return null
        }
        
        let sel = countries.filter((val) => 
            val.cca2 === pais
            || val.cca3 === form.values.PaisForm
            || val.cca3 === pais
            || pais.includes(val.name.common)
            || pais.includes(val.name.official)
            // || (val.name.common === form.values.PaisNome && form.values.PaisNome !== undefined)
            || (val.altSpellings[1] === form.values.PaisNome && form.values.PaisNome !== undefined)
        );
        
		if (sel && sel.length > 0) { 
            if (setLabelCep != null) {
                setLabelCep(sel[0].name.common === 'Brazil' ? "CEP" : "Código Postal");
            }

            return sel[0];
        }

		return null;
	}, [pais]);

	const loadOptions = useCallback((search)=>{
		return new Promise((resolve, reject) => {
			const filteredCountries = countries.filter((country) => {
				if (country.idd.suffixes[0] === undefined) {
					return false;
				}

				if (!search) return true;

				return new RegExp(search, 'gi').test(country.name.common)
				 || new RegExp(search, 'gi').test(`${country.idd.root}${country.idd.suffixes[0]}`);
			})

			resolve(filteredCountries);
		});
	}, []);

    async function setFieldValues(value) {
        if (value) {
            form.setFieldValue(`${name}`, `${value.cca3}` || '')
            //vai mudar o  PaisForm BRA
            await getIdPais(value.name.common);
        } else {
            form.setFieldValue(`${name}`, null);
            resetaCampos(null, null);
        }
    }

    const getIdPais = async query => {
        const response = await axios.get('paises', {params: {value: query}});
        
        if (response.status === 200) {
            if (response.data !== null && response.data !== undefined && response.data !== '') {
                if (response.data.length === 1) {
                    resetaCampos(response.data[0].IdPais, response.data[0].Nome)
                } else {
                    if (query === 'United States') {
                        if (response.data[2].IdPais === 840) resetaCampos(response.data[2].IdPais, response.data[2].Nome)
                        else if (response.data[1].IdPais === 840) resetaCampos(response.data[1].IdPais, response.data[1].Nome)
                        else if (response.data[0].IdPais === 840) resetaCampos(response.data[0].IdPais, response.data[0].Nome)
                    }
                }
            } else {
                resetaCampos(null, null);
            }
        } else 
            resetaCampos(null, null)
    };

    function resetaCampos(field1, field2) {
        form.setFieldValue(`pais`, field1)
        form.setFieldValue(`Pais`, field1)
        form.setFieldValue(`PaisNome`, field2)
    }

	return (
		<FormSelect
			field={{
				name: `${name}Codigo`,
				placeholder: "Digite para localizar...",
				isSearchable: true,
				isClearable: false,
				defaultOptions: true,
				loadOptions,
				getOptionLabel: (option) => `${option.name.common}`,
				loadingMessage: () => "Carregando...",
				noOptionsMessage: (input) => input.inputValue === "" ? "Digite para localizar" : "Nenhum resultado encontrado",
				value: selecionado,
                onChange: (value) => setFieldValues(value),
			}}
			form={form}
			async
			label="País *"
		/>
	);
}

PaisForm.propTypes = {
	pais: PropTypes.string,
	form: PropTypes.object.isRequired,
};

PaisForm.defaultProps = {
	pais: null,
	name: "PaisForm",
};
