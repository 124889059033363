/* eslint-disable max-len */
/* Geral / Bibliotecas */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";

/* Ícones */
import Save from "@material-ui/icons/Save";
import Done from "@material-ui/icons/Done";
import Block from "@material-ui/icons/Block";
import Delete from "@material-ui/icons/Delete";

/* Métodos / Ações */
import * as form from "actions/form";
import { fetch, save } from "actions/ajudas";
import { defaultLoadOptions } from "actions/default";

/* Componentes */
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import FormInput from "components/FormInput";
import Button from "components/CustomButton";
import { Container } from "assets/jss/doSul-dashboard";
import Loading from "components/Loading";
import FormSelect from "components/FormSelect";

/* Validação */
import validationSchema from "./validation";

const loadIdiomasOptions = (inputValue) =>
    defaultLoadOptions(inputValue, "idiomas", ["IdIdioma", "Nome", "Sigla"]);

const Model = {
    IdAjuda: null,
    Titulo: "",
    Texto: "",
    Video: "",
    idioma: null,
    nomeIdioma: "",
    Status: 1,
};

export default function AjudasForm({
    id,
    changeStatus,
    deleteRecord,
    afterSave,
    afterChangeStatusSuccess,
    afterDeleteSuccess,
}) {
    const formData = useSelector((state) => state.form);
    const permissions = useSelector((state) => state.permissions);

    const afterFetch = null; // Sobrescrever se precisar

    useEffect(() => {
        form.start(
            "ajudas",
            "IdAjuda",
            id,
            Model,
            fetch,
            save,
            changeStatus,
            deleteRecord,
            afterSave,
            afterChangeStatusSuccess,
            afterDeleteSuccess,
            afterFetch
        );
        return form.stop;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // É chamado toda vez que muda o ID
        form.setModel(Model);
        if (id) {
            form.fetchData(id);
        }
    }, [id]);

    if (formData.loading) {
        return <Loading />;
    }

    const disabled = permissions && permissions[formData.root] < 2;

    return (
        <Container>
            <Formik
                validationSchema={validationSchema}
                initialValues={formData.model}
                onSubmit={form.handleSubmit}
                render={(FormikProps) => (
                    <Form onSubmit={FormikProps.handleSubmit}>
                        <GridContainer>
                            <GridItem xs={12}>
                                <FormSelect
                                    field={{
                                        name: "idioma",
                                        placeholder: "Digite para localizar...",
                                        isSearchable: true,
                                        defaultOptions: true,
                                        loadOptions: loadIdiomasOptions,
                                        getOptionLabel: (option) =>
                                            `${option.Nome}`,
                                        loadingMessage: () => "Carregando...",
                                        noOptionsMessage: (input) =>
                                            input.inputValue === ""
                                                ? "Digite para localizar..."
                                                : "Nenhum resultado encontrado",
                                        disabled,
                                        value: FormikProps.values.idioma
                                            ? {
                                                  IdIdioma:
                                                      FormikProps.values.idioma,
                                                  Nome:
                                                      FormikProps.values
                                                          .nomeIdioma,
                                              }
                                            : null,
                                        onChange: (value) => {
                                            if (value && value.IdIdioma) {
                                                FormikProps.setFieldValue(
                                                    "idioma",
                                                    btoa(value.IdIdioma)
                                                );
                                                FormikProps.setFieldValue(
                                                    "nomeIdioma",
                                                    value.Nome
                                                );
                                            } else {
                                                FormikProps.setFieldValue(
                                                    "idioma",
                                                    null
                                                );
                                            }
                                        },
                                    }}
                                    form={FormikProps}
                                    async
                                    label="Idioma *"
                                />
                            </GridItem>
                            <GridItem xs={12} lg={12}>
                                <FormInput
                                    field={{
                                        name: "Titulo",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Título *"
                                />
                            </GridItem>
                            <GridItem xs={12} lg={12}>
                                <FormInput
                                    field={{
                                        name: "Video",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Video *"
                                />
                            </GridItem>
                            <GridItem xs={12} lg={12}>
                                <FormInput
                                    field={{
                                        name: "Texto",
                                        disabled,
                                        multiline: true,
                                        rows: 5,
                                    }}
                                    form={FormikProps}
                                    label="Texto *"
                                />
                            </GridItem>

                            <GridItem xs={12}>
                                <div
                                    style={{
                                        paddingTop: "40px",
                                        paddingBottom: "40px",
                                    }}
                                >
                                    {!disabled && (
                                        <Button
                                            color="success"
                                            type="submit"
                                            disabled={FormikProps.isSubmitting}
                                        >
                                            <Save /> Salvar
                                        </Button>
                                    )}
                                    {!disabled &&
                                        formData.model[formData.idField] && (
                                            <Button
                                                color="danger"
                                                type="button"
                                                right
                                                onClick={form.handleDelete}
                                            >
                                                <Delete /> Excluir
                                            </Button>
                                        )}
                                    {!disabled &&
                                        formData.model[formData.idField] &&
                                        formData.model.Status === 0 && (
                                            <Button
                                                color="info"
                                                type="button"
                                                right
                                                onClick={() => {
                                                    form.handleChangeStatus(1);
                                                }}
                                            >
                                                <Done /> Ativar
                                            </Button>
                                        )}
                                    {!disabled &&
                                        formData.model[formData.idField] &&
                                        formData.model.Status === 1 && (
                                            <Button
                                                color="warning"
                                                type="button"
                                                right
                                                onClick={() => {
                                                    form.handleChangeStatus(0);
                                                }}
                                            >
                                                <Block /> Bloquear
                                            </Button>
                                        )}
                                </div>
                            </GridItem>
                        </GridContainer>
                    </Form>
                )}
            />
        </Container>
    );
}

AjudasForm.propTypes = {
    id: PropTypes.any,
    afterSave: PropTypes.func,
    changeStatus: PropTypes.func,
    deleteRecord: PropTypes.func,
    afterChangeStatusSuccess: PropTypes.func,
    afterDeleteSuccess: PropTypes.func,
};
