import React from "react";
import CurrencyInput from "react-currency-masked-input";

export default function Decimal(props) {
    const tmpProps = {};
    Object.keys(props).map((idx) => { // Não pode-se colocar funções customizadas
        if (idx !== "inputRef") { tmpProps[idx] = props[idx]; }

        if ((idx === "value" || idx === "defaultValue") && props[idx] && typeof props[idx] !== "string") { tmpProps[idx] = props[idx].toString(); }
        return null;
    });

    return (
        <CurrencyInput
            separator="."
            {...tmpProps}
            // pattern="[\d]{0,}\,[\d]{2}"
            pattern="[\d]{0,}[\d]{2}"
        />
    );
}
