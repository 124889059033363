import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Button from "components/CustomButton";
import { useSelector } from "react-redux";

// Ações
import * as l from "actions/list";

// Ícones
import Block from "@material-ui/icons/Block";
import Delete from "@material-ui/icons/Delete";
import Done from "@material-ui/icons/Done";
import Launch from "@material-ui/icons/Launch";
import HourglassEmpty from "@material-ui/icons/HourglassEmpty";

const MySwal = withReactContent(Swal);

function ListButtons(props) {
    const listData = useSelector((state) => state.list[props.view]);
    const permissions = useSelector((state) => state.permissions);

    const {
        cellProps,
        custom,
        disableRemove,
        disableEdit,
        justificativaBlock,
        statusLabelActive,
        statusLabelBlocked
    } = props;
    const edit = l.openEditModal;
    const list = l.requestData;
    const status = listData.changeStatus;
    const exclude = listData.delete;
    const idProp = listData.idField;
    let { root } = listData;
    if (props.root) {
        root = props.root;
    }

    if (permissions && permissions[root] > 1) {
        // Usuário privilegiado
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingRight: "10px",
                }}
            >
                {custom}
                {cellProps.original.Status === 1 && (
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            if (!props.dontAllowBlock) {
                                status(cellProps.original[idProp], 0, () => {
                                    if (list) {
                                        list(props.view);
                                    }
                                });
                            }
                        }}
                        color="success"
                        link
                        size="sm"
                        round
                        justIcon
                        title={ statusLabelActive || "Ativo"}
                    >
                        <Done />
                    </Button>
                )}
                {cellProps.original.Status === 0 && (
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            status(cellProps.original[idProp], 1, () => {
                                if (list) {
                                    list(props.view);
                                }
                            });
                        }}
                        color="danger"
                        link
                        size="sm"
                        round
                        justIcon
                        title={ statusLabelBlocked || "Bloqueado"}
                    >
                        <Block />
                    </Button>
                )}
                {cellProps.original.Status === -1 && (
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            MySwal.fire({
                                title: "",
                                type: "warning",
                                html: (
                                    <div>
                                        { cellProps.original.statusLoja === -1 && (
                                            <div style={{ backgroundColor: "red", color: "white", borderRadius: "20px", padding: "10px 10px", marginBottom: "20px" }}>
                                                <h4>Irmão sem Potência / Loja Vinculada</h4>
                                                <hr style={{ color: "white", margin: "15px 0" }} />
                                                <div>
                                                    <strong style={{ fontWeight: "bold" }}>Potência:</strong><br />
                                                    { cellProps.original.nomeLoja }
                                                    <br /><br />
                                                    <strong style={{ fontWeight: "bold" }}>Loja:</strong><br />
                                                    { cellProps.original.nomePotencia }
                                                </div>
                                            </div>
                                        )}
                                        <h4 style={{ textAlign: "center" }}>
                                            O que você deseja fazer com este
                                            registro pendente?
                                        </h4>
                                        <div style={{ width: "100%" }}>
                                            <Button
                                                color="info"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    status(
                                                        cellProps.original[idProp],
                                                        1,
                                                        () => {
                                                            if (list) {
                                                                list(
                                                                    props.view
                                                                );
                                                            }
                                                        },
                                                        null,
                                                        null,
                                                        cellProps.original.De ? cellProps.original.De : null
                                                    );
                                                }}
                                            >
                                                &nbsp;Aceitar / Ativar&nbsp;
                                            </Button>
                                            <Button
                                                color="warning"
                                                onClick={(e) => {
                                                    if (justificativaBlock) {
                                                        e.stopPropagation();
                                                        MySwal.fire({
                                                            title:
                                                                "Informe a justificativa",
                                                            type: "question",
                                                            input: "text",
                                                            showConfirmButton: true,
                                                            confirmButtonText: (
                                                                <Button
                                                                    component="span"
                                                                    size="lg"
                                                                    color="warning"
                                                                    title="Recusar / Bloquear"
                                                                >
                                                                    "Recusar /
                                                                    Bloquear"
                                                                </Button>
                                                            ),
                                                        }).then((result) => {
                                                            if (result.value) {
                                                                status(
                                                                    cellProps
                                                                        .original[
                                                                        idProp
                                                                    ],
                                                                    0,
                                                                    () => {
                                                                        if (
                                                                            list
                                                                        ) {
                                                                            list(
                                                                                props.view
                                                                            );
                                                                        }
                                                                    },
                                                                    null,
                                                                    result.value,
                                                                    cellProps.original.De ? cellProps.original.De : null
                                                                );
                                                            }
                                                        });
                                                    } else {
                                                        status(
                                                            cellProps.original[
                                                                idProp
                                                            ],
                                                            0,
                                                            () => {
                                                                if (list) {
                                                                    list(
                                                                        props.view
                                                                    );
                                                                }
                                                            },
                                                            null,
                                                            null,
                                                            cellProps.original.De ? cellProps.original.De : null
                                                        );
                                                    }
                                                }}
                                            >
                                                Recusar / Bloquear
                                            </Button>
                                        </div>
                                        <div style={{ width: "100%" }}>
                                            <Button
                                                link
                                                onClick={() => {
                                                    MySwal.close();
                                                }}
                                            >
                                                Nada, fechar
                                            </Button>
                                        </div>
                                    </div>
                                ),
                                showCancelButton: false,
                                showConfirmButton: false,
                            });
                        }}
                        color="warning"
                        link
                        size="sm"
                        round
                        justIcon
                        title="Aguardando Avaliação"
                    >
                        <HourglassEmpty />
                    </Button>
                )}

                {!disableRemove && (
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            exclude(cellProps.original[idProp], () => {
                                if (list) {
                                    list(props.view);
                                }
                            });
                        }}
                        color="danger"
                        link
                        size="sm"
                        round
                        justIcon
                        title="Remover"
                    >
                        <Delete />
                    </Button>
                )}

                {!disableEdit && (
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            edit(cellProps.original[idProp], props.view);
                        }}
                        color="primary"
                        link
                        size="sm"
                        round
                        justIcon
                        title="Ver / Editar"
                    >
                        <Launch />
                    </Button>
                )}
            </div>
        );
    } // Sem permissão de alteração
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "10px",
            }}
        >
            {custom}
            {cellProps.original.Status === 1 && (
                <Button
                    color="success"
                    link
                    size="sm"
                    round
                    justIcon
                    title="Ativo"
                >
                    <Done />
                </Button>
            )}
            {cellProps.original.Status === 0 && (
                <Button
                    color="danger"
                    link
                    size="sm"
                    round
                    justIcon
                    title="Bloqueado"
                >
                    <Block />
                </Button>
            )}
            {cellProps.original.Status === -1 && (
                <Button
                    color="warning"
                    link
                    size="sm"
                    round
                    justIcon
                    title="Aguardando Avaliação"
                >
                    <HourglassEmpty />
                </Button>
            )}
            <Button
                onClick={(e) => {
                    e.stopPropagation();
                    edit(cellProps.original[idProp], props.view);
                }}
                color="primary"
                link
                size="sm"
                round
                justIcon
                title="Ver / Editar"
            >
                <Launch />
            </Button>
        </div>
    );
}

export default ListButtons;
