import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    DataAprendiz: Yup.date("Digite uma data válida").nullable(),
    DataCompanheiro: Yup.date("Digite uma data válida").nullable(),
    DataMestre: Yup.date("Digite uma data válida").nullable(),
    DataInstalacao: Yup.date("Digite uma data válida").nullable(),
});

export default validationSchema;
