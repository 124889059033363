import React from "react";
import PropTypes from "prop-types";

import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBRLocale from "date-fns/locale/pt-BR";

// Estilo
import { HelperTextError, CustomKeyboardDatePicker } from "./styles";

function DatePicker(props) {
	const { form } = props;
	const fieldProps = props.field;
	const { touched, errors } = form;

	return (
		<>
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
				{fieldProps.type === "datetime-local" ? (
					<KeyboardDateTimePicker
						disableToolbar
						variant="inline"
						format="dd/MM/yyyy HH:mm"
						margin="normal"
						ampm={false}
						label="Data"
						KeyboardButtonProps={{
							"aria-label": "Alterar data/hora"
						}}
						{...props}
					/>
				) : (
					<CustomKeyboardDatePicker
						disableToolbar
						variant="inline"
						format="dd/MM/yyyy"
						margin="normal"
						label="Data"
						KeyboardButtonProps={{
							"aria-label": "Alterar data"
						}}
						{...props}
					/>
				)}
			</MuiPickersUtilsProvider>
			{touched[fieldProps.name] && errors[fieldProps.name] && (
				<HelperTextError error>{errors[fieldProps.name]}</HelperTextError>
			)}
		</>
	);
}

DatePicker.propTypes = {
	// value: PropTypes.object.isRequired,
	label: PropTypes.any,
	onChange: PropTypes.func.isRequired
};

export default DatePicker;
