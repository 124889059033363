import * as Yup from 'yup';
import { addInYup as addCep } from "helpers/validation/Cep";
import { addInYup as addLatLng } from "helpers/validation/LatitudeLongitude";

addCep();
addLatLng();

const validationSchema = Yup.object().shape({
    Nome: Yup.string().required('Por favor digite o nome'),
    Endereco: Yup.string().required('Por favor digite o endereço'),
    Bairro: Yup.string().required('Por favor digite o bairro'),
    // Cep: Yup.string().required("Por favor digite o Cep").cep("Por favor digite um CEP válido"),
    cidade: Yup.string().required("Por favor selecione a cidade"),
    Telefone: Yup.string().required("Por favor digite o Telefone"),
    Telefone2: Yup.string().nullable(),
    Telefone3: Yup.string().nullable(),
    Latitude: Yup.string().latLng("Por favor digite uma latitude válida").nullable(),
    Longitude: Yup.string().latLng("Por favor digite uma longitude válida").nullable(),
});

export default validationSchema;