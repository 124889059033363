import styled from 'styled-components';

export const CustomCardBody = styled.div`
    padding: 0.9375rem 20px;
    flex: 1 1 auto;
    -webkit-box-flex: 1;
    position: relative;
    
    ${(props) => {
        let extra = '';

        if(props.profile){
            extra += `margin-top: -15px;`
        }

        if(props.plain){
            extra += `
                padding-left: 5px!important;
                padding-right: 5px!important;
            `
        }
        
        return extra;
    }}
` 