import { combineReducers } from "redux";

import errors from "./errors";
import auth from "./auth";
import permissions from "./permissions";
import list from "./list";
import form from "./form";

export default combineReducers({
  errors,
  permissions,
  auth,
  list,
  form
});
