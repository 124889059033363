import axios from "axios";
import jwt_decode from "jwt-decode";
import { setPermissions } from "actions/permissions";
import setAuthToken from "./setAuthToken";
import store from "./store";
import { JWT_TOKEN } from "./consts";

const updatePermissions = () => {
    axios
        .get("permissions", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(JWT_TOKEN)}`,
                Platform: "dashboard"
            }
        })
        .then(res => {
            const { permissions } = res.data;
            store.dispatch(setPermissions(permissions));
        })
        .catch(err => {
            store.dispatch(setPermissions({}));
        });
};

const refreshToken = (callBackAllOk = () => {}, callbackNoToken = () => {}) => {
    let canView = false;
    if (localStorage.getItem(JWT_TOKEN)) {
    // Atualiza as permissões de usuário também
        updatePermissions();

        const decoded = jwt_decode(localStorage.getItem(JWT_TOKEN));

        const currentTime = Date.now() / 1000;
        if (decoded.exp - 900 < currentTime) {
            // Quinze minutos antes para não perder um formulário por exemplo
            axios
                .post("refresh", {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(JWT_TOKEN)}`,
                        Platform: "dashboard"
                    }
                })
                .then(res => {
                    const { data } = res.data;
                    localStorage.setItem(JWT_TOKEN, data);
                    setAuthToken(data);
                })
                .catch(err => {
                    localStorage.removeItem(JWT_TOKEN);
                    setAuthToken(false);
                    window.location.reload();
                });
        } else {
            canView = true;
            callBackAllOk();
        }
    } else {
        store.dispatch(setPermissions({}));
        callbackNoToken();
    }

    return canView;
};

export default refreshToken;
