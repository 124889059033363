import styled from 'styled-components';

export const CardTitleWhite = styled.h4`
    color: #FFFFFF;
    margin-top: 0;
    min-height: auto;
    font-weight: 300;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    margin-bottom: 3px;
    text-decoration: none;
    & small {
        color: '#777';
        font-size: 65%;
        font-weight: 400;
        line-height: 1;
    }
`

export const CardLegend = styled.p`
    &,& a,& a:hover,& a:focus {
        color: rgba(255,255,255,.62)!important;
        margin: 0;
        font-size: 14px;
        margin-top: 0;
        margin-bottom: 0;
    }
    & a,& a:hover,& a:focus {
        color: #FFFFFF;
    }
`