import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import CustomInput from "components/CustomInput";

import { HelperTextError } from "./styles";

function FormInput(props) {
    const { form, label } = props;
    const fieldProps = props.field;
    const { touched, errors } = form;


    return (
        <>
            <Field {...fieldProps}>
                {({ field }) => {
                    const inputProps = Object.assign(field, fieldProps);
                    let error = false;
                    let success = false;
                    if (touched[fieldProps.name] && errors[fieldProps.name]) {
                        error = true;
                    }
                    if (touched[fieldProps.name] && !errors[fieldProps.name]) {
                        success = true;
                    }

                    if (inputProps.value === null) inputProps.value = "";

                    return (
                        <CustomInput
                            success={success}
                            error={error}
                            labelText={label}
                            inputProps={inputProps}
                            formControlProps={{
                                fullWidth: true
                            }}
                        />
                    );
                }}
            </Field>
            {typeof errors !== "undefined" && touched[fieldProps.name] && errors[fieldProps.name] && <HelperTextError error>{errors[fieldProps.name]}</HelperTextError>}
        </>
    );
}


FormInput.propTypes = {
    field: PropTypes.object.isRequired,
    label: PropTypes.any,
    form: PropTypes.any
};

export default FormInput;
