import {
    defaultFetchList,
    defaultChangeStatus,
    defaultDelete,
    defaultSave,
    defaultFetch
} from "./default";

import store from "helpers/store";
import { navigate } from "hookrouter";
import { setParentId } from "./list";

const model = "compras";
const idField = "IdCompra";

export const fetchList = (reqData, resolve, reject, dispatch) => defaultFetchList(model, reqData, resolve, reject, dispatch);
export const fetch = (id, callback) => defaultFetch(model, id, callback);
export const changeStatus = (id, status, afterChange, afterError) => defaultChangeStatus(model, id, status, afterChange, afterError);
export const deleteRecord = (id, afterDelete, afterError) => defaultDelete(model, id, afterDelete, afterError);
export const save = (data, actions, afterSave, afterError) => defaultSave(model, idField, data, false, actions, afterSave, afterError);

export const openPaymentModal = (id = null) => {
    const state = store.getState();
    if (state.list.compras.root && id) {
      store.dispatch(setParentId(btoa(id), "payment"));
      navigate(`/${state.list.compras.root}/payment/${btoa(id)}`);
    }
  };

export const closePaymentModal = () => {
    const state = store.getState();
    if (state.list.compras.root) {
        navigate(`/${state.list.compras.root}`);
    }
};
