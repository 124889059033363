import React, { Component } from 'react'
import MaskedInput from 'react-text-mask'

export default class CpfCnpj extends Component {
    constructor() {
        super();
        
        let anyNum = /[0-9]/;
        this._maskCpf = [anyNum, anyNum, anyNum, '.', anyNum, anyNum, anyNum, '.', anyNum, anyNum, anyNum, '-', anyNum, anyNum, anyNum];
        this._maskCnpj = [anyNum, anyNum, '.', anyNum, anyNum, anyNum, '.', anyNum, anyNum, anyNum, '/', anyNum, anyNum, anyNum, anyNum, '-', anyNum, anyNum];
    }

    render() {
        let props = {}
        Object.keys(this.props).map((idx)=>{ // Não pode-se colocar funções customizadas
            if(idx !== 'inputRef' && idx !== "tipo") 
                props[idx] = this.props[idx]
            
            return null
        })
        return (
            <MaskedInput guide={false} mask={props.value.replace(/[^0-9]/g, '').length > 11 ? this._maskCnpj : this._maskCpf} {...props}/>
        )
    }
}