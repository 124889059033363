import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Button from "components/CustomButton";
import CustomInput from "components/CustomInput";
import { useSelector } from "react-redux";
import { HelperTextError } from "components/FormInput/styles";

// Ações
import * as l from "actions/list";

// Ícones
import Block from "@material-ui/icons/Block";
import Delete from "@material-ui/icons/Delete";
import Done from "@material-ui/icons/Done";
import Launch from "@material-ui/icons/Launch";
import HourglassEmpty from "@material-ui/icons/HourglassEmpty";

const MySwal = withReactContent(Swal);

function ListButtonsMembros(props) {
    const listData = useSelector(state => state.list[props.view]);
    const permissions = useSelector(state => state.permissions);

    const { cellProps, custom } = props;
    const edit = l.openEditModal;
    const list = l.requestData;
    const status = listData.changeStatus;
    const exclude = listData.delete;
    const idProp = listData.idField;
    let { root } = listData;
    if (props.root) {
        root = props.root;
    }
    const motivo = '';

    if (permissions && permissions[root] > 1) { // Usuário privilegiado
        return (
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "10px"
            }}
            >
                {custom}
                {cellProps.original.Status === 1
                    && (
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();

                                MySwal.fire({
                                    title: "",
                                    type: "info",
                                    html: <div>
                                        <h4 style={{ textAlign: "center" }}>Para bloquear o usuário, informe um motivo:</h4>
                                        <div style={{ width: "100%" }}>
                                            <CustomInput
                                                id="inputMotivoMembro"
                                                labelText={'Motivo'}
                                                inputProps={{ defaultValue: motivo, onChange: (e) => { document.getElementById("msgErrorMotivoMembro").style.display = "none" } }}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                            />
                                            <HelperTextError id="msgErrorMotivoMembro" style={{ display: 'none' }}>Motivo inválido</HelperTextError>
                                            <Button
                                                color="warning"
                                                onClick={() => {
                                                    let motivo = document.getElementById('inputMotivoMembro').value;
                                                    if (!motivo) {
                                                        document.getElementById("msgErrorMotivoMembro").style.display = "block";
                                                    }
                                                    else {
                                                        document.getElementById("msgErrorMotivoMembro").style.display = "none";

                                                        status(cellProps.original[idProp], 0, motivo, () => {
                                                            if (list) { list(props.view); }
                                                        });
                                                    }
                                                }}
                                            >
                                                Bloquear
                                            </Button>
                                        </div>
                                        <div style={{ width: "100%" }}>
                                            <Button link onClick={() => { MySwal.close(); }}>Cancelar</Button>
                                        </div>
                                    </div>,
                                    showCancelButton: false,
                                    showConfirmButton: false
                                });
                            }}
                            color="success"
                            link
                            size="sm"
                            round
                            justIcon
                            title="Ativo"
                        >
                            <Done />

                        </Button>
                    )}
                {cellProps.original.Status === 0
                    && (
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                status(cellProps.original[idProp], 1, null, () => {
                                    if (list) { list(props.view); }
                                });
                            }}
                            color="danger"
                            link
                            size="sm"
                            round
                            justIcon
                            title="Bloqueado"
                        >
                            <Block />

                        </Button>
                    )}
                {cellProps.original.Status === -1
                    && (
                        // <Button
                        //     onClick={(e) => {
                        //         e.stopPropagation();
                        //         MySwal.fire({
                        //             title: "",
                        //             type: "warning",
                        //             html: <div>
                        //                 <h4 style={{ textAlign: "center" }}>O que você deseja fazer com este registro pendente?</h4>
                        //                 <div style={{ width: "100%" }}>
                        //                     <Button
                        //                         color="info"
                        //                         onClick={(e) => {
                        //                             e.stopPropagation();
                        //                             status(cellProps.original[idProp], 1, null, () => {
                        //                                 if (list) { list(props.view); }
                        //                             });
                        //                         }}
                        //                     >
                        //                         &nbsp;Aceitar / Ativar&nbsp;

                        //                     </Button>
                        //                     <Button
                        //                         color="warning"
                        //                         onClick={() => {
                        //                             status(cellProps.original[idProp], 0, null, () => {
                        //                                 if (list) { list(props.view); }
                        //                             });
                        //                         }}
                        //                     >
                        //                         Recusar / Bloquear

                        //                     </Button>
                        //                 </div>
                        //                 <div style={{ width: "100%" }}>
                        //                     <Button link onClick={() => { MySwal.close(); }}>Nada, fechar</Button>
                        //                 </div>
                        //             </div>,
                        //             showCancelButton: false,
                        //             showConfirmButton: false
                        //         });
                        //     }}
                        //     color="warning"
                        //     link
                        //     size="sm"
                        //     round
                        //     justIcon
                        //     title="Aguardando Avaliação"
                        // >
                        //     <HourglassEmpty />

                        // </Button>
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                MySwal.fire({
                                    title: "",
                                    type: "warning",
                                    html: <div>
                                        <h4 style={{ textAlign: "center" }}>A confirmação / recusa do cadastro deverá ser feita em Autorizações</h4>
                                        <div style={{ width: "100%" }}>
                                            <Button
                                                color="warning"
                                                onClick={() => {
                                                    MySwal.close();
                                                }}
                                            >
                                                Entendi
                                            </Button>
                                        </div>
                                    </div>,
                                    showCancelButton: false,
                                    showConfirmButton: false
                                });
                            }}
                            color="warning"
                            link
                            size="sm"
                            round
                            justIcon
                            title="Aguardando Avaliação"
                        >
                            <HourglassEmpty />

                        </Button>
                    )}

                <Button
                    onClick={(e) => {
                        e.stopPropagation();
                        exclude(cellProps.original[idProp], () => {
                            if (list) { list(props.view); }
                        });
                    }}
                    color="danger"
                    link
                    size="sm"
                    round
                    justIcon
                    title="Remover"
                >
                    <Delete />

                </Button>

                <Button
                    onClick={(e) => {
                        e.stopPropagation();
                        edit(cellProps.original[idProp], props.view);
                    }}
                    color="primary"
                    link
                    size="sm"
                    round
                    justIcon
                    title="Ver / Editar"
                >
                    <Launch />

                </Button>
            </div>
        );
    } // Sem permissão de alteração
    return (
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            paddingRight: "10px"
        }}
        >
            {custom}
            {cellProps.original.Status === 1
                && <Button color="success" link size="sm" round justIcon title="Ativo"><Done /></Button>}
            {cellProps.original.Status === 0
                && <Button color="danger" link size="sm" round justIcon title="Bloqueado"><Block /></Button>}
            {cellProps.original.Status === -1
                && <Button color="warning" link size="sm" round justIcon title="Aguardando Avaliação"><HourglassEmpty /></Button>}
            <Button
                onClick={(e) => {
                    e.stopPropagation();
                    edit(cellProps.original[idProp], props.view);
                }}
                color="primary"
                link
                size="sm"
                round
                justIcon
                title="Ver / Editar"
            >
                <Launch />

            </Button>
        </div>
    );
}

export default ListButtonsMembros;
