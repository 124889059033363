// Geral
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

// Ações
import * as list from "actions/list";
import { fetchList, changeStatus, deleteRecord } from "actions/usuarios";

// Componentes
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card";
import CardBody from "components/Card/CardBody";
import RightPanel from "components/RightPanel";
import DynamicTable from "components/DynamicTable";
import ListButtons from "components/ListButtons";
import Pesquisa from "components/Pesquisa";
import ListHeader from "components/ListHeader";

// Formulário
import Form from "./form";

const view = "usuarios";

export default function Usuarios({ params }) {
    useEffect(() => {
        list.start(view, "IdUsuario", fetchList, changeStatus, deleteRecord);

    }, []);

    const listData = useSelector(state => state.list[view]);

    const form = listData
        ? (
            <Form
                changeStatus={listData.changeStatus}
                deleteRecord={listData.delete}
                afterSave={() => list.afterSaveSuccess(view)}
                afterChangeStatusSuccess={() => list.afterChangeStatusSuccess(view)}
                afterDeleteSuccess={() => list.afterDeleteSuccess(view)}
                id={params ? params.id : null}
            />
        ) : null;
    return (
        <GridContainer>
            {listData && (
                <>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <ListHeader
                                title="Usuários"
                                add="Novo Usuário"
                                legend="Pessoas que tem acesso a este Painel Administrativo"
                                view={view}
                            />
                            <CardBody>
                                <Pesquisa view={view} />
                                <DynamicTable view={view}
                                    {...listData.tableProps}
                                    columns={[
                                        {
                                            Header: "Nome",
                                            accessor: "Nome"
                                        },
                                        {
                                            Header: "E-mail",
                                            accessor: "Email"
                                        },
                                        {
                                            Header: "Status",
                                            accessor: "Status",
                                            maxWidth: 150,
                                            style: {
                                                padding: 0
                                            },
                                            Cell: props => <ListButtons cellProps={props} view={view} />
                                        }
                                    ]}
                                    onFetchData={(state, instance) => list.requestData(view)}
                                    onPageSizeChange={(pageSize, pageIndex) => list.onPageSizeChange(pageSize, pageIndex, view)}
                                    onPageChange={(pageIndex) => list.onPageChange(pageIndex, view)}
                                    onSortedChange={(newSorted, column, shiftKey) => list.onSortedChange(newSorted, column, shiftKey, view)}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>

                    <RightPanel
                        root={listData.root}
                        isPaneOpen={params && params.action === "new"}
                        title="Novo Usuário"
                        path="new"
                    >
                        {form}
                    </RightPanel>
                    <RightPanel
                        root={listData.root}
                        isPaneOpen={params && params.action === "edit"}
                        title="Edição de Usuário"
                        path="edit"
                    >
                        {form}
                    </RightPanel>
                </>
            )}
        </GridContainer>
    );
}
