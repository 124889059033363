/* Geral / Bibliotecas */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";

/* Ícones */
import Save from "@material-ui/icons/Save";
import StarIcon from '@material-ui/icons/Star';
import Delete from '@material-ui/icons/Delete';
import AddIcon from "@material-ui/icons/Add";

/* Métodos / Ações */
import { saveMaconicos } from "actions/membros";
import { loadOptions as loadOptionsLojas } from "actions/lojas";
import { loadOptions as loadOptionsEntidades } from "actions/entidades";

/* Componentes */
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import FormInput from "components/FormInput";
import Button from "components/CustomButton";
import { Container, Theme } from "assets/jss/doSul-dashboard";
import Loading from "components/Loading";
import FormSelect from "components/FormSelect";
import DynamicTable from "components/DynamicTable";
import ListHeader from "components/ListHeader";
import CardBody from "components/Card/CardBody";
import Card from "components/Card";

/* Validação */
import validationSchema from "./validationMaconicos";

const Model = {
    IdMembro: null,
    Cim: "",
    Grau: "",
    Lojas: [],
    Entidades: [],
    lojaPrincipal: null
};

export default function MembrosMaconicosForm({
    id, form, formData
}) {
    const [tmpLoja, setTmpLoja] = useState(null);
    const [tmpEntidade, setTmpEntidade] = useState(null);
    const permissions = useSelector(state => state.permissions);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(form.setAfterSave(() => { }));
        dispatch(form.setSave(saveMaconicos));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // É chamado toda vez que muda o ID
        form.setModel(Model);
        if (id) {
            form.fetchData(id);
        }
    }, [id, form]);

    if (formData.loading) {
        return <Loading />;
    }

    const disabled = permissions && permissions[formData.root] < 2;

    const handleSubmit = (values, actions) => {
        let loja = [];
        for (let i = 0; i < values.Lojas.length; i++) {
            loja.push(parseInt(values.Lojas[i].loja));
        }

        let entidade = [];
        for (let i = 0; i < values.Entidades.length; i++) {
            entidade.push(parseInt(values.Entidades[i].entidade));
        }

        values.loja = loja;
        values.entidade = entidade;
        formData.save(values, actions, formData.afterSave);
    };

    return (
        <Container>
            <Formik
                validationSchema={validationSchema}
                initialValues={formData.model}
                onSubmit={handleSubmit}
                render={FormikProps => (
                    <Form onSubmit={FormikProps.handleSubmit}>
                        <GridContainer>
                            {(FormikProps.values.Tipo === "E") &&
                                <p>Membro do tipo Empresa, nada para alterar aqui.</p>
                            }
                            {(FormikProps.values.Tipo !== "E") &&
                                <>
                                    {(FormikProps.values.Tipo === "I"
                                        && (
                                            <>
                                                <GridItem xs={12} lg={6}>
                                                    <FormInput
                                                        field={{
                                                            name: "Cim",
                                                            disabled
                                                        }}
                                                        form={FormikProps}
                                                        label="Cim *"
                                                    />
                                                </GridItem>

                                                <GridItem xs={12} lg={6}>
                                                    <FormInput
                                                        field={{
                                                            name: "Grau",
                                                            disabled
                                                        }}
                                                        form={FormikProps}
                                                        label="Grau *"
                                                    />
                                                </GridItem>
                                            </>
                                        )
                                    )}

                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card>
                                            <ListHeader
                                                title="Lojas"
                                                add={false}
                                                legend={false}
                                                view={'membros'}
                                            />
                                            <Container style={{ width: '100%' }}>
                                                <GridContainer alignContent="space-between" alignItems="flex-end">
                                                    <GridItem xs>
                                                        <FormSelect
                                                            field={{
                                                                name: "loja",
                                                                placeholder: "Digite para localizar...",
                                                                isSearchable: true,
                                                                loadOptions: loadOptionsLojas,
                                                                getOptionLabel: (option) => `${option.Nome}`,
                                                                loadingMessage: () => "Carregando...",
                                                                noOptionsMessage: (input) => (input.inputValue === "" ? "Digite para localizar..." : "Nenhum resultado encontrado"),
                                                                value: tmpLoja,
                                                                onChange: (value) => {
                                                                    if (!value || FormikProps.values.Lojas.filter((val, idx) => val.loja === value.IdLoja).length === 0)
                                                                        setTmpLoja(value);
                                                                    else
                                                                        setTmpLoja(null);
                                                                }
                                                            }}
                                                            form={FormikProps}
                                                            async
                                                            label="Adicionar loja"
                                                        />
                                                    </GridItem>
                                                    <GridItem xs="auto">
                                                        <Button color="success" type="button" onClick={() => {
                                                            let tmpLojas = FormikProps.values.Lojas;
                                                            if (tmpLoja) {
                                                                tmpLojas.push({
                                                                    loja: tmpLoja.IdLoja,
                                                                    nomeLoja: tmpLoja.Nome
                                                                });
                                                                setTmpLoja(null);
                                                            }
                                                            FormikProps.setFieldValue('Lojas', tmpLojas);
                                                        }}>
                                                            <AddIcon />
                                                        </Button>
                                                    </GridItem>
                                                </GridContainer>
                                            </Container>
                                            <CardBody>
                                                <DynamicTable
                                                    sortable={false}
                                                    showPagination={false}
                                                    defaultPageSize={9999}
                                                    data={FormikProps.values.Lojas}
                                                    style={{ margin: "-0.9375rem -20px", marginTop: "10px" }}
                                                    columns={[
                                                        {
                                                            Header: "Nome",
                                                            accessor: "nomeLoja"
                                                        },
                                                        {
                                                            Header: "Opções",
                                                            acessor: "nomeLoja",
                                                            maxWidth: 120,
                                                            style: {
                                                                padding: 0,
                                                                display: 'flex',
                                                                justifyContent: 'space-between'
                                                            },
                                                            Cell: props => {
                                                                if (parseInt(props.original.loja) === parseInt(FormikProps.values.lojaPrincipal)) {
                                                                    return <div style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                    }}><StarIcon style={{ color: Theme.warningColor[0] }} /> Principal</div>;
                                                                }
                                                                return <>
                                                                    <Button onClick={() => {
                                                                        FormikProps.setFieldValue('lojaPrincipal', props.original.loja);
                                                                    }} color="info" link size="sm" round justIcon title="Remover"><StarIcon></StarIcon></Button>
                                                                    <Button onClick={() => {
                                                                        let tmpLojas = FormikProps.values.Lojas.filter((val, idx) => idx !== props.index);
                                                                        FormikProps.setFieldValue('Lojas', tmpLojas);
                                                                    }} color="danger" link size="sm" round justIcon title="Remover"><Delete></Delete></Button>
                                                                </>
                                                            }
                                                        }
                                                    ]}
                                                />
                                            </CardBody>
                                        </Card>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card>
                                            <ListHeader
                                                title="Entidades"
                                                add={false}
                                                legend={false}
                                                view={'membros'}
                                            />
                                            <Container style={{ width: '100%' }}>
                                                <GridContainer alignContent="space-between" alignItems="flex-end">
                                                    <GridItem xs>
                                                        <FormSelect
                                                            field={{
                                                                name: "entidade",
                                                                placeholder: "Digite para localizar...",
                                                                isSearchable: true,
                                                                loadOptions: loadOptionsEntidades,
                                                                getOptionLabel: (option) => `${option.Nome}`,
                                                                loadingMessage: () => "Carregando...",
                                                                noOptionsMessage: (input) => (input.inputValue === "" ? "Digite para localizar..." : "Nenhum resultado encontrado"),
                                                                value: tmpEntidade,
                                                                onChange: (value) => {
                                                                    if (!value || FormikProps.values.Entidades.filter((val, idx) => val.entidade === value.IdEntidade).length === 0)
                                                                        setTmpEntidade(value);
                                                                    else
                                                                        setTmpEntidade(null);
                                                                }
                                                            }}
                                                            form={FormikProps}
                                                            async
                                                            label="Adicionar entidade"
                                                        />
                                                    </GridItem>
                                                    <GridItem xs="auto">
                                                        <Button color="success" type="button" onClick={() => {
                                                            let tmpEntidades = FormikProps.values.Entidades;
                                                            if (tmpEntidade) {
                                                                tmpEntidades.push({
                                                                    entidade: tmpEntidade.IdEntidade,
                                                                    nomeEntidade: tmpEntidade.Nome
                                                                });
                                                                setTmpEntidade(null);
                                                            }
                                                            FormikProps.setFieldValue('Entidades', tmpEntidades);
                                                        }}>
                                                            <AddIcon />
                                                        </Button>
                                                    </GridItem>
                                                </GridContainer>
                                            </Container>
                                            <CardBody>
                                                <DynamicTable
                                                    sortable={false}
                                                    showPagination={false}
                                                    defaultPageSize={9999}
                                                    data={FormikProps.values.Entidades}
                                                    style={{ margin: "-0.9375rem -20px", marginTop: "10px" }}
                                                    columns={[
                                                        {
                                                            Header: "Nome",
                                                            accessor: "nomeEntidade"
                                                        },
                                                        {
                                                            Header: "Opções",
                                                            acessor: "nomeEntidade",
                                                            maxWidth: 60,
                                                            style: {
                                                                padding: 0,
                                                                display: 'flex',
                                                                justifyContent: 'center'
                                                            },
                                                            Cell: props => {
                                                                return <>
                                                                    <Button onClick={() => {
                                                                        let tmpEntidades = FormikProps.values.Entidades.filter((val, idx) => idx !== props.index);
                                                                        FormikProps.setFieldValue('Entidades', tmpEntidades);
                                                                    }} color="danger" link size="sm" round justIcon title="Remover"><Delete></Delete></Button>
                                                                </>
                                                            }
                                                        }
                                                    ]}
                                                />
                                            </CardBody>
                                        </Card>
                                    </GridItem>

                                    <GridItem xs={12}>
                                        <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                                            {!disabled && (
                                                <Button color="success" type="submit" disabled={FormikProps.isSubmitting}>
                                                    <Save />
                                                    {" "}
                                                    Salvar
                                        </Button>
                                            )}
                                        </div>
                                    </GridItem>
                                </>
                            }
                        </GridContainer>

                    </Form>
                )
                }
            />
        </Container >
    );
}

MembrosMaconicosForm.propTypes = {
    id: PropTypes.any,
    form: PropTypes.any,
    formData: PropTypes.any
};