import axios from "axios";
import jwt_decode from "jwt-decode";


// Types
import errorTypes from "types/errors";
import types from "types/auth";

import setAuthToken from "helpers/setAuthToken";
import refreshToken from "helpers/refresh";
import { JWT_TOKEN } from "helpers/consts";
import { getPreferenciasLayout } from "./usuarios";

export const loginUser = (user) => dispatch => {
    axios.post(user.adm ? "auth-backoffice" : "auth-membro", user, { headers: { Accept: "application/json", Platform: "dashboard" } })
        .then(res => {
            const { data } = res.data;
            localStorage.setItem(JWT_TOKEN, data);
            setAuthToken(data);
            getPreferenciasLayout();

            const decoded = jwt_decode(data);
            refreshToken();
            dispatch(setCurrentUser(decoded));
        })
        .catch(err => {
            let erro = { message: "Não foi possível fazer o login" };
            if (err.response) {
                erro = err.response.data;
            }
            dispatch({
                type: errorTypes.GET_ERRORS,
                payload: erro
            });
        });
};

export const setCurrentUser = decoded => ({
    type: types.SET_CURRENT_USER,
    payload: decoded
});

export const logoutUser = () => dispatch => {
    localStorage.removeItem(JWT_TOKEN);
    localStorage.clear();
    setAuthToken(false);
    dispatch(setCurrentUser({}));

    window.location.href = `${process.env.PUBLIC_URL}/login`;
};
