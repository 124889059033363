import * as yup from 'yup';

const LatitudeLongitude = (val = "") => (/^-?[0-9]{1,3}(?:\.[0-9]{1,10})?$/.test(val) || val === '')

export default LatitudeLongitude;

export const addInYup = () => {
    yup.addMethod(yup.string, "latLng", function (message) {
        return this.test("latLng", message || "Informe um valor válido", value => LatitudeLongitude(value));
    });
}