import React from "react";
import PropTypes from "prop-types";

// Estilo
import { CustomButton } from "./styles";

function RegularButton({ ...props }) {
  const { 
      children, 
      color,
      size,
      round,
      disabled,
      block,
      simple,
      link,
      right,
      justIcon,
      ...rest } = props;
  return (
    <CustomButton {...rest} options={{
        color,
        size,
        round,
        disabled,
        block,
        link,
        simple,
        right,
        justIcon
    }}>
      {children}
    </CustomButton>
  );
}

RegularButton.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "transparent"
  ]),
  size: PropTypes.oneOf(["sm", "lg"]),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  right: PropTypes.bool,
  children: PropTypes.node
};

export default RegularButton;
