/* eslint-disable import/prefer-default-export */
/* eslint-disable indent */
import axios from "axios";

export const loadCidadesOptions = inputValue => new Promise((resolve, reject) => {
    axios
      .get(`cidades?value=${inputValue}`)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        let erro = "Não foi possível obter as cidades";
        if (err.response && err.response.data) {
          erro = err.response.data;
        }
        reject(erro);
      });
  });
