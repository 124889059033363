/* Geral / Bibliotecas */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";

/* Ações */
import * as form from "actions/form";
import { fetch, save, loadPermissionsOptions } from "actions/usuarios";

/* Ícones */
import Save from "@material-ui/icons/Save";
import Done from "@material-ui/icons/Done";
import Block from "@material-ui/icons/Block";
import Delete from "@material-ui/icons/Delete";

/* Componentes */
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import FormInput from "components/FormInput";
import FormSelect from "components/FormSelect";
import Button from "components/CustomButton";
import { Container, Theme } from "assets/jss/doSul-dashboard";
import Loading from "components/Loading";

/* Validação */
import validationSchema from "./validation";

const Model = {
    IdUsuario: null,
    Nome: "",
    Email: "",
    Senha: "",
    ConfirmaSenha: "",
    Privilegio: {},
    Status: 1
};

export default function UsuariosForm({
    id, changeStatus, deleteRecord, afterSave, afterChangeStatusSuccess, afterDeleteSuccess
}) {
    const dispatch = useDispatch();
    const formData = useSelector(state => state.form);
    const permissions = useSelector(state => state.permissions);

    const [paginasPrivilegios, setPaginasPrivilegios] = useState([]);

    const afterFetch = data => {
        const model = data.result;
        model.Senha = "";
        model.Privilegio = JSON.parse(model.Privilegio);
        dispatch(form.setModel(model));
        dispatch(form.setLoading(false));
    };

    useEffect(() => {
        form.start("usuarios", "IdUsuario", id, Model, fetch, save, changeStatus, deleteRecord, afterSave, afterChangeStatusSuccess, afterDeleteSuccess, afterFetch);

        loadPermissionsOptions(data => {
            setPaginasPrivilegios(data);
        });
        return form.stop;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // É chamado toda vez que muda o ID
        form.setModel(Model);
        if (id) {
            form.fetchData(id);
        }
    }, [id]);

    // const handleSubmit = form.handleSubmit    ----> :) Poderia ser usado o padrão se não precisar nenhum tratamento
    const handleSubmit = (values, actions) => {
        values.Privilegio = JSON.stringify(values.Privilegio);
        formData.save(values, actions, formData.afterSave);
    };

    if (formData.loading) {
        return <Loading />;
    }

    const disabled = permissions && permissions[formData.root] < 2;

    const permissionsOptions = [{ value: 0, label: "Não permitido" }, { value: 1, label: "Ver" }, { value: 2, label: "Ver e Interagir (Criar, editar e excluir)" }];

    const getPermissionValue = (value = 0) => permissionsOptions[value];

    return (
        <Container>
            <Formik
                validationSchema={validationSchema}
                initialValues={formData.model}
                onSubmit={handleSubmit}
                render={FormikProps => (
                    <Form onSubmit={FormikProps.handleSubmit}>
                        <GridContainer>
                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "Nome",
                                        disabled
                                    }}
                                    form={FormikProps}
                                    label="Nome *"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "Email",
                                        autoComplete: "username",
                                        disabled
                                    }}
                                    form={FormikProps}
                                    label="E-mail *"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "Senha",
                                        type: "password",
                                        autoComplete: "new-password",
                                        disabled
                                    }}
                                    form={FormikProps}
                                    label={formData.model[formData.idField] ? "Senha" : "Senha *"}
                                />
                            </GridItem>

                            {FormikProps.values.senha !== "" && (
                                <GridItem xs={12} lg={6}>
                                    <FormInput
                                        field={{
                                            name: "ConfirmaSenha",
                                            type: "password",
                                            autoComplete: "new-password",
                                            disabled
                                        }}
                                        form={FormikProps}
                                        label="Confirme a senha *"
                                    />
                                </GridItem>
                            )}

                            <GridItem xs={12} lg={12}>
                                <p
                                    style={{
                                        color: Theme.primaryColor[1],
                                        paddingTop: "15px",
                                        fontSize: "17px"
                                    }}
                                >
                                    Permissões
                </p>

                                <GridContainer>
                                    {paginasPrivilegios.map((val, idx) => {
                                        const key = Object.keys(val)[0];
                                        return (
                                            <GridItem key={idx} xs={12} lg={6}>
                                                <FormSelect
                                                    field={{
                                                        options: permissionsOptions,
                                                        value: getPermissionValue(FormikProps.values.Privilegio[key]),
                                                        isSearchable: false,
                                                        disabled,
                                                        onChange: value => {
                                                            const { Privilegio } = FormikProps.values;
                                                            Privilegio[key] = value.value;
                                                            FormikProps.setFieldValue("Privilegio", Privilegio);
                                                        }
                                                    }}
                                                    form={FormikProps}
                                                    async={false}
                                                    label={val[key]}
                                                />
                                            </GridItem>
                                        );
                                    })}
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                                    {!disabled && (
                                        <Button color="success" type="submit" disabled={FormikProps.isSubmitting}>
                                            <Save />
                                            {" "}
                                            Salvar
                                        </Button>
                                    )}
                                    {!disabled && formData.model[formData.idField] && (
                                        <Button color="danger" type="button" right onClick={form.handleDelete}>
                                            <Delete />
                                            {" "}
                                            Excluir
                                        </Button>
                                    )}
                                    {!disabled && formData.model[formData.idField] && formData.model.Status === 0 && (
                                        <Button
                                            color="info"
                                            type="button"
                                            right
                                            onClick={() => {
                                                form.handleChangeStatus(1);
                                            }}
                                        >
                                            <Done />
                                            {" "}
                                            Ativar
                                        </Button>
                                    )}
                                    {!disabled && formData.model[formData.idField] && formData.model.Status === 1 && (
                                        <Button
                                            color="warning"
                                            type="button"
                                            right
                                            onClick={() => {
                                                form.handleChangeStatus(0);
                                            }}
                                        >
                                            <Block />
                                            {" "}
                                            Bloquear
                                        </Button>
                                    )}
                                </div>
                            </GridItem>
                        </GridContainer>
                    </Form>
                )}
            />
        </Container>
    );
}

UsuariosForm.propTypes = {
    id: PropTypes.any,
    afterSave: PropTypes.func,
    changeStatus: PropTypes.func,
    deleteRecord: PropTypes.func,
    afterChangeStatusSuccess: PropTypes.func,
    afterDeleteSuccess: PropTypes.func
};
