// Geral
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";

// Ações
import * as list from "actions/list";
import { fetchList, changeStatus, deleteRecord, openMembrosModal, openLojasModal } from "actions/potencias";
import { JWT_TOKEN } from "helpers/consts";

// Componentes
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card";
import CardBody from "components/Card/CardBody";
import RightPanel from "components/RightPanel";
import DynamicTable from "components/DynamicTable";
import ListButtons from "components/ListButtons";
import Pesquisa from "components/Pesquisa";
import ListHeader from "components/ListHeader";
import Button from "components/CustomButton";

// Ícones
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

// Formulário
import Form from "./form";
import ListMembros from "./listMembros";
import ListLojas from "./listLojas";

const view = "potencias";

export default function EmpresasPotencias({ params }) {
    const token = jwt_decode(localStorage.getItem(JWT_TOKEN));

    useEffect(() => {
        list.start(view, "IdPotencia", fetchList, changeStatus, deleteRecord);
    }, []);

    const listData = useSelector(state => state.list[view]);

    const form = listData
        ? (
            <Form
                changeStatus={listData.changeStatus}
                deleteRecord={listData.delete}
                afterSave={() => list.afterSaveSuccess(view)}
                afterChangeStatusSuccess={() => list.afterChangeStatusSuccess(view)}
                afterDeleteSuccess={() => list.afterDeleteSuccess(view)}
                id={params ? params.id : null}
            />
        ) : null;

    const listMembros = (
        <ListMembros
            id={params ? params.id : null}
        />
    );
    const listLojas = (
        <ListLojas
            id={params ? params.id : null}
        />
    );

    return (
        <GridContainer>
            {listData && (
                <>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <ListHeader
                                title="Potências"
                                add={token.platform === "dashboard" ? "Nova Potência" : false}
                                legend={token.platform === "dashboard" ? "Potências com as quais os usuários serão associados" : "Potências em que sou administrador"}
                                view={view}
                            />
                            <CardBody>
                                <Pesquisa view={view} />
                                <DynamicTable
                                    view={view}
                                    {...listData.tableProps}
                                    columns={[
                                        {
                                            Header: "Nome",
                                            accessor: "Nome"
                                        },
                                        {
                                            Header: "Sigla",
                                            accessor: "Sigla"
                                        },
                                        {
                                            Header: "Status",
                                            accessor: "Status",
                                            maxWidth: 150,
                                            style: {
                                                padding: 0
                                            },
                                            Cell: props => <ListButtons cellProps={props} view={view} root={token.platform === "dashboard" ? view : "a"} />
                                        },
                                        {
                                            Header: "Membros",
                                            maxWidth: 90,
                                            style: {
                                                padding: 0,
                                                textAlign: "center"
                                            },
                                            Cell: props => (
                                                <Button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        openMembrosModal(props.original[listData.idField]);
                                                    }}
                                                    color="info"
                                                    link
                                                    size="sm"
                                                    round
                                                    justIcon
                                                    title="Membros"
                                                >
                                                    <PeopleAltIcon />
                                                </Button>
                                            )
                                        },
                                        {
                                            Header: "Lojas",
                                            maxWidth: 60,
                                            style: {
                                                padding: 0,
                                                textAlign: "center"
                                            },
                                            Cell: props => (
                                                <Button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        openLojasModal(props.original[listData.idField]);
                                                    }}
                                                    color="info"
                                                    link
                                                    size="sm"
                                                    round
                                                    justIcon
                                                    title="Lojas"
                                                >
                                                    <AccountBalanceIcon />
                                                </Button>
                                            )
                                        }
                                    ]}
                                    onFetchData={(state, instance) => list.requestData(view)}
                                    onPageSizeChange={(pageSize, pageIndex) => list.onPageSizeChange(pageSize, pageIndex, view)}
                                    onPageChange={(pageIndex) => list.onPageChange(pageIndex, view)}
                                    onSortedChange={(newSorted, column, shiftKey) => list.onSortedChange(newSorted, column, shiftKey, view)}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>

                    <RightPanel root={listData.root} isPaneOpen={params && params.action === "new"} title="Nova Potência" path="new">
                        {form}
                    </RightPanel>
                    <RightPanel root={listData.root} isPaneOpen={params && params.action === "edit"} title="Edição de Potência" path="edit">
                        {form}
                    </RightPanel>
                    <RightPanel root={listData.root} isPaneOpen={params && params.action === "membros"} title="Membros da Potência" path="membros">
                        {listMembros}
                    </RightPanel>
                    <RightPanel root={listData.root} isPaneOpen={params && params.action === "lojas"} title="Lojas da Potência" path="lojas">
                        {listLojas}
                    </RightPanel>
                </>
            )}
        </GridContainer>
    );
}
