// Geral
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";

// Ações
import * as list from "actions/list";
import {
    fetchList, changeStatus, deleteRecord, openMembrosModal
} from "actions/lojas";
import { JWT_TOKEN } from "helpers/consts";
import { loadOptions as loadOptionsPotencias } from "actions/potencias";

// Componentes
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card";
import CardBody from "components/Card/CardBody";
import RightPanel from "components/RightPanel";
import DynamicTable from "components/DynamicTable";
import ListButtons from "components/ListButtons";
import Pesquisa from "components/Pesquisa";
import ListHeader from "components/ListHeader";
import Button from "components/CustomButton";
import { CustomAsyncSelect } from "components/FormSelect/styles";

// Ícones
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";

// Formulário
import Form from "./form";
import ListMembros from "./listMembros";

const view = "lojas";

export default function EmpresasPotencias({ params }) {
    const [potencia, setPotencia] = useState(null);
    const token = jwt_decode(localStorage.getItem(JWT_TOKEN));

    const dispatch = useDispatch();

    useEffect(() => {
        list.start(view, "IdLoja", fetchList, changeStatus, deleteRecord);
    }, []);

    useEffect(() => {
        dispatch(list.setExtraFilter({
            potencia: potencia ? btoa(potencia.IdPotencia) : null
        }, view));

        list.requestData(view);
    }, [potencia, dispatch])

    const listData = useSelector(state => state.list[view]);

    const form = listData
        ? (
            <Form
                changeStatus={listData.changeStatus}
                deleteRecord={listData.delete}
                afterSave={() => list.afterSaveSuccess(view)}
                afterChangeStatusSuccess={() => list.afterChangeStatusSuccess(view)}
                afterDeleteSuccess={() => list.afterDeleteSuccess(view)}
                id={params ? params.id : null}
            />
        ) : null;

    const listMembros = (
        <ListMembros
            id={params ? params.id : null}
        />
    );

    return (
        <GridContainer>
            {listData && (
                <>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <ListHeader
                                title="Lojas"
                                add={token.platform === "dashboard" ? "Nova Loja" : false}
                                legend={token.platform === "dashboard" ? "Lojas com as quais os usuários serão associados" : "Lojas em que sou administrador"}
                                view={view}
                            />
                            <CardBody>
                                <GridContainer alignContent="space-between" alignItems="flex-end">
                                    <GridItem xs={12} lg={6}>
                                        <Pesquisa view={view} />
                                    </GridItem>
                                    <GridItem xs={12} lg={6}>
                                        <CustomAsyncSelect
                                            name={potencia}
                                            placeholder=" "
                                            isSearchable={true}
                                            defaultOptions={true}
                                            loadOptions={loadOptionsPotencias}
                                            getOptionLabel={(option) => `${option.Nome}`}
                                            loadingMessage={() => "Carregando..."}
                                            noOptionsMessage={(input) => (input.inputValue === "" ? "Digite para localizar..." : "Nenhum resultado encontrado")}
                                            value={potencia}
                                            onChange={(value) => {
                                                setPotencia(value);
                                            }}
                                            data-label="Potência"
                                        />
                                    </GridItem>
                                </GridContainer>
                                <DynamicTable
                                    view={view}
                                    {...listData.tableProps}
                                    columns={[
                                        {
                                            Header: "Nome",
                                            accessor: "Nome"
                                        },
                                        {
                                            Header: "Potência",
                                            accessor: "SiglaPotencia"
                                        },
                                        {
                                            Header: "Status",
                                            accessor: "Status",
                                            maxWidth: 150,
                                            style: {
                                                padding: 0
                                            },
                                            Cell: props => <ListButtons cellProps={props} view={view} root={token.platform === "dashboard" ? view : "a"} />
                                        },
                                        {
                                            Header: "Membros",
                                            maxWidth: 100,
                                            style: {
                                                padding: 0,
                                                textAlign: "center"
                                            },
                                            Cell: props => (
                                                <Button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        openMembrosModal(props.original[listData.idField]);
                                                    }}
                                                    color="info"
                                                    link
                                                    size="sm"
                                                    round
                                                    justIcon
                                                    title="Membros"
                                                >
                                                    <PeopleAltIcon />
                                                </Button>
                                            )
                                        }
                                    ]}
                                    onFetchData={(state, instance) => list.requestData(view)}
                                    onPageSizeChange={(pageSize, pageIndex) => list.onPageSizeChange(pageSize, pageIndex, view)}
                                    onPageChange={(pageIndex) => list.onPageChange(pageIndex, view)}
                                    onSortedChange={(newSorted, column, shiftKey) => list.onSortedChange(newSorted, column, shiftKey, view)}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>

                    <RightPanel root={listData.root} isPaneOpen={params && params.action === "new"} title="Nova Loja" path="new">
                        {form}
                    </RightPanel>
                    <RightPanel root={listData.root} isPaneOpen={params && params.action === "edit"} title="Edição de Loja" path="edit">
                        {form}
                    </RightPanel>
                    <RightPanel root={listData.root} isPaneOpen={params && params.action === "membros"} title="Membros da Loja" path="membros">
                        {listMembros}
                    </RightPanel>
                </>
            )}
        </GridContainer>
    );
}
