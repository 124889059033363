import React from "react";
import Search from '@material-ui/icons/Search';

function SearchLabel(props) {
  return (
    <span style={{display:'flex', alignItems: 'center'}} {...props}><Search/>&nbsp;Pesquisa</span>
  );
}

export default SearchLabel;
