import React from 'react';
import { Editor as Wysiwyg } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './styles.css';
export default class Editor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            value: ''
        };
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        }, ()=>{
            this.props.changeDone(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        });
    };

    componentDidMount(){
        this.setState({
            value: this.props.fieldValue
        }, ()=> this.updateEditor());
    }

    updateEditor = () => {
        const blocksFromHtml = htmlToDraft(this.state.value);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.push(this.state.editorState, contentState);
        
        this.setState({
            editorState
        });
    }

	render() {
        const { editorState } = this.state;
        return (
            <Wysiwyg
                editorState={editorState}
                wrapperClassName="editor-wrapper"
                editorClassName="editor-editor"
                toolbarClassName="editor-toolbar"
                onEditorStateChange={this.onEditorStateChange}
                localization={{
                    locale: 'pt',
                }}
                {...this.props} />
        );
    }
};