/* eslint-disable import/prefer-default-export */
/* eslint-disable indent */
import axios from "axios";

export const loadEstadosOptions = inputValue => new Promise((resolve, reject) => {
    axios
        .get(`estados`)
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            let erro = "Não foi possível obter as estados";
            if (err.response && err.response.data) {
                erro = err.response.data;
            }
            reject(erro);
        });
});
