import styled from 'styled-components';
import { Theme, primaryBoxShadow } from 'assets/jss/doSul-dashboard';

export const CustomCardIcon = styled.div`
    border-radius: 3px;
    background-color: ${Theme.grayColor[0]};
    padding: 15px;
    margin-top: -20px;
    margin-right: 15px;
    float: left;

    background: linear-gradient(60deg, ${Theme.primaryColor[1]}, ${Theme.primaryColor[2]});
    box-shadow: ${primaryBoxShadow};
`