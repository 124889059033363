import React from "react";
// Ícones
import Home from "@material-ui/icons/Home";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import StorefrontIcon from '@material-ui/icons/Storefront';
// import LocalConvenienceStoreIcon from '@material-ui/icons/LocalConvenienceStore';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import ShareIcon from '@material-ui/icons/Share';
import PeopleIcon from '@material-ui/icons/People';
import EventNoteIcon from '@material-ui/icons/EventNote';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import FolderIcon from '@material-ui/icons/Folder';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import BookIcon from '@material-ui/icons/Book';
import SchoolIcon from '@material-ui/icons/School';
import BuildIcon from '@material-ui/icons/Build';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

// Componentes
import HomePage from "views/Home";

import Usuarios from "views/Usuarios";
// import EmpresasCategorias from "views/EmpresasCategorias";
import Compras from "views/Compras";
import Empresas from "views/Empresas";
import Potencias from "views/Potencias";
import Lojas from "views/Lojas";
import Entidades from "views/Entidades";
import Membros from "views/Membros";
import Planos from "views/Planos";
import Anuncios from "views/Anuncios";
import Agenda from "views/Agenda";
import Publicacoes from "views/Publicacoes";
import Denuncias from "views/Denuncias";
import AutorizacoesMembro from "views/AutorizacoesMembro";
import Ritos from "views/Ritos";
import Ajudas from "views/Ajudas";
import TermosUso from "views/TermosUso";
import TermosCadastro from "views/TermosCadastro";
import MembrosTokens from "views/MembrosTokens";
import Cupons from "views/Cupons";

/* Idiomas */
import { Trans } from 'react-i18next';

export const defaultLabel = "BackOffice";

const dashboardRoutes = [
    {
        path: "home",
        name: <Trans i18nKey="routes.home">Início</Trans>,
        icon: Home,
        component: params => <HomePage params={params} />
    },
    {
        type: "group",
        name: <Trans i18nKey="routes.comercial.main">Comercial</Trans>,
        icon: MonetizationOnIcon,
        id: "comercial",
        childrens: [
            {
                path: "anuncios/:action/:id",
                name: <Trans i18nKey="routes.comercial.adverts">Anúncios</Trans>,
                icon: PhotoAlbumIcon,
                component: params => <Anuncios params={params} />
            },
            {
                path: "compras/:action/:id",
                name: <Trans i18nKey="routes.comercial.purchases">Compras</Trans>,
                icon: ShoppingBasketIcon,
                component: params => <Compras params={params} />
            },
            {
                path: "cupons/:action/:id",
                name: <Trans i18nKey="routes.comercial.coupons">Cupons</Trans>,
                icon: ConfirmationNumberIcon,
                component: params => <Cupons params={params} />
            },
            {
                path: "planos/:action/:id",
                name: <Trans i18nKey="routes.comercial.plans">Planos</Trans>,
                icon: AddToPhotosIcon,
                component: params => <Planos params={params} />
            },
            {
                path: "empresas/:action/:id",
                name: <Trans i18nKey="routes.comercial.companies">Empresas</Trans>,
                icon: StorefrontIcon,
                component: params => <Empresas params={params} />
            },
            // {
            //     path: "empresascategorias/:action/:id",
            //     name: "Empresas / Categorias",
            //     icon: LocalConvenienceStoreIcon,
            //     component: params => <EmpresasCategorias params={params} />
            // }
        ]
    },
    {
        type: "group",
        name: "App",
        icon: PhoneIphoneIcon,
        id: "app",
        childrens: [
            {
                path: "publicacoes/:action/:id",
                name: <Trans i18nKey="routes.app.publications">Publicações</Trans>,
                icon: ShareIcon,
                component: params => <Publicacoes params={params} />
            },
            {
                path: "membros/:action/:id",
                name: <Trans i18nKey="routes.app.members">Membros</Trans>,
                icon: PeopleIcon,
                component: params => <Membros params={params} />
            },
            {
                path: "agenda/:action/:id",
                name: <Trans i18nKey="routes.app.schedule">Agenda</Trans>,
                icon: EventNoteIcon,
                component: params => <Agenda params={params} />
            },
            {
                path: "denuncias/:action/:id",
                name: <Trans i18nKey="routes.app.complaints">Denúncias</Trans>,
                icon: SmsFailedIcon,
                component: params => <Denuncias params={params} />
            },
            {
                path: "autorizacoesmembro/:action/:id",
                name: <Trans i18nKey="routes.app.authorizations">Autorizações</Trans>,
                icon: LockOpenIcon,
                component: params => <AutorizacoesMembro params={params} />
            },
            {
                path: "membrostokens/:action/:id",
                name: <Trans i18nKey="routes.app.logins">Logins</Trans>,
                icon: VpnKeyIcon,
                component: params => <MembrosTokens params={params} />
            },

        ]
    },
    {
        type: "group",
        name: <Trans i18nKey="routes.registrations.main">Cadastros</Trans>,
        icon: FolderIcon,
        id: "cadastros",
        childrens: [
            {
                path: "lojas/:action/:id",
                name: <Trans i18nKey="routes.registrations.stores">Lojas</Trans>,
                icon: AccountBalanceIcon,
                component: params => <Lojas params={params} />
            },
            {
                path: "potencias/:action/:id",
                name: <Trans i18nKey="routes.registrations.masonics">Potências</Trans>,
                icon: HomeWorkIcon,
                component: params => <Potencias params={params} />
            },
            {
                path: "entidades/:action/:id",
                name: <Trans i18nKey="routes.registrations.entities">Entidades</Trans>,
                icon: SchoolIcon,
                component: params => <Entidades params={params} />
            },
            {
                path: "ritos/:action/:id",
                name: <Trans i18nKey="routes.registrations.rites">Ritos</Trans>,
                icon: BookIcon,
                component: params => <Ritos params={params} />
            },
        ]
    },
    {
        type: "group",
        name: <Trans i18nKey="routes.management.main">Administração</Trans>,
        icon: BuildIcon,
        id: "admin",
        childrens: [
            {
                path: "ajudas/:action/:id",
                name: <Trans i18nKey="routes.management.help">Ajuda</Trans>,
                icon: LiveHelpIcon,
                component: params => <Ajudas params={params} />
            },
            {
                path: "termosuso/:action/:id",
                name: <Trans i18nKey="routes.management.use-terms">Termos de uso</Trans>,
                icon: MenuBookIcon,
                component: params => <TermosUso params={params} />
            },
            {
                path: "termoscadastro/:action/:id",
                name: <Trans i18nKey="routes.management.terms-conditions-registration">Termos e condições - cadastro</Trans>,
                icon: MenuBookIcon,
                component: params => <TermosCadastro params={params} />
            },
            {
                path: "usuarios/:action/:id",
                name: <Trans i18nKey="routes.management.users">Usuários</Trans>,
                icon: AccountCircleIcon,
                component: params => <Usuarios params={params} />
            },
        ]
    }
];

export default dashboardRoutes;
