import React from "react";
import PropTypes from "prop-types";

// Estilo
import { CustomCard } from './styles'

function Card({ ...props }) {

    const {children, ...rest} = props
    return (
        <CustomCard {...rest}>
            {children}
        </CustomCard>
    );
}

Card.propTypes = {
  plain: PropTypes.bool,
  profile: PropTypes.bool,
  chart: PropTypes.bool,
  children: PropTypes.node
};

export default Card;
