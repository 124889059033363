// Geral
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getQueryParams } from "hookrouter";

// Ações
import * as list from "actions/list";
import { fetchListComentarios } from "actions/publicacoes";

// Componentes
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Container, Theme } from "assets/jss/doSul-dashboard";

const view = "comentarioPublicacao";

const BoxComentario = styled.div`
    border-bottom: ${props => props.noborder ? 'none' : '1px solid #333'};
    padding: 20px;
    ${props => {
        if (props.denuncia) {
            return `
                border: 3px solid ${Theme.dangerColor[0]};
                border-radius: 10px;
            `
        }
    }}
`

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const RoundedImg = styled.img`
    width: 35px;
    display: block;
    border-radius: 50%;
    margin-right: 15px;
`

const Texto = styled.p`
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 10px;
    background-color: #cbecf1;

    ${props => {
        return `&:after{
            content: "${props.datahora}";
            display: block;
            font-size: 10px;
            text-align:right;
        }`
    }}

    ${props => {
        if (props.denuncia) {
            return `background-color: #ffcbcb;
            
            &:before{
                content: "DENUNCIADO";
                display: block;
                font-size: 10px;
                text-align:right;
            }
            `
        }
    }}
`

const Comentario = ({ id, membro, foto, texto, data, comentarios, noborder = false, denuncia = "0" }) => {
    const queryParams = getQueryParams()
    const denunciado = ((queryParams && queryParams.denuncia && queryParams.denuncia === id) || (denuncia === "1"));
    return <BoxComentario noborder={noborder} denuncia={denunciado}>
        <FlexRow>
            <small style={{ display: 'flex', alignItems: 'center', marginBottom: '2px' }}>
                <RoundedImg src={foto} title={membro} alt={membro} />
                {membro}
            </small>
        </FlexRow>
        <Texto denuncia={denunciado} datahora={data}>{texto}</Texto>
        {comentarios && comentarios.length > 0 &&
            comentarios.map((val, idx) => {
                return <Comentario
                    key={idx}
                    id={btoa(val.IdPublicacaoComentario)}
                    foto={val.mFoto}
                    membro={val.mNome}
                    texto={val.Texto}
                    data={val.DataHoraDisplay}
                    comentarios={val.Comentarios}
                    noborder={true}
                    denuncia={val.Denunciado} />
            })
        }
    </BoxComentario>
}

export default function PublicacaoListComentarios({ id }) {
    useEffect(() => {
        list.start(view, "IdPublicacao", fetchListComentarios, () => { }, () => { }, id);
    }, [id]);

    const listData = useSelector(state => state.list[view]);

    return (
        <Container style={{ paddingTop: '10px' }}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    {listData && (
                        <>
                            {listData.results.map((val, idx) => {
                                return <Comentario
                                    key={idx}
                                    id={btoa(val.IdPublicacaoComentario)}
                                    foto={val.mFoto}
                                    membro={val.mNome}
                                    texto={val.Texto}
                                    data={val.DataHoraDisplay}
                                    comentarios={val.Comentarios}
                                    denuncia={val.Denunciado} />
                            })}
                        </>
                    )}
                </GridItem>
            </GridContainer>
        </Container>
    );
}
