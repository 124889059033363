import axios from "axios";
import {
    defaultFetchList, defaultChangeStatus, defaultDelete, defaultSave, defaultFetch, defaultLoadOptions
} from "./default";

const model = "cupons";
const idField = "IdCupom";

export const fetchList = (reqData, resolve, reject, dispatch) => defaultFetchList(model, reqData, resolve, reject, dispatch);
export const fetch = (id, callback) => defaultFetch(model, id, callback);
export const changeStatus = (id, status, afterChange, afterError) => defaultChangeStatus(model, id, status, afterChange, afterError);
export const deleteRecord = (id, afterDelete, afterError) => defaultDelete(model, id, afterDelete, afterError);
export const save = (data, actions, afterSave, afterError) => defaultSave(model, idField, data, false, actions, afterSave, afterError);

export const loadOptions = (inputValue) => defaultLoadOptions(inputValue, model, ["IdCupom", "Nome", "Codigo", "Desconto"]);
export const loadDesconto = inputValue => new Promise((resolve, reject) => {
    axios
        .get(`${model}/codigo/${inputValue}`)
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            let erro = "Não foi possível obter o cupom";
            if (err.response && err.response.data) {
                erro = err.response.data;
            }
            reject(erro);
        });
});