import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import countries from "world-countries";
import FormSelect from "components/FormSelect";
import axios from "axios";

import labelCountries from "assets/json/countriesDocuments"
 
export default function Nacionalidade({ pais, setLabelDocumentName, setLabelCep, form, name }) {
    
    const selecionado = useMemo(() => {
        let sel = countries.filter((val) => val.cca2 === pais || val.cca3 === form.values.Nacionalidade);
		if (sel && sel.length > 0) {
            const countryProps = labelCountries.find(
                country => country.PaisNome[1] === sel[0].name.common,
            );
            
            setLabels(countryProps);

			return sel[0];
		}
		return null;
	}, [pais]);

	const loadOptions = useCallback((search)=>{
		return new Promise((resolve, reject) => {
			const filteredCountries = countries.filter((country) => {
				if (country.idd.suffixes[0] === undefined) {
					return false;
				}

				if (!search) return true;

				return new RegExp(search, 'gi').test(country.name.common)
				 || new RegExp(search, 'gi').test(`${country.idd.root}${country.idd.suffixes[0]}`);
			})
			resolve(filteredCountries);
		});
	}, []);

    async function setFieldValues(value) {
        if (value) {
            resetaCampos(name, value.cca2, value.cca3)

            await getIdPais(value.name.common);
            
            const countryProps = labelCountries.find(country => country.PaisNome[1] === value.name.common);
            countryProps !== null ? setLabels(countryProps) : setLabels(null);
        
        } else {
            resetaCampos(name, null, null)
            setLabels(null);
        }
    }

    function setLabels (countryProps) {
        if(typeof countryProps !== 'undefined') {
            setLabelDocumentName(countryProps.DocumentoSigla || 'Documento Único Nacional');
            setLabelCep(countryProps.DocumentoSigla === "CNPJ" ? "CEP" : "Código Postal");
        } else {
            setLabelDocumentName('Documento Único Nacional');
            setLabelCep("Código Postal");
        }
    }

    const getIdPais = async query => {
        const response = await axios.get('paises', {params: {value: query}});
        
        if (response.status === 200) {
            if (typeof response.data[0] !== 'undefined') {
                formataPaisForm(response.data[0].IdPais, response.data[0].Nome)
            }
            else formataPaisForm(null, null)
        } else 
            formataPaisForm(null, null)
    };

    function resetaCampos(field1, field2, field3) {
        form.setFieldValue(`${field1}Pais`, field2);
        form.setFieldValue(`${field1}`, field3);

        form.setFieldValue(`PaisForm`, field3);
    }

    function formataPaisForm(field1, field2) {
        form.setFieldValue(`pais`, field1)
        form.setFieldValue(`Pais`, field1)
        form.setFieldValue(`NomePais`, field2)
        form.setFieldValue(`PaisNome`, field2)
    }

	return (
		<FormSelect
			field={{
				name: `${name}Codigo`,
				placeholder: "Digite para localizar...",
				isSearchable: true,
				isClearable: false,
				defaultOptions: true,
				loadOptions: loadOptions,
				getOptionLabel: (option) => `${option.flag} ${option.name.common}`,
				loadingMessage: () => "Carregando...",
				noOptionsMessage: (input) => input.inputValue === "" ? "Digite para localizar" : "Nenhum resultado encontrado",
				value: selecionado,
				onChange: (value) => setFieldValues(value),
			}}
			form={form}
			async
			label="Nacionalidade *"
		/>
	);
}

Nacionalidade.propTypes = {
	pais: PropTypes.string,
	form: PropTypes.object.isRequired,
};

Nacionalidade.defaultProps = {
	pais: null,
	name: "Nacionalidade",
};
