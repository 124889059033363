import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { defaultFetchList, defaultChangeStatus, defaultDelete, defaultSave, defaultFetch } from './default';
import { DEFAULT_TOAST_POSITION, DEFAULT_TOAST_TIMEOUT } from "helpers/consts";

const MySwal = withReactContent(Swal);

const model = "usuarios";
const idField = "IdUsuario";

export const fetchList = (reqData, resolve, reject, dispatch) => defaultFetchList(model, reqData, resolve, reject, dispatch)
export const fetch = (id, callback) => defaultFetch(model, id, callback)
export const changeStatus = (id, status, afterChange, afterError) => defaultChangeStatus(model, id, status, afterChange, afterError)
export const deleteRecord = (id, afterDelete, afterError) => defaultDelete(model, id, afterDelete, afterError)
export const save = (data, actions, afterSave, afterError) => defaultSave(model, idField, data, true, actions, afterSave, afterError)

export const loadPermissionsOptions = (callback) => {
    axios.get(`${model}/permissions_options`)
    .then(res => {
        callback(res.data)
    })
    .catch(err => {
        if(err.response){
            MySwal.fire({
                type: 'error',
                title: "Não foi possível obter os dados. " + err.response.data.message,
                toast: true,
                position: DEFAULT_TOAST_POSITION,
                showConfirmButton: false,
                timer: DEFAULT_TOAST_TIMEOUT,
            })
        }
    })
}

export const savePreferenciasLayout = (data, callback) => {
    axios.post(`${model}/save_preferencias_layout`, data)
    .then(res => {
        callback(res.data)
    })
    .catch(err => {
        if(err.response){
            MySwal.fire({
                type: 'error',
                title: "Não foi possível salvar os dados. " + err.response.data.message,
                toast: true,
                position: DEFAULT_TOAST_POSITION,
                showConfirmButton: false,
                timer: DEFAULT_TOAST_TIMEOUT,
            })
        }
    })
}

export const getPreferenciasLayout = () => {
    axios.get(`${model}/get_preferencias_layout`)
    .then(res => {
        localStorage.setItem('layout_preferences', res.data.result);
    })
    .catch(err => {
        if(err.response){
            MySwal.fire({
                type: 'error',
                title: "Não foi possível obter os dados. " + err.response.data.message,
                toast: true,
                position: DEFAULT_TOAST_POSITION,
                showConfirmButton: false,
                timer: DEFAULT_TOAST_TIMEOUT,
            })
        }
    })
}