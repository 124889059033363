import * as Yup from "yup";
import { addInYup as addCep } from "helpers/validation/Cep";
import { addInYup as addCnpjCpf } from "helpers/validation/CnpjCpf";

addCep();
addCnpjCpf();

const validationSchema = Yup.object().shape({
    empresa: Yup.string().required("Por favor selecione uma empresa"),
    planos: Yup.string().required("Por favor selecione pelo menos um plano"),
    CnpjCpf: Yup.string().required("Por favor informe o CNPJ/CPF").cnpjCpf("Informe um CNPJ/CPF válido"),
    Nome: Yup.string().required("Por favor digite o nome"),
    Email: Yup.string().required("Por favor digite o e-mail").email("Por favor digite um e-mail válido"),
    Endereco: Yup.string().required("Por favor digite o endereço"),
    Bairro: Yup.string().required("Por favor digite o bairro"),
    Cep: Yup.string().required("Por favor digite o Cep").cep("Por favor digite um CEP válido"),
    cidade: Yup.string().required("Por favor selecione a cidade"),
    Telefone: Yup.string().required("Por favor digite o Telefone")
});

export default validationSchema;
