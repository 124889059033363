import React from "react";
import PropTypes from "prop-types";

// Estilo
import { CustomCardIcon } from "./styles"

function CardIcon({ ...props }) {
  const { children, ...rest } = props;
  return (
    <CustomCardIcon {...rest}>
      {children}
    </CustomCardIcon>
  );
}

CardIcon.propTypes = {
  children: PropTypes.node
};

export default CardIcon;
