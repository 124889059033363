import styled from 'styled-components';
import FormHelperText from "@material-ui/core/InputLabel";
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import { Theme } from "assets/jss/doSul-dashboard";
import { hasClass, addClass, removeClass } from "helpers/cssClass";

export const HelperTextError = styled(FormHelperText)`
    color: ${Theme.dangerColor[0]}!important;
    font-size: 12px!important;
`

export const CustomSelect = styled(Select).attrs(props => ({
    className: 'custom_select',
    theme: theme => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: Theme.grayColor[11],
            primary: Theme.grayColor[2],
            primary50: Theme.grayColor[10],
        },
    })
}))`
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
    box-sizing: border-box;
    align-items: center;
    line-height: 1.1875em;
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    margin: 0;
    display: block;
    padding: 10px 0 0px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    margin-top: 15px;
    -webkit-tap-highlight-color: transparent;

    ${(props) => {
        let style = "";

        if (props.defaultValue && props.defaultValue.value !== '' && props.defaultValue.value !== null && props.defaultValue.value !== undefined) {
            style += `
                    &::before{
                        top: 0!important;
                        left: 0!important;
                        position: absolute!important;
                        transform: translate(0, 1.5px) scale(0.75)!important;
                        transform-origin: top left!important;
                    }
                `;
            return style;
        }

        if (props.value && props.value.value !== '' && props.value.value !== null && props.value.value !== undefined) {
            style += `
                    &::before{
                        top: 0!important;
                        left: 0!important;
                        position: absolute!important;
                        transform: translate(0, 1.5px) scale(0.75)!important;
                        transform-origin: top left!important;
                    }
                `;
            return style;
        }

        return style;
    }}
    & > div {
        border:none;
        box-shadow: none!important;
        padding-bottom: 10px!important;
    }
    & > div > div > span {
        background-color: red;
    }

    & > div{
        &:nth-child(3){
            border: 1px solid rgba(0, 0, 0, 0.42);
            z-index: 9999;
        }
    }

    &::before {
        transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        top: 0;
        left: 0;
        position: absolute;
        transform: translate(0, 24px) scale(1);
        color: #AAAAAA!important;
        padding: 0;
        font-size: 1rem;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        z-index: 1001;
        font-weight: 400;
        line-height: 1;
        content: '${(props) => props['data-label'] ? props['data-label'] : 'Selecione'}';
    }

    &::after {
        left: 0;
        right: 0;
        bottom: 0;
        content: "\\00a0";
        position: absolute;
        transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        pointer-events: none;
        border-color: ${Theme.grayColor[4]}!important;
        margin-bottom: 10px!important;
    }

    &:active, &:focus{
        &::before{
            top: 0;
            left: 0;
            position: absolute;
            transform: translate(0, 1.5px) scale(0.75);
            transform-origin: top left;
        }
        &::after{
            border-color: ${Theme.primaryColor[0]}!important;
            border-width: 2px!important;
        }
    }
`

export const CustomAsyncSelect = styled(AsyncSelect).attrs(props => ({
    className: props.className ? props.className : 'custom_async_select',
    isSearchable: props.isSearchable ? props.isSearchable : true,
    isClearable: props.isClearable !== undefined ? props.isClearable : true,
    defaultOptions: props.defaultOptions ? props.defaultOptions : true,
    getOptionLabel: props.getOptionLabel ? props.getOptionLabel : (option) => `${option['Nome']}`,
    loadingMessage: props.loadingMessage ? props.loadingMessage : () => "Carregando...",
    noOptionsMessage: props.noOptionsMessage ? props.noOptionsMessage : (input) => input.inputValue === '' ? "Digite para localizar..." : "Nenhum resultado encontrado",
    theme: theme => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: Theme.grayColor[11],
            primary: Theme.grayColor[2],
            primary50: Theme.grayColor[10],
        },
    })
}))`
    margin: 15px 0 0 0!important;
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
    box-sizing: border-box;
    align-items: center;
    line-height: 1.1875em;
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    margin: 0;
    display: block;
    padding: 10px 0 0px;
    min-width: 0;
    background-color: white;
    box-sizing: content-box;
    -webkit-tap-highlight-color: transparent;

    ${(props) => {
        let style = "";

        if (props.defaultValue && props.defaultValue !== '' && props.defaultValue !== null && props.defaultValue !== undefined) {
            style += `
                    &::before{
                        top: 0!important;
                        left: 0!important;
                        position: absolute!important;
                        transform: translate(0, 1.5px) scale(0.75)!important;
                        transform-origin: top left!important;
                    }
                `;
            return style;
        }

        if (props.value && props.value !== '' && props.value !== null && props.value !== undefined) {
            style += `
                    &::before{
                        top: 0!important;
                        left: 0!important;
                        position: absolute!important;
                        transform: translate(0, 1.5px) scale(0.75)!important;
                        transform-origin: top left!important;
                    }
                `;
            return style;
        }

        return style;
    }}
    & > div {
        border:none;
        box-shadow: none!important;
        padding-bottom: 10px;
    }
    &.noMarginBottom {
        margin: 0px!important;
        padding: 0px!important;
    }
    &.noMarginBottom > div {
        padding-bottom: 0px!important;
        background-color: white;
    }
    & > div > div > span {
        background-color: transparent!important;
    }

    & > div{
        &:nth-child(3){
            border: 1px solid rgba(0, 0, 0, 0.42);
            z-index: 9999!important;
        }
    }

    &::before {
        transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        top: 0;
        left: 0;
        position: absolute;
        transform: translate(0, 24px) scale(1);
        color: #AAAAAA!important;
        padding: 0;
        font-size: 1rem;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        z-index: 1001;
        font-weight: 400;
        line-height: 1;
        content: '${(props) => props['data-label'] ? props['data-label'] : ''}';
    }

    &::after {
        left: 0;
        right: 0;
        bottom: 0;
        content: "\\00a0";
        position: absolute;
        transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        pointer-events: none;
        border-color: ${Theme.grayColor[4]}!important;
        margin-bottom: 10px!important;
    }
    &.noMarginBottom::after {
        display: none!important;
    }

    &:active, &:focus{
        &::before{
            top: 0;
            left: 0;
            position: absolute;
            transform: translate(0, 1.5px) scale(0.75);
            transform-origin: top left;
        }
        &::after{
            border-color: ${Theme.primaryColor[0]}!important;
            border-width: 2px!important;
        }
    }
`

document.addEventListener('focus', (e) => {
    let selects = document.querySelectorAll('.custom_select');
    if (selects.length > 0) {
        for (let i = 0; i < selects.length; i++) {
            if (selects[i] && selects[i].querySelector('input') === e.target) {
                if (!hasClass(selects[i], 'active-react-select')) {
                    addClass(selects[i], 'active-react-select');
                }
                return;
            }
        }
    }

    let selects_async = document.querySelectorAll('.custom_async_select');
    if (selects_async.length > 0) {
        for (let i = 0; i < selects_async.length; i++) {
            if (selects_async[i] && selects_async[i].querySelector('input') === e.target) {
                if (!hasClass(selects_async[i], 'active-react-select')) {
                    addClass(selects_async[i], 'active-react-select');
                }
                return;
            }
        }
    }
}, true);

document.addEventListener('blur', (e) => {
    let selects = document.querySelectorAll('.custom_select');
    if (selects.length > 0) {
        for (let i = 0; i < selects.length; i++) {
            if (selects[i] && selects[i].querySelector('input') === e.target) {
                if (hasClass(selects[i], 'active-react-select')) {
                    removeClass(selects[i], 'active-react-select');
                }
                break;
            }
        }
    }

    let selects_async = document.querySelectorAll('.custom_async_select');
    if (selects_async.length > 0) {
        for (let i = 0; i < selects_async.length; i++) {
            if (selects_async[i] && selects_async[i].querySelector('input') === e.target) {
                if (hasClass(selects_async[i], 'active-react-select')) {
                    removeClass(selects_async[i], 'active-react-select');
                }
                break;
            }
        }
    }
}, true);