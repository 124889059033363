import React from "react";
import axios from "axios";
import objToQueryString from "helpers/objToQueryString";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DEFAULT_TOAST_POSITION, DEFAULT_TOAST_TIMEOUT } from "helpers/consts";
import Button from "components/CustomButton";

const MySwal = withReactContent(Swal);

export function defaultFetchList(model, reqData, resolve, reject, dispatch) {
    const ignoreNulls = true;

    axios
        .get(`${model}/list${objToQueryString(reqData, ignoreNulls)}`)
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            let erro = { message: "Não foi possível obter os dados" };
            if (err.response) {
                erro = err.response.data;
            }
            reject(erro.message);
        });
}

export const defaultChangeStatus = (
    model,
    id,
    status = 0,
    afterChange,
    afterError,
    justificativa = null
) => {
    axios
        .post(`${model}/change-status/${btoa(id)}`, { status, justificativa })
        .then(res => {
            MySwal.fire({
                toast: true,
                position: DEFAULT_TOAST_POSITION,
                showConfirmButton: false,
                timer: DEFAULT_TOAST_TIMEOUT,
                type: "success",
                title: "Status alterado com sucesso"
            });

            if (afterChange) afterChange();
        })
        .catch(err => {
            if (err.response && err.response.data) {
                MySwal.fire({
                    type: "error",
                    title: `Não foi possível alterar o status. ${err.response.data.message}`,
                    toast: true,
                    position: DEFAULT_TOAST_POSITION,
                    showConfirmButton: false,
                    timer: DEFAULT_TOAST_TIMEOUT
                });
                if (afterError) afterError();
            }
        });
};

export const defaultDelete = (model, id, afterDelete, afterError) => {
    MySwal.fire({
        title: "",
        type: "warning",
        html:
            "Deseja realmente excluir o registro? <br>Essa ação não pode ser revertida!",
        showCancelButton: true,
        confirmButtonText: (
            <Button component="span" size="lg" color="danger" title="Sim, excluir">
                Sim, excluir
            </Button>
        ),
        cancelButtonText: (
            <Button component="span" size="lg" color="white" title="Cancelar">
                Cancelar
            </Button>
        ),
        reverseButtons: true
    }).then(result => {
        if (result.value) {
            axios
                .delete(`${model}/${btoa(id)}`)
                .then(res => {
                    MySwal.fire({
                        toast: true,
                        position: DEFAULT_TOAST_POSITION,
                        showConfirmButton: false,
                        timer: DEFAULT_TOAST_TIMEOUT,
                        type: "success",
                        title: "Registro excluído com sucesso"
                    });

                    if (afterDelete) afterDelete();
                })
                .catch(err => {
                    if (err.response && err.response.data) {
                        MySwal.fire({
                            type: "error",
                            title: `Não foi possível excluir o registro. ${err.response.data.message}`,
                            toast: true,
                            position: DEFAULT_TOAST_POSITION,
                            showConfirmButton: false,
                            timer: DEFAULT_TOAST_TIMEOUT
                        });
                        if (afterError) afterError();
                    }
                });
        }
    });
};

export const defaultSave = (
    model,
    idField,
    data = {},
    formData = false,
    actions,
    afterSave,
    afterError
) => {
    let sendData = { ...data };

    if (formData) {
        sendData = new FormData();
        Object.keys(data).forEach(key => {
            if (data[key] !== null) sendData.append(key, data[key]);
        });
    }
    let url = `${model}/`;
    if (data[idField]) {
        url = `${model}/${btoa(data[idField])}`;
    }

    axios
        .post(url, sendData)
        .then(res => {
            MySwal.fire({
                toast: true,
                position: DEFAULT_TOAST_POSITION,
                showConfirmButton: false,
                timer: DEFAULT_TOAST_TIMEOUT,
                type: "success",
                title: "Registro salvo com sucesso"
            });
            actions.setSubmitting(false);

            if (afterSave) afterSave(res);
        })
        .catch(err => {
            if (err.response) {
                MySwal.fire({
                    type: "error",
                    title: `Não foi possível salvar o registro. ${err.response.data.message}`,
                    toast: true,
                    position: DEFAULT_TOAST_POSITION,
                    showConfirmButton: false,
                    timer: DEFAULT_TOAST_TIMEOUT
                });
                actions.setErrors(err.response.data.errors);
            }
            actions.setSubmitting(false);

            if (afterError) afterError();
        });
};

export function defaultFetch(model, id, callback = () => { }) {
    axios
        .get(`${model}/${btoa(id)}`)
        .then(res => {
            callback(res.data);
        })
        .catch(err => {
            if (err.response) {
                MySwal.fire({
                    type: "error",
                    title: `Não foi possível obter os dados. ${err.response.data.message}`,
                    toast: true,
                    position: DEFAULT_TOAST_POSITION,
                    showConfirmButton: false,
                    timer: DEFAULT_TOAST_TIMEOUT
                });
            }
        });
}

export const defaultLoadOptions = (
    inputValue = "",
    model,
    fieldFrom = [],
    fieldTo = fieldFrom,
    filters = {}
) => new Promise((resolve, reject) => {
    if (fieldFrom.length !== fieldTo.length) {
        reject("Não é possível utilizar arrays de tamanhos diferentes");
    }
    const reqData = {
        s: inputValue,
        pg: 1
    };
    Object.assign(reqData, filters);
    const promise = new Promise((res, rej) => {
        defaultFetchList(model, reqData, res, rej);
    });

    promise.then(data => {
        const res = [];
        for (let i = 0; i < data.results.length; i++) {
            const tmp = {};
            for (let j = 0; j < fieldFrom.length; j++) {
                tmp[fieldTo[j]] = data.results[i][fieldFrom[j]];
            }
            res.push(tmp);
        }
        resolve(res);
    });
});
