import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import pt from './locales/pt/pt.json';
import en from './locales/en/en.json';

const langDetectorOptions = {
    order: ['localStorage', 'cookie', 'navigator'], /* Ordenacao das preferencias do usuario */
    lookupCookie: 'locale',
    lookupLocalStorage: 'locale',

    /* cache do idioma */
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], /* idiomas que nao devem persistir */

    checkWhitelist: true, /* traducao apenas dos idiomas autorizados */
};

i18n.on('languageChanged', function (lng) {
    localStorage.setItem("lng", lng);
})

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: {
            pt: {
                translation: pt
            },
            en: {
                translation: en
            }
        },
        fallbackLng: 'pt',
        debug: process.env.NODE_ENV === 'production' ? false : true,
        whitelist: ['pt', 'en'], /* Idiomas que sao permitidos no sistema */
        detection: langDetectorOptions,
        interpolation: {
            escapeValue: false,
        }
})

export default i18n;