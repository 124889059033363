/* Geral / Bibliotecas */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import InputLabel from "@material-ui/core/InputLabel";
import styled from "styled-components";
import jwt_decode from "jwt-decode";

/* Ícones */
import Save from "@material-ui/icons/Save";
import Done from "@material-ui/icons/Done";
import Block from "@material-ui/icons/Block";
import Delete from "@material-ui/icons/Delete";

/* Métodos / Ações */
import * as form from "actions/form";
import { fetch, save } from "actions/entidades";
import { loadCidades } from "actions/cidadesNew";
import { getCoordinates } from "actions/coordinates";
import { JWT_TOKEN } from "helpers/consts";

/* Componentes */
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import FormInput from "components/FormInput";
import Button from "components/CustomButton";
import { Container } from "assets/jss/doSul-dashboard";
import Loading from "components/Loading";
import FormSelect from "components/FormSelect";
import ImageUpload from "components/CustomInput/ImageUpload";

import PaisForm from "components/CustomInput/Pais";
import EstadoForm from "components/CustomInput/Estado";
import PaisTelefone from "components/CustomInput/PaisTelefone";
import Nacionalidade from "components/CustomInput/Nacionalidade";

/* Validação */
import validationSchema from "./validation";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

/* stilos */
const CustomLabel = styled(InputLabel)`
    text-align: center;
`;

const Model = {
    IdEntidade: null,
    NacionalidadePais: "",
    Nacionalidade: "",
    Nome: "",
    Endereco: "",
    Numero: "",
    Complemento: "",
    Bairro: "",
    
    Cep: "",
    pais: "",
    Pais: "",
    PaisForm: "",
    PaisNome: "",
    estado: "",
    Estado: "",
    EstadoForm: "",
    EstadoNome: "",
    cidade: "",
    Cidade: "",
    CidadeForm: "",
    CidadeNome: "",

    TelefonePais: "",
    TelefonePaisCodigo: "",
    Telefone: "",
    Telefone2Pais: "",
    Telefone2PaisCodigo: "",
    Telefone2: "",
    Logo: "",
    Capa: "",
    Latitude: "",
    Longitude: "",
    LogoChanged: false,
    CapaChanged: false,
    Status: 1,
};

export default function EntidadesForm({
    id,
    changeStatus,
    deleteRecord,
    afterSave,
    afterChangeStatusSuccess,
    afterDeleteSuccess,
}) {
    const token = jwt_decode(localStorage.getItem(JWT_TOKEN));

    const formData = useSelector((state) => state.form);
    const permissions = useSelector((state) => state.permissions);

    const [labelCep, setLabelCep] = useState('CEP');
    const [labelDocumentName, setLabelDocumentName] = useState('Documento Único Nacional');

    const afterFetch = null; // Sobrescrever se precisar

    useEffect(() => {
        form.start(
            "entidades",
            "IdEntidade",
            id,
            Model,
            fetch,
            save,
            changeStatus,
            deleteRecord,
            afterSave,
            afterChangeStatusSuccess,
            afterDeleteSuccess,
            afterFetch
        );
        return form.stop;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // É chamado toda vez que muda o ID
        form.setModel(Model);
        if (id) {
            form.fetchData(id);
        }
    }, [id]);

    if (formData.loading) {
        return <Loading />;
    }

    const disabled = permissions && permissions[formData.root] < 2;

    // const handleSubmit = form.handleSubmit    ----> :) Poderia ser usado o padrão se não precisar nenhum tratamento
    const handleSubmit = async (values, actions) => {
        if (values.Pais && values.pais === undefined) values.pais = values.Pais;
        if (values.Estado && values.estado === undefined) values.estado = values.Estado;

        // Para não resetar a logo caso não seja trocada
        if (!values.LogoChanged) delete values.Logo;
        if (!values.CapaChanged) delete values.Capa;

        /* Checa se os valores de latitude e longitude vieram vazios  */
        
        if (!values.Latitude || !values.Longitude) {
            const { lat, lng } = await fnGetCoordinates({ values: values});
        
            if (lat) values.Latitude = lat;
            if (lng) values.Longitude = lng;    
        }

        formData.save(values, actions, formData.afterSave);
    };

     /* Recupera as coordenadas por meio do endereco informado */
     const MySwal = withReactContent(Swal)
     async function fnGetCoordinates(props) {
         let { Endereco, Bairro, EstadoNome, CidadeNome, PaisNome } = props.values;
         
         if (Endereco.length > 0 && Bairro.length > 0 && EstadoNome.length > 0 && CidadeNome.length > 0 && PaisNome.length > 0) {
             MySwal.fire({
                 toast: true,
                 position: 'center',
                 showConfirmButton: false,
                 timer: 1000,
                 type: 'info',
                 title: 'Aguarde um instante'
             })
 
             const response = await getCoordinates(props);
             const { lat, lng } = response.results[0].geometry.location;
     
             if (props.setFieldValue !== undefined) {
                 props.setFieldValue(`Latitude`, lat.toFixed(8));
                 props.setFieldValue(`Longitude`, lng.toFixed(8));
             } else {
                 return response.results[0].geometry.location;
             }
         } else {
             MySwal.fire({
                 toast: true,
                 position: 'center',
                 showConfirmButton: false,
                 timer: 3000,
                 type: 'warning',
                 title: 'Preencha o logradouro completo primeiro'
             })
         }
     }

    return (
        <Container>
            <Formik
                validationSchema={validationSchema}
                initialValues={formData.model}
                onSubmit={handleSubmit}
                render={(FormikProps) => (
                    <Form onSubmit={FormikProps.handleSubmit}>
                        <GridContainer>
                            <GridItem xs={12} lg={4}>
                                <Nacionalidade
                                    pais={FormikProps.values.Nacionalidade}
                                    setLabelDocumentName={setLabelDocumentName}
                                    setLabelCep={setLabelCep}
                                    form={FormikProps}
                                />
                            </GridItem>

                            <GridItem xs={12} lg={8}>
                                <FormInput
                                    field={{
                                        name: "Nome",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Nome *"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "Cep",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label={labelCep}
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <PaisForm
                                    pais={FormikProps.values.PaisForm}
                                    nacionalidade={FormikProps.values.Nacionalidade}
                                    form={FormikProps}
                                    setLabelCep={setLabelCep}
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <EstadoForm
                                    estado={FormikProps.values.EstadoForm}
                                    pais={FormikProps.values.Pais}
                                    nacionalidade={FormikProps.values.Nacionalidade}
                                    form={FormikProps}
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                {
                                    (FormikProps.values.Pais === 1058 || FormikProps.values.Pais === 840) && +FormikProps.values.Estado > 0
                                    ? 
                                    <FormSelect
                                        field={{
                                            name: "cidade",
                                            placeholder: "Digite para localizar...",
                                            isSearchable: true,
                                            loadOptions: (cidade) => {
                                                return loadCidades({ cidade, estado: FormikProps.values.Estado, pais: FormikProps.values.Pais })
                                            },
                                            getOptionLabel: (option) =>
                                                `${option.Nome}${option.NomeEstado ? ` - ${option.NomeEstado}` : ""}`,
                                            loadingMessage: () => "Carregando...",
                                            noOptionsMessage: (input) =>
                                                input.inputValue === "" ? "Digite para localizar..." : "Nenhum resultado encontrado",
                                            disabled,
                                            value: FormikProps.values.cidade
                                                ? { IdCidade: FormikProps.values.cidade, Nome: FormikProps.values.CidadeNome }
                                                : null,
                                            onChange: (value) => {
                                                if (value) {
                                                    if (value.IdCidade) {
                                                        FormikProps.setFieldValue("cidade", btoa(value.IdCidade) ); 
                                                        FormikProps.setFieldValue("Cidade", btoa(value.IdCidade) ); 
                                                    }
                                                    FormikProps.setFieldValue("NomeCidade", value.Nome);
                                                    FormikProps.setFieldValue("CidadeNome", value.Nome);
                                                    FormikProps.setFieldValue("CidadeForm", value.Nome);
                                                } else {
                                                    FormikProps.setFieldValue("cidade", "");
                                                    FormikProps.setFieldValue("Cidade", "");
                                                    FormikProps.setFieldValue("NomeCidade", "");
                                                    FormikProps.setFieldValue("CidadeForm", "");
                                                }
                                            },
                                        }}
                                        form={FormikProps}
                                        async
                                        label="Cidade *"
                                    />
                                    :
                                    <FormInput
                                        field={{
                                            name: "CidadeNome",
                                            disabled,
                                        }}
                                        form={FormikProps}
                                        label="Cidade *"
                                    />
                                }
                            </GridItem>

                            <GridItem xs={12} lg={8}>
                                <FormInput
                                    field={{
                                        name: "Endereco",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Endereco *"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={4}>
                                <FormInput
                                    field={{
                                        name: "Numero",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Número"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "Complemento",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Complemento"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "Bairro",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Bairro *"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={4}>
                                <FormInput
                                    field={{
                                        name: "Latitude",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Latitude"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={4}>
                                <FormInput
                                    field={{
                                        name: "Longitude",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Longitude"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={4}>
                                <Button
                                    color="info"
                                    type="button"
                                    left
                                    onClick={() => fnGetCoordinates(FormikProps)}
                                    ref={(input) => (this.inputElement = input)}
                                >
                                    Pegar coordenadas
                                </Button>
                            </GridItem>
                            
                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "Telefone",
                                        disabled,
                                        startAdornment: (
                                            <PaisTelefone
                                                pais={
                                                    FormikProps.values
                                                        .TelefonePais
                                                }
                                                codigo={
                                                    FormikProps.values
                                                        .TelefonePaisCodigo
                                                }
                                                form={FormikProps}
                                            />
                                        ),
                                    }}
                                    form={FormikProps}
                                    label="Telefone *"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "Telefone2",
                                        disabled,
                                        startAdornment: (
                                            <PaisTelefone
                                                pais={
                                                    FormikProps.values
                                                        .Telefone2Pais
                                                }
                                                codigo={
                                                    FormikProps.values
                                                        .Telefone2PaisCodigo
                                                }
                                                form={FormikProps}
                                                name="Telefone2"
                                            />
                                        ),
                                    }}
                                    form={FormikProps}
                                    label="Telefone 2 *"
                                />
                            </GridItem>
                                    
                            <GridItem
                                xs={12}
                                lg={6}
                                style={{ marginTop: "50px" }}
                            >
                                <CustomLabel>Logo</CustomLabel>
                                <ImageUpload
                                    extraData={{ path: "entidades" }}
                                    singleImage
                                    image={FormikProps.values.Logo}
                                    afterLoad={(response) => {
                                        FormikProps.setFieldValue(
                                            "Logo",
                                            response.name
                                        );
                                        FormikProps.setFieldValue(
                                            "LogoChanged",
                                            true
                                        );
                                    }}
                                />
                            </GridItem>

                            <GridItem
                                xs={12}
                                lg={6}
                                style={{ marginTop: "50px" }}
                            >
                                <CustomLabel>Capa</CustomLabel>
                                <ImageUpload
                                    extraData={{ path: "entidades" }}
                                    singleImage
                                    image={FormikProps.values.Capa}
                                    afterLoad={(response) => {
                                        FormikProps.setFieldValue(
                                            "Capa",
                                            response.name
                                        );
                                        FormikProps.setFieldValue(
                                            "CapaChanged",
                                            true
                                        );
                                    }}
                                />
                            </GridItem>

                            <GridItem xs={12}>
                                <div
                                    style={{
                                        paddingTop: "40px",
                                        paddingBottom: "40px",
                                    }}
                                >
                                    {!disabled && (
                                        <Button
                                            color="success"
                                            type="submit"
                                            disabled={FormikProps.isSubmitting}
                                        >
                                            <Save /> Salvar
                                        </Button>
                                    )}
                                    {!disabled &&
                                        formData.model[formData.idField] &&
                                        token.platform === "dashboard" && (
                                            <Button
                                                color="danger"
                                                type="button"
                                                right
                                                onClick={form.handleDelete}
                                            >
                                                <Delete /> Excluir
                                            </Button>
                                        )}
                                    {!disabled &&
                                        formData.model[formData.idField] &&
                                        formData.model.Status === 0 &&
                                        token.platform === "dashboard" && (
                                            <Button
                                                color="info"
                                                type="button"
                                                right
                                                onClick={() => {
                                                    form.handleChangeStatus(1);
                                                }}
                                            >
                                                <Done /> Ativar
                                            </Button>
                                        )}
                                    {!disabled &&
                                        formData.model[formData.idField] &&
                                        formData.model.Status === 1 &&
                                        token.platform === "dashboard" && (
                                            <Button
                                                color="warning"
                                                type="button"
                                                right
                                                onClick={() => {
                                                    form.handleChangeStatus(0);
                                                }}
                                            >
                                                <Block /> Bloquear
                                            </Button>
                                        )}
                                </div>
                            </GridItem>
                        </GridContainer>
                    </Form>
                )}
            />
        </Container>
    );
}

EntidadesForm.propTypes = {
    id: PropTypes.any,
    afterSave: PropTypes.func,
    changeStatus: PropTypes.func,
    deleteRecord: PropTypes.func,
    afterChangeStatusSuccess: PropTypes.func,
    afterDeleteSuccess: PropTypes.func,
};
