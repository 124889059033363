/* eslint-disable max-len */
// Geral
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

// Ações
import * as list from "actions/list";
import { fetchList, changeStatus, deleteRecord } from "actions/ajudas";

// Componentes
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card";
import CardBody from "components/Card/CardBody";
import RightPanel from "components/RightPanel";
import DynamicTable from "components/DynamicTable";
import ListButtons from "components/ListButtons";
import Pesquisa from "components/Pesquisa";
import ListHeader from "components/ListHeader";

// Formulário
import Form from "./form";

const view = "ajudas";

export default function Ajudas({ params }) {
    useEffect(() => {
        list.start(view, "IdAjuda", fetchList, changeStatus, deleteRecord);
    }, []);

    const listData = useSelector((state) => state.list[view]);

    const form = listData ? (
        <Form
            changeStatus={listData.changeStatus}
            deleteRecord={listData.delete}
            afterSave={() => list.afterSaveSuccess(view)}
            afterChangeStatusSuccess={() => list.afterChangeStatusSuccess(view)}
            afterDeleteSuccess={() => list.afterDeleteSuccess(view)}
            id={params ? params.id : null}
        />
    ) : null;

    return (
        <GridContainer>
            {" "}
            {listData && (
                <>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <ListHeader
                                title="Ajudas"
                                add="Nova Ajuda"
                                legend="Ajudas visualizadas no App"
                                view={view}
                            />
                            <CardBody>
                                <Pesquisa view={view} />
                                <DynamicTable
                                    view={view}
                                    {...listData.tableProps}
                                    columns={[
                                        {
                                            Header: "Título",
                                            accessor: "Titulo",
                                        },
                                        {
                                            Header: "Idioma",
                                            accessor: "Idioma",
                                        },
                                        {
                                            Header: "Status",
                                            accessor: "Status",
                                            maxWidth: 150,
                                            style: {
                                                padding: 0,
                                            },
                                            Cell: (props) => (
                                                <ListButtons
                                                    cellProps={props}
                                                    view={view}
                                                />
                                            ),
                                        },
                                    ]}
                                    onFetchData={(state, instance) =>
                                        list.requestData(view)
                                    }
                                    onPageSizeChange={(pageSize, pageIndex) =>
                                        list.onPageSizeChange(
                                            pageSize,
                                            pageIndex,
                                            view
                                        )
                                    }
                                    onPageChange={(pageIndex) =>
                                        list.onPageChange(pageIndex, view)
                                    }
                                    onSortedChange={(
                                        newSorted,
                                        column,
                                        shiftKey
                                    ) =>
                                        list.onSortedChange(
                                            newSorted,
                                            column,
                                            shiftKey,
                                            view
                                        )
                                    }
                                />
                            </CardBody>
                        </Card>
                    </GridItem>

                    <RightPanel
                        root={listData.root}
                        isPaneOpen={params && params.action === "new"}
                        title="Nova Ajuda"
                        path="new"
                    >
                        {form}
                    </RightPanel>
                    <RightPanel
                        root={listData.root}
                        isPaneOpen={params && params.action === "edit"}
                        title="Edição de Ajuda"
                        path="edit"
                    >
                        {form}
                    </RightPanel>
                </>
            )}
        </GridContainer>
    );
}
