import * as Yup from 'yup';
import { addInYup as addCpf } from "helpers/validation/Cpf"; 

// Adiciona a função de CPF no YUP (Não existente por padrão)
addCpf();

const validationSchema = Yup.object().shape({
    Nome: Yup.string().required('Por favor digite o nome'),
    Email: Yup.string().required('Por favor digite o e-mail').email('Por favor digite um e-mail válido'),
    Senha: Yup.string().when("IdUsuario", (IdUsuario, field) => IdUsuario ? field : field.required('Por favor digite a senha')),
    ConfirmaSenha: Yup.string().oneOf([Yup.ref('Senha'), null, ''], 'Por favor digite a confirmação de senha')
});

export default validationSchema;