import types from "types/form";
import store from "helpers/store";

export const clearForm = () => ({ type: types.CLEAR_FORM, payload: null });
export const setId = data => ({ type: types.SET_FORM_ID, payload: data });
export const setRoot = data => ({ type: types.SET_FORM_ROOT, payload: data });
export const setSave = data => ({ type: types.SET_FORM_SAVE, payload: data });
export const setAfterSave = data => ({
    type: types.SET_FORM_AFTER_SAVE,
    payload: data
});
export const setChangeStatus = data => ({
    type: types.SET_FORM_CHANGE_STATUS,
    payload: data
});
export const setAfterChangeStatusSuccess = data => ({
    type: types.SET_FORM_AFTER_CHANGE_STATUS_SUCCESS,
    payload: data
});
export const setFetch = data => ({ type: types.SET_FORM_FETCH, payload: data });
export const setAfterFetch = data => ({
    type: types.SET_FORM_AFTER_FETCH,
    payload: data
});
export const setDelete = data => ({
    type: types.SET_FORM_DELETE,
    payload: data
});
export const setAfterDeleteSuccess = data => ({
    type: types.SET_FORM_AFTER_DELETE_SUCCESS,
    payload: data
});
export const setIdField = data => ({
    type: types.SET_FORM_IDFIELD,
    payload: data
});
export const setLoading = data => ({
    type: types.SET_FORM_LOADING,
    payload: data
});
export const setModel = data => ({ type: types.SET_FORM_MODEL, payload: data });

export const handleSubmit = (values, actions) => {
    const state = store.getState();

    state.form.save(values, actions, state.form.afterSave);
};

export const fetchData = id => {
    const state = store.getState();

    state.form.formFetch(atob(id), data => {
        if (data.result) {
            if (state.form.afterFetch) {
                state.form.afterFetch(data);
            } else {
                // Função padrão
                const model = data.result;
                store.dispatch(setModel(model));
                store.dispatch(setLoading(false));
            }
        }
    });
};

export const handleDelete = () => {
    const state = store.getState();

    if (state.form.id && state.form.delete) {
        const id = atob(state.form.id);
        state.form.delete(id, state.form.afterDeleteSuccess);
    }
};

export const handleChangeStatus = (status = 0) => {
    const state = store.getState();

    if (state.form.id && state.form.changeStatus) {
        const id = atob(state.form.id);
        state.form.changeStatus(id, status, state.form.afterChangeStatusSuccess);
    }
};

export const start = (
    page,
    idField,
    id,
    model,
    fetchForm,
    save,
    changeStatus,
    deleteRecord,
    afterSave,
    afterChangeStatusSuccess,
    afterDeleteSuccess,
    afterFetch
) => {
    store.dispatch(clearForm());
    store.dispatch(setRoot(page));
    store.dispatch(setId(id));
    store.dispatch(setModel(model));
    store.dispatch(setIdField(idField));
    store.dispatch(setFetch(fetchForm));
    store.dispatch(setSave(save));
    store.dispatch(setChangeStatus(changeStatus));
    store.dispatch(setDelete(deleteRecord));
    store.dispatch(setAfterSave(afterSave));
    store.dispatch(setAfterChangeStatusSuccess(afterChangeStatusSuccess));
    store.dispatch(setAfterDeleteSuccess(afterDeleteSuccess));
    store.dispatch(setAfterFetch(afterFetch));

    if (id) {
        fetchData(id);
    } else {
        store.dispatch(setLoading(false));
    }
};

export const stop = () => {
    store.dispatch(clearForm());
};
