// Geral
import React from "react";
import { useSelector } from "react-redux";

// Ações
import * as list from "actions/list";

// Ícones
import Add from "@material-ui/icons/Add";

// Componentes
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButton";

// Estilos
import { CardTitleWhite, CardLegend } from "views/Base/styles";

export default function ListHeader({
    title, add, legend, view
}) {
    const listData = useSelector(state => state.list[view]);
    const permissions = useSelector(state => state.permissions);

    return (
        <CardHeader color="primary">
            <CardTitleWhite>
                {title}
                {permissions && permissions[listData.root] > 1 && add
                    && (
                        <Button color="success" onClick={() => { list.openNewModal(view); }} style={{ float: "right" }}>
                            <Add />
                            {add}
                            &nbsp;
                        </Button>
                    )}
            </CardTitleWhite>
            <CardLegend>
                {legend}
            </CardLegend>
        </CardHeader>
    );
}
