import React, { useState } from 'react'
import { Formik, Form } from "formik";
import moment from 'moment';

// Componentes
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import DatePicker from "components/DatePicker";

const StatisticForm = (props) => {
    
    let today = moment();
    let removeDays = today.subtract(30, 'days');
    
    const [dateStart, setDateStart] = useState(removeDays);
    const [dateEnd, setDateEnd] = useState(new Date());

    const handleDateStart = (date) => {
        setDateStart(date);
        
        props.notifyStatistic({ dateStart: date, dateEnd });
    };

    const handleDateEnd = (date) => {
        setDateEnd(date);

        props.notifyStatistic({ dateStart, dateEnd: date });
    };

    return (
        <>
            <Formik
                // onSubmit={form.handleSubmit}
                render={FormikProps => (
                    <Form onSubmit={FormikProps.handleSubmit}>
                        { FormikProps.values.DataInicio }
                        <GridContainer>
                            <GridItem xs={12} lg={6}>
                                <DatePicker
                                    style={{ width: "95%" }}
                                    field={{
                                        name: "DataInicio",
                                        type: "date-local"
                                    }}
                                    value={ dateStart }
                                    onChange={handleDateStart}
                                    form={FormikProps}
                                    label="Data de Início *"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <DatePicker
                                    style={{ width: "95%" }}
                                    xs={12}
                                    field={{
                                        name: "DataFinal",
                                        type: "date-local"
                                    }}
                                    value={ dateEnd }
                                    onChange={handleDateEnd}
                                    form={FormikProps}
                                    label="Data de Final *"
                                />
                            </GridItem>
                        </GridContainer>
                    </Form>
                )}
            />
        </>
    );
}

export default StatisticForm