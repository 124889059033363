import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Theme } from "assets/jss/doSul-dashboard";

function Loading(props) {
    return (<div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '20px'
    }}>
        <CircularProgress style={{color: Theme.primaryColor[0], marginRight: '40px'}} {...props}></CircularProgress> Carregando...
    </div>);
}

export default Loading;