// Geral
import React from "react";

// Componentes
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

export default function Loading(){
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <h4 style={{textAlign:'center'}}>Carregando... É rapidinho ;)</h4>
            </GridItem>
        </GridContainer>
    );
};