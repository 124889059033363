import React from "react";

// Estilo
import { CustomTable } from "./styles";

function DynamicTable({ ...props }) {
  return (
    <CustomTable {...props} />
  );
}

export default DynamicTable;
