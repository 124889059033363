/* Geral / Bibliotecas */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import styled from "styled-components";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";

import isValid from "date-fns/isValid";
import FNSformat from "date-fns/format";
import ptBRLocale from "date-fns/locale/pt-BR";

/* Ícones */
import Save from "@material-ui/icons/Save";
import Done from "@material-ui/icons/Done";
import Block from "@material-ui/icons/Block";
import Delete from "@material-ui/icons/Delete";
import VisibilityIcon from '@material-ui/icons/Visibility';
import MouseIcon from '@material-ui/icons/Mouse';

/* Métodos / Ações */
import * as form from "actions/form";
import { fetch, save } from "actions/agenda";
import { loadCidades } from "actions/cidadesNew";
import { loadOptions as loadOptionsLojas } from "actions/lojas";
import { loadOptions as loadOptionsEntidades } from "actions/entidades";
import { loadOptions as loadOptionsPotencias } from "actions/potencias";

/* Componentes */
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import FormInput from "components/FormInput";
import Button from "components/CustomButton";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormSelect from "components/FormSelect";
import { Container, Theme } from "assets/jss/doSul-dashboard";
import Loading from "components/Loading";
import DatePicker from "components/DatePicker";
import ImageUpload from "components/CustomInput/ImageUpload";
import DynamicTable from "components/DynamicTable";
import CardBody from "components/Card/CardBody";
import Card from "components/Card";

import { getCoordinates } from "actions/coordinates";

import PaisForm from "components/CustomInput/Pais";
import EstadoForm from "components/CustomInput/Estado";

/* Validação */
import validationSchema from "./validation";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

/* Estilos */
const CustomList = styled(List)`
    li{ padding: 0px;}
`;
const CustomLabel = styled(InputLabel)`
    text-align: center;
`;

const Model = {
    IdAgenda: null,
    DataHoraInicio: null,
    DataHoraFim: null,
    loja: null,
    nomeLoja: '',
    entidade: null,
    nomeEntidade: '',
    potencia: null,
    nomePotencia: '',
    Titulo: "",
    Descricao: "",
    Status: 1,
    ImagemChanged: false,
    Imagem: "",
    Cliques: 0,
    Visualizacoes: 0,
    Endereco: "",
    Numero: "",
    Bairro: "",

    Cep: "",
    pais: "",
    Pais: "",
    PaisForm: "",
    PaisNome: "",
    estado: "",
    Estado: "",
    EstadoForm: "",
    EstadoNome: "",
    cidade: "",
    Cidade: "",
    CidadeForm: "",
    CidadeNome: "",
        
    Complemento: ""
};

export default function AgendaForm({
    id, changeStatus, deleteRecord, afterSave, afterChangeStatusSuccess, afterDeleteSuccess
}) {
    const formData = useSelector(state => state.form);
    const permissions = useSelector(state => state.permissions);

    const [labelCep, setLabelCep] = useState('CEP');

    /* Determina o tipo inicial do responsavel */
    const [typeResponsible, setTypeResponsible] = useState("Loja");

    const dispatch = useDispatch();

    const afterFetch = (data) => {
        const model = data.result;

        if (model.Loja) model.loja = btoa(model.Loja);
        if (model.Entidade) model.entidade = btoa(model.Entidade);

        if (model.Cidade) model.cidade = btoa(model.Cidade);

        // etc
        dispatch(form.setModel(model));
        dispatch(form.setLoading(false));

        if (model.Entidade) {
            setTypeResponsible('Entidade');
        } else if (model.Loja) {
            setTypeResponsible('Loja');
        } else if (model.Potencia) {
            setTypeResponsible('Potencia')            
        }
    }; // Sobrescrever se precisar

    useEffect(() => {
        form.start("agenda", "IdAgenda", id, Model, fetch, save, changeStatus, deleteRecord, afterSave, afterChangeStatusSuccess, afterDeleteSuccess, afterFetch);

        return form.stop;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // É chamado toda vez que muda o ID
        form.setModel(Model);
        if (id) {
            form.fetchData(id);
        }
    }, [id]);

    // const handleSubmit = form.handleSubmit    ----> :) Poderia ser usado o padrão se não precisar nenhum tratamento
    const handleSubmit = async (values, actions) => {
        if (values.Pais && values.pais === undefined) values.pais = values.Pais;
        if (values.Estado && values.estado === undefined) values.estado = values.Estado;

        // Para não resetar a logo caso não seja trocada
        if (!values.LogoChanged) delete values.Logo;
        if (!values.CapaChanged) delete values.Capa;

        /* Checa se os valores de latitude e longitude vieram vazios  */
        if (!values.Latitude || !values.Longitude) {
            const { lat, lng } = await fnGetCoordinates({ values: values});
        
            if (lat) values.Latitude = lat;
            if (lng) values.Longitude = lng;    
        }

        /* Tratativa para evitar erros ao mudar o responsavel */
        if (typeResponsible === 'Entidade') {
            values.loja = null;
            values.potencia = null;
        } else if (typeResponsible === 'Loja') {
            values.entidade = null;
            values.potencia = null;
        } else if (typeResponsible === 'Potencia') {
            values.entidade = null;
            values.loja = null;
        }

        // Para não resetar a foto caso não seja trocada
        if (!values.ImagemChanged)
            delete values.Imagem;

        formData.save(values, actions, formData.afterSave);
    };

    if (formData.loading) {
        return <Loading />;
    }

    /* Recupera as coordenadas por meio do endereco informado */
    const MySwal = withReactContent(Swal)
    async function fnGetCoordinates(props) {
        let { Endereco, Bairro, EstadoNome, CidadeNome, PaisNome } = props.values;
        
        if (Endereco.length > 0 && Bairro.length > 0 && EstadoNome.length > 0 && CidadeNome.length > 0 && PaisNome.length > 0) {
            MySwal.fire({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 1000,
                type: 'info',
                title: 'Aguarde um instante'
            })

            const response = await getCoordinates(props);
            const { lat, lng } = response.results[0].geometry.location;
    
            if (props.setFieldValue !== undefined) {
                props.setFieldValue(`Latitude`, lat.toFixed(8));
                props.setFieldValue(`Longitude`, lng.toFixed(8));
            } else {
                return response.results[0].geometry.location;
            }
        } else {
            MySwal.fire({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 3000,
                type: 'warning',
                title: 'Preencha o logradouro completo primeiro'
            })
        }
    }

    //const token = jwt_decode(localStorage.getItem(JWT_TOKEN));

    const disabled = permissions && permissions[formData.root] < 2;

    return (
        <Container>
            <Formik
                validationSchema={validationSchema}
                initialValues={formData.model}
                onSubmit={handleSubmit}
                render={FormikProps => (
                    <Form onSubmit={FormikProps.handleSubmit}>
                        <GridContainer>
                        <GridItem xs={12} lg={12}>
                                <InputLabel htmlFor="TipoResponsavel" style={{ marginTop: "15px" }}>
                                    Selecione o Responsável *
                                </InputLabel>
                                <RadioGroup
                                    disabled={disabled}
                                    id="TipoResponsavel"
                                    aria-label="TipoResponsavel"
                                    name="TipoResponsavel"
                                    value={typeResponsible}
                                    onChange={event => {
                                        return setTypeResponsible(event.target.value)
                                    }}
                                    row
                                >
                                    <FormControlLabel
                                        value="Loja"
                                        control={<Radio style={{ color: Theme.infoColor[0] }} />}
                                        label="Loja"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="Entidade"
                                        control={<Radio style={{ color: Theme.infoColor[0] }} />}
                                        label="Entidade"
                                        labelPlacement="end"
                                    />
                                     <FormControlLabel
                                        value="Potencia"
                                        control={<Radio style={{ color: Theme.infoColor[0] }} />}
                                        label="Potência"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </GridItem>
                            {typeResponsible === "Potencia" &&
                                <GridItem xs={12}>
                                    <FormSelect
                                        field={{
                                            name: "potencia",
                                            placeholder: "Digite para localizar...",
                                            isSearchable: true,
                                            defaultOptions: true,
                                            loadOptions: loadOptionsPotencias,
                                            getOptionLabel: (option) => `${option.Nome}`,
                                            loadingMessage: () => "Carregando...",
                                            noOptionsMessage: (input) => (input.inputValue === "" ? "Digite para localizar..." : "Nenhum resultado encontrado"),
                                            disabled,
                                            value: FormikProps.values.potencia ? {
                                                IdPotencia: FormikProps.values.potencia,
                                                Nome: FormikProps.values.nomePotencia
                                            } : null,
                                            onChange: (value) => {
                                                if (value) {
                                                    if (value.IdPotencia) { FormikProps.setFieldValue("potencia", btoa(value.IdPotencia)); }
                                                    FormikProps.setFieldValue("nomePotencia", value.Nome);
                                                } else {
                                                    FormikProps.setFieldValue('potencia', null);
                                                }
                                            }
                                        }}
                                        form={FormikProps}
                                        async
                                        label="Potência"
                                    />
                                </GridItem>
                            }

                            {typeResponsible === "Loja" &&
                                <GridItem xs={12}>
                                    <FormSelect
                                        field={{
                                            name: "loja",
                                            placeholder: "Digite para localizar...",
                                            isSearchable: true,
                                            defaultOptions: true,
                                            loadOptions: loadOptionsLojas,
                                            getOptionLabel: (option) => `${option.Nome}`,
                                            loadingMessage: () => "Carregando...",
                                            noOptionsMessage: (input) => (input.inputValue === "" ? "Digite para localizar..." : "Nenhum resultado encontrado"),
                                            disabled,
                                            value: FormikProps.values.loja ? {
                                                IdLoja: FormikProps.values.loja,
                                                Nome: FormikProps.values.nomeLoja
                                            } : null,
                                            onChange: (value) => {
                                                if (value) {
                                                    if (value.IdLoja) { FormikProps.setFieldValue("loja", btoa(value.IdLoja)); }
                                                    FormikProps.setFieldValue("nomeLoja", value.Nome);
                                                } else {
                                                    FormikProps.setFieldValue('loja', null);
                                                }
                                            }
                                        }}
                                        form={FormikProps}
                                        async
                                        label="Loja"
                                    />
                                </GridItem>
                            }

                            {typeResponsible === "Entidade" &&
                                <GridItem xs={12}>
                                    <FormSelect
                                        field={{
                                            name: "entidade",
                                            placeholder: "Digite para localizar...",
                                            isSearchable: true,
                                            defaultOptions: true,
                                            loadOptions: loadOptionsEntidades,
                                            getOptionLabel: (option) => `${option.Nome}`,
                                            loadingMessage: () => "Carregando...",
                                            noOptionsMessage: (input) => (input.inputValue === "" ? "Digite para localizar..." : "Nenhum resultado encontrado"),
                                            disabled,
                                            value: FormikProps.values.entidade ? {
                                                IdEntidade: FormikProps.values.entidade,
                                                Nome: FormikProps.values.nomeEntidade
                                            } : null,
                                            onChange: (value) => {
                                                if (value) {
                                                    if (value.IdEntidade) { FormikProps.setFieldValue("entidade", btoa(value.IdEntidade)); }
                                                    FormikProps.setFieldValue("nomeEntidade", value.Nome);
                                                } else {
                                                    FormikProps.setFieldValue('entidade', null);
                                                }
                                            }
                                        }}
                                        form={FormikProps}
                                        async
                                        label="Entidade"
                                    />
                                </GridItem>
                            }

                            <GridItem xs={12}>
                                <FormInput
                                    field={{
                                        name: "Titulo",
                                        disabled
                                    }}
                                    form={FormikProps}
                                    label="Título *"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <DatePicker
                                    style={{ width: "100%" }}
                                    field={{
                                        name: "DataHoraInicio",
                                        disabled,
                                        type: "datetime-local"
                                    }}
                                    value={FormikProps.values.DataHoraInicio ? new Date(FormikProps.values.DataHoraInicio) : null}
                                    onChange={function (value) {
                                        FormikProps.setFieldValue("DataHoraInicio", isValid(value) ? FNSformat(value, "yyyy-MM-dd HH:mm", { locale: ptBRLocale }) : null);
                                    }}
                                    form={FormikProps}
                                    label="Data/Hora de Início *"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <DatePicker
                                    style={{ width: "100%" }}
                                    xs={12}
                                    field={{
                                        name: "DataHoraFim",
                                        disabled,
                                        type: "datetime-local"
                                    }}
                                    value={FormikProps.values.DataHoraFim ? new Date(FormikProps.values.DataHoraFim) : null}
                                    onChange={function (value) {
                                        FormikProps.setFieldValue("DataHoraFim", isValid(value) ? FNSformat(value, "yyyy-MM-dd HH:mm", { locale: ptBRLocale }) : null);
                                    }}
                                    form={FormikProps}
                                    label="Data/Hora de Fim *"
                                />
                            </GridItem>

                            <GridItem xs={12}>
                                <FormInput
                                    field={{
                                        name: "Descricao",
                                        disabled,
                                        multiline: true,
                                        rows: 5
                                    }}
                                    form={FormikProps}
                                    label="Descrição *"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={4}>
                                <PaisForm
                                    pais={FormikProps.values.PaisForm}
                                    nacionalidade={FormikProps.values.Nacionalidade}
                                    form={FormikProps}
                                    setLabelCep={setLabelCep}
                                />
                            </GridItem>

                            <GridItem xs={12} lg={4}>
                                <FormInput
                                    field={{
                                        name: "Cep",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label={labelCep}
                                />
                            </GridItem>

                            <GridItem xs={12} lg={4}>
                                <EstadoForm
                                    estado={FormikProps.values.EstadoForm}
                                    pais={FormikProps.values.Pais}
                                    nacionalidade={FormikProps.values.Nacionalidade}
                                    form={FormikProps}
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                {
                                    (FormikProps.values.Pais === 1058 || FormikProps.values.Pais === 840) && +FormikProps.values.Estado > 0
                                    ? 
                                    <FormSelect
                                        field={{
                                            name: "cidade",
                                            placeholder: "Digite para localizar...",
                                            isSearchable: true,
                                            loadOptions: (cidade) => {
                                                return loadCidades({ cidade, estado: FormikProps.values.Estado, pais: FormikProps.values.Pais })
                                            },
                                            getOptionLabel: (option) =>
                                                `${option.Nome}`,
                                            loadingMessage: () => "Carregando...",
                                            noOptionsMessage: (input) =>
                                                input.inputValue === "" ? "Digite para localizar..." : "Nenhum resultado encontrado",
                                            disabled,
                                            value: FormikProps.values.cidade
                                                ? { IdCidade: FormikProps.values.cidade, Nome: FormikProps.values.CidadeNome }
                                                : null,
                                            onChange: (value) => {
                                                if (value) {
                                                    if (value.IdCidade) {
                                                        FormikProps.setFieldValue("cidade", btoa(value.IdCidade) ); 
                                                        FormikProps.setFieldValue("Cidade", btoa(value.IdCidade) ); 
                                                    }
                                                    FormikProps.setFieldValue("NomeCidade", value.Nome);
                                                    FormikProps.setFieldValue("CidadeNome", value.Nome);
                                                    FormikProps.setFieldValue("CidadeForm", value.Nome);
                                                } else {
                                                    FormikProps.setFieldValue("cidade", "");
                                                    FormikProps.setFieldValue("Cidade", "");
                                                    FormikProps.setFieldValue("NomeCidade", "");
                                                    FormikProps.setFieldValue("CidadeForm", "");
                                                }
                                            },
                                        }}
                                        form={FormikProps}
                                        async
                                        label="Cidade *"
                                    />
                                    :
                                    <FormInput
                                        field={{
                                            name: "CidadeNome",
                                            disabled,
                                        }}
                                        form={FormikProps}
                                        label="Cidade *"
                                    />
                                }
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "Bairro",
                                        disabled
                                    }}
                                    form={FormikProps}
                                    label="Bairro *"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={8}>
                                <FormInput
                                    field={{
                                        name: "Endereco",
                                        disabled
                                    }}
                                    form={FormikProps}
                                    label="Endereco *"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={4}>
                                <FormInput
                                    field={{
                                        name: "Numero",
                                        disabled
                                    }}
                                    form={FormikProps}
                                    label="Número *"
                                />
                            </GridItem>

                            <GridItem xs={12}>
                                <FormInput
                                    field={{
                                        name: "Complemento",
                                        disabled
                                    }}
                                    form={FormikProps}
                                    label="Complemento"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={4}>
                                <FormInput
                                    field={{
                                        name: "Latitude",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Latitude"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={4}>
                                <FormInput
                                    field={{
                                        name: "Longitude",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Longitude"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={4}>
                                <Button
                                    color="info"
                                    type="button"
                                    left
                                    onClick={() => fnGetCoordinates(FormikProps)}
                                    ref={(input) => (this.inputElement = input)}
                                >
                                    Pegar coordenadas
                                </Button>
                            </GridItem>

                            {/* <GridItem xs={12} style={{ marginTop: "50px" }}>
                                <CustomLabel>Imagem</CustomLabel>
                                <ImageUpload
                                    extraData={{ path: "agenda" }}
                                    singleImage
                                    image={FormikProps.values.Imagem}
                                    afterLoad={response => {
                                        FormikProps.setFieldValue("Imagem", response.name);
                                        FormikProps.setFieldValue("ImagemChanged", true);
                                    }}
                                />
                            </GridItem> */}

                            {formData.model[formData.idField] && (
                                <>
                                    <GridItem xs={12} lg={6}>
                                        <CustomList subheader={<li />}>
                                            <li>
                                                <ul>
                                                    <ListSubheader>Visualizações</ListSubheader>
                                                    <ListItem>
                                                        <ListItemText primary={<span><VisibilityIcon fontSize="small" /> {FormikProps.values.Visualizacoes}</span>} />
                                                    </ListItem>
                                                    <Divider />
                                                </ul>
                                            </li>
                                        </CustomList>
                                    </GridItem>
                                    <GridItem xs={12} lg={6}>
                                        <CustomList subheader={<li />}>
                                            <li>
                                                <ul>
                                                    <ListSubheader>Cliques</ListSubheader>
                                                    <ListItem>
                                                        <ListItemText primary={<span><MouseIcon fontSize="small" /> {FormikProps.values.Visualizacoes}</span>} />
                                                    </ListItem>
                                                    <Divider />
                                                </ul>
                                            </li>
                                        </CustomList>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card>
                                            <CardBody>
                                                <DynamicTable
                                                    sortable={false}
                                                    showPagination={false}
                                                    defaultPageSize={9999}
                                                    data={FormikProps.values.AgendaConfirmacao}
                                                    style={{ margin: "-0.9375rem -20px", marginTop: "10px" }}
                                                    columns={[
                                                        {
                                                            Header: "Nome",
                                                            accessor: "NomeMembro"
                                                        },
                                                        {
                                                            Header: "Confirmação",
                                                            accessor: "DataHoraConfirmacaoDisplay",
                                                            Footer: (
                                                                <span>
                                                                    <strong>Total:</strong>
                                                                    {" "}
                                                                    {FormikProps.values.AgendaConfirmacao.length}
                                                                </span>
                                                            )
                                                        }
                                                    ]}
                                                />
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                </>
                            )}

                            <GridItem xs={12}>
                                <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                                    {!disabled && (
                                        <Button color="success" type="submit" disabled={FormikProps.isSubmitting}>
                                            <Save />
                                            {" "}
                                            Salvar
                                        </Button>
                                    )}
                                    {!disabled && formData.model[formData.idField] && (
                                        <Button color="danger" type="button" right onClick={form.handleDelete}>
                                            <Delete />
                                            {" "}
                                            Excluir
                                        </Button>
                                    )}
                                    {!disabled && formData.model[formData.idField] && formData.model.Status === 0 && (
                                        <Button
                                            color="info"
                                            type="button"
                                            right
                                            onClick={() => {
                                                form.handleChangeStatus(1);
                                            }}
                                        >
                                            <Done />
                                            {" "}
                                            Ativar
                                        </Button>
                                    )}
                                    {!disabled && formData.model[formData.idField] && formData.model.Status === 1 && (
                                        <Button
                                            color="warning"
                                            type="button"
                                            right
                                            onClick={() => {
                                                form.handleChangeStatus(0);
                                            }}
                                        >
                                            <Block />
                                            {" "}
                                            Bloquear
                                        </Button>
                                    )}
                                </div>
                            </GridItem>
                        </GridContainer>

                    </Form>
                )}
            />
        </Container>
    );
}

AgendaForm.propTypes = {
    id: PropTypes.any,
    afterSave: PropTypes.func,
    changeStatus: PropTypes.func,
    deleteRecord: PropTypes.func,
    afterChangeStatusSuccess: PropTypes.func,
    afterDeleteSuccess: PropTypes.func
};
