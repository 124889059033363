import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';

import { Theme } from "assets/jss/doSul-dashboard";

export const CustomTabs = styled(Tabs)`
    & .MuiTab-textColorPrimary.Mui-selected{
        color: ${Theme.primaryColor[0]};
    }
    & .MuiTabs-indicator{
        background-color: ${Theme.primaryColor[0]};
    }
`;