import React from "react";
import Button from "components/CustomButton";
import { useSelector } from "react-redux";

// Ações
import * as l from "actions/list";

// Ícones
import Delete from "@material-ui/icons/Delete";
import Done from "@material-ui/icons/CheckCircle";

function ListButtonsSubList(props) {
    const state = useSelector((state) => state.list);
    const listData = state[props.view];
    const permissions = useSelector((state) => state.permissions);

    const { cellProps, custom, hideDelete } = props;
    const list = l.requestData;
    const status = listData.changeStatus;
    const exclude = listData.delete;
    const idProp = listData.idField;
    let { root } = listData;
    if (props.root) {
        root = props.root;
    }

    if (permissions && permissions[root] > 1) {
        // Usuário privilegiado
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingRight: "10px",
                }}
            >
                {custom}
                {cellProps.original.Administrador === 1 ? (
                    <Button
                        color="success"
                        link
                        size="sm"
                        round
                        justIcon
                        title="Remover da administração"
                        onClick={(e) => {
                            e.stopPropagation();
                            status(cellProps.original[idProp], 0, () => {
                                if (list) {
                                    list(props.view);
                                }
                            });
                        }}
                    >
                        <Done />
                    </Button>
                ) : (
                    <Button
                        color="transparent"
                        link
                        size="sm"
                        round
                        justIcon
                        title="Tornar administrador"
                        onClick={(e) => {
                            e.stopPropagation();
                            status(cellProps.original[idProp], 1, () => {
                                if (list) {
                                    list(props.view);
                                }
                            });
                        }}
                    >
                        <Done />
                    </Button>
                )}

                {!hideDelete && (
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            exclude(cellProps.original[idProp], () => {
                                if (list) {
                                    list(props.view);
                                }
                            });
                        }}
                        color="danger"
                        link
                        size="sm"
                        round
                        justIcon
                        title="Remover"
                    >
                        <Delete />
                    </Button>
                )}
            </div>
        );
    } // Sem permissão de alteração
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "10px",
            }}
        >
            {custom}
            {cellProps.original.Administrador === 1 && (
                <Button
                    color="success"
                    link
                    size="sm"
                    round
                    justIcon
                    title="Administrador"
                >
                    {" "}
                    <Done />
                </Button>
            )}
            {cellProps.original.Administrador === 0 && (
                <Button
                    color="transparent"
                    link
                    size="sm"
                    round
                    justIcon
                    title="Não administrador"
                >
                    {" "}
                    <Done />
                </Button>
            )}
        </div>
    );
}

export default ListButtonsSubList;
