// Geral
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

// Ações
import * as list from "actions/list";
import { fetchListMembros, changeStatusMembros } from "actions/potencias";

// Componentes
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card";
import CardBody from "components/Card/CardBody";
import DynamicTable from "components/DynamicTable";
import Pesquisa from "components/Pesquisa";
import { Container } from "assets/jss/doSul-dashboard";

// Ícones
import ListButtonsSubList from "components/ListButtonsSubList";

const view = "membroPotencia";

export default function PotenciasListMembros({ id }) {
    useEffect(() => {
        list.start(
            view,
            "IdMembro",
            fetchListMembros,
            changeStatusMembros,
            () => {},
            id
        );
    }, [id]);

    const listData = useSelector((state) => state.list[view]);

    return (
        <Container>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            {listData && (
                                <>
                                    <Pesquisa view={view} />
                                    <DynamicTable
                                        {...listData.tableProps}
                                        columns={[
                                            {
                                                Header: "Nome",
                                                accessor: "Nome",
                                            },
                                            {
                                                Header: "Administrador",
                                                accessor: "Detalhes",
                                                maxWidth: 150,
                                                style: {
                                                    padding: 0,
                                                },
                                                Cell: (props) => (
                                                    <ListButtonsSubList
                                                        hideDelete={true}
                                                        cellProps={props}
                                                        view={view}
                                                        style={{
                                                            display: "inline",
                                                        }}
                                                        root="potencias"
                                                    />
                                                ),
                                            },
                                        ]}
                                        clickable={false}
                                        view={view}
                                        onFetchData={(state, instance) =>
                                            list.requestData(view)
                                        }
                                        onPageSizeChange={(
                                            pageSize,
                                            pageIndex
                                        ) =>
                                            list.onPageSizeChange(
                                                pageSize,
                                                pageIndex,
                                                view
                                            )
                                        }
                                        onPageChange={(pageIndex) =>
                                            list.onPageChange(pageIndex, view)
                                        }
                                        onSortedChange={(
                                            newSorted,
                                            column,
                                            shiftKey
                                        ) =>
                                            list.onSortedChange(
                                                newSorted,
                                                column,
                                                shiftKey,
                                                view
                                            )
                                        }
                                    />
                                </>
                            )}
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </Container>
    );
}
