import axios from "axios";
import store from "helpers/store";
import objToQueryString from "helpers/objToQueryString";

import {
    defaultFetchList,
    defaultChangeStatus,
    defaultDelete,
    defaultSave,
    defaultFetch
} from "./default";

const model = "publicacoes";
const idField = "IdPublicacao";

export const fetchList = (reqData, resolve, reject, dispatch) => defaultFetchList(model, reqData, resolve, reject, dispatch);
export const fetch = (id, callback) => defaultFetch(model, id, callback);
export const changeStatus = (id, status, afterChange, afterError) => defaultChangeStatus(model, id, status, afterChange, afterError);
export const deleteRecord = (id, afterDelete, afterError) => defaultDelete(model, id, afterDelete, afterError);
export const save = (data, actions, afterSave, afterError) => defaultSave(model, idField, data, true, actions, afterSave, afterError);

export const fetchListCurtidores = (reqData, resolve, reject, dispatch) => {
    const state = store.getState().list.curtidaPublicacao;

    const ignoreNulls = true;

    axios
        .get(
            `${model}/curtir/${state.parentId}${objToQueryString(
                reqData,
                ignoreNulls
            )}`
        )
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            let erro = { message: "Não foi possível obter os dados" };
            if (err.response) {
                erro = err.response.data;
            }
            reject(erro.message);
        });
};

export const fetchListComentarios = (reqData = {}, resolve, reject, dispatch) => {
    const state = store.getState().list.comentarioPublicacao;

    const ignoreNulls = true;

    reqData.pg = 0;
    reqData.pgSize = 9999;
    reqData.mesmoDenunciado = 1;

    axios
        .get(
            `${model}/comentarios/${state.parentId}${objToQueryString(
                reqData,
                ignoreNulls
            )}`
        )
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            let erro = { message: "Não foi possível obter os dados" };
            if (err.response) {
                erro = err.response.data;
            }
            reject(erro.message);
        });
};