/* eslint-disable import/prefer-default-export */
import React from "react";
import styled from "styled-components";
import ReactTable from "react-table";
import "react-table/react-table.css";
// Ícones
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";

import { Theme } from "assets/jss/doSul-dashboard";

export const CustomTable = styled(ReactTable).attrs((props) => ({
    manual: true,
    multiSort: false,
    filterable: false,
    previousText: <ChevronLeft />,
    nextText: <ChevronRight />,
    resizable: false,
    loadingText: "Carregando...",
    noDataText: "Nenhum resultado encontrado :(",
    pageText: "Página",
    ofText: "de",
    rowsText: "linhas",
    pageJumpText: "ir para a página",
    rowsSelectorText: "linhas por página",
    pageSizeOptions: [5, 10, 20, 50],
    defaultPageSize: 10,
    minRows: 0,
    className: "-highlight",
    getTdProps: (_state, rowInfo) => ({
        onClick: (_e, handleOriginal) => {
            if (props.clickable === false || (props.isDisabledRow && props.isDisabledRow(rowInfo))) return;
            if (props.idField && props.edit) { props.edit(rowInfo.original[props.idField], props.view); }

            // Chama o Evento Original
            if (handleOriginal) {
                handleOriginal();
            }
        }
    })
}))`
    border: none!important;
    & .rt-thead .rt-th *{
        font-weight: 500;
        font-size: 1em!important;
    }
    & .rt-thead .rt-th{
        text-align: left;
        padding: 12px!important;
        border-right: none!important;
    }
    & .rt-thead.-header{
        color: ${Theme.primaryColor[0]};
        font-size: 1em;
        border-bottom: solid 1px rgba(0,0,0,0.15);
        box-shadow:none!important;
    }
    & .rt-tbody .rt-td{
        font-size: 1.1em!important;
        line-height: 1.42857143;
        padding: 12px 8px;
        vertical-align: middle;
        font-size: 0.8125rem;
        border-right: none!important;
        padding-left: 12px!important;
        cursor: pointer;
    }
    & .rt-tbody .rt-td:last-child{
        cursor: unset;
    }
    & .rt-tbody .rt-tr-group{
        border-bottom: solid 1px rgba(0,0,0,0.15)!important;
    }
    & .rt-thead .rt-th.-sort-asc{
        box-shadow: none!important;
    }
    & .rt-thead .rt-th.-sort-asc > div:after {
        display: inline-block;
        width: 15px;
        height: 100%;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAQAAABLCVATAAAAk0lEQVR4Ae3MAQaFUBRF0Z1qsAEQAgqax4cvIBBoYgUUVwepqp5H5G3ce8Ai9NFKSjyUMzGRe2FEeWBEeWBEuTOj3UGUK9NT26/pXSgxHSmVrYqUTtRzJgEEQfKMEtMaA4IwqhV1n4lhA0F8jxLTiFlBopprqliYCA4hiBaq4KSMn5hdSNSfjOvOIfUmFKAAfbDQDNDDk1HPYqOIAAAAAElFTkSuQmCC');
        background-repeat: no-repeat;
        background-position: right center;
        background-size: auto 100%;
        margin-left: 10px;
        content: '\\00A0';
    }
    & .rt-thead .rt-th.-sort-desc{
        box-shadow: none!important;
    }
    & .rt-thead .rt-th.-sort-desc > div:after {
        display: inline-block;
        width: 15px;
        height: 100%;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAQAAABLCVATAAAAoklEQVR4Ae3QAQbDMBjF8ffpSs1s5xywIxRsyA0GBgwoKCgFhYJepwVSniol+pRSKv+QEMmPBLFTlps3jzxCR0ARMtz3gb7o8JCQ4YcnVnK81OG2Chn+3HuJ/zCPllQYSsjI57qZugahBMV0woFpqiG1hC6aCVPZAkpRaiZM1chmKEUlGEG9uX40o6ie86AZSXFoRlNbGUFpRlMbGEGROWGxEW0ilvteBik8AAAAAElFTkSuQmCC');
        background-repeat: no-repeat;
        background-position: right center;
        background-size: auto 100%;
        margin-left: 10px;
        content: '\\00A0';
    }
    & .-pagination{
        border-top: none!important;
    }
    & .-pagination .-previous{
        order: 3;
        flex: unset!important;
    }
    & .-pagination .-next{
        order: 4;
        flex: unset!important;
    }
    & .-pagination .-center{
        justify-content: flex-end!important;
    }
    & .-pagination .-btn{
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms!important;
        background: none!important;
        border-radius: 50%!important;
        width: 39px!important;
    }
    & .-pagination .-btn:hover, & .-pagination .-btn:active, & .-pagination .-btn:focus{
        background: rgba(0,0,0,0.1)!important;
        color: rgba(0,0,0,0.6)!important;
    }
    & .-pagination .-btn[disabled]:hover, & .-pagination .-btn[disabled]:active, & .-pagination .-btn[disabled]:focus{
        background: none!important;
    }
`;
