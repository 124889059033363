/* Geral / Bibliotecas */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
// import { JWT_TOKEN } from "helpers/consts";
// import jwt_decode from "jwt-decode";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import InputLabel from "@material-ui/core/InputLabel";
import styled from "styled-components";

/* Ícones */
import Save from "@material-ui/icons/Save";
import Done from "@material-ui/icons/Done";
import Block from "@material-ui/icons/Block";
import Delete from "@material-ui/icons/Delete";
import VisibilityIcon from '@material-ui/icons/Visibility';
import MouseIcon from '@material-ui/icons/Mouse';

/* Métodos / Ações */
import * as form from "actions/form";
import { fetch, save } from "actions/anuncios";
import { loadOptions as loadOptionsEmpresas } from "actions/empresas";
import { loadCidadesOptions } from "actions/cidades";
import { loadEstadosOptions } from "actions/estados";
import { loadPaisesOptions } from "actions/paises";

/* Componentes */
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import FormInput from "components/FormInput";
import Button from "components/CustomButton";
import FormSelect from "components/FormSelect";
import { Container, Theme } from "assets/jss/doSul-dashboard";
import Loading from "components/Loading";
import ImageUpload from "components/CustomInput/ImageUpload";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";


/* Validação */
import validationSchema from "./validation";

/* Estilos */
const CustomList = styled(List)`
    li{ padding: 0px;}
`;
const CustomLabel = styled(InputLabel)`
    text-align: center;
`;

const Model = {
    IdAnuncio: null,
    empresa: null,
    DataHora: "",
    Titulo: "",
    Descricao: "",
    Status: 1,
    Imagem: "",
    ImagemChanged: false,
    Cliques: 0,
    Visualizacoes: 0,
    Link: "",
    Sexo: null,
    cidade: null,
    estado: null,
    pais: null,
    abrangencia: btoa(1)
};

export default function AnunciosForm({
    id, changeStatus, deleteRecord, afterSave, afterChangeStatusSuccess, afterDeleteSuccess
}) {
    const formData = useSelector(state => state.form);
    const permissions = useSelector(state => state.permissions);

    const dispatch = useDispatch();

    const afterFetch = (data) => {
        const model = data.result;
        if (model.Membro) model.membro = btoa(model.Membro);
        if (model.Empresa) model.empresa = btoa(model.Empresa);
        if (model.Estado) model.estado = btoa(model.Estado);
        if (model.Cidade) model.cidade = btoa(model.Cidade);
        if (model.Pais) model.pais = btoa(model.Pais);
        if (model.Abrangencia) model.abrangencia = btoa(model.Abrangencia);
        // etc
        dispatch(form.setModel(model));
        dispatch(form.setLoading(false));
    }; // Sobrescrever se precisar

    useEffect(() => {
        form.start("anuncios", "IdAnuncio", id, Model, fetch, save, changeStatus, deleteRecord, afterSave, afterChangeStatusSuccess, afterDeleteSuccess, afterFetch);
        return form.stop;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // É chamado toda vez que muda o ID
        form.setModel(Model);
        if (id) {
            form.fetchData(id);
        }
    }, [id]);

    // const handleSubmit = form.handleSubmit    ----> :) Poderia ser usado o padrão se não precisar nenhum tratamento
    const handleSubmit = (values, actions) => {
        // Para não resetar a foto caso não seja trocada
        if (!values.ImagemChanged)
            delete values.Imagem;
        formData.save(values, actions, formData.afterSave);
    };

    if (formData.loading) {
        return <Loading />;
    }

    // const token = jwt_decode(localStorage.getItem(JWT_TOKEN));

    const disabled = permissions && permissions[formData.root] < 2;

    return (
        <Container>
            <Formik
                validationSchema={validationSchema}
                initialValues={formData.model}
                onSubmit={handleSubmit}
                render={FormikProps => (
                    <Form onSubmit={FormikProps.handleSubmit}>
                        <GridContainer>
                            <GridItem xs={12}>
                                <FormSelect
                                    field={{
                                        name: "empresa",
                                        placeholder: "Digite para localizar...",
                                        isSearchable: true,
                                        defaultOptions: true,
                                        loadOptions: loadOptionsEmpresas,
                                        getOptionLabel: (option) => `${option.Nome}`,
                                        loadingMessage: () => "Carregando...",
                                        noOptionsMessage: (input) => (input.inputValue === "" ? "Digite para localizar..." : "Nenhum resultado encontrado"),
                                        disabled,
                                        value: FormikProps.values.empresa ? {
                                            IdEmpresa: FormikProps.values.empresa,
                                            Nome: FormikProps.values.nomeEmpresa
                                        } : null,
                                        onChange: (value) => {
                                            if (value.IdEmpresa) { FormikProps.setFieldValue("empresa", btoa(value.IdEmpresa)); }
                                            FormikProps.setFieldValue("nomeEmpresa", value.Nome);
                                        }
                                    }}
                                    form={FormikProps}
                                    async
                                    label="Empresa *"
                                />
                            </GridItem>

                            <GridItem xs={12}>
                                <FormInput
                                    field={{
                                        name: "Titulo",
                                        disabled
                                    }}
                                    form={FormikProps}
                                    label="Título *"
                                />
                            </GridItem>

                            <GridItem xs={12}>
                                <FormInput
                                    field={{
                                        name: "Link",
                                        disabled
                                    }}
                                    form={FormikProps}
                                    label="Link *"
                                />
                            </GridItem>

                            <GridItem xs={12}>
                                <FormInput
                                    field={{
                                        name: "Descricao",
                                        disabled,
                                        multiline: true,
                                        rows: 5
                                    }}
                                    form={FormikProps}
                                    label="Descrição *"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={12}>
                                <InputLabel htmlFor="abrangencia" style={{ marginTop: "15px" }}>
                                    Selecione o Abrangência *
                                </InputLabel>
                                <RadioGroup
                                    disabled={disabled}
                                    id="abrangencia"
                                    aria-label="abrangencia"
                                    name="abrangencia"
                                    value={FormikProps.values.abrangencia}
                                    onChange={event => {
                                        FormikProps.setFieldValue("abrangencia", event.target.value);
                                    }}
                                    row
                                >
                                    <FormControlLabel
                                        value={btoa(1)}
                                        control={<Radio style={{ color: Theme.infoColor[0] }} />}
                                        label="Municipal"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value={btoa(2)}
                                        control={<Radio style={{ color: Theme.infoColor[0] }} />}
                                        label="Estadual"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value={btoa(3)}
                                        control={<Radio style={{ color: Theme.infoColor[0] }} />}
                                        label="Nacional"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value={btoa(4)}
                                        control={<Radio style={{ color: Theme.infoColor[0] }} />}
                                        label="Internacional"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </GridItem>

                            {FormikProps.values.abrangencia && atob(FormikProps.values.abrangencia) === "3" &&
                                <GridItem xs={12}>
                                    <FormSelect
                                        field={{
                                            name: "pais",
                                            placeholder: "Digite para localizar...",
                                            isSearchable: true,
                                            loadOptions: loadPaisesOptions,
                                            getOptionLabel: (option) => option.Nome,
                                            loadingMessage: () => "Carregando...",
                                            noOptionsMessage: (input) => (input.inputValue === "" ? "Digite para localizar..." : "Nenhum resultado encontrado"),
                                            disabled,
                                            value: FormikProps.values.pais ? {
                                                IdPais: FormikProps.values.pais,
                                                Nome: FormikProps.values.nomePais
                                            } : null,
                                            onChange: (value) => {
                                                if (value) {
                                                    if (value.IdPais) { FormikProps.setFieldValue("pais", btoa(value.IdPais)); }
                                                    FormikProps.setFieldValue("nomePais", value.Nome)
                                                } else {
                                                    FormikProps.setFieldValue("pais", null);
                                                }
                                            }
                                        }}
                                        form={FormikProps}
                                        async
                                        label="Pais *"
                                    />
                                </GridItem>
                            }

                            {FormikProps.values.abrangencia && atob(FormikProps.values.abrangencia) === "2" &&
                                <GridItem xs={12}>
                                    <FormSelect
                                        field={{
                                            name: "estado",
                                            placeholder: "Digite para localizar...",
                                            isSearchable: true,
                                            loadOptions: loadEstadosOptions,
                                            getOptionLabel: (option) => option.Nome,
                                            loadingMessage: () => "Carregando...",
                                            noOptionsMessage: (input) => (input.inputValue === "" ? "Digite para localizar..." : "Nenhum resultado encontrado"),
                                            disabled,
                                            value: FormikProps.values.estado ? {
                                                IdEstado: FormikProps.values.estado,
                                                Nome: FormikProps.values.nomeUF
                                            } : null,
                                            onChange: (value) => {
                                                if (value) {
                                                    if (value.IdEstado) { FormikProps.setFieldValue("estado", btoa(value.IdEstado)); }
                                                    FormikProps.setFieldValue("nomeUF", value.Nome)
                                                } else {
                                                    FormikProps.setFieldValue("estado", null);
                                                }
                                            }
                                        }}
                                        form={FormikProps}
                                        async
                                        label="Estado *"
                                    />
                                </GridItem>
                            }

                            {FormikProps.values.abrangencia && atob(FormikProps.values.abrangencia) === "1" &&
                                <GridItem xs={12}>
                                    <FormSelect
                                        field={{
                                            name: "cidade",
                                            placeholder: "Digite para localizar...",
                                            isSearchable: true,
                                            loadOptions: loadCidadesOptions,
                                            getOptionLabel: (option) => `${option.Nome}${option.NomeEstado ? ` - ${option.NomeEstado}` : ""}${option.NomePais ? ` - ${option.NomePais}` : ""}`,
                                            loadingMessage: () => "Carregando...",
                                            noOptionsMessage: (input) => (input.inputValue === "" ? "Digite para localizar..." : "Nenhum resultado encontrado"),
                                            disabled,
                                            value: FormikProps.values.cidade ? {
                                                IdCidade: FormikProps.values.cidade,
                                                Nome: FormikProps.values.nomeCidade,
                                                NomeEstado: FormikProps.values.nomeEstado,
                                                NomePais: FormikProps.values.nomePais
                                            } : null,
                                            onChange: (value) => {
                                                if (value) {
                                                    if (value.IdCidade) { FormikProps.setFieldValue("cidade", btoa(value.IdCidade)); }
                                                    FormikProps.setFieldValue("nomeCidade", value.Nome);
                                                    FormikProps.setFieldValue("nomeEstado", value.NomeEstado);
                                                    FormikProps.setFieldValue("nomePais", value.NomePais);
                                                } else {
                                                    FormikProps.setFieldValue("cidade", null);
                                                }
                                            }
                                        }}
                                        form={FormikProps}
                                        async
                                        label="Cidade *"
                                    />
                                </GridItem>
                            }

                            <GridItem xs={12}>
                                <FormSelect
                                    field={{
                                        isSearchable: false,
                                        name: "Sexo",
                                        disabled,
                                        options: getSexoOptions(),
                                        value: getSexoValue(FormikProps.values.Sexo),
                                        onChange: (value) => {
                                            FormikProps.setFieldValue("Sexo", value.value);
                                        }
                                    }}
                                    form={FormikProps}
                                    async={false}
                                    label="Sexo"
                                />
                            </GridItem>

                            <GridItem xs={12} style={{ marginTop: "50px" }}>
                                <CustomLabel>Imagem</CustomLabel>
                                <ImageUpload
                                    extraData={{ path: "anuncios" }}
                                    singleImage
                                    image={FormikProps.values.Imagem}
                                    afterLoad={response => {
                                        FormikProps.setFieldValue("Imagem", response.name);
                                        FormikProps.setFieldValue("ImagemChanged", true);
                                    }}
                                />
                            </GridItem>

                            {formData.model[formData.idField] && (
                                <>
                                    <GridItem xs={12} lg={6}>
                                        <CustomList subheader={<li />}>
                                            <li>
                                                <ul>
                                                    <ListSubheader>Visualizações</ListSubheader>
                                                    <ListItem>
                                                        <ListItemText primary={<span><VisibilityIcon fontSize="small" /> {FormikProps.values.Visualizacoes}</span>} />
                                                    </ListItem>
                                                    <Divider />
                                                </ul>
                                            </li>
                                        </CustomList>
                                    </GridItem>
                                    <GridItem xs={12} lg={6}>
                                        <CustomList subheader={<li />}>
                                            <li>
                                                <ul>
                                                    <ListSubheader>Cliques</ListSubheader>
                                                    <ListItem>
                                                        <ListItemText primary={<span><MouseIcon fontSize="small" /> {FormikProps.values.Visualizacoes}</span>} />
                                                    </ListItem>
                                                    <Divider />
                                                </ul>
                                            </li>
                                        </CustomList>
                                    </GridItem>
                                </>
                            )}

                            <GridItem xs={12}>
                                <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                                    {!disabled && (
                                        <Button color="success" type="submit" disabled={FormikProps.isSubmitting}>
                                            <Save />
                                            {" "}
                                            Salvar
                                        </Button>
                                    )}
                                    {!disabled && formData.model[formData.idField] && (
                                        <Button color="danger" type="button" right onClick={form.handleDelete}>
                                            <Delete />
                                            {" "}
                                            Excluir
                                        </Button>
                                    )}
                                    {!disabled && formData.model[formData.idField] && formData.model.Status === 0 && (
                                        <Button
                                            color="info"
                                            type="button"
                                            right
                                            onClick={() => {
                                                form.handleChangeStatus(1);
                                            }}
                                        >
                                            <Done />
                                            {" "}
                                            Ativar
                                        </Button>
                                    )}
                                    {!disabled && formData.model[formData.idField] && formData.model.Status === 1 && (
                                        <Button
                                            color="warning"
                                            type="button"
                                            right
                                            onClick={() => {
                                                form.handleChangeStatus(0);
                                            }}
                                        >
                                            <Block />
                                            {" "}
                                            Bloquear
                                        </Button>
                                    )}
                                </div>
                            </GridItem>
                        </GridContainer>

                    </Form>
                )}
            />
        </Container>
    );
}

const sexoOptions = [
    { value: null, label: "" },
    { value: "M", label: "Masculino" },
    { value: "F", label: "Feminino" }
];

const getSexoOptions = () => {
    return sexoOptions;
};

const getSexoValue = (value = "") => {
    const index = sexoOptions.findIndex((opt) => opt.value === value);
    return sexoOptions[index] ? sexoOptions[index] : "";
};

AnunciosForm.propTypes = {
    id: PropTypes.any,
    afterSave: PropTypes.func,
    changeStatus: PropTypes.func,
    deleteRecord: PropTypes.func,
    afterChangeStatusSuccess: PropTypes.func,
    afterDeleteSuccess: PropTypes.func
};
