import styled from 'styled-components';
import Button from "@material-ui/core/Button";
import { Theme, hexToRgb } from 'assets/jss/doSul-dashboard';

export const CustomButton = styled(Button)`
    min-height: auto!important;
    min-width: auto!important;
    background-color: ${Theme.grayColor[0]}!important;
    color: ${Theme.whiteColor}!important;
    box-shadow: 0 2px 2px 0 rgba(${hexToRgb(Theme.grayColor[0])}, 0.14), 0 3px 1px -2px rgba(${hexToRgb(Theme.grayColor[0])}, 0.2), 0 1px 5px 0 rgba(${hexToRgb(Theme.grayColor[0])}, 0.12)!important;
    border: none!important;
    border-radius: 3px!important;
    position: relative!important;
    padding: 12px 30px!important;
    margin: .3125rem 1px!important;
    font-size: 12px!important;
    font-weight: 400!important;
    text-transform: uppercase!important;
    letter-spacing: 0!important;
    will-change: box-shadow, transform!important;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)!important;
    line-height: 1.42857143!important;
    text-align: center!important;
    white-space: nowrap!important;
    vertical-align: middle!important;
    touch-action: manipulation!important;
    cursor: pointer!important;

    & > span {
        font-weight: 500!important;
    }

    &:hover,&:focus {
        color: ${Theme.whiteColor}!important;
        background-color: ${Theme.grayColor[0]}!important;
        box-shadow: 0 14px 26px -12px rgba(${hexToRgb(Theme.grayColor[0])}, 0.42), 0 4px 23px 0px rgba(${hexToRgb(Theme.blackColor)}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(Theme.grayColor[0])}, 0.2)!important;
    }

    & .fab,& .fas,& .far,& .fal, &.material-icons {
        position: relative!important;
        display: inline-block!important;
        top: 0!important;
        margin-top: -1em!important;
        margin-bottom: -1em!important;
        font-size: 1.1rem!important;
        margin-right: 4px!important;
        vertical-align: middle!important;
    }

    & svg {
        position: relative!important;
        display: inline-block!important;
        top: 0!important;
        width: 18px!important;
        height: 18px!important;
        margin-right: 4px!important;
        vertical-align: middle!important;
    }

    ${(props) => {
        let extra = '';

        if(props.options.justIcon){
            extra += `
            & .fab,& .fas,& .far,& .fal,& .material-icons {
                margin-top: 0px!important;
                position: absolute!important;
                width: 100%!important;
                transform: none!important;
                left: 0px!important;
                top: 0px!important;
                height: 100%!important;
                line-height: 41px!important;
                font-size: 20px!important;
            }
            `
        }

        if(props.options.right){
            extra += `
            float: right!important;
            `
        }

        if(props.options.color === "white"){
            extra += `
                &,&:focus,&:hover {
                    background-color: ${Theme.whiteColor}!important;
                    color: ${Theme.grayColor[0]}!important;
                }
            `
        }

        if(props.options.color === "rose"){
            extra += `
                background-color: ${Theme.roseColor[0]}!important;
                box-shadow: 0 2px 2px 0 rgba(${hexToRgb(Theme.roseColor[0])}, 0.14), 0 3px 1px -2px rgba(${hexToRgb(Theme.roseColor[0])}, 0.2), 0 1px 5px 0 rgba(${hexToRgb(Theme.roseColor[0])}, 0.12)!important;
                &:hover,&:focus {
                    background-color: ${Theme.roseColor[0]}!important;
                    box-shadow: 0 14px 26px -12px rgba(${hexToRgb(Theme.roseColor[0])}, 0.42), 0 4px 23px 0px rgba(${hexToRgb(Theme.blackColor)}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(Theme.roseColor[0])}, 0.2)!important;
                }
            `
        }
        if(props.options.color === "primary"){
            extra += `
                background-color: ${Theme.primaryColor[0]}!important;
                box-shadow: 0 2px 2px 0 rgba(${hexToRgb(Theme.primaryColor[0])}, 0.14), 0 3px 1px -2px rgba(${hexToRgb(Theme.primaryColor[0])}, 0.2), 0 1px 5px 0 rgba(${hexToRgb(Theme.primaryColor[0])}, 0.12)!important;
                &:hover,&:focus {
                    background-color: ${Theme.primaryColor[0]}!important;
                    box-shadow: 0 14px 26px -12px rgba(${hexToRgb(Theme.primaryColor[0])}, 0.42), 0 4px 23px 0px rgba(${hexToRgb(Theme.blackColor)}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(Theme.primaryColor[0])}, 0.2)!important;
                }
            `
        }
        if(props.options.color === "info"){
            extra += `
                background-color: ${Theme.infoColor[0]}!important;
                box-shadow: 0 2px 2px 0 rgba(${hexToRgb(Theme.infoColor[0])}, 0.14), 0 3px 1px -2px rgba(${hexToRgb(Theme.infoColor[0])}, 0.2), 0 1px 5px 0 rgba(${hexToRgb(Theme.infoColor[0])}, 0.12)!important;
                &:hover,&:focus {
                    background-color: ${Theme.infoColor[0]}!important;
                    box-shadow: 0 14px 26px -12px rgba(${hexToRgb(Theme.infoColor[0])}, 0.42), 0 4px 23px 0px rgba(${hexToRgb(Theme.blackColor)}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(Theme.infoColor[0])}, 0.2)!important;
                }
            `
        }
        if(props.options.color === "success"){
            extra += `
                background-color: ${Theme.successColor[0]}!important;
                box-shadow: 0 2px 2px 0 rgba(${hexToRgb(Theme.successColor[0])}, 0.14), 0 3px 1px -2px rgba(${hexToRgb(Theme.successColor[0])}, 0.2), 0 1px 5px 0 rgba(${hexToRgb(Theme.successColor[0])}, 0.12)!important;
                &:hover,&:focus {
                    background-color: ${Theme.successColor[0]}!important;
                    box-shadow: 0 14px 26px -12px rgba(${hexToRgb(Theme.successColor[0])}, 0.42), 0 4px 23px 0px rgba(${hexToRgb(Theme.blackColor)}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(Theme.successColor[0])}, 0.2)!important;
                }
            `
        }
        if(props.options.color === "warning"){
            extra += `
                background-color: ${Theme.warningColor[0]}!important;
                box-shadow: 0 2px 2px 0 rgba(${hexToRgb(Theme.warningColor[0])}, 0.14), 0 3px 1px -2px rgba(${hexToRgb(Theme.warningColor[0])}, 0.2), 0 1px 5px 0 rgba(${hexToRgb(Theme.warningColor[0])}, 0.12)!important;
                &:hover,&:focus {
                    background-color: ${Theme.warningColor[0]}!important;
                    box-shadow: 0 14px 26px -12px rgba(${hexToRgb(Theme.warningColor[0])}, 0.42), 0 4px 23px 0px rgba(${hexToRgb(Theme.blackColor)}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(Theme.warningColor[0])}, 0.2)!important;
                }
            `
        }
        if(props.options.color === "danger"){
            extra += `
                background-color: ${Theme.dangerColor[0]}!important;
                box-shadow: 0 2px 2px 0 rgba(${hexToRgb(Theme.dangerColor[0])}, 0.14), 0 3px 1px -2px rgba(${hexToRgb(Theme.dangerColor[0])}, 0.2), 0 1px 5px 0 rgba(${hexToRgb(Theme.dangerColor[0])}, 0.12)!important;
                &:hover,&:focus {
                    background-color: ${Theme.dangerColor[0]}!important;
                    box-shadow: 0 14px 26px -12px rgba(${hexToRgb(Theme.dangerColor[0])}, 0.42), 0 4px 23px 0px rgba(${hexToRgb(Theme.blackColor)}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(Theme.dangerColor[0])}, 0.2)!important;
                }
            `
        }
        if(props.options.color === "transparent"){
            extra += `
                &,&:focus,&:hover {
                    color: inherit!important;
                    background: transparent!important;
                    box-shadow: none!important;
                }
            `
        }
        if(props.options.simple){
            extra += `
                &,&:focus,&:hover {
                    color: ${Theme.whiteColor}!important;
                    background: transparent!important;
                    box-shadow: none!important;
                }
            `
            if(props.options.color === 'rose'){
                extra += `
                    &,&:focus,&:hover,&:visited {
                        color: ${Theme.roseColor[0]}!important;
                    }
                    `
            } else if(props.options.color === 'primary'){
                extra += `
                    &,&:focus,&:hover,&:visited {
                        color: ${Theme.primaryColor[0]}!important;
                    }
                    `
            } else if(props.options.color === 'info'){
                extra += `
                    &,&:focus,&:hover,&:visited {
                        color: ${Theme.infoColor[0]}!important;
                    }
                    `
            } else if(props.options.color === 'success'){
                extra += `
                    &,&:focus,&:hover,&:visited {
                        color: ${Theme.successColor[0]}!important;
                    }
                    `
            } else if(props.options.color === 'warning'){
                extra += `
                    &,&:focus,&:hover,&:visited {
                        color: ${Theme.warningColor[0]}!important;
                    }
                    `
            } else if(props.options.color === 'danger'){
                extra += `
                    &,&:focus,&:hover,&:visited {
                        color: ${Theme.dangerColor[0]}!important;
                    }
                    `
            }
        }

        if(props.options.disabled){
            extra += `
                opacity: 0.65!important;
                pointer-events: none!important;
            `
        }
        if(props.options.size === "lg"){
            extra += `
                padding: 1.125rem 2.25rem!important;
                font-size: 0.875rem!important;
                line-height: 1.333333!important;
                border-radius: 0.2rem!important;
            `
        } else if(props.options.size === "sm"){
            extra += `
                padding: 0.40625rem 1.25rem!important;
                font-size: 0.6875rem!important;
                line-height: 1.5!important;
                border-radius: 0.2rem!important;
            `
        }
        if(props.options.round){
            extra += `
                border-radius: 30px!important;
            `
        }
        if(props.options.block){
            extra += `
                width: 100%!important!important;
            `
        }
        if(props.options.link){
            extra += `
                &,&:hover,&:focus {
                    background-color: transparent!important;
                    box-shadow: none!important;
                }
            `

            if(props.options.color === "rose"){
                extra += `
                    color: ${Theme.roseColor[0]}!important;
                    &:hover,&:focus {
                        color: ${Theme.roseColor[0]}!important;
                        box-shadow: 0 14px 26px -12px rgba(${hexToRgb(Theme.roseColor[0])}, 0.42), 0 4px 23px 0px rgba(${hexToRgb(Theme.blackColor)}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(Theme.roseColor[0])}, 0.2)!important;
                    }
                `
            } else if(props.options.color === "primary"){
                extra += `
                    color: ${Theme.primaryColor[0]}!important;
                    &:hover,&:focus {
                        color: ${Theme.primaryColor[0]}!important;
                        box-shadow: 0 14px 26px -12px rgba(${hexToRgb(Theme.primaryColor[0])}, 0.42), 0 4px 23px 0px rgba(${hexToRgb(Theme.blackColor)}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(Theme.primaryColor[0])}, 0.2)!important;
                    }
                `
            } else if(props.options.color === "info"){
                extra += `
                    color: ${Theme.infoColor[0]}!important;
                    &:hover,&:focus {
                        color: ${Theme.infoColor[0]}!important;
                        box-shadow: 0 14px 26px -12px rgba(${hexToRgb(Theme.infoColor[0])}, 0.42), 0 4px 23px 0px rgba(${hexToRgb(Theme.blackColor)}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(Theme.infoColor[0])}, 0.2)!important;
                    }
                `
            } else if(props.options.color === "success"){
                extra += `
                    color: ${Theme.successColor[0]}!important;
                    &:hover,&:focus {
                        color: ${Theme.successColor[0]}!important;
                        box-shadow: 0 14px 26px -12px rgba(${hexToRgb(Theme.successColor[0])}, 0.42), 0 4px 23px 0px rgba(${hexToRgb(Theme.blackColor)}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(Theme.successColor[0])}, 0.2)!important;
                    }
                `
            } else if(props.options.color === "warning"){
                extra += `
                    color: ${Theme.warningColor[0]}!important;
                    &:hover,&:focus {
                        color: ${Theme.warningColor[0]}!important;
                        box-shadow: 0 14px 26px -12px rgba(${hexToRgb(Theme.warningColor[0])}, 0.42), 0 4px 23px 0px rgba(${hexToRgb(Theme.blackColor)}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(Theme.warningColor[0])}, 0.2)!important;
                    }
                `
            } else if(props.options.color === "danger"){
                extra += `
                    color: ${Theme.dangerColor[0]}!important;
                    &:hover,&:focus {
                        color: ${Theme.dangerColor[0]}!important;
                        box-shadow: 0 14px 26px -12px rgba(${hexToRgb(Theme.dangerColor[0])}, 0.42), 0 4px 23px 0px rgba(${hexToRgb(Theme.blackColor)}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(Theme.dangerColor[0])}, 0.2)!important;
                    }
                `
            } else {
                extra += `
                &,&:hover,&:focus {
                    color: ${Theme.grayColor[0]}!important;
                }
                `
            }
        }

        if(props.options.justIcon){
            extra += `
                padding-left: 12px!important;
                padding-right: 12px!important;
                font-size: 20px!important;
                height: 41px!important;
                min-width: 41px!important;
                width: 41px!important;
                & .fab,& .fas,& .far,& .fal,& svg,& .material-icons {
                    margin-right: 0px!important;
                }
            `

            if(props.options.size === "lg"){
                extra += `
                    height: 57px!important;
                    min-width: 57px!important;
                    width: 57px!important;
                    line-height: 56px!important;
                    & .fab,& .fas,& .far,& .fal,& .material-icons {
                        font-size: 32px!important;
                        line-height: 56px!important;
                    }
                    & svg {
                        width: 32px!important;
                        height: 32px!important;
                    }
                `
            } else if(props.options.size === "sm"){
                extra += `
                    height: 30px!important;
                    min-width: 30px!important;
                    width: 30px!important;
                    & .fab,& .fas,& .far,& .fal,& .material-icons {
                        font-size: 17px!important;
                        line-height: 29px!important;
                    }
                    & svg {
                        width: 17px!important;
                        height: 17px!important;
                    }
                `
            }
        }
        
        return extra;
    }}
`