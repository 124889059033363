import * as Yup from "yup";

const validationGenericSchema = Yup.object().shape({
    Nome: Yup.string().required("Por favor digite o nome"),
    Categoria: Yup.string().required("Por favor informe a Categoria"),
    Cnpj: Yup.string().required("Por favor digite a identificação única desta empresa"),
    Email: Yup.string().required("Por favor digite o e-mail").email("Por favor digite um e-mail válido"),
    Endereco: Yup.string().required("Por favor digite o endereço"),
    Bairro: Yup.string().required("Por favor digite o bairro"),
    // cidade: Yup.string().nullable(),
    // NomeCidade: Yup.string().required("Por favor informe a cidade"),
    Telefone: Yup.string().required("Por favor digite o Telefone"),
    Telefone2: Yup.string().nullable(),
    Telefone3: Yup.string().nullable(),
    Atividades: Yup.string().required("Por favor digite as atividades")
});

export default validationGenericSchema;
