/* eslint-disable max-len */
/* Geral / Bibliotecas */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";

/* Métodos / Ações */
import * as form from "actions/form";
import { fetch, save, closePaymentModal} from "actions/compras";

/* Componentes */
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Container } from "assets/jss/doSul-dashboard";
import Loading from "components/Loading";
import { PayPalButton } from "react-paypal-button-v2";
import DynamicTable from "components/DynamicTable";
import CardBody from "components/Card/CardBody";
import Card from "components/Card";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
    DEFAULT_TOAST_POSITION, DEFAULT_TOAST_TIMEOUT, PAYPAL_SANBOX_TOKEN, PAYPAL_LIVE_TOKEN, PAYPAL_PRODUCTION
} from "helpers/consts";

const MySwal = withReactContent(Swal);

const Model = {
    IdCompra: null,
    planos: {},
    OrderId: null,
    Transacao: null,
    Status: 2,
};

export default function ComprasForm({
    id, changeStatus, deleteRecord, afterSave, afterChangeStatusSuccess, afterDeleteSuccess
}) {
    const formData = useSelector(state => state.form);
    const permissions = useSelector(state => state.permissions);

    const dispatch = useDispatch();

    const afterFetch = (data) => {
        const model = data.result;
        // if (model.Empresa) model.empresa = btoa(model.Empresa);
        // if (model.Membro) model.membro = btoa(model.Membro);
        // if (model.Cupom) model.cupom = btoa(model.Cupom);
        if (model.Planos) model.planos = model.Planos;
        console.log(model.planos);
        // etc
        dispatch(form.setModel(model));
        dispatch(form.setLoading(false));
    }; // Sobrescrever se precisar

    const afterSaveFetch = () => {
        closePaymentModal();
    }

    useEffect(() => {
        form.start("compras", "IdCompra", id, Model, fetch, save, changeStatus, deleteRecord, afterSaveFetch, afterChangeStatusSuccess, afterDeleteSuccess, afterFetch);
        return form.stop;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // É chamado toda vez que muda o ID
        form.setModel(Model);
        if (id) {
            form.fetchData(id);
        }
    }, [id]);

    const handleSubmit = (values, actions) => {
        formData.save(values, actions, formData.afterSave);
    };

    if (formData.loading) {
        return <Loading />;
    }

    const disabled = id || (permissions && permissions[formData.root] < 2);

    return (
        <Container>
            <Formik
                initialValues={formData.model}
                onSubmit={handleSubmit}
                render={FormikProps => (
                    <Form onSubmit={FormikProps.handleSubmit}>
                        <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardBody>
                                        <DynamicTable
                                            sortable={false}
                                            showPagination={false}
                                            defaultPageSize={9999}
                                            data={[FormikProps.values.planos]}
                                            style={{ margin: "-0.9375rem -20px", marginTop: "10px" }}
                                            columns={[
                                                {
                                                    Header: "Nome",
                                                    accessor: "Nome"
                                                },
                                                {
                                                    Header: "Moeda",
                                                    accessor: "Moeda"
                                                },
                                                {
                                                    Header: "Valor",
                                                    accessor: "Valor",
                                                    Footer: (
                                                        <span>
                                                            <strong>Total:</strong>
                                                            {" "}
                                                            {FormikProps.values.Valorpago} {FormikProps.values.Desconto > 0 && `( - ${FormikProps.values.Desconto}% )`}
                                                        </span>
                                                    )
                                                }
                                            ]}
                                        />
                                    </CardBody>
                                </Card>
                            </GridItem>
                            {disabled && FormikProps.values.planos ? (
                                <GridItem xs={12}>
                                    <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                                        <GridContainer justify="center" spacing={5}>
                                            <PayPalButton
                                                amount={FormikProps.values.Valorpago}
                                                //amount={0.05}
                                                //currency={FormikProps.values.planos.Codigo}
                                                currency={FormikProps.values.planos.Codigo || 'BRL'}
                                                shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                                onSuccess={(details, data) => {
                                                    console.log(details);
                                                    FormikProps.setFieldValue("OrderId", details.id);
                                                    FormikProps.setFieldValue("Transacao", JSON.stringify({ details, data }));
                                                    FormikProps.setFieldValue("Valorpago", parseFloat(details.purchase_units[0].amount.value));
                                                    FormikProps.setFieldValue("Status", details.status === 'COMPLETED' ? 1 : 2);
                                                    FormikProps.submitForm();
                                                }}
                                                catchError={(data) => {
                                                    console.log(data);
                                                    MySwal.fire({
                                                        type: "error",
                                                        title: "Não foi possível concluir o pagamento.",
                                                        toast: true,
                                                        position: DEFAULT_TOAST_POSITION,
                                                        showConfirmButton: false,
                                                        timer: DEFAULT_TOAST_TIMEOUT
                                                    });
                                                }}
                                                onError={(data) => {
                                                    console.log(data);
                                                    MySwal.fire({
                                                        type: "error",
                                                        title: "Não foi possível concluir o pagamento.",
                                                        toast: true,
                                                        position: DEFAULT_TOAST_POSITION,
                                                        showConfirmButton: false,
                                                        timer: DEFAULT_TOAST_TIMEOUT
                                                    });
                                                }}
                                                onCancel={(data) => { // {orderID: "51H98396DY964493P"}
                                                    console.log(data);
                                                    MySwal.fire({
                                                        type: "info",
                                                        title: "O pagamento não foi realizado.",
                                                        toast: true,
                                                        position: DEFAULT_TOAST_POSITION,
                                                        showConfirmButton: false,
                                                        timer: DEFAULT_TOAST_TIMEOUT
                                                    });
                                                }}
                                                options={{
                                                    clientId: PAYPAL_PRODUCTION ? PAYPAL_LIVE_TOKEN : PAYPAL_SANBOX_TOKEN,
                                                    currency: FormikProps.values.planos.Codigo
                                                }}
                                            />
                                        </GridContainer>
                                    </div>
                                </GridItem>
                            ) : ""}
                        </GridContainer>
                    </Form>
                )}
            />
        </Container>
    );
}

ComprasForm.propTypes = {
    id: PropTypes.any,
    afterSave: PropTypes.func,
    changeStatus: PropTypes.func,
    deleteRecord: PropTypes.func,
    afterChangeStatusSuccess: PropTypes.func,
    afterDeleteSuccess: PropTypes.func
};
