// Geral
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";

// Ações
import * as list from "actions/list";
import { fetchList, changeStatus, deleteRecord } from "actions/membros";
import { JWT_TOKEN } from "helpers/consts";

// Componentes
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card";
import CardBody from "components/Card/CardBody";
import RightPanel from "components/RightPanel";
import DynamicTable from "components/DynamicTable";
import ListButtonsMembros from "components/ListButtonsMembros";
import Pesquisa from "components/Pesquisa";
import ListHeader from "components/ListHeader";

// Formulário
import Form from "./form";

const view = "membros";


export default function Membros({ params }) {
    const token = jwt_decode(localStorage.getItem(JWT_TOKEN));

    useEffect(() => {
        list.start(view, "IdMembro", fetchList, changeStatus, deleteRecord);
    }, []);

    const listData = useSelector(state => state.list[view]);

    const form = listData
        ? (
            <Form
                changeStatus={listData.changeStatus}
                deleteRecord={listData.delete}
                afterSave={() => list.afterSaveSuccess(view)}
                afterChangeStatusSuccess={() => list.afterChangeStatusSuccess(view)}
                afterDeleteSuccess={() => list.afterDeleteSuccess(view)}
                id={params ? params.id : null}
            />
        ) : null;

    return (
        <GridContainer>
            {listData && (
                <>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <ListHeader
                                title="Membros"
                                add={token.platform === "dashboard" ? "Novo Membro" : false}
                                legend={token.platform === "dashboard" ? "Membros que utilizam o App" : "Meus cadastros"}
                                view={view}
                            />
                            <CardBody>
                                <Pesquisa view={view} />
                                <DynamicTable
                                    view={view}
                                    {...listData.tableProps}
                                    columns={[
                                        {
                                            Header: "Nome",
                                            accessor: "Nome"
                                        },
                                        {
                                            Header: "Tipo",
                                            accessor: "TipoDisplay"
                                        },
                                        {
                                            Header: "Status",
                                            accessor: "Status",
                                            maxWidth: 150,
                                            style: {
                                                padding: 0
                                            },
                                            Cell: props => <ListButtonsMembros cellProps={props} view={view} root={token.platform === "dashboard" ? view : "a"} />
                                        }
                                    ]}
                                    onFetchData={(state, instance) => list.requestData(view)}
                                    onPageSizeChange={(pageSize, pageIndex) => list.onPageSizeChange(pageSize, pageIndex, view)}
                                    onPageChange={(pageIndex) => list.onPageChange(pageIndex, view)}
                                    onSortedChange={(newSorted, column, shiftKey) => list.onSortedChange(newSorted, column, shiftKey, view)}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>

                    <RightPanel root={listData.root} isPaneOpen={params && params.action === "new"} title="Novo Membro" path="new">
                        {form}
                    </RightPanel>
                    <RightPanel root={listData.root} isPaneOpen={params && params.action === "edit"} title="Edição de Membro" path="edit">
                        {form}
                    </RightPanel>
                </>
            )}
        </GridContainer>
    );
}
