// Geral
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

// Ações
import * as list from "actions/list";
import { fetchList, changeStatus } from "actions/autorizacoes-membro";

// Componentes
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card";
import CardBody from "components/Card/CardBody";
import RightPanel from "components/RightPanel";
import DynamicTable from "components/DynamicTable";
import ListButtons from "components/ListButtons";
import Pesquisa from "components/Pesquisa";
import ListHeader from "components/ListHeader";

// Formulário
import Form from "./form";

const view = "autorizacoesmembro";

export default function EmpresasCategorias({ params }) {
    useEffect(() => {
        list.start(view, "IdAutorizacaoMembro", fetchList, changeStatus);
    }, []);

    const listData = useSelector((state) => state.list[view]);

    const form = listData ? (
        <Form
            changeStatus={listData.changeStatus}
            afterChangeStatusSuccess={() => list.afterChangeStatusSuccess(view)}
            id={params ? params.id : null}
        />
    ) : null;

    return (
        <GridContainer>
            {" "}
            {listData && (
                <>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <ListHeader
                                title="Autorizações"
                                legend="Autorizações gerais"
                                view={view}
                            />
                            <CardBody>
                                <Pesquisa view={view} />
                                <DynamicTable
                                    view={view}
                                    {...listData.tableProps}
                                    columns={[
                                        {
                                            Header: "Data / Hora",
                                            accessor: "DataHoraDisplay",
                                        },
                                        {
                                            Header: "Tipo",
                                            accessor: "TipoDisplay",
                                        },
                                        {
                                            Header: "De",
                                            accessor: "nomeDe",
                                        },
                                        {
                                            Header: "Para",
                                            accessor: "nomePara",
                                        },
                                        {
                                            Header: "Status",
                                            accessor: "Status",
                                            maxWidth: 150,
                                            style: {
                                                padding: 0,
                                            },
                                            Cell: (props) => (
                                                <ListButtons
                                                    cellProps={props}
                                                    view={view}
                                                    disableRemove
                                                    justificativaBlock
                                                    dontAllowBlock
                                                />
                                            ),
                                        },
                                    ]}
                                    onFetchData={(state, instance) =>
                                        list.requestData(view)
                                    }
                                    onPageSizeChange={(pageSize, pageIndex) =>
                                        list.onPageSizeChange(
                                            pageSize,
                                            pageIndex,
                                            view
                                        )
                                    }
                                    onPageChange={(pageIndex) =>
                                        list.onPageChange(pageIndex, view)
                                    }
                                    onSortedChange={(
                                        newSorted,
                                        column,
                                        shiftKey
                                    ) =>
                                        list.onSortedChange(
                                            newSorted,
                                            column,
                                            shiftKey,
                                            view
                                        )
                                    }
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                    <RightPanel
                        root={listData.root}
                        isPaneOpen={params && params.action === "edit"}
                        title="Visualização de autorização"
                        path="edit"
                    >
                        {form}
                    </RightPanel>
                </>
            )}
        </GridContainer>
    );
}
