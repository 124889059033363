import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'hookrouter';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import styled from 'styled-components';
import { Theme, primaryBoxShadow } from 'assets/jss/doSul-dashboard';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import './styles.css';

import closeImage from "./times.png";

const CustomSlidingPane = styled(SlidingPane)`
    width: ${(props) => props.root ? Theme.modalWidth : Theme.modalWidth - 5}%!important; 
    position: fixed; 
    right: 0;

    .slide-pane__header{
        background: linear-gradient(60deg, ${Theme.primaryColor[1]}, ${Theme.primaryColor[2]});
        z-index: 2001;
        border: none;
        box-shadow: ${primaryBoxShadow};
        border-bottom: none;
    }
    .slide-pane__title{
        font-weight: 600!important;
        color: ${Theme.whiteColor};
        text-align: left!important;
        font-size: 1rem!important;
        text-transform: uppercase;
    }
    .slide-pane__close{
        color: #343434;
        font-size: 2em;
        opacity: 1!important;
        z-index: 2001;
        margin-left: 0;
        padding-left: 1.5rem;
        display: flex;
        align-items: center;
    }
    .slide-pane__close:hover img{
        filter: brightness(-2.5); 
    }
    .slide-pane__title-wrapper{
        margin-left: 0;
    }
    .slide-pane__content{ background-color: white; z-index: 2002; margin-top:0!important; padding: 0!important; overflow-y: auto!important; }
    @media (max-width: ${Theme.breakpointMd}) {
        width: 96%!important;
        
        .slide-pane__title{
            font-size: 16px!important;
        }
    }
`

function RightPanel(props) {
  return <div>
    <CustomSlidingPane
      className='right-panel-modal-custom'
      overlayClassName='right-panel-modal-custom-overlay'
      isOpen={props.isPaneOpen === null ? false : props.isPaneOpen}
      title={props.title}
      root={props.root}
      closeIcon={<img src={closeImage} alt="Fechar" title="Fechar" className="img-fluid " style={{
        maxWidth: '20px',
        transform: 'translateY(-2px)'
      }} />}
      onRequestClose={() => {
        let scr = document.documentElement.scrollTop;
        if (props.onClose)
          props.onClose();

        window.history.replaceState(null, null, ' ');
        document.documentElement.scrollTop = scr;

        if (props.root) {
          navigate('/' + props.root);
        }
      }}>
      {props.children}
    </CustomSlidingPane>
  </div>
}
RightPanel.propTypes = {
  isPaneOpen: PropTypes.bool,
  onClose: PropTypes.func,
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
  root: PropTypes.string,
  component: PropTypes.any,
}

Modal.setAppElement(document.getElementById('root'))

export default RightPanel