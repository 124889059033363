const types = {
  CLEAR_FORM: "CLEAR_FORM",
  SET_FORM_ID: "SET_FORM_ID",
  SET_FORM_ROOT: "SET_FORM_ROOT",
  SET_FORM_SAVE: "SET_FORM_SAVE",
  SET_FORM_AFTER_SAVE: "SET_FORM_AFTER_SAVE",
  SET_FORM_CHANGE_STATUS: "SET_FORM_CHANGE_STATUS",
  SET_FORM_AFTER_CHANGE_STATUS_SUCCESS: "SET_FORM_AFTER_CHANGE_STATUS_SUCCESS",
  SET_FORM_FETCH: "SET_FORM_FETCH",
  SET_FORM_AFTER_FETCH: "SET_FORM_AFTER_FETCH",
  SET_FORM_DELETE: "SET_FORM_DELETE",
  SET_FORM_AFTER_DELETE_SUCCESS: "SET_FORM_AFTER_DELETE_SUCCESS",
  SET_FORM_IDFIELD: "SET_FORM_IDFIELD",
  SET_FORM_LOADING: "SET_FORM_LOADING",
  SET_FORM_MODEL: "SET_FORM_MODEL"
};

export default types;
