import styled from 'styled-components';
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";

import { Theme } from 'assets/jss/doSul-dashboard';

export const MaterialInput = styled(Input)`
    min-height: auto!important;
    min-width: auto!important;
    
    &:hover:not(:disabled):before, &:before {
        border-color: ${Theme.grayColor[4]}!important;
        border-width: 1px!important;
    }
    &:after {
        border-color: ${Theme.primaryColor[0]}!important;
    }
    ${(props) => {
        let style = "";
        
        if(props.disabled){
            style += `
                &:before {
                    background-color: transparent!important;
                }
            `;
        }
        if(props['data-error'] && props['data-error'] === "true"){
            style += `
                &:after {
                    border-color: ${Theme.dangerColor[0]}!important;
                }
            `;
        }
        if(props['data-success'] && props['data-success'] === "true"){
            style += `
                &:after {
                    border-color: ${Theme.successColor[0]}!important;
                }
            `;
        }

        return style;
    }}
        
`

export const MaterialInputLabel = styled(InputLabel)`
    color: ${Theme.grayColor[3]}!important;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.42857;
    letter-spacing: unset;

    ${(props) => {
        let style = "";

        if(props['data-error'] && props['data-error'] === "true"){
            style += `color: ${Theme.dangerColor[0]}`;
        }
        if(props['data-success'] && props['data-success'] === "true"){
            style += `color: ${Theme.successColor[0]}`;
        }

        return style;
    }}
`

export const ErrorClear = styled(Clear)`
    position: absolute;
    top: 18px;
    right: 0;
    z-index: 2;
    display: block;
    width: 24px;
    height: 24px;
    text-align: center;
    pointer-events: none;
    color: ${Theme.dangerColor[0]};
`

export const SuccessCheck = styled(Check)`
    position: absolute;
    top: 18px;
    right: 0;
    z-index: 2;
    display: block;
    width: 24px;
    height: 24px;
    text-align: center;
    pointer-events: none;
    color: ${Theme.successColor[0]};
`

export const CustomFormControl = styled(FormControl)`
    padding-bottom: 10px!important;
    margin: 15px 0 0 0!important;
    position: relative!important;
    vertical-align: unset!important;
`
