// Geral
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

// Ações
import * as list from "actions/list";
import { fetchList, changeStatus, deleteRecord } from "actions/agenda";

// Componentes
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card";
import CardBody from "components/Card/CardBody";
import RightPanel from "components/RightPanel";
import DynamicTable from "components/DynamicTable";
import ListButtons from "components/ListButtons";
import Pesquisa from "components/Pesquisa";
import PesquisaDatas from "components/PesquisaDatas";
import ListHeader from "components/ListHeader";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import ptLocale from "@fullcalendar/core/locales/pt";

// Formulário
import { Theme } from "assets/jss/doSul-dashboard";
import Form from "./form";

// Estilos do calendario
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";

const view = "agenda";

export default function Agenda({ params }) {
    useEffect(() => {
        list.start(view, "IdAgenda", fetchList, changeStatus, deleteRecord);
    }, []);

    const listData = useSelector(state => state.list[view]);

    const form = listData
        ? (
            <Form
                changeStatus={listData.changeStatus}
                deleteRecord={listData.delete}
                afterSave={() => list.afterSaveSuccess(view)}
                afterChangeStatusSuccess={() => list.afterChangeStatusSuccess(view)}
                afterDeleteSuccess={() => list.afterDeleteSuccess(view)}
                id={params ? params.id : null}
            />
        ) : null;

    function parseDataCalendar(data) {
        return {
            id: data.IdAgenda,
            title: data.Titulo,
            start: data.DataHoraInicio,
            end: data.DataHoraFim,
            description: data.Descricao,
            backgroundColor: Theme.primaryColor[1],
            borderColor: "#FFF"
        };
    }

    return (
        <GridContainer>
            {listData && (
                <>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <ListHeader
                                title="Agenda"
                                add="Novo Compromisso"
                                legend="Agenda do App"
                                view={view}
                            />
                            <CardBody>
                                <GridContainer alignContent="space-between" alignItems="flex-end">
                                    <GridItem xs={12} lg={6}>
                                        <Pesquisa view={view} />
                                    </GridItem>
                                    
                                    <GridItem xs={12} lg={6}>
                                        <PesquisaDatas view={view} />
                                    </GridItem>
                                </GridContainer>
                                {(!listData.s && 1 > 2
                                    ? (
                                        <FullCalendar
                                            defaultView="dayGridMonth"
                                            plugins={[dayGridPlugin]}
                                            events={listData.results}
                                            eventDataTransform={parseDataCalendar}
                                            locale={ptLocale}
                                            eventClick={(data) => list.openEditModal(data.event.id, view)}
                                        />
                                    )
                                    : (
                                        <DynamicTable
                                            view={view}
                                            {...listData.tableProps}
                                            columns={[
                                                {
                                                    Header: "Data/Hora",
                                                    accessor: "DataHoraDisplay"
                                                },
                                                {
                                                    Header: "Titulo",
                                                    accessor: "Titulo"
                                                },
                                                {
                                                    Header: "Tipo",
                                                    Cell: (props) => {
                                                        let p = props.original
                                                        if (p.Entidade) return 'Entidade';
                                                        else if (p.Potencia) return 'Potência';
                                                        else if (p.Loja) return 'Loja';
                                                    },
                                                },
                                                {
                                                    Header: "Autoria",
                                                    Cell: (props) => props.original.NomeResponsavel,
                                                },
                                                {
                                                    Header: "Status",
                                                    accessor: "Status",
                                                    maxWidth: 150,
                                                    style: {
                                                        padding: 0
                                                    },
                                                    Cell: props => <ListButtons cellProps={props} view={view} />
                                                }
                                            ]}
                                            onFetchData={(state, instance) => list.requestData(view)}
                                            onPageSizeChange={(pageSize, pageIndex) => list.onPageSizeChange(pageSize, pageIndex, view)}
                                            onPageChange={(pageIndex) => list.onPageChange(pageIndex, view)}
                                            onSortedChange={(newSorted, column, shiftKey) => list.onSortedChange(newSorted, column, shiftKey, view)}
                                        />
                                    )
                                )}
                            </CardBody>
                        </Card>
                    </GridItem>

                    <RightPanel
                        root={listData.root}
                        isPaneOpen={params && params.action === "new"}
                        title="Novo compromisso"
                        path="new"
                    >
                        {form}
                    </RightPanel>
                    <RightPanel
                        root={listData.root}
                        isPaneOpen={params && params.action === "edit"}
                        title="Edição de Compromisso"
                        path="edit"
                    >
                        {form}
                    </RightPanel>
                </>
            )}
        </GridContainer>
    );
}
