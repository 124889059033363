// Geral
import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import Menu from "@material-ui/icons/Menu";

// Componentes
import NavbarLinks from "./NavbarLinks.jsx";

// Estilos
import { CustomAppBar, CustomContainer, Flex, Title } from "./styles";

/* Multi idiomas */
import FlagsContainer from "components/I18n/index";

import { defaultLabel } from 'routes';

function Header({ ...props }) {

    function makeBrand() {
        var name;
        var qtd = 0;

        props.routes.map(prop => {
            if(prop.type && prop.type === 'group'){
                
                if(qtd === 0)
                    name = typeof prop.name === 'object' && prop.name !== null ? 
                    prop.name.props.children : 
                    prop.name;


                console.log(prop.name);

                prop.childrens.map(innerProp => {
                    
                    let path = innerProp.path.split('/')[0];

                    if (window.location.href.indexOf(path) !== -1) {
                        //console.log(prop.name.props.children)
                        qtd++;

                        if(!innerProp.showLabel)
                            name += ' > '+ innerProp.name.props.children;
                            //name += ' > '+innerProp.name;
                        else 
                            name += ' > '+innerProp.showLabel;
                    }
                    return innerProp;
                });
            } 
            else {
                let path = prop.path.split('/')[0];

                if (window.location.href.indexOf(path) !== -1) {
                    if(!prop.showLabel)
                        name = prop.name;
                    else 
                        name = prop.showLabel;
                }
            }

            return <span>&nbsp;</span>;
        });

    if(qtd === 0){
        name = defaultLabel;
    }
    
    return name || <span>&nbsp;</span>;
  }

  return (
    <CustomAppBar>
        <CustomContainer>
            <Flex>
                <Title color="transparent">
                    {makeBrand()}
                </Title>
                
                <FlagsContainer />
            </Flex>
            <Hidden smDown implementation="css">
                <NavbarLinks />
            </Hidden>
            <Hidden mdUp implementation="css">
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={props.handleDrawerToggle}
                >
                    <Menu />
                </IconButton>
            </Hidden>
        </CustomContainer>
    </CustomAppBar>
  );
}

Header.propTypes = {
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};

export default Header;
