import React from 'react';
import styled from 'styled-components';
import { A } from 'hookrouter';

import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";

import { Theme, hexToRgb, defaultBoxShadow } from 'assets/jss/doSul-dashboard';

let drawerWidth = 260;

export const NavLinkItem = styled(A)`
    position: relative
    display: block
    text-decoration: none
    color: ${Theme.whiteColor}
    &:hover, &:focus {
        color: ${Theme.grayColor[10]};
    }
`

export const CustomListItem = styled(ListItem)`
    width: auto!important
    transition: all 300ms linear!important
    margin: ${props => props.sublist === 'true' ? '0px 15px 0px 15px!important' : '10px 15px 0!important'}
    border-radius: ${props => props.sublist === 'true' ? '0px!important' : ((props.parentlist === 'true' && props.active === 'true') ? '3px 3px 0px 0px!important' : '3px!important')}
    position: relative!important
    display: flex!important
    padding: ${props => props.sublist === 'true' ? '0px 30px 0px 15px!important' : '10px 15px!important'}
    background-color: ${props => props.active === 'true' ? Theme.primaryColor[0] + '!important' : (props.sublist === 'true' ? `rgba(${hexToRgb(Theme.primaryColor[0])}, 0.4)!important` : 'transparent!important')}
    box-shadow: ${props => {
        if (props.active === 'true') {
            return "0 12px 20px -10px rgba(" +
                hexToRgb(Theme.primaryColor[0]) +
                ",.28), 0 4px 20px 0 rgba(" +
                hexToRgb(Theme.blackColor) +
                ",.12), 0 7px 8px -5px rgba(" +
                hexToRgb(Theme.primaryColor[0]) +
                ",.2)"
        }
        return 'none'
    }}!important

    ${props => {
        if (props.sublist === 'true') {
            return `
            border-bottom: 1px solid ${Theme.primaryColor[0]}!important;
            border-left: 1px solid ${Theme.primaryColor[0]}!important;
            border-right: 1px solid ${Theme.primaryColor[0]}!important;

            &:hover,&:focus {
                background-color: ${Theme.primaryColor[2]}!important;
                box-shadow: ${"0 12px 20px -10px rgba(" +
                hexToRgb(Theme.primaryColor[2]) +
                ",.28), 0 4px 20px 0 rgba(" +
                hexToRgb(Theme.blackColor) +
                ",.12), 0 7px 8px -5px rgba(" +
                hexToRgb(Theme.primaryColor[2]) +
                ",.2)"
                }!important
            }
            `
        } else {
            return `&:hover,&:focus {
                background-color: ${Theme.primaryColor[0]}!important;
                box-shadow: ${"0 12px 20px -10px rgba(" +
                hexToRgb(Theme.primaryColor[0]) +
                ",.28), 0 4px 20px 0 rgba(" +
                hexToRgb(Theme.blackColor) +
                ",.12), 0 7px 8px -5px rgba(" +
                hexToRgb(Theme.primaryColor[0]) +
                ",.2)"
                }!important
            }`;
        }
    }}
`

export const CustomIcon = styled(Icon)`
    width: 24px
    height: 30px
    font-size: 24px
    line-height: 30px
    float: left
    margin-right: 15px
    text-align: center
    vertical-align: middle
    color: rgba(${hexToRgb(Theme.whiteColor)}, 0.8)
`

export const DynamicIcon = styled(
    ({ component, ...props }) => React.cloneElement(component, props)
)`
    width: 24px!important
    height: 30px!important
    font-size: 24px!important
    line-height: 30px
    float: left
    margin-right: 15px
    text-align: center
    vertical-align: middle
    color: rgba(${hexToRgb(Theme.whiteColor)}, 0.8)
`

export const CustomListItemText = styled(ListItemText)`
    margin: 0
    line-height: 30px!important
    font-size: 14px!important
    color: ${Theme.whiteColor}!important
`

export const Logo = styled.div`
    position: relative
    padding: 15px 15px
    z-index: 5

    &:after {
        display: block
        content: "";
        position: absolute
        bottom: 0
        height: 1px
        right: 15px
        width: calc(100% - 30px);
        background-color: rgba(${hexToRgb(Theme.grayColor[6])}, 0.3)
    }
`

export const LogoImage = styled.div`
    width: 100%
    display: flex
    align-items: center
    justify-content: center
`

export const Img = styled.img`
    width: ${drawerWidth - 30 - 30}px;
    top: 22px
    overflow: hidden
    border: 0
    padding-left: 15px
    padding-right: 15px
`

export const Background = styled.div`
    position: absolute
    z-index: 1
    height: 100%
    width: 100%
    display: block
    top: 0
    left: 0
    background-size: cover
    background-position: center center

    &:after {
        position: absolute
        z-index: 3
        width: 100%
        height: 100%
        content: '';
        display: block
        background: ${Theme.blackColor}
        opacity: .8
    }
`

export const DrawerPaper = styled(Drawer)`
    & > .MuiDrawer-paper{
        border: none
        position: fixed
        top: 0
        bottom: 0
        left: 0
        z-index: 1
        box-shadow: ${defaultBoxShadow};
        width: ${drawerWidth}px;
    }

    & > .MuiDrawer-paper{
        @media (min-width: ${Theme.breakpointMd}) {
            width: ${drawerWidth}px;
            position: fixed
            height: 100%
        }

        @media (max-width: ${Theme.breakpointMd}) {
            width: ${drawerWidth}px;
            box-shadow: ${defaultBoxShadow};
            position: fixed
            display: block
            top: 0
            height: 100vh
            right: 0
            left: auto!important
            z-index: 1032
            visibility: visible
            overflow-y: visible
            border-top: none
            text-align: left
            padding-right: 0px
            padding-left: 0
            transform: translate3d(${drawerWidth}px, 0, 0)
        }
    }    
`

export const SidebarWrapper = styled.div`
    position: relative
    height: calc(100vh - 75px);
    overflow: auto
    width: 260px
    z-index: 4
    overflow-scrolling: touch
`