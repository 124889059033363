import Geocode from "react-geocode";
// Geocode.setApiKey("AIzaSyCUkzG5Wx3wlIyMNKug6NIdosifsgx2MMQ");
Geocode.setApiKey("AIzaSyAkVqQuOEYACMturcvHSpLyZybKA_erZ0o");

export const getCoordinates = async dados => {
    let { Endereco, Numero, Bairro, EstadoNome, CidadeNome, PaisNome } = dados.values;
    
    const response = await Geocode.fromAddress(`${Endereco} ${Numero}, ${Bairro}, ${CidadeNome}, ${EstadoNome}, ${PaisNome}`);

    return response;
}
