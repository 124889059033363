import {
    defaultFetchList,
    defaultChangeStatus,
    defaultDelete,
    defaultSave,
    defaultFetch,
    defaultLoadOptions
} from "./default";

import axios from "axios";
import store from "helpers/store";
import { navigate } from "hookrouter";
import { setParentId } from "./list";

import { URL_VIEW_STATISTIC } from "helpers/consts";

const model = "anuncios";
const idField = "IdAnuncio";

export const fetchList = (reqData, resolve, reject, dispatch) => defaultFetchList(model, reqData, resolve, reject, dispatch);
export const fetch = (id, callback) => defaultFetch(model, id, callback);
export const changeStatus = (id, status, afterChange, afterError) => defaultChangeStatus(model, id, status, afterChange, afterError);
export const deleteRecord = (id, afterDelete, afterError) => defaultDelete(model, id, afterDelete, afterError);
export const save = (data, actions, afterSave, afterError) => defaultSave(model, idField, data, true, actions, afterSave, afterError);

export const loadOptions = (inputValue, filters) => defaultLoadOptions(inputValue, model, ["IdAnuncio", "Titulo"], ["IdAnuncio", "Titulo"], filters);

export const loadEstatistica = obj => new Promise((resolve, reject) => {
    let { tipo, concept, dateStart, dateEnd } = obj;

    let url = '';
    
    if(tipo === 1) url = `gender`;
    else if(tipo === 2) url = `location`;
    else if(tipo === 3) url = `age`;

    axios
        .post(`estatisticas/concepts/${url}`, {
            concept,
            concept_id: 2,
            date_start: dateStart,
	        date_end: dateEnd
        })
        .then(res => resolve(res.data))
        .catch(err => (err.response && err.response.data)
            ? reject(err.response.data)
            : reject("Não foi possível obter as estatísticas")
        );
});


export const openStatisticModal = (id = null) => {
    const state = store.getState();
    
    store.dispatch(setParentId(btoa(id), "statistics"));
    navigate(`/${state.list.anuncios.root}/${URL_VIEW_STATISTIC}/${btoa(id)}`);
};