import { navigate } from "hookrouter";
import types from "types/list";
import store from "helpers/store";
import { URL_NEW, URL_EDIT } from "helpers/consts";

export const setParentId = (data, view) => ({
  type: types.SET_PARENT_ID,
  payload: data,
  view
});
export const setRoot = view => ({
  type: types.SET_LIST_ROOT,
  payload: view,
  view
});
export const setChangeStatus = (data, view) => ({
  type: types.SET_LIST_CHANGE_STATUS,
  payload: data,
  view
});
export const setFetch = (data, view) => ({
  type: types.SET_LIST_FETCH,
  payload: data,
  view
});
export const setDelete = (data, view) => ({
  type: types.SET_LIST_DELETE,
  payload: data,
  view
});
export const setIdField = (data, view) => ({
  type: types.SET_LIST_IDFIELD,
  payload: data,
  view
});
export const setLoading = (data, view) => ({
  type: types.SET_LIST_LOADING,
  payload: data,
  view
});
export const setResults = (data, view) => ({
  type: types.SET_LIST_RESULTS,
  payload: data,
  view
});
export const setTotal = (data, view) => ({
  type: types.SET_LIST_TOTAL,
  payload: data,
  view
});
export const setPg = (data, view) => ({
  type: types.SET_LIST_PG,
  payload: data,
  view
});
export const setPgSize = (data, view) => ({
  type: types.SET_LIST_PGSIZE,
  payload: data,
  view
});
export const setO = (data, view) => ({
  type: types.SET_LIST_O,
  payload: data,
  view
});
export const setS = (data, view) => ({
  type: types.SET_LIST_S,
  payload: data,
  view
});
export const setT = (data, view) => ({
  type: types.SET_LIST_T,
  payload: data,
  view
});
export const setSorted = (data, view) => ({
  type: types.SET_LIST_SORTED,
  payload: data,
  view
});
export const setPaginacao = (data, view) => ({
  type: types.SET_LIST_PAGINACAO,
  payload: data,
  view
});
export const setExtraFilter = (data, view) => ({
  type: types.SET_LIST_EXTRA_FILTER,
  payload: data,
  view
});

export const onPageChange = (pageIndex, view) => {
  const state = store.getState();

  localStorage.setItem(`pg_${state.list[view].root}`, pageIndex);
  store.dispatch(setPg(pageIndex, view));
};

export const onPageSizeChange = (pageSize, pageIndex, view) => {
  const state = store.getState();

  localStorage.setItem(`pgSize_${state.list[view].root}`, pageSize);
  localStorage.setItem(`pg_${state.list[view].root}`, pageIndex);
  store.dispatch(setPg(pageIndex, view));
  store.dispatch(setPgSize(pageSize, view));
};

export const onSortedChange = (newSorted, column, shiftKey, view) => {
  const state = store.getState();

  let o = null;
  let t = null;
  if (newSorted.length > 0) {
    o = newSorted[0].id;
    if (o !== null) t = newSorted[0].desc ? "desc" : "asc";
  }
  localStorage.setItem(`o_${state.list[view].root}`, o);
  localStorage.setItem(`t_${state.list[view].root}`, t);
  store.dispatch(setO(o, view));
  store.dispatch(setT(t, view));
  store.dispatch(
    setSorted(
      o && t ? [{ id: o, desc: t && t.toLowerCase() === "desc" }] : [],
      view
    )
  );
};

export const handlePesquisa = (evt, view) => {
  const state = store.getState();
  evt.persist();
  if (evt && evt.target) {
    localStorage.setItem(`s_${state.list[view].root}`, evt.target.value);

    store.dispatch(setS(evt.target.value, view));
    store.dispatch(setLoading(true, view));

    requestData(view);
  }
};

export const requestData = view => {
  store.dispatch(setLoading(true, view));
  const state = store.getState().list[view];
  const reqData = Object.assign({
    pg: state.pg + 1,
    pgSize: state.pgSize,
    o: state.o,
    s: state.s,
    t: state.t
  }, state.extraFilter);
  return new Promise((resolve, reject) => {
    if (state.listFetch) {
      state.listFetch(reqData, resolve, reject, store.dispatch);
    }
  })
    .then(res => {
      let paginacao = {};
      if (res.paginacao) {
        paginacao = {
          total: res.paginacao.total,
          paginas: res.paginacao.paginas,
          pg: res.paginacao.pg
        };
      }
      store.dispatch(setResults(res.results, view));
      store.dispatch(setTotal(res.total, view));
      store.dispatch(setPaginacao(paginacao, view));
      store.dispatch(setLoading(false, view));
    })
    .catch(err => {
      store.dispatch(setResults([], view));
      store.dispatch(setTotal(0, view));
      store.dispatch(setLoading(false, view));
    });
};

export const closeModal = (onClose, view) => {
  const state = store.getState();

  const scr = document.documentElement.scrollTop;
  if (onClose) onClose();

  window.history.replaceState(null, null, " ");
  document.documentElement.scrollTop = scr;

  navigate(`${process.env.PUBLIC_URL}/${state.list[view].root}`);
};

export const afterSuccessDefault = (view) => {
  requestData(view);
  closeModal(null, view);
};

export const afterSaveSuccess = (view) => {
  afterSuccessDefault(view);
};

export const afterChangeStatusSuccess = (view) => {
  afterSuccessDefault(view);
};

export const afterDeleteSuccess = (view) => {
  afterSuccessDefault(view);
};

export const changeStatus = (id, status, view) => {
  const state = store.getState();

  if (state.list[view].changeStatus) {
    state.list[view].changeStatus(id, status);
  }
};

export const deleteRecord = (id, view) => {
  const state = store.getState();

  if (state.list[view].delete) {
    state.list[view].delete(id);
  }
};

export const openNewModal = view => {
  const state = store.getState();
  if (state.list[view].root) {
    navigate(`/${state.list[view].root}/${URL_NEW}`);
  }
};

export const openEditModal = (id = null, view) => {
  const state = store.getState();
  if (state.list[view].root && id) {
    navigate(`/${state.list[view].root}/${URL_EDIT}/${btoa(id)}`);
  }
};

export const start = (
  view,
  idField,
  fetchList,
  changeStatus,
  deleteRecord,
  id = null
) => {
  store.dispatch(setRoot(view));
  store.dispatch(setParentId(id, view));
  store.dispatch(setIdField(idField, view));
  store.dispatch(setFetch(fetchList, view));
  store.dispatch(setChangeStatus(changeStatus, view));
  store.dispatch(setDelete(deleteRecord, view));

  requestData(view);
};
