/* eslint-disable max-len */
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DEFAULT_TOAST_POSITION, DEFAULT_TOAST_TIMEOUT } from "helpers/consts";
import {
    defaultFetchList,
    defaultDelete,
    defaultSave,
    defaultFetch,
    defaultLoadOptions,
    defaultChangeStatus
} from "./default";

const MySwal = withReactContent(Swal);

const model = "membros";
const idField = "IdMembro";

export const fetchList = (reqData, resolve, reject, dispatch) => defaultFetchList(model, reqData, resolve, reject, dispatch);
export const fetch = (id, callback) => defaultFetch(model, id, callback);
export const changeStatus = (id, status, motivo, afterChange, afterError) => changeStatusMembros(model, id, status, motivo, afterChange, afterError);
export const deleteRecord = (id, afterDelete, afterError) => defaultDelete(model, id, afterDelete, afterError);
export const save = (data, actions, afterSave, afterError) => defaultSave(model, idField, data, true, actions, afterSave, afterError);
export const savePessoal = (data, actions, afterSave, afterError) => defaultSave(`${model}/update/pessoais`, idField, data, false, actions, afterSave, afterError);
export const saveMaconicos = (data, actions, afterSave, afterError) => defaultSave(`${model}/update/maconicos`, idField, data, false, actions, afterSave, afterError);
export const saveProfissionais = (data, actions, afterSave, afterError) => defaultSave(`${model}/update/profissionais`, idField, data, false, actions, afterSave, afterError);
export const saveFoto = (data, actions, afterSave, afterError) => defaultSave(`${model}/update/foto`, idField, data, false, actions, afterSave, afterError);
export const saveCapa = (data, actions, afterSave, afterError) => defaultSave(`${model}/update/capa`, idField, data, false, actions, afterSave, afterError);
export const saveOutros = (data, actions, afterSave, afterError) => defaultSave(`${model}/update/outros`, idField, data, false, actions, afterSave, afterError);

export const loadOptions = inputValue => defaultLoadOptions(inputValue, model, ["IdMembro", "Nome"]);
export const loadOptionsIrmaos = inputValue => defaultLoadOptions(
    inputValue,
    model,
    ["IdMembro", "Nome"],
    ["IdMembro", "Nome"],
    { tp: "I" }
);
export const loadOptionsIrmaosEmpresas = inputValue => defaultLoadOptions(
    inputValue,
    model,
    ["IdMembro", "Nome"],
    ["IdMembro", "Nome"],
    { tpEmpresa: 1 }
);

export const savePreferenciasLayout = (data, callback) => {
    axios
        .post(`${model}/save_preferencias_layout`, data)
        .then(res => {
            callback(res.data);
        })
        .catch(err => {
            if (err.response) {
                MySwal.fire({
                    type: "error",
                    title: `Não foi possível salvar os dados. ${err.response.data.message}`,
                    toast: true,
                    position: DEFAULT_TOAST_POSITION,
                    showConfirmButton: false,
                    timer: DEFAULT_TOAST_TIMEOUT
                });
            }
        });
};

export const getPreferenciasLayout = () => {
    axios
        .get(`${model}/get_preferencias_layout`)
        .then(res => {
            localStorage.setItem("layout_preferences", res.data.result);
        })
        .catch(err => {
            if (err.response) {
                MySwal.fire({
                    type: "error",
                    title: `Não foi possível obter os dados. ${err.response.data.message}`,
                    toast: true,
                    position: DEFAULT_TOAST_POSITION,
                    showConfirmButton: false,
                    timer: DEFAULT_TOAST_TIMEOUT
                });
            }
        });
};

const changeStatusMembros = (
    model,
    id,
    status = 0,
    motivo = null,
    afterChange,
    afterError,
    extraId
) => {
    axios
        .post(`${model}/change-status/${btoa(id)}`, { status, motivo })
        .then(res => {
            MySwal.fire({
                toast: true,
                position: DEFAULT_TOAST_POSITION,
                showConfirmButton: false,
                timer: DEFAULT_TOAST_TIMEOUT,
                type: "success",
                title: "Status alterado com sucesso"
            });

            /* Faz a solicitação para alterar também em Autorização */
            changeAuthorization(status, motivo);

            /* Faz a solicitação para executar a notificação via push ou e-mail */
            // changeNotifyUsers(status, motivo);

            if (afterChange) afterChange();
        })
        .catch(err => {
            if (err.response && err.response.data) {
                MySwal.fire({
                    type: "error",
                    title: `Não foi possível alterar o status. ${err.response.data.message}`,
                    toast: true,
                    position: DEFAULT_TOAST_POSITION,
                    showConfirmButton: false,
                    timer: DEFAULT_TOAST_TIMEOUT
                });
                if (afterError) afterError();
            }
        });

    const changeAuthorization = _ => axios
        .get(`membros/getIdAuthorization/${id}`)
        .then(res => {
            if (res.data) {
                if (res.data.IdAutorizacaoMembro > 0) {
                    /* Faz a alteração do status tambem em autorizacaoMembro */
                    defaultChangeStatus('autorizacoesmembro', res.data.IdAutorizacaoMembro, status, afterChange, afterError, motivo);
                }
            }
        })
        .catch(err => {
            console.log('deu erro :(')
        });

    const changeNotifyUsers = (status, motivo) => axios
        .post(`membros/notify_users/${btoa(id)}`, 
            { status, motivo }
        )
        .catch(err => {
            console.log('Deu erro em changeNotifyUsers p1 -> ', err)
        });
};