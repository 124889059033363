import React from 'react'
import ChartistGraph from 'react-chartist'

// Componentes
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card";

import './graph.css';

const AgeGraph = ({ title, dataGraph }) => {

    let labels = [];
    let series = [[], []];

    if(typeof dataGraph !== undefined) {
        /* Recupera o parametro recebido */
        Object.keys(dataGraph).map((key, index) => {
            let data = dataGraph[key][0]
            let masc = Number(data.masc);
            let fem = Number(data.fem);
            let desc = data.range_name_;

            labels.push(desc);

            series[0].push({ meta: "", value: masc})
            series[1].push({ meta: "", value: fem})

            return true;
        })
    }

    var chartData = {
        labels,
        series
    }
    
    let chartOptions = {
        axisX: {
            onlyInteger: true, 
        },
        axisY: {
            offset: 50,
        },
        referenceValue: 100,
        height: 200,
        seriesBarDistance: 10
    }
    
    /* Faz o somatorio do total entre todos os valores */
    let sum = dataGraph.reduce((a, b) => a + (Number(b[0]['total']) || 0), 0);
    
    /* Renderiza para o usuário uma mensagem de que não há resultados disponíveis para serem visualizados */
    let missing = (Number(sum) === 0) ? <GridItem lg={12}>Essa pesquisa retornou sem registros para o período informado</GridItem> : null;

    return (
        <>
            <GridContainer style={{ justifyContent: 'center', marginTop: '20px' }}>
                <h4>{ title }</h4>
                <GridItem className="legendas">
                    <section>
                        <div className="dot dot__masc"></div>
                        Masculino
                    </section>
                    <section>
                        <div className="dot dot__fem"></div>
                        Feminino
                    </section>
                </GridItem>
            </GridContainer>
            <Card>
                <GridContainer style={{ textAlign: 'center'}}>
                    { 
                        sum > 0 && <ChartistGraph className="ct-chart" data={chartData} type={'Bar'} options={chartOptions} style={{ padding: '20px 0 30px 0', marginBottom: '10px' }} /> 
                    }
                    
                    { missing }
                </GridContainer>
                    
                <GridContainer style={{ justifyContent: 'center' }}>
                    {
                        Object.keys(dataGraph).map((key, index) => {
                            let data = dataGraph[key][0]
                            let masc = Number(data.masc);
                            let fem = Number(data.fem);
                            let desc = data.range_name_;

                            if(masc > 0 || fem > 0) {
                                return (
                                    <div key={index} className="legendas_localidade">
                                        <section>[{desc}]</section>
                                        <section>
                                            <div className="dot dot__masc"></div>
                                            {masc}
                                        </section>
                                        <section>
                                            <div className="dot dot__fem"></div>
                                            {fem}
                                        </section>
                                    </div>
                                )
                            } else {
                                return ''
                            }
                        })
                    }
                </GridContainer>
            </Card>
        </>
    )
}

export default AgeGraph