/* eslint-disable max-len */
/* Geral / Bibliotecas */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";

/* Ícones */
import Save from "@material-ui/icons/Save";
import Block from "@material-ui/icons/Block";
import Done from "@material-ui/icons/Done";

/* Métodos / Ações */
import * as form from "actions/form";
import { fetch, save } from "actions/compras";
import { loadCidadesOptions } from "actions/cidades";
import { loadOptions as loadOptionsEmpresas, fetch as fetchEmpresa } from "actions/empresas";
import { loadOptions as loadOptionsPlanos } from "actions/planos";
import { loadDesconto } from "actions/cupons";
import { loadOptions as loadOptionsAnuncios } from "actions/anuncios";

/* Componentes */
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButton";
import FormSelect from "components/FormSelect";
import { Container } from "assets/jss/doSul-dashboard";
import Loading from "components/Loading";
import { PayPalButton } from "react-paypal-button-v2";
import DynamicTable from "components/DynamicTable";
import CardBody from "components/Card/CardBody";
import Card from "components/Card";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FormInput from "components/FormInput";
import Cep from "components/CustomInput/Cep";
import CpfCnpj from "components/CustomInput/CpfCnpj";
import cep from "cep-promise";
import PaisTelefone from "components/CustomInput/PaisTelefone";
import {
    DEFAULT_TOAST_POSITION, DEFAULT_TOAST_TIMEOUT, PAYPAL_SANBOX_TOKEN, PAYPAL_LIVE_TOKEN, PAYPAL_PRODUCTION
} from "helpers/consts";

/* Validação */
import validationSchema from "./validation";

const MySwal = withReactContent(Swal);

const Model = {
    IdCompra: null,
    Descricao: "",
    empresa: null,
    nomeEmpresa: null,
    membro: null,
    nomeMembro: null,
    planos: null,
    plano: null,
    anuncios: [],
    anuncio: null,
    OrderId: null,
    Transacao: null,
    Valor: 0.00,
    Codcupom: null,
    cupom: null,
    Desconto: 0.00,
    Valorpago: 0.00,
    Status: 2,
    Nome: "",
    CnpjCpf: "",
    Endereco: "",
    Numero: null,
    Complemento: "",
    Bairro: "",
    Cep: "",
    cidade: null,
    nomeCidade: "",
    nomeEstado: "",
    nomePais: "",
    IdPais: 0,
    TelefonePais: "",
    TelefonePaisCodigo: "",
    Telefone: "",
    StatusFaturamento: 0
};

export default function ComprasForm({
    id, changeStatus, deleteRecord, afterSave, afterChangeStatusSuccess, afterDeleteSuccess
}) {
    const formData = useSelector(state => state.form);
    const permissions = useSelector(state => state.permissions);

    const dispatch = useDispatch();

    const afterFetch = (data) => {
        const model = data.result;
        if (model.Empresa) model.empresa = btoa(model.Empresa);
        if (model.Membro) model.membro = btoa(model.Membro);
        if (model.Cupom) model.cupom = btoa(model.Cupom);
        if (model.Planos) model.planos = model.Planos;
        // etc
        dispatch(form.setModel(model));
        dispatch(form.setLoading(false));
    }; // Sobrescrever se precisar


    useEffect(() => {
        form.start("compras", "IdCompra", id, Model, fetch, save, changeStatus, deleteRecord, afterSave, afterChangeStatusSuccess, afterDeleteSuccess, afterFetch);
        return form.stop;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // É chamado toda vez que muda o ID
        form.setModel(Model);
        if (id) {
            form.fetchData(id);
        }
    }, [id]);

    const handleSubmit = (values, actions) => {
        formData.save(values, actions, formData.afterSave);
    };

    const getDesconto = async (FormikProps) => {
        if (FormikProps.values.Codcupom) {
            var result = await loadDesconto(FormikProps.values.Codcupom);
            var desconto = 0;
            if (result.length) {
                desconto = result[0].Desconto;
                MySwal.fire({
                    type: "success",
                    title: "Cupom aplicado",
                    toast: true,
                    position: DEFAULT_TOAST_POSITION,
                    showConfirmButton: false,
                    timer: DEFAULT_TOAST_TIMEOUT
                });
            } else {
                MySwal.fire({
                    type: "info",
                    title: "Código de cupom não encontrado",
                    toast: true,
                    position: DEFAULT_TOAST_POSITION,
                    showConfirmButton: false,
                    timer: DEFAULT_TOAST_TIMEOUT
                });
            }
            FormikProps.setFieldValue('Desconto', desconto);
            FormikProps.setFieldValue('Valorpago', (FormikProps.values.Valor - (FormikProps.values.Valor * (desconto / 100))).toFixed(2));
            FormikProps.setFieldValue('cupom', btoa(result[0].IdCupom));
        }
    };

    if (formData.loading) {
        return <Loading />;
    }

    const disabled = id || (permissions && permissions[formData.root] < 2);

    return (
        <Container>
            <Formik
                validationSchema={validationSchema}
                initialValues={formData.model}
                onSubmit={handleSubmit}
                render={FormikProps => (
                    <Form onSubmit={FormikProps.handleSubmit}>
                        <GridContainer>
                            <GridItem xs={12}>
                                <FormSelect
                                    field={{
                                        name: "empresa",
                                        placeholder: "Digite para localizar...",
                                        isSearchable: true,
                                        defaultOptions: true,
                                        loadOptions: loadOptionsEmpresas,
                                        getOptionLabel: (option) => `${option.Nome}`,
                                        loadingMessage: () => "Carregando...",
                                        noOptionsMessage: (input) => (input.inputValue === "" ? "Digite para localizar..." : "Nenhum resultado encontrado"),
                                        value: FormikProps.values.empresa ? {
                                            IdEmpresa: FormikProps.values.empresa,
                                            Nome: FormikProps.values.nomeEmpresa
                                        } : null,
                                        onChange: (value) => {
                                            const set = FormikProps.setFieldValue;
                                            if (value.IdEmpresa) {
                                                set("empresa", btoa(value.IdEmpresa));
                                                fetchEmpresa(value.IdEmpresa, (values) => {
                                                    const data = values.result;
                                                    if (data) {
                                                        set('Nome', data.Nome);
                                                        set('Email', data.Email);
                                                        set('CnpjCpf', data.Cnpj);
                                                        set('Endereco', data.Endereco);
                                                        set('Numero', data.Numero);
                                                        set('Complemento', data.Complemento);
                                                        set('Bairro', data.Bairro);
                                                        set('Cep', data.Cep);
                                                        set('cidade', data.cidade);
                                                        set('nomeCidade', data.nomeCidade);
                                                        set('nomeEstado', data.nomeEstado);
                                                        set('nomePais', data.nomePais);
                                                        
                                                        if (data.IdPais > 0) set('IdPais', data.IdPais);
                                                        else if (data.Pais > 0) set('IdPais', data.Pais);
                                                        
                                                        set('TelefonePais', data.TelefonePais);
                                                        set('TelefonePaisCodigo', data.TelefonePaisCodigo);
                                                        set('Telefone', data.Telefone);
                                                    }
                                                });
                                            }
                                            set("nomeEmpresa", value.Nome);
                                        }
                                    }}
                                    disabled={true}
                                    form={FormikProps}
                                    async
                                    label="Empresa *"
                                />
                            </GridItem>
                            {!id && FormikProps.values.IdPais ? (
                                <GridItem xs={12}>
                                    <FormSelect
                                        field={{
                                            loadOptions: (inputValue) => loadOptionsPlanos(inputValue, { pais: FormikProps.values.IdPais, status: 1}),
                                            name: "planos",
                                            getOptionLabel: (option) => `${option.Nome}`,
                                            getOptionValue: (option) => option.IdPlanoPais,
                                            value: FormikProps.values.planos || [],
                                            isMulti: false,
                                            disabled,
                                            onChange: (value) => {
                                                let valor = parseFloat(value.Valor.replace('.', '').replace(',', '.'));
                                                FormikProps.setFieldValue("Valor", parseFloat(valor.toFixed(2)));
                                                FormikProps.setFieldValue("Valorpago", (valor - (valor * (FormikProps.values.Desconto / 100))).toFixed(2));
                                                FormikProps.setFieldValue("planos", value);
                                                FormikProps.setFieldValue("plano", btoa(value.IdPlanoPais));
                                                FormikProps.setFieldValue("anuncios", []);
                                                FormikProps.setFieldValue("anuncio", null);
                                            }
                                        }}
                                        form={FormikProps}
                                        async
                                        label="Selecionar Planos *"
                                    />
                                </GridItem>
                            ) : ""}
                            {!id && FormikProps.values.planos && FormikProps.values.planos.Categoria === 2 && FormikProps.values.empresa ? (
                                <GridItem xs={12}>
                                    <FormSelect
                                        field={{
                                            key: `${FormikProps.values.empresa}-${FormikProps.values.planos.Abrangencia}`,
                                            loadOptions: (inputValue) => loadOptionsAnuncios(inputValue, { 
                                                empresa: FormikProps.values.empresa,
                                                abrangencia: FormikProps.values.planos.Abrangencia,
                                                situacao: 1
                                            }),
                                            name: "anuncios",
                                            getOptionLabel: (option) => `${option.Titulo}`,
                                            getOptionValue: (option) => option.IdAnuncio,
                                            noOptionsMessage: () => 'Essa empresa não possui anúncios nessa categoria, por gentilize cadastre e tente novamente',
                                            value: FormikProps.values.anuncios,
                                            isMulti: false,
                                            disabled,
                                            onChange: (value) => {
                                                FormikProps.setFieldValue("anuncios", value);
                                                FormikProps.setFieldValue("anuncio", btoa(value.IdAnuncio));
                                            }
                                        }}
                                        form={FormikProps}
                                        async
                                        label="Selecionar o Anuncio *"
                                    />
                                </GridItem>
                            ) : ""}
                            <GridItem xs={12} lg={12}>
                                <FormInput
                                    field={{
                                        name: "Codcupom",
                                        disabled,
                                        onBlur: () => {
                                            getDesconto(FormikProps);
                                        }
                                    }}
                                    form={FormikProps}
                                    label="Código do cupom de desconto"
                                />
                            </GridItem>
                            {FormikProps.values.planos && (
                                <GridItem xs={12} sm={12} md={12}>
                                    <Card>
                                        <CardBody>
                                            <DynamicTable
                                                sortable={false}
                                                showPagination={false}
                                                defaultPageSize={9999}
                                                data={[FormikProps.values.planos]}
                                                style={{ margin: "-0.9375rem -20px", marginTop: "10px" }}
                                                columns={[
                                                    {
                                                        Header: "Nome",
                                                        accessor: "Nome"
                                                    },
                                                    {
                                                        Header: "Moeda",
                                                        accessor: "Moeda"
                                                    },
                                                    {
                                                        Header: "Duracao",
                                                        accessor: "Duracao",
                                                        Cell: props => `${props.value} Dias`
                                                    },
                                                    {
                                                        Header: "Abrangência",
                                                        accessor: "NomeAbrangencia",
                                                        Cell: props => props.value || 'Geral'
                                                    },
                                                    {
                                                        Header: "Valor",
                                                        accessor: "Valor",
                                                        Footer: (
                                                            <span>
                                                                <strong>Total:</strong>
                                                                {" "}
                                                                {FormikProps.values.Valorpago} {FormikProps.values.Desconto > 0 && `( - ${FormikProps.values.Desconto}% )`}
                                                            </span>
                                                        )
                                                    }
                                                ]}
                                            />
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            )}
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <h4>Dados para faturamento</h4>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={12} lg={6}>
                                                <FormInput
                                                    field={{
                                                        name: "Nome",
                                                        disabled,
                                                    }}
                                                    form={FormikProps}
                                                    label="Razão Social / Nome *"
                                                />
                                            </GridItem>
                                            <GridItem xs={12} lg={6}>
                                                <FormInput
                                                    field={{
                                                        name: "CnpjCpf",
                                                        disabled,
                                                        inputComponent: CpfCnpj,
                                                    }}
                                                    form={FormikProps}
                                                    label="CNPJ/CPF *"
                                                />
                                            </GridItem>

                                            <GridItem xs={12} lg={8}>
                                                <FormInput
                                                    field={{
                                                        name: "Cep",
                                                        disabled,
                                                        inputComponent: Cep,
                                                        inputProps: {
                                                            callbackCep: (value) => {
                                                                cep(
                                                                    value.replace("-", "")
                                                                ).then((objCep) => {
                                                                    FormikProps.setFieldValue(
                                                                        "Endereco",
                                                                        objCep.street
                                                                    );
                                                                    FormikProps.setFieldValue(
                                                                        "Bairro",
                                                                        objCep.neighborhood
                                                                    );
                                                                    loadCidadesOptions(
                                                                        objCep.city
                                                                    ).then((data) => {
                                                                        if (
                                                                            data.length &&
                                                                            data[0].IdCidade
                                                                        ) {
                                                                            FormikProps.setFieldValue(
                                                                                "cidade",
                                                                                btoa(
                                                                                    data[0]
                                                                                        .IdCidade
                                                                                )
                                                                            );
                                                                            FormikProps.setFieldValue(
                                                                                "nomeCidade",
                                                                                data[0].Nome
                                                                            );
                                                                            FormikProps.setFieldValue(
                                                                                "nomeEstado",
                                                                                data[0]
                                                                                    .NomeEstado
                                                                            );
                                                                            FormikProps.setFieldValue(
                                                                                "nomePais",
                                                                                data[0].NomePais
                                                                            );
                                                                        }
                                                                    });
                                                                });
                                                            },
                                                        },
                                                    }}
                                                    form={FormikProps}
                                                    label="Cep *"
                                                />
                                            </GridItem>

                                            <GridItem xs={12}>
                                                <FormSelect
                                                    field={{
                                                        name: "cidade",
                                                        placeholder: "Digite para localizar...",
                                                        isSearchable: true,
                                                        loadOptions: loadCidadesOptions,
                                                        getOptionLabel: (option) =>
                                                            `${option.Nome}${
                                                                option.NomeEstado
                                                                    ? ` - ${option.NomeEstado}`
                                                                    : ""
                                                            }${
                                                                option.NomePais
                                                                    ? ` - ${option.NomePais}`
                                                                    : ""
                                                            }`,
                                                        loadingMessage: () => "Carregando...",
                                                        noOptionsMessage: (input) =>
                                                            input.inputValue === ""
                                                                ? "Digite para localizar..."
                                                                : "Nenhum resultado encontrado",
                                                        disabled,
                                                        value: FormikProps.values.cidade
                                                            ? {
                                                                IdCidade:
                                                                    FormikProps.values.cidade,
                                                                Nome:
                                                                    FormikProps.values
                                                                        .nomeCidade,
                                                                NomeEstado:
                                                                    FormikProps.values
                                                                        .nomeEstado,
                                                                NomePais:
                                                                    FormikProps.values
                                                                        .nomePais,
                                                            }
                                                            : null,
                                                        onChange: (value) => {
                                                            if (value) {
                                                                if (value.IdCidade) {
                                                                    FormikProps.setFieldValue(
                                                                        "cidade",
                                                                        btoa(value.IdCidade)
                                                                    );
                                                                }
                                                                FormikProps.setFieldValue(
                                                                    "nomeCidade",
                                                                    value.Nome
                                                                );
                                                                FormikProps.setFieldValue(
                                                                    "nomeEstado",
                                                                    value.NomeEstado
                                                                );
                                                                FormikProps.setFieldValue(
                                                                    "nomePais",
                                                                    value.NomePais
                                                                );
                                                            } else {
                                                                FormikProps.setFieldValue(
                                                                    "cidade",
                                                                    ""
                                                                );
                                                            }
                                                        },
                                                    }}
                                                    form={FormikProps}
                                                    async
                                                    label="Cidade"
                                                />
                                            </GridItem>

                                            <GridItem xs={12} lg={8}>
                                                <FormInput
                                                    field={{
                                                        name: "Endereco",
                                                        disabled,
                                                    }}
                                                    form={FormikProps}
                                                    label="Endereco *"
                                                />
                                            </GridItem>

                                            <GridItem xs={12} lg={4}>
                                                <FormInput
                                                    field={{
                                                        name: "Numero",
                                                        disabled,
                                                    }}
                                                    form={FormikProps}
                                                    label="Número"
                                                />
                                            </GridItem>

                                            <GridItem xs={12} lg={6}>
                                                <FormInput
                                                    field={{
                                                        name: "Complemento",
                                                        disabled,
                                                    }}
                                                    form={FormikProps}
                                                    label="Complemento"
                                                />
                                            </GridItem>

                                            <GridItem xs={12} lg={6}>
                                                <FormInput
                                                    field={{
                                                        name: "Bairro",
                                                        disabled,
                                                    }}
                                                    form={FormikProps}
                                                    label="Bairro *"
                                                />
                                            </GridItem>

                                            <GridItem xs={12} lg={6}>
                                                <FormInput
                                                    field={{
                                                        name: "Telefone",
                                                        disabled,
                                                        startAdornment: (
                                                            <PaisTelefone
                                                                pais={
                                                                    FormikProps.values
                                                                        .TelefonePais
                                                                }
                                                                codigo={
                                                                    FormikProps.values
                                                                        .TelefonePaisCodigo
                                                                }
                                                                form={FormikProps}
                                                            />
                                                        ),
                                                    }}
                                                    form={FormikProps}
                                                    label="Telefone *"
                                                />
                                            </GridItem>
                                            <GridItem xs={12} lg={6}>
                                                <FormInput
                                                    field={{
                                                        name: "Email",
                                                        disabled,
                                                    }}
                                                    form={FormikProps}
                                                    label="E-mail *"
                                                />
                                            </GridItem>

                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridItem>

                            {!id && FormikProps.values.empresa && FormikProps.values.planos && (FormikProps.values.planos.Categoria !== 2 || (!!FormikProps.values.anuncio) ) ? (
                                <GridItem xs={12}>
                                    <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                                        <GridContainer justify="center" spacing={5}>
                                            <PayPalButton
                                                amount={FormikProps.values.Valorpago}
                                                currency={FormikProps.values.planos.Codigo || 'BRL'}
                                                shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                                onSuccess={(details, data) => {
                                                    FormikProps.setFieldValue("OrderId", details.id);
                                                    FormikProps.setFieldValue("Transacao", JSON.stringify({ details, data }));
                                                    FormikProps.setFieldValue("Valorpago", parseFloat(details.purchase_units[0].amount.value));
                                                    FormikProps.setFieldValue("Status", details.status === 'COMPLETED' ? 1 : 2);
                                                    FormikProps.submitForm();
                                                }}
                                                catchError={(data) => {
                                                    console.log('Erro2 -> ', data);
                                                    MySwal.fire({
                                                        type: "error",
                                                        title: "Não foi possível concluir o pagamento.",
                                                        toast: true,
                                                        position: DEFAULT_TOAST_POSITION,
                                                        showConfirmButton: false,
                                                        timer: DEFAULT_TOAST_TIMEOUT
                                                    });
                                                }}
                                                onError={(data) => {
                                                    console.log(data);
                                                    MySwal.fire({
                                                        type: "error",
                                                        title: "Não foi possível concluir o pagamento.",
                                                        toast: true,
                                                        position: DEFAULT_TOAST_POSITION,
                                                        showConfirmButton: false,
                                                        timer: DEFAULT_TOAST_TIMEOUT
                                                    });
                                                }}
                                                onCancel={(data) => { // {orderID: "51H98396DY964493P"}
                                                    console.log(data);
                                                    MySwal.fire({
                                                        type: "info",
                                                        title: "O pagamento não foi realizado.",
                                                        toast: true,
                                                        position: DEFAULT_TOAST_POSITION,
                                                        showConfirmButton: false,
                                                        timer: DEFAULT_TOAST_TIMEOUT
                                                    });
                                                }}
                                                options={{
                                                    clientId: PAYPAL_PRODUCTION ? PAYPAL_LIVE_TOKEN : PAYPAL_SANBOX_TOKEN,
                                                    currency: FormikProps.values.planos.Codigo || 'BRL'
                                                }}
                                            />
                                        </GridContainer>
                                    </div>
                                </GridItem>
                            ) : ""}

                            <GridItem xs={12}>
                                <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                                    {!disabled && FormikProps.values.planos && (FormikProps.values.planos.Categoria !== 2 || FormikProps.values.anuncio ) && (
                                        <Button color="success" type="submit" disabled={FormikProps.isSubmitting}>
                                            <Save />
                                            {" "}
                                            Salvar
                                        </Button>
                                    )}
                                    {!disabled && formData.model[formData] && formData.model.Status === 1 && (
                                        <Button
                                            color="warning"
                                            type="button"
                                            right
                                            onClick={() => {
                                                this.handleChangeStatus(0);
                                            }}
                                        >
                                            <Block />
                                            {" "}
                                            Cancelar
                                        </Button>
                                    )}

                                    {(permissions && permissions[formData.root] >= 2) && formData.model[formData.idField] && formData.model.StatusFaturamento === 0 && (
                                        <React.Fragment>
                                            <p>Compra não faturada&nbsp;</p>
                                            <Button
                                                color="success"
                                                type="button"
                                                right
                                                onClick={() => {
                                                    form.handleChangeStatus(1);
                                                }}
                                            >
                                                <Done /> Faturar compra
                                            </Button>
                                        </React.Fragment>
                                    )}
                                    {(permissions && permissions[formData.root] >= 2) && formData.model[formData.idField] && formData.model.StatusFaturamento === 1 && (
                                        <React.Fragment>
                                            <p>Compra faturada&nbsp;</p>
                                            <Button
                                                color="warning"
                                                type="button"
                                                right
                                                onClick={() => {
                                                    form.handleChangeStatus(0);
                                                }}
                                            >
                                                <Block /> Cancelar faturamento
                                            </Button>
                                        </React.Fragment>
                                    )}
                                </div>
                            </GridItem>
                        </GridContainer>
                    </Form>
                )}
            />
        </Container>
    );
}

ComprasForm.propTypes = {
    id: PropTypes.any,
    afterSave: PropTypes.func,
    changeStatus: PropTypes.func,
    deleteRecord: PropTypes.func,
    afterChangeStatusSuccess: PropTypes.func,
    afterDeleteSuccess: PropTypes.func
};
