// Geral
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

// Ações
import * as list from "actions/list";
import { fetchList, changeStatus, deleteRecord } from "actions/membros-tokens";

// Componentes
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card";
import CardBody from "components/Card/CardBody";
import DynamicTable from "components/DynamicTable";
import ListButtons from "components/ListButtons";
import Pesquisa from "components/Pesquisa";
import ListHeader from "components/ListHeader";

const view = "membrostokens";

export default function EmpresasCategorias({ params }) {
    useEffect(() => {
        list.start(view, "IdMembroToken", fetchList, changeStatus, deleteRecord);

    }, []);

    const listData = useSelector(state => state.list[view]);

    return (
        <GridContainer>
            {" "}
            {listData && (
                <>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <ListHeader
                                title="Logins"
                                add=""
                                legend="Logins dos membros"
                                view={view}
                            />
                            <CardBody>
                                <Pesquisa view={view} />
                                <DynamicTable view={view}
                                    {...listData.tableProps}
                                    columns={[
                                        {
                                            Header: "Data/Hora",
                                            accessor: "DataHoraCriacaoDisplay"
                                        },
                                        {
                                            Header: "Nome",
                                            accessor: "NomeMembro"
                                        },
                                        {
                                            Header: "Dispositivo",
                                            accessor: "HttpUserAgent"
                                        },
                                        {
                                            Header: "Data/Hora Validade",
                                            accessor: "DataHoraValidadeDisplay"
                                        },
                                        {
                                            Header: "Status",
                                            accessor: "Status",
                                            maxWidth: 150,
                                            style: {
                                                padding: 0
                                            },
                                            Cell: props => <ListButtons cellProps={props} view={view} disableEdit />
                                        }
                                    ]}
                                    onFetchData={(state, instance) => list.requestData(view)}
                                    onPageSizeChange={(pageSize, pageIndex) => list.onPageSizeChange(pageSize, pageIndex, view)}
                                    onPageChange={(pageIndex) => list.onPageChange(pageIndex, view)}
                                    onSortedChange={(newSorted, column, shiftKey) => list.onSortedChange(newSorted, column, shiftKey, view)}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </>
            )}
        </GridContainer>
    );
}
