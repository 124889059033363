import {
    defaultFetchList, defaultChangeStatus, defaultDelete, defaultSave, defaultFetch, defaultLoadOptions
} from "./default";
import axios from "axios";

const model = "autorizacoesmembro";
const idField = "IdAutorizacaoMembro";

export const fetchList = (reqData, resolve, reject, dispatch) => defaultFetchList(model, reqData, resolve, reject, dispatch);
export const fetch = (id, callback) => defaultFetch(model, id, callback);
export const changeStatus = (id, status, afterChange, afterError, jutificativa = null, extraId = null) => {
    
    defaultChangeStatus(model, id, status, afterChange, afterError, jutificativa);

    /* Faz a alteração do status tambem em membros */
    if (extraId > 0) {
        defaultChangeStatus('membros', extraId, status, afterChange, afterError, jutificativa);

        axios
        .post(`membros/notify_users/${btoa(extraId)}`, 
            { status, motivo: jutificativa }
        )
        .catch(err => {
            console.log('Deu erro em changeNotifyUsers p2 -> ', err)
        });
    }
}
export const deleteRecord = (id, afterDelete, afterError) => defaultDelete(model, id, afterDelete, afterError);
export const save = (data, actions, afterSave, afterError) => defaultSave(model, idField, data, false, actions, afterSave, afterError);

export const loadOptions = (inputValue) => defaultLoadOptions(inputValue, model, ["IdAutorizacaoMembro", "Nome"]);
