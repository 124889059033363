import React from "react";
import PropTypes from "prop-types";

// Estilo
import { MaterialInput, MaterialInputLabel, ErrorClear, SuccessCheck, CustomFormControl } from "./styles";

function CustomInput({ ...props }) {
    const {
        formControlProps,
        labelText,
        id,
        labelProps = {},
        inputProps,
        error,
        success
    } = props;

    if (inputProps.value !== '' && inputProps.value !== null && inputProps.value !== undefined) {
        labelProps.shrink = true;
    }

    return (
        <CustomFormControl {...formControlProps}>
            {labelText !== undefined ? (
                <MaterialInputLabel data-error={error ? error.toString() : "false"} data-success={success ? success.toString() : "false"} htmlFor={id} {...labelProps}>
                    {labelText}
                </MaterialInputLabel>
            ) : null}
            <MaterialInput data-error={error ? error.toString() : "false"} data-success={success ? success.toString() : "false"} id={id} {...inputProps} />
            {error ? (<ErrorClear />) : (success ? (<SuccessCheck />) : null)}
        </CustomFormControl>
    );
}

CustomInput.propTypes = {
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    error: PropTypes.bool,
    success: PropTypes.bool
};

export default CustomInput;
