export default function objToQueryString (obj, ignoreNulls = false) {
    if(typeof obj !== "object") return "";
    return Object.keys(obj).reduce(function (str, key, i) {
        var delimiter, val;
        delimiter = (i === 0) ? '?' : '&';
        key = encodeURIComponent(key);
        val = encodeURIComponent(obj[key]);
        
        if(ignoreNulls && val.toString() === "null") return str;

        return [str, delimiter, key, '=', val].join('');
    }, '');
}