import styled from 'styled-components';
import { Theme } from 'assets/jss/doSul-dashboard';

export const CardCategory = styled.p`
    color: ${Theme.grayColor[0]};
    margin: 0;
    font-size: 14px;
    margin-top: 0;
    padding-top: 10px;
    margin-bottom: 0;
`

export const CardTitle = styled.h3`
    color: ${Theme.grayColor[2]};
    margin-top: 0px;
    min-height: auto;
    font-weight: 300;
    margin-bottom: 3px;
    text-decoration: none;
    & small {
        color: ${Theme.grayColor[1]};
        font-weight: 400;
        line-height: 1;
    }
`

export const Stats = styled.div`
    color: ${Theme.grayColor[0]};
    display: inline-flex;
    font-size: 12px;
    line-height: 22px;
    & svg {
        top: 4px;
        width: 16px;
        height: 16px;
        position: relative;
        margin-right: 3px;
        margin-left: 3px;
    }
    & .fab,& .fas,& .far,& .fal,& .material-icons{
        top: 4px;
        font-size: 16px;
        position: relative;
        margin-right: 3px;
        margin-left: 3px;
    }
`

/*successText: {
    color: successColor[0]
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px"
  },
  
  cardCategoryWhite: {
    color: "rgba(" + hexToRgb(whiteColor) + ",.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  }*/