/* Geral / Bibliotecas */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";

/* Ícones */
import Save from "@material-ui/icons/Save";
/* import Done from "@material-ui/icons/Done";
import Block from "@material-ui/icons/Block";
import Delete from "@material-ui/icons/Delete"; */

/* Métodos / Ações */
import * as form from "actions/form";
import { fetch, save } from "actions/termos-uso";

/* Componentes */
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import FormEditor from "components/FormEditor";
import { MaterialInputLabel } from "components/CustomInput/styles";
import Button from "components/CustomButton";
import { Container } from "assets/jss/doSul-dashboard";
import Loading from "components/Loading";

/* Validação */
import validationSchema from "./validation";

const Model = {
    IdTermosUso: null,
    Descricao: "",
    Status: 1
};

export default function TermosUsoForm({ id, changeStatus, deleteRecord, afterSave, afterChangeStatusSuccess, afterDeleteSuccess }) {
    const formData = useSelector(state => state.form);
    const permissions = useSelector(state => state.permissions);

    const afterFetch = null; // Sobrescrever se precisar

    useEffect(() => {
        form.start("termosuso", "IdTermosUso", id, Model, fetch, save, changeStatus, deleteRecord, afterSave, afterChangeStatusSuccess, afterDeleteSuccess, afterFetch);
        return form.stop;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // É chamado toda vez que muda o ID
        form.setModel(Model);
        if (id) {
            form.fetchData(id);
        }
    }, [id]);

    if (formData.loading) {
        return <Loading />;
    }

    const disabled = permissions && permissions[formData.root] < 2;

    return (
        <Container>
            <Formik
                validationSchema={validationSchema}
                initialValues={formData.model}
                onSubmit={form.handleSubmit}
                render={FormikProps => (
                    <Form onSubmit={FormikProps.handleSubmit}>
                        <GridContainer>
                            <GridItem xs={12} lg={12}>
                                <MaterialInputLabel style={{ paddingTop: "15px", paddingBottom: "5px" }} htmlFor="descricao_produto">Descrição *</MaterialInputLabel>
                                <FormEditor field={{
                                    id: 'descricao',
                                    name: 'Descricao',
                                    disabled
                                }} form={FormikProps} label="Descrição *" />
                            </GridItem>

                            <GridItem xs={12}>
                                <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                                    {!disabled && (
                                        <Button color="success" type="submit" disabled={FormikProps.isSubmitting}>
                                            <Save /> Salvar
                                        </Button>
                                    )}
                                    {/*
                                        {!disabled && formData.model[formData.idField] && (
                                            <Button color="danger" type="button" right onClick={form.handleDelete}>
                                                <Delete /> Excluir
                                            </Button>
                                        )}
                                        {!disabled && formData.model[formData.idField] && formData.model.Status === 0 && (
                                            <Button
                                                color="info"
                                                type="button"
                                                right
                                                onClick={() => {
                                                    form.handleChangeStatus(1);
                                                }}
                                            >
                                                <Done /> Ativar
                                            </Button>
                                        )}
                                        {!disabled && formData.model[formData.idField] && formData.model.Status === 1 && (
                                            <Button
                                                color="warning"
                                                type="button"
                                                right
                                                onClick={() => {
                                                    form.handleChangeStatus(0);
                                                }}
                                            >
                                                <Block /> Bloquear
                                            </Button>
                                        )}
                                    */}
                                </div>
                            </GridItem>
                        </GridContainer>
                    </Form>
                )}
            />
        </Container>
    );
}

TermosUsoForm.propTypes = {
    id: PropTypes.any,
    afterSave: PropTypes.func,
    changeStatus: PropTypes.func,
    deleteRecord: PropTypes.func,
    afterChangeStatusSuccess: PropTypes.func,
    afterDeleteSuccess: PropTypes.func
};
