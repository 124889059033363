import * as yup from 'yup';
import Cpf from './Cpf';
import Cnpj from './Cnpj';

function CnpjCpf(val = "") {
    val = val.replace(/[^0-9]/g,'')
    
    if(val.length < 12){
        return Cpf(val)
    }
    return Cnpj(val)
}

export default CnpjCpf;

export const addInYup = () => {
    yup.addMethod(yup.string, "cnpjCpf", function(message) {
        return this.test("cnpjCpf", message || "Número de CNPJ/CPF inválido", value => CnpjCpf(value));
    });
}