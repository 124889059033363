import React from "react";
import PropTypes from "prop-types";
import { Field } from 'formik';

import { HelperTextError, CustomSelect, CustomAsyncSelect } from "./styles";

function FormSelect(props) {
    const { form, label, async } = props;
    const fieldProps = props.field;
    const { touched, errors } = form;
    
    return (
        <>
            <Field {...fieldProps}>{({ field }) => {
                let inputProps = Object.assign(field, fieldProps);
                let error = false;
                let success = false;
                if(touched[fieldProps.name] && errors[fieldProps.name]){
                    error = true;
                }
                if(touched[fieldProps.name] && !errors[fieldProps.name]){
                    success = true;
                }
                inputProps.placeholder="";
                inputProps.openMenuOnFocus = true;
                inputProps.openMenuOnClick = true;
                inputProps.closeMenuOnSelect = true;
                if(async){
                    return <CustomAsyncSelect data-label={label} {...inputProps} 
                    data-error={error}
                    data-success={success} />
                } 
                
                return <CustomSelect data-label={label} {...inputProps} 
                    data-error={error}
                    data-success={success} />
                }}
            </Field>
            {touched[fieldProps.name] && errors[fieldProps.name] && <HelperTextError error>{errors[fieldProps.name]}</HelperTextError>}
        </>
    );
}


FormSelect.propTypes = {
    field: PropTypes.object.isRequired,
    label: PropTypes.any,
    form: PropTypes.any,
    async: PropTypes.bool.isRequired
}

export default FormSelect;
