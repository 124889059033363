/* Geral / Bibliotecas */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import isValid from "date-fns/isValid";
import FNSformat from "date-fns/format";
import ptBRLocale from "date-fns/locale/pt-BR";

/* Ícones */
import Save from "@material-ui/icons/Save";

/* Métodos / Ações */
import { saveOutros } from "actions/membros";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import FormInput from "components/FormInput";
import Button from "components/CustomButton";
import DatePicker from "components/DatePicker";
import { Container } from "assets/jss/doSul-dashboard";
import Loading from "components/Loading";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

/* Validação */
import validationSchema from "./validationOutros";

const Model = {
    IdMembro: null,
    DoadorSangue: "",
    TipoSanguineo: "",
    DataNascimento: null,
    DataAprendiz: null,
    DataCompanheiro: null,
    DataMestre: null,
    DataInstalacao: null
};

export default function MembrosOutrosForm({
    id, form, formData
}) {
    const permissions = useSelector(state => state.permissions);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(form.setAfterSave(() => { }));
        dispatch(form.setSave(saveOutros));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // É chamado toda vez que muda o ID
        form.setModel(Model);
        if (id) {
            form.fetchData(id);
        }
    }, [id, form]);

    if (formData.loading) {
        return <Loading />;
    }

    const disabled = permissions && permissions[formData.root] < 2;

    const handleSubmit = (values, actions) => {
        formData.save(values, actions, formData.afterSave);
    };

    return (
        <Container>
            <Formik
                validationSchema={validationSchema}
                initialValues={formData.model}
                onSubmit={handleSubmit}
                render={FormikProps => (
                    <Form onSubmit={FormikProps.handleSubmit}>
                        <GridContainer>
                            {(FormikProps.values.Tipo === "E") &&
                                <p>Membro do tipo Empresa, nada para alterar aqui.</p>
                            }
                            {(FormikProps.values.Tipo !== "E") &&
                                <>

                                    <GridItem xs={12} lg={6}>
                                        <FormInput
                                            field={{
                                                name: "TipoSanguineo",
                                                disabled
                                            }}
                                            form={FormikProps}
                                            label="Tipo Sanguíneo *"
                                        />
                                    </GridItem>

                                    <GridItem xs={12} lg={6}>
                                        <FormControlLabel style={{ marginTop: '25px' }}
                                            control={(
                                                <Checkbox
                                                    checked={FormikProps.values.DoadorSangue === 1}
                                                    onChange={function (event) { FormikProps.setFieldValue("DoadorSangue", event.target.checked ? 1 : 0); }}
                                                    value="1"
                                                    color="primary"
                                                />
                                            )}
                                            label=" Doador de Sangue"
                                        />
                                    </GridItem>


                                    <GridItem xs={12} lg={6}>
                                        <DatePicker
                                            field={{
                                                name: "DataAprendiz",
                                                disabled
                                            }}
                                            value={FormikProps.values.DataAprendiz ? new Date(FormikProps.values.DataAprendiz) : null}
                                            form={FormikProps}
                                            onChange={function (value) {
                                                FormikProps.setFieldValue("DataAprendiz", isValid(value) ? FNSformat(value, "yyyy-MM-dd 00:00:00", { locale: ptBRLocale }) : null);
                                            }}
                                            label="Data Aprendiz"
                                        />
                                    </GridItem>

                                    <GridItem xs={12} lg={6}>
                                        <DatePicker
                                            field={{
                                                name: "DataCompanheiro",
                                                disabled
                                            }}
                                            value={FormikProps.values.DataCompanheiro ? new Date(FormikProps.values.DataCompanheiro) : null}
                                            form={FormikProps}
                                            onChange={function (value) {
                                                FormikProps.setFieldValue("DataCompanheiro", isValid(value) ? FNSformat(value, "yyyy-MM-dd 00:00:00", { locale: ptBRLocale }) : null);
                                            }}
                                            label="Data Companheiro"
                                        />
                                    </GridItem>

                                    <GridItem xs={12} lg={6}>
                                        <DatePicker
                                            field={{
                                                name: "DataMestre",
                                                disabled
                                            }}
                                            value={FormikProps.values.DataMestre ? new Date(FormikProps.values.DataMestre) : null}
                                            form={FormikProps}
                                            onChange={function (value) {
                                                FormikProps.setFieldValue("DataMestre", isValid(value) ? FNSformat(value, "yyyy-MM-dd 00:00:00", { locale: ptBRLocale }) : null);
                                            }}
                                            label="Data Mestre"
                                        />
                                    </GridItem>

                                    <GridItem xs={12} lg={6}>
                                        <DatePicker
                                            field={{
                                                name: "DataInstalacao",
                                                disabled
                                            }}
                                            value={FormikProps.values.DataInstalacao ? new Date(FormikProps.values.DataInstalacao) : null}
                                            form={FormikProps}
                                            onChange={function (value) {
                                                FormikProps.setFieldValue("DataInstalacao", isValid(value) ? FNSformat(value, "yyyy-MM-dd 00:00:00", { locale: ptBRLocale }) : null);
                                            }}
                                            label="Data Instalação"
                                        />
                                    </GridItem>


                                    <GridItem xs={12}>
                                        <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                                            {!disabled && (
                                                <Button color="success" type="submit" disabled={FormikProps.isSubmitting}>
                                                    <Save />
                                                    {" "}
                                                    Salvar
                                                </Button>
                                            )}
                                        </div>
                                    </GridItem>
                                </>}
                        </GridContainer>

                    </Form>
                )}
            />
        </Container>
    );
}

MembrosOutrosForm.propTypes = {
    id: PropTypes.any,
    form: PropTypes.any,
    formData: PropTypes.any
};
