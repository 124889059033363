// Geral
import React from "react";

// Componentes
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

export default function Unauthorized() {
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <h4 style={{ textAlign: "center" }}>Oops, parece que você não tem a permissão necessária para acessar esta página</h4>
            </GridItem>
        </GridContainer>
    );
}
