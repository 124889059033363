import * as Yup from "yup";
import { addInYup as addCep } from "helpers/validation/Cep";

addCep();

const validationSchema = Yup.object().shape({
    Nome: Yup.string().required("Por favor digite o Nome"),
    Tipo: Yup.string().required("Por favor selecione o Tipo"),
    Telefone: Yup.string().required("Por favor digite o Telefone"),
    Telefone2: Yup.string(),
    responsavel: Yup.string().when("Tipo", (Tipo, field) => (Tipo !== "I" && Tipo !== "E" ? field.required("Por favor selecione o Responsável") : field)),
    DataNascimento: Yup.mixed().when("Tipo", (Tipo, field) => (Tipo !== "E" ? field.date("Digite um data válida").required("Por favor digite a Data de Nascimento") : field)),
    Email: Yup.string().required("Por favor digite o e-mail").email("Por favor digite um e-mail válido"),
    Senha: Yup.string().when("IdMembro", (IdMembro, field) => (IdMembro ? field : field.required("Por favor digite a senha"))),
    ConfirmaSenha: Yup.string().oneOf([Yup.ref("Senha"), null, ""], "Por favor digite a confirmação de senha"),
    Cep: Yup.string().cep('Por favor digite um CEP válido')
});

export default validationSchema;
