import React from "react";
import PropTypes from "prop-types";
import { FastField } from 'formik';
import FormControl from '@material-ui/core/FormControl';

import { CustomEditor, HelperTextError } from "./styles";

function FormEditor(props) {
    const { form } = props;
    const fieldProps = props.field;
    const { touched, errors, values, setFieldValue } = form;

    return (
        <>
            <FastField {...fieldProps}>{({ field }) => {
                let inputProps = Object.assign(field, fieldProps);
                let error = false;
                let success = false;
                if(touched[fieldProps.name] && errors[fieldProps.name]){
                    error = true;
                }
                if(touched[fieldProps.name] && !errors[fieldProps.name]){
                    success = true;
                } 
                delete inputProps.onChange;
                delete inputProps.onBlur;
                delete inputProps.value;

                return (<FormControl>
                    <CustomEditor 
                        data-error={error}
                        data-success={success}
                        changeDone={(value)=>{
                            setFieldValue(fieldProps.name, value);
                        }}
                        fieldValue={values[fieldProps.name]}
                        toolbar={{
                            options: ['inline', 'fontSize', 'list', 'textAlign', 'remove', 'history'],
                    }} {...inputProps}/>
                    {touched[fieldProps.name] && errors[fieldProps.name] && <HelperTextError error>{errors[fieldProps.name]}</HelperTextError>}
                </FormControl>);
                }}
            </FastField>
        </>
    );
}


FormEditor.propTypes = {
    field: PropTypes.object.isRequired,
    label: PropTypes.any,
    form: PropTypes.any
}

export default FormEditor;
