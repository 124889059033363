/* Geral / Bibliotecas */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { navigate } from "hookrouter";

import { JWT_TOKEN } from "helpers/consts";
import jwt_decode from "jwt-decode";

/* Ícones */
import Save from "@material-ui/icons/Save";

/* Métodos / Ações */
import { loadOptionsIrmaos as loadOptionsMembros } from "actions/membros";
import { loadOptions as loadOptionsLojas } from "actions/lojas";
import { loadCidades } from "actions/cidadesNew";
import { getCoordinates } from "actions/coordinates";

/* Componentes */
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import FormInput from "components/FormInput";
import Button from "components/CustomButton";
import FormSelect from "components/FormSelect";
import { Container } from "assets/jss/doSul-dashboard";
import Loading from "components/Loading";

import PaisForm from "components/CustomInput/Pais";
import EstadoForm from "components/CustomInput/Estado";

/* Validação */
import validationSchema from "./validationNew";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Model = {
    Nome: "",
    // cidade: null,
    /**/
    Endereco: "",
    Numero: null,
    Complemento: "",
    Bairro: "",
    Cep: "",
    pais: "",
    Pais: "",
    PaisForm: "",
    PaisNome: "",
    estado: "",
    Estado: "",
    EstadoForm: "",
    EstadoNome: "",
    cidade: "",
    Cidade: "",
    CidadeForm: "",
    CidadeNome: "",
    /**/
    potencia: null,
    NomePotencia: "",
    loja: null,
    NomeLoja: "",
    Cim: "",
    Grau: "",
    responsavel: "",
    Telefone: "",
    Email: "",
    Tipo: "",
    Senha: "",
    ConfirmaSenha: "",
    Sexo: "M",
};

export default function MembrosForm({ form, formData }) {
    const permissions = useSelector((state) => state.permissions);
    const [labelCep, setLabelCep] = useState('CEP');

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(form.setModel(Model));
        dispatch(
            form.setAfterSave((res) => {
                const { data } = res;
                if (data && data.id) {
                    navigate(`/membros/edit/${btoa(data.id)}`);
                }
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const token = jwt_decode(localStorage.getItem(JWT_TOKEN));

    if (formData.loading) {
        return <Loading />;
    }

    const disabled = permissions && permissions[formData.root] < 2;

    const handleSubmit = (values, actions) => {
        if (values.Pais && values.pais === undefined) values.pais = values.Pais;
        if (values.Estado && values.estado === undefined) values.estado = values.Estado;

        formData.save(values, actions, formData.afterSave);
    };

    /* Recupera as coordenadas por meio do endereco informado */
    const MySwal = withReactContent(Swal)
    async function fnGetCoordinates(props) {
        let { Endereco, Bairro, EstadoNome, CidadeNome, PaisNome } = props.values;
        
        if (Endereco.length > 0 && Bairro.length > 0 && EstadoNome.length > 0 && CidadeNome.length > 0 && PaisNome.length > 0) {
            MySwal.fire({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 1000,
                type: 'info',
                title: 'Aguarde um instante'
            })

            const response = await getCoordinates(props);
            const { lat, lng } = response.results[0].geometry.location;
    
            props.setFieldValue(`Latitude`, lat.toFixed(8));
            props.setFieldValue(`Longitude`, lng.toFixed(8));
        } else {
            MySwal.fire({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 3000,
                type: 'warning',
                title: 'Preencha o logradouro completo primeiro'
            })
        }
    }

    return (
        <Container>
            <Formik
                validationSchema={validationSchema}
                initialValues={Model}
                onSubmit={handleSubmit}
                render={(FormikProps) => (
                    <Form onSubmit={FormikProps.handleSubmit}>
                        <GridContainer>
                            <GridItem xs={12} lg={12}>
                                <FormInput
                                    field={{
                                        name: "Nome",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Nome *"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <FormSelect
                                    field={{
                                        isSearchable: false,
                                        name: "Tipo",
                                        disabled,
                                        options: getTipoOptions(token),
                                        value: getTipoValue(
                                            FormikProps.values.Tipo
                                        ),
                                        onChange: (value) => {
                                            FormikProps.setFieldValue(
                                                "Tipo",
                                                value.value
                                            );
                                        },
                                    }}
                                    form={FormikProps}
                                    async={false}
                                    label="Tipo *"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "Telefone",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Telefone *"
                                />
                            </GridItem>

                            {FormikProps.values.Tipo === "S" && (
                                <GridItem xs={12} lg={6}>
                                    <FormSelect
                                        field={{
                                            isSearchable: false,
                                            name: "Sexo",
                                            disabled,
                                            options: getSexoOptions(),
                                            value: getSexoValue(
                                                FormikProps.values.Sexo
                                            ),
                                            onChange: (value) => {
                                                FormikProps.setFieldValue(
                                                    "Sexo",
                                                    value.value
                                                );
                                            },
                                        }}
                                        form={FormikProps}
                                        async={false}
                                        label="Sexo *"
                                    />
                                </GridItem>
                            )}

                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "Cep",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label={labelCep}
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <PaisForm
                                    pais={FormikProps.values.PaisForm}
                                    form={FormikProps}
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <EstadoForm
                                    estado={FormikProps.values.EstadoForm}
                                    pais={FormikProps.values.Pais}
                                    form={FormikProps}
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                {
                                    (FormikProps.values.Pais === 1058 || FormikProps.values.Pais === 840) && +FormikProps.values.Estado > 0
                                    ? 
                                    <FormSelect
                                        field={{
                                            name: "cidade",
                                            placeholder: "Digite para localizar...",
                                            isSearchable: true,
                                            loadOptions: (cidade) => {
                                                return loadCidades({ cidade, estado: FormikProps.values.Estado, pais: FormikProps.values.Pais })
                                            },
                                            getOptionLabel: (option) =>
                                                `${option.Nome}${option.NomeEstado ? ` - ${option.NomeEstado}` : ""}`,
                                            loadingMessage: () => "Carregando...",
                                            noOptionsMessage: (input) =>
                                                input.inputValue === "" ? "Digite para localizar..." : "Nenhum resultado encontrado",
                                            disabled,
                                            value: FormikProps.values.cidade
                                                ? { IdCidade: FormikProps.values.cidade, Nome: FormikProps.values.CidadeNome }
                                                : null,
                                            onChange: (value) => {
                                                if (value) {
                                                    if (value.IdCidade) {
                                                        FormikProps.setFieldValue("cidade", btoa(value.IdCidade) ); 
                                                        FormikProps.setFieldValue("Cidade", btoa(value.IdCidade) ); 
                                                    }
                                                    FormikProps.setFieldValue("NomeCidade", value.Nome);
                                                    FormikProps.setFieldValue("CidadeNome", value.Nome);
                                                    FormikProps.setFieldValue("CidadeForm", value.Nome);
                                                } else {
                                                    FormikProps.setFieldValue("cidade", "");
                                                    FormikProps.setFieldValue("Cidade", "");
                                                    FormikProps.setFieldValue("NomeCidade", "");
                                                    FormikProps.setFieldValue("CidadeForm", "");
                                                }
                                            },
                                        }}
                                        form={FormikProps}
                                        async
                                        label="Cidade *"
                                    />
                                    :
                                    <FormInput
                                        field={{
                                            name: "CidadeNome",
                                            disabled,
                                        }}
                                        form={FormikProps}
                                        label="Cidade *"
                                    />
                                }
                            </GridItem>

                            <GridItem xs={12} lg={8}>
                                <FormInput
                                    field={{
                                        name: "Endereco",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Endereco *"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={4}>
                                <FormInput
                                    field={{
                                        name: "Numero",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Número"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "Complemento",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Complemento"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "Bairro",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Bairro *"
                                />
                            </GridItem>

                            {FormikProps.values.Tipo !== "I" &&
                                FormikProps.values.Tipo !== "E" && (
                                    <GridItem xs={12}>
                                        <FormSelect
                                            field={{
                                                name: "responsavel",
                                                placeholder:
                                                    "Digite para localizar...",
                                                isSearchable: true,
                                                defaultOptions: true,
                                                loadOptions: loadOptionsMembros,
                                                getOptionLabel: (option) =>
                                                    `${option.Nome}`,
                                                loadingMessage: () =>
                                                    "Carregando...",
                                                noOptionsMessage: (input) =>
                                                    input.inputValue === ""
                                                        ? "Digite para localizar..."
                                                        : "Nenhum resultado encontrado",
                                                disabled,
                                                value: FormikProps.values
                                                    .responsavel
                                                    ? {
                                                          IdMembro:
                                                              FormikProps.values
                                                                  .responsavel,
                                                          Nome:
                                                              FormikProps.values
                                                                  .nomeResponsavel,
                                                      }
                                                    : null,
                                                onChange: (value) => {
                                                    if (value) {
                                                        if (value.IdMembro) {
                                                            FormikProps.setFieldValue(
                                                                "responsavel",
                                                                btoa(
                                                                    value.IdMembro
                                                                )
                                                            );
                                                        }
                                                        FormikProps.setFieldValue(
                                                            "nomeResponsavel",
                                                            value.Nome
                                                        );
                                                    } else {
                                                        FormikProps.setFieldValue(
                                                            "responsavel",
                                                            null
                                                        );
                                                    }
                                                },
                                            }}
                                            form={FormikProps}
                                            async
                                            label="Responsável *"
                                        />
                                    </GridItem>
                                )}

                            {FormikProps.values.Tipo === "I" && (
                                <>
                                    <GridItem xs={12} lg={6}>
                                        <FormInput
                                            field={{
                                                name: "Cim",
                                                disabled,
                                            }}
                                            form={FormikProps}
                                            label="Cim *"
                                        />
                                    </GridItem>

                                    <GridItem xs={12} lg={6}>
                                        <FormInput
                                            field={{
                                                name: "Grau",
                                                disabled,
                                            }}
                                            form={FormikProps}
                                            label="Grau *"
                                        />
                                    </GridItem>
                                </>
                            )}

                            {FormikProps.values.Tipo === "I" && (
                                <GridItem xs={12}>
                                    <FormSelect
                                        field={{
                                            name: "loja",
                                            placeholder:
                                                "Digite para localizar...",
                                            isSearchable: true,
                                            defaultOptions: true,
                                            loadOptions: loadOptionsLojas,
                                            getOptionLabel: (option) =>
                                                `${option.Nome}`,
                                            loadingMessage: () =>
                                                "Carregando...",
                                            noOptionsMessage: (input) =>
                                                input.inputValue === ""
                                                    ? "Digite para localizar..."
                                                    : "Nenhum resultado encontrado",
                                            disabled,
                                            value: FormikProps.values.loja
                                                ? {
                                                      IdLoja:
                                                          FormikProps.values
                                                              .loja,
                                                      Nome:
                                                          FormikProps.values
                                                              .nomeLoja,
                                                  }
                                                : null,
                                            onChange: (value) => {
                                                if (value) {
                                                    if (value.IdLoja) {
                                                        FormikProps.setFieldValue(
                                                            "loja",
                                                            btoa(value.IdLoja)
                                                        );
                                                    }
                                                    FormikProps.setFieldValue(
                                                        "nomeLoja",
                                                        value.Nome
                                                    );
                                                } else {
                                                    FormikProps.setFieldValue(
                                                        "loja",
                                                        null
                                                    );
                                                }
                                            },
                                        }}
                                        form={FormikProps}
                                        async
                                        label="Loja Principal *"
                                    />
                                </GridItem>
                            )}

                            <GridItem xs={12}>
                                <FormInput
                                    field={{
                                        name: "Email",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="E-mail *"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "Senha",
                                        type: "password",
                                        autoComplete: "new-password",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label={"Senha *"}
                                />
                            </GridItem>

                            {FormikProps.values.senha !== "" && (
                                <GridItem xs={12} lg={6}>
                                    <FormInput
                                        field={{
                                            name: "ConfirmaSenha",
                                            type: "password",
                                            autoComplete: "new-password",
                                            disabled,
                                        }}
                                        form={FormikProps}
                                        label="Confirme a senha *"
                                    />
                                </GridItem>
                            )}

                            <GridItem xs={12} lg={4}>
                                <FormInput
                                    field={{
                                        name: "Latitude",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Latitude"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={4}>
                                <FormInput
                                    field={{
                                        name: "Longitude",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Longitude"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={4}>
                                <Button
                                    color="info"
                                    type="button"
                                    left
                                    onClick={() => fnGetCoordinates(FormikProps)}
                                >
                                    Pegar coordenadas
                                </Button>
                            </GridItem>

                            <GridItem xs={12}>
                                <div
                                    style={{
                                        paddingTop: "40px",
                                        paddingBottom: "40px",
                                    }}
                                >
                                    {!disabled && (
                                        <Button
                                            color="success"
                                            type="submit"
                                            disabled={FormikProps.isSubmitting}
                                        >
                                            <Save /> Salvar
                                        </Button>
                                    )}
                                </div>
                            </GridItem>
                        </GridContainer>
                    </Form>
                )}
            />
        </Container>
    );
}

MembrosForm.propTypes = {
    form: PropTypes.any,
    formData: PropTypes.any,
};

const tipoOptions = [
    { value: "C", label: "Cunhada" },
    { value: "S", label: "Sobrinho(a)" },
];

const getTipoOptions = (token) => {
    if (token.platform === "dashboard" && tipoOptions.length < 3) {
        tipoOptions.push({ value: "I", label: "Irmão" });
    }
    if (token.platform === "dashboard" && tipoOptions.length < 4) {
        tipoOptions.push({ value: "E", label: "Empresa (Não Maçonico)" });
    }
    return tipoOptions;
};

const getTipoValue = (value = "") => {
    const index = tipoOptions.findIndex((opt) => opt.value === value);
    return tipoOptions[index] ? tipoOptions[index] : "";
};

const sexoOptions = [
    { value: "M", label: "Masculino" },
    { value: "F", label: "Feminino" },
];

const getSexoOptions = () => {
    return sexoOptions;
};

const getSexoValue = (value = "") => {
    const index = sexoOptions.findIndex((opt) => opt.value === value);
    return sexoOptions[index] ? sexoOptions[index] : "";
};
