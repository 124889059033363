/* eslint-disable max-len */
/* Geral / Bibliotecas */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";

/* Métodos / Ações */
import * as form from "actions/form";
import { fetch, save } from "actions/autorizacoes-membro";

/* Componentes */
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import FormInput from "components/FormInput";
import { Container } from "assets/jss/doSul-dashboard";
import Loading from "components/Loading";
import Button from "components/CustomButton";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Done from "@material-ui/icons/Done";

/* Validação */
import validationSchema from "./validation";

const Model = {
    IdAutorizacoesMembro: null,
    NomeDe: "",
    NomePara: "",
    Status: 1,
};

const MySwal = withReactContent(Swal);

export default function AutorizacoesMembroForm({
    id,
    changeStatus,
    deleteRecord,
    afterSave,
    afterChangeStatusSuccess,
    afterDeleteSuccess,
}) {
    const formData = useSelector((state) => state.form);
    const afterFetch = null; // Sobrescrever se precisar

    useEffect(() => {
        form.start(
            "autorizacoes-membro",
            "IdAutorizacoesMembro",
            id,
            Model,
            fetch,
            save,
            changeStatus,
            deleteRecord,
            afterSave,
            afterChangeStatusSuccess,
            afterDeleteSuccess,
            afterFetch
        );
        return form.stop;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // É chamado toda vez que muda o ID
        form.setModel(Model);
        if (id) {
            form.fetchData(id);
        }
    }, [id]);

    if (formData.loading) {
        return <Loading />;
    }

    const disabled = true;

    return (
        <Container>
            <Formik
                validationSchema={validationSchema}
                initialValues={formData.model}
                render={(FormikProps) => (
                    <Form onSubmit={FormikProps.handleSubmit}>
                        <GridContainer>
                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "DataHoraDisplay",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Data / Hora"
                                />
                            </GridItem>
                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "TipoDisplay",
                                        disabled,
                                    }}
                                    form={FormikProps}
                                    label="Tipo"
                                />
                            </GridItem>
                            {FormikProps.values && FormikProps.values.De && (
                                <GridItem xs={12} lg={6}>
                                    <div style={{ paddingTop: "25px" }}>
                                        <b>De:</b>
                                    </div>
                                    <p>
                                        {FormikProps.values.De.Nome &&
                                            FormikProps.values.De.Nome}
                                    </p>
                                    <p>
                                        {FormikProps.values.De.Email &&
                                            FormikProps.values.De.Email}
                                    </p>
                                    <p>
                                        {FormikProps.values.De.Endereco &&
                                            FormikProps.values.De.Endereco}{" "}
                                        {FormikProps.values.De.Numero &&
                                            `, ${FormikProps.values.De.Numero}`}
                                    </p>
                                    <p>
                                        {FormikProps.values.De.Bairro &&
                                            FormikProps.values.De.Bairro}
                                    </p>
                                    <p>
                                        {FormikProps.values.De.Cep &&
                                            FormikProps.values.De.Cep}
                                    </p>
                                    <p>
                                        {FormikProps.values.De.NomeCidade &&
                                            FormikProps.values.De.NomeCidade}
                                    </p>
                                    <p>
                                        {FormikProps.values.De.Telefone &&
                                            FormikProps.values.De.Telefone}
                                    </p>
                                    <p>
                                        {FormikProps.values.De.Telefone2 &&
                                            FormikProps.values.De.Telefone2}
                                    </p>
                                    <p>
                                        {FormikProps.values.De.Telefone3 &&
                                            FormikProps.values.De.Telefone3}
                                    </p>
                                </GridItem>
                            )}
                            {FormikProps.values && FormikProps.values.Para && (
                                <GridItem xs={12} lg={6}>
                                    <div style={{ paddingTop: "25px" }}>
                                        <b>Para:</b>
                                    </div>
                                    <p>
                                        {FormikProps.values.Para.Nome &&
                                            FormikProps.values.Para.Nome}
                                    </p>
                                    <p>
                                        {FormikProps.values.Para.Email &&
                                            FormikProps.values.Para.Email}
                                    </p>
                                    <p>
                                        {FormikProps.values.Para.Endereco &&
                                            FormikProps.values.Para
                                                .Endereco}{" "}
                                        {FormikProps.values.Para.Numero &&
                                            `, ${FormikProps.values.Para.Numero}`}
                                    </p>
                                    <p>
                                        {FormikProps.values.Para.Bairro &&
                                            FormikProps.values.Para.Bairro}
                                    </p>
                                    <p>
                                        {FormikProps.values.Para.Cep &&
                                            FormikProps.values.Para.Cep}
                                    </p>
                                    <p>
                                        {FormikProps.values.Para.NomeCidade &&
                                            FormikProps.values.Para.NomeCidade}
                                    </p>
                                    <p>
                                        {FormikProps.values.Para.Telefone &&
                                            FormikProps.values.Para.Telefone}
                                    </p>
                                    <p>
                                        {FormikProps.values.Para.Telefone2 &&
                                            FormikProps.values.Para.Telefone2}
                                    </p>
                                    <p>
                                        {FormikProps.values.Para.Telefone3 &&
                                            FormikProps.values.Para.Telefone3}
                                    </p>
                                </GridItem>
                            )}

                            <GridItem xs={12}>
                                <FormInput
                                    field={{
                                        name: "Mensagem",
                                        disabled,
                                        multiline: true,
                                        rows: 5,
                                    }}
                                    form={FormikProps}
                                    label="Mensagem"
                                />
                            </GridItem>

                            {FormikProps.values.Justificativa && (
                                <GridItem xs={12}>
                                    <FormInput
                                        field={{
                                            name: "Justificativa",
                                            disabled,
                                            multiline: true,
                                            rows: 5,
                                        }}
                                        form={FormikProps}
                                        label="Justificativa"
                                    />
                                </GridItem>
                            )}

                            <GridItem xs={12}>
                                <div
                                    style={{
                                        paddingTop: "40px",
                                        paddingBottom: "40px",
                                    }}
                                >
                                    {FormikProps.values.Status === -1 && (
                                        <Button
                                            color="warning"
                                            type="button"
                                            right
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                MySwal.fire({
                                                    title: "",
                                                    type: "warning",
                                                    html: (
                                                        <div>
                                                            <h4
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                O que você
                                                                deseja fazer com
                                                                este registro
                                                                pendente?
                                                            </h4>
                                                            <div
                                                                style={{
                                                                    width:
                                                                        "100%",
                                                                }}
                                                            >
                                                                <Button
                                                                    color="info"
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        changeStatus(
                                                                            FormikProps
                                                                                .values
                                                                                .IdAutorizacaoMembro,
                                                                            1,
                                                                            () => {
                                                                                FormikProps.setFieldValue(
                                                                                    "Status",
                                                                                    1
                                                                                );
                                                                            },
                                                                            null,
                                                                            null, 
                                                                            FormikProps.values.De.IdMembro ? FormikProps.values.De.IdMembro : null
                                                                        );
                                                                    }}
                                                                >
                                                                    &nbsp;Aceitar
                                                                    /
                                                                    Ativar&nbsp;
                                                                </Button>
                                                                <Button
                                                                    color="warning"
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        MySwal.fire(
                                                                            {
                                                                                title:
                                                                                    "Informe a justificativa",
                                                                                type:
                                                                                    "question",
                                                                                input:
                                                                                    "text",
                                                                                showConfirmButton: true,
                                                                                confirmButtonText: (
                                                                                    <Button
                                                                                        component="span"
                                                                                        size="lg"
                                                                                        color="warning"
                                                                                        title="Recusar / Bloquear"
                                                                                    >
                                                                                        "Recusar
                                                                                        /
                                                                                        Bloquear"
                                                                                    </Button>
                                                                                ),
                                                                            }
                                                                        ).then(
                                                                            (
                                                                                result
                                                                            ) => {
                                                                                if (
                                                                                    result.value
                                                                                ) {
                                                                                    changeStatus(
                                                                                        FormikProps
                                                                                            .values
                                                                                            .IdAutorizacaoMembro,
                                                                                        0,
                                                                                        () => {
                                                                                            FormikProps.setFieldValue(
                                                                                                "Status",
                                                                                                0
                                                                                            );
                                                                                        },
                                                                                        null,
                                                                                        result.value
                                                                                    );
                                                                                }
                                                                            }
                                                                        );
                                                                    }}
                                                                >
                                                                    Recusar /
                                                                    Bloquear
                                                                </Button>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    width:
                                                                        "100%",
                                                                }}
                                                            >
                                                                <Button
                                                                    link
                                                                    onClick={() => {
                                                                        MySwal.close();
                                                                    }}
                                                                >
                                                                    Nada, fechar
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    ),
                                                    showCancelButton: false,
                                                    showConfirmButton: false,
                                                });
                                            }}
                                        >
                                            <Done /> Avaliar
                                        </Button>
                                    )}
                                </div>
                            </GridItem>
                        </GridContainer>
                    </Form>
                )}
            />
        </Container>
    );
}

AutorizacoesMembroForm.propTypes = {
    id: PropTypes.any,
    changeStatus: PropTypes.func,
    afterChangeStatusSuccess: PropTypes.func,
};
