import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
// import { navigate } from "hookrouter";
import jwt_decode from "jwt-decode";

// @material-ui/core components
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
//import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
//import Notifications from "@material-ui/icons/Notifications";

import { JWT_TOKEN } from "helpers/consts";

// Ações
import { logoutUser } from "actions/auth";

//Estilos
import { ButtonLink, Manager, DropdownItem, PopperNav } from "./styles";

/* Idiomas */
import { Trans } from 'react-i18next';

export default function NavbarLinks() {
    const dispatch = useDispatch();

    const token = jwt_decode(localStorage.getItem(JWT_TOKEN));

    const [openNotifcation] = useState(false);
    const [openProfile, setOpenProfile] = useState(false);

    //const anchorNotification = useRef(null);
    const anchorProfile = useRef(null);

    /* const handleToggleNotification = () => {
        setOpenNotification(!openNotifcation);
    };

    const handleCloseNotification = event => {
        if (anchorNotification.contains(event.target)) {
            return;
        }
        setOpenNotification(false);
    }; */
    const handleToggleProfile = () => {
        setOpenProfile(!openProfile);
    };

    const handleCloseProfile = event => {
        if (anchorProfile.current.contains(event.target)) {
            return;
        }
        setOpenProfile(false);
    };

    const onLogout = e => {
        e.preventDefault();
        dispatch(logoutUser());
    };

    // const goToPreferencias = () => {
    //     navigate("/preferencias");
    // };

    return (
        <div>
            {/*<Manager>
                <ButtonLink
                buttonRef={anchorNotification}
                color={window.innerWidth > 959 ? "transparent" : "white"}
                justIcon={window.innerWidth > 959}
                simple={!(window.innerWidth > 959)}
                aria-owns={openNotifcation ? "notification-menu-list-grow" : null}
                aria-haspopup="true"
                onClick={this.handleToggleNotification}
                >
                <Notifications />
                <NotificationsAmount>5</NotificationsAmount>
                <Hidden mdUp implementation="css">
                    <LinkText onClick={this.handleClick}>
                    Notificações
                    </LinkText>
                </Hidden>
                </ButtonLink>
                <PopperNav
                open={openNotifcation}
                anchorEl={this.anchorNotification}
                transition
                disablePortal
                >
                {({ TransitionProps, placement }) => (
                    <Grow
                    {...TransitionProps}
                    id="notification-menu-list-grow"
                    style={{
                        transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom"
                    }}
                    >
                    <Paper>
                        <ClickAwayListener onClickAway={this.handleCloseNotification}>
                        <MenuList role="menu">
                            <DropdownItem onClick={this.handleCloseNotification}>
                            Mike John responded to your email
                            </DropdownItem>
                            <DropdownItem
                            onClick={this.handleCloseNotification}
                            >
                            You have 5 new tasks
                            </DropdownItem>
                            <DropdownItem
                            onClick={this.handleCloseNotification}
                            >
                            You{"'"}re now friend with Andrew
                            </DropdownItem>
                            <DropdownItem
                            onClick={this.handleCloseNotification}
                            >
                            Another Notification
                            </DropdownItem>
                            <DropdownItem
                            onClick={this.handleCloseNotification}
                            >
                            Another One
                            </DropdownItem>
                        </MenuList>
                        </ClickAwayListener>
                    </Paper>
                    </Grow>
                )}
                </PopperNav>
            </Manager>*/}
            <Manager>
                <ButtonLink
                    buttonRef={anchorProfile}
                    color={window.innerWidth > 959 ? "transparent" : "white"}
                    simple={!(window.innerWidth > 959)}
                    aria-owns={openNotifcation ? "profile-menu-list-grow" : null}
                    aria-haspopup="true"
                    onClick={handleToggleProfile}
                >
                    {token.foto ? (
                        <img
                            src={token.foto}
                            alt={token.nome}
                            title={token.nome}
                            style={{
                                maxHeight: "40px",
                                marginRight: "10px",
                                borderRadius: "4px"
                            }}
                        />
                    ) : (
                            <Person />
                        )}{" "}
                    {token.nome}
                </ButtonLink>
                <PopperNav open={openProfile} anchorEl={anchorProfile.current} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            id="profile-menu-list-grow"
                            style={{
                                transformOrigin: placement === "bottom" ? "center top" : "center bottom"
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseProfile}>
                                    <MenuList role="menu">
                                        {/* <DropdownItem onClick={goToPreferencias}>Preferências</DropdownItem> */}
                                        <DropdownItem onClick={onLogout}>
                                            <Trans i18nKey="navbar.logout">Sair do sistema</Trans>
                                        </DropdownItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </PopperNav>
            </Manager>
        </div>
    );
}
