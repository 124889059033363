/* Geral / Bibliotecas */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

/* Métodos / Ações */
import * as form from "actions/form";
import { fetch, save } from "actions/membros";

/* Componentes */
import Loading from "components/Loading";

import FormNew from './formNew';
import FormEdit from './formEdit';

const Model = {
    IdMembro: null
};

export default function MembrosForm({
    id, changeStatus, deleteRecord, afterSave, afterChangeStatusSuccess, afterDeleteSuccess
}) {
    const formData = useSelector(state => state.form);

    const afterFetch = null; // Sobrescrever se precisar

    useEffect(() => {
        form.start("membros", "IdMembro", id, Model, fetch, save, changeStatus, deleteRecord, afterSave, afterChangeStatusSuccess, afterDeleteSuccess, afterFetch);
        return form.stop;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (formData.loading) {
        return <Loading />;
    }

    return formData.model[formData.idField] ? <FormEdit id={id} form={form} formData={formData} /> : <FormNew form={form} formData={formData} />
}

MembrosForm.propTypes = {
    id: PropTypes.any,
    afterSave: PropTypes.func,
    changeStatus: PropTypes.func,
    deleteRecord: PropTypes.func,
    afterChangeStatusSuccess: PropTypes.func,
    afterDeleteSuccess: PropTypes.func
};