import * as yup from 'yup';

const Cep = (val = "") => (/[0-9]{5}-[0-9]{3}/.test(val) || val === '')

export default Cep;

export const addInYup = () => {
    yup.addMethod(yup.string, "cep", function(message) {
        return this.test("cep", message || "Número de CEP inválido", value => Cep(value));
    });
}