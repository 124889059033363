import * as Yup from "yup";
import { addInYup as addCnpj } from "helpers/validation/Cnpj";
import { addInYup as addCep } from "helpers/validation/Cep";

addCnpj();
addCep();

const validationSchema = Yup.object().shape({
    Nome: Yup.string().required("Por favor digite o nome"),
    Cnpj: Yup.string().required("Por favor digite o CNPJ ou CPF"),
    Categoria: Yup.string().required("Por favor informe a Categoria"),
    Email: Yup.string().required("Por favor digite o e-mail").email("Por favor digite um e-mail válido"),
    Endereco: Yup.string().required("Por favor digite o endereço"),
    Bairro: Yup.string().required("Por favor digite o bairro"),
    // Cep: Yup.string().required("Por favor digite o Cep").cep("Por favor digite um CEP válido"),
    cidade: Yup.string().required("Por favor selecione a cidade"),
    NomeCidade: Yup.string().nullable(),
    Telefone: Yup.string().required("Por favor digite o Telefone"),
    Telefone2: Yup.string().nullable(),
    Telefone3: Yup.string().nullable(),
    Atividades: Yup.string().required("Por favor digite as atividades")
});

export default validationSchema;
