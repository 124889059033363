// Geral
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

// Ações
import * as list from "actions/list";
import { fetchList, changeStatus, deleteRecord, openStatisticModal } from "actions/anuncios";

// Componentes
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card";
import CardBody from "components/Card/CardBody";
import RightPanel from "components/RightPanel";
import DynamicTable from "components/DynamicTable";
import ListButtons from "components/ListButtons";
import Pesquisa from "components/Pesquisa";
import Button from "components/CustomButton";
import ListHeader from "components/ListHeader";

// Ícones
import EqualizerIcon from '@material-ui/icons/Equalizer';

// Formulário
import Form from "./form";
import Statistic from "./statistics";

const view = "anuncios";

export default function Anuncios({ params }) {
    useEffect(() => {
        list.start(view, "IdAnuncio", fetchList, changeStatus, deleteRecord);
    }, []);

    const listData = useSelector(state => state.list[view]);

    const form = listData
        ? (
            <Form
                changeStatus={listData.changeStatus}
                deleteRecord={listData.delete}
                afterSave={() => list.afterSaveSuccess(view)}
                afterChangeStatusSuccess={() => list.afterChangeStatusSuccess(view)}
                afterDeleteSuccess={() => list.afterDeleteSuccess(view)}
                id={params ? params.id : null}
            />
        ) : null;
    
    const statisticsForm = (
        <Statistic
            id={params ? params.id : null}
        />
    );

    return (
        <GridContainer>
            {listData && (
                <>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <ListHeader
                                title="Anuncios"
                                add="Novo Anúncio"
                                legend="Anuncios do App"
                                view={view}
                            />
                            <CardBody>
                                <Pesquisa view={view} />
                                <DynamicTable
                                    view={view}
                                    {...listData.tableProps}
                                    isDisabledRow={({ original }) => original.Situacao !== 1}
                                    columns={[
                                        {
                                            Header: "Data/Hora",
                                            accessor: "DataHoraDisplay"
                                        },
                                        {
                                            Header: "Titulo",
                                            accessor: "Titulo"
                                        },
                                        {
                                            Header: "Abrangência",
                                            accessor: "Abrangencia",
                                            Cell: props => {
                                                const status = {
                                                    1: 'Municipal',
                                                    2: 'Estadual',
                                                    3: 'Nacional',
                                                    4: 'Internacional'
                                                }
                                                return status[props.value] || status[1]
                                            }
                                        },
                                        {
                                            Header: "Situação",
                                            accessor: "Situacao",
                                            Cell: props => {
                                                const status = {
                                                    1: 'Cadastrado',
                                                    2: 'Aguardando Pagamento',
                                                    3: 'Pago - Em andamento',
                                                    4: 'Finalizado'
                                                }
                                                return status[props.value] || status[1]
                                            }
                                        },
                                        {
                                            Header: "Publicado",
                                            accessor: "Status",
                                            maxWidth: 150,
                                            style: {
                                                padding: 0
                                            },
                                            Cell: props => 
                                                <ListButtons 
                                                    cellProps={props} 
                                                    view={view} 
                                                    disableEdit={props.original.Situacao !== 1}
                                                    disableRemove={props.original.Situacao !== 1}
                                                    statusLabelActive="Sim"
                                                    statusLabelBlocked="Não"
                                                />
                                        },
                                        {
                                            Header: "Estatísticas",
                                            maxWidth: 100,
                                            style: {
                                                padding: 0,
                                                textAlign: "center"
                                            },
                                            Cell: props => (
                                                <Button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        openStatisticModal(props.original[listData.idField]);
                                                    }}
                                                    color="info"
                                                    link
                                                    size="sm"
                                                    round
                                                    justIcon
                                                    title="Estatísticass"
                                                >
                                                    <EqualizerIcon />
                                                </Button>
                                            )
                                        },
                                    ]}
                                    onFetchData={(state, instance) => list.requestData(view)}
                                    onPageSizeChange={(pageSize, pageIndex) => list.onPageSizeChange(pageSize, pageIndex, view)}
                                    onPageChange={(pageIndex) => list.onPageChange(pageIndex, view)}
                                    onSortedChange={(newSorted, column, shiftKey) => list.onSortedChange(newSorted, column, shiftKey, view)}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>

                    <RightPanel root={listData.root} isPaneOpen={params && params.action === "new"} title="Novo Anúncio" path="new">
                        {form}
                    </RightPanel>
                    <RightPanel root={listData.root} isPaneOpen={params && params.action === "edit"} title="Edição de Anúncio" path="edit">
                        {form}
                    </RightPanel>
                    <RightPanel root={listData.root} isPaneOpen={params && params.action === "viewStatistic"} title="Visualização de estatísticas" path="view">
                        {statisticsForm}
                    </RightPanel>
                </>
            )}
        </GridContainer>
    );
}
