// Geral
import React from "react";
import { useSelector } from "react-redux";

// Ações
import * as list from "actions/list";

// Componentes
import CustomInput from "components/CustomInput";
import SearchLabel from "components/SearchLabel";

export default function Pesquisa(props) {
    const listData = useSelector(state => state.list[props.view]);

    return (
        <CustomInput
            labelText={<SearchLabel />}
            id={`pesquisa-${listData.root}`}
            inputProps={{
                onChange: (e) => { list.handlePesquisa(e, props.view); },
                value: listData.s,
                page: props.view
            }}
            formControlProps={{
                fullWidth: true
            }}
        />
    );
}
