/* Geral / Bibliotecas */
import React, { useState } from "react";
import PropTypes from "prop-types";
import Tab from '@material-ui/core/Tab';
import { JWT_TOKEN } from "helpers/consts";
import jwt_decode from "jwt-decode";

/* Componentes */
import Tabs from "components/Tabs";
import TabPanel from "components/TabPanel";
import { Container } from "assets/jss/doSul-dashboard";
import Card from "components/Card";
import CardBody from "components/Card/CardBody";

/* Formulários */
import FormPessoal from './tabs/pessoal';
import FormMaconicos from './tabs/maconicos';
import FormProfissionais from './tabs/profissionais';
import FormImagens from './tabs/imagens';
import FormOutros from './tabs/outros';

export default function MembrosForm({
    id, form, formData
}) {
    const [tab, setTab] = useState(0);
    const [dadosDisplayMaconicos, setDisplayDadosMaconicos] = useState(formData.model.Tipo === 'I');

    const token = jwt_decode(localStorage.getItem(JWT_TOKEN));

    return (
        <Container>
            <Tabs
                value={tab}
                onChange={(event, newValue) => { setTab(newValue); }}
                variant="scrollable"
                scrollButtons="on"
                aria-label="Tabs de Seleção"
            >
                <Tab label="Pessoal" id="scrollable-force-tab-0" aria-controls="scrollable-force-tabpanel-0" />
                {dadosDisplayMaconicos &&
                    <Tab label="Dados Maçônicos" id="scrollable-force-tab-1" aria-controls="scrollable-force-tabpanel-1" />
                }
                <Tab label="Profissional" id="scrollable-force-tab-2" aria-controls="scrollable-force-tabpanel-2" />
                <Tab label="Imagens" id="scrollable-force-tab-3" aria-controls="scrollable-force-tabpanel-3" />
                {token.platform === 'dashboard' &&
                    <Tab label="Outros" id="scrollable-force-tab-4" aria-controls="scrollable-force-tabpanel-4" />
                }
            </Tabs>

            <TabPanel value={tab} index={0}>
                <Card style={{ margin: 0 }}>
                    <CardBody>
                        <FormPessoal id={id} form={form} formData={formData} setDisplayDadosMaconicos={setDisplayDadosMaconicos} />
                    </CardBody>
                </Card>
            </TabPanel>
            {dadosDisplayMaconicos &&
                <TabPanel value={tab} index={1}>
                    <Card style={{ margin: 0 }}>
                        <CardBody>
                            <FormMaconicos id={id} form={form} formData={formData} />
                        </CardBody>
                    </Card>
                </TabPanel>
            }

            <TabPanel value={tab} index={2}>
                <Card style={{ margin: 0 }}>
                    <CardBody>
                        <FormProfissionais id={id} form={form} formData={formData} />
                    </CardBody>
                </Card>
            </TabPanel>

            <TabPanel value={tab} index={3}>
                <Card style={{ margin: 0 }}>
                    <CardBody>
                        <FormImagens id={id} form={form} formData={formData} />
                    </CardBody>
                </Card>
            </TabPanel>

            {token.platform === 'dashboard' &&
                <TabPanel value={tab} index={4}>
                    <Card style={{ margin: 0 }}>
                        <CardBody>
                            <FormOutros id={id} form={form} formData={formData} />
                        </CardBody>
                    </Card>
                </TabPanel>
            }

        </Container>
    );
}

MembrosForm.propTypes = {
    id: PropTypes.any,
    form: PropTypes.any,
    formData: PropTypes.any
};