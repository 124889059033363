/* Geral / Bibliotecas */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import styled from 'styled-components';
import InputLabel from "@material-ui/core/InputLabel";

/* Métodos / Ações */
import { saveFoto, saveCapa } from "actions/membros";

/* Componentes */
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import ImageUpload from "components/CustomInput/ImageUpload";
import { Container } from "assets/jss/doSul-dashboard";
import Loading from "components/Loading";

/* stilos */
const CustomLabel = styled(InputLabel)`
    text-align: center;
`;

const Model = {
    IdMembro: null,
    Capa: "",
    Foto: ""
};

export default function MembrosImagensForm({
    id, form, formData
}) {
    const permissions = useSelector(state => state.permissions);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(form.setAfterSave(() => { }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // É chamado toda vez que muda o ID
        form.setModel(Model);
        if (id) {
            form.fetchData(id);
        }
    }, [id, form]);

    if (formData.loading) {
        return <Loading />;
    }

    const disabled = permissions && permissions[formData.root] < 2;

    const handleSubmitFoto = (values, actions) => {
        values.Foto = values.FotoChanged;
        formData.save(values, actions, formData.afterSave);
    };

    const handleSubmitCapa = (values, actions) => {
        values.Capa = values.CapaChanged;
        formData.save(values, actions, formData.afterSave);
    };

    return (
        <Container>
            <Formik
                initialValues={formData.model}
                onSubmit={handleSubmitFoto}
                render={FormikProps => (
                    <Form onSubmit={FormikProps.handleSubmit}>
                        <GridContainer>
                            <GridItem xs={12} style={{ marginTop: "50px" }}>
                                <CustomLabel>Foto</CustomLabel>
                                <ImageUpload
                                    extraData={{ path: "membros" }}
                                    singleImage
                                    image={FormikProps.values.Foto}
                                    afterLoad={response => {
                                        if (!disabled) {
                                            dispatch(form.setSave(saveFoto));

                                            FormikProps.setFieldValue("Foto", response.url);
                                            FormikProps.setFieldValue("FotoChanged", response.name);
                                            FormikProps.submitForm();
                                        }
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                    </Form>
                )
                }
            />
            <Formik
                initialValues={formData.model}
                onSubmit={handleSubmitCapa}
                render={FormikProps => (
                    <Form onSubmit={FormikProps.handleSubmit}>
                        <GridContainer style={{ marginTop: '30px' }}>
                            <GridItem xs={12} style={{ marginTop: "50px" }}>
                                <CustomLabel>Capa</CustomLabel>
                                <ImageUpload
                                    extraData={{ path: "membros" }}
                                    singleImage
                                    image={FormikProps.values.Capa}
                                    afterLoad={response => {
                                        if (!disabled) {
                                            dispatch(form.setSave(saveCapa));

                                            FormikProps.setFieldValue("Capa", response.url);
                                            FormikProps.setFieldValue("CapaChanged", response.name);
                                            FormikProps.submitForm();
                                        }
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                    </Form>
                )
                }
            />
        </Container >
    );
}

MembrosImagensForm.propTypes = {
    id: PropTypes.any,
    form: PropTypes.any,
    formData: PropTypes.any
};