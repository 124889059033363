import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import countries from "world-countries";
import FormSelect from "components/FormSelect";

export default function PaisTelefone({ pais, codigo, form, name }) {
	const selecionado = useMemo(() => {
		let sel = countries.filter((val) => val.cca2 === pais);
		if (sel && sel.length > 0) {
			return sel[0];
		}
		return null;
	}, [pais]);

	const loadOptions = useCallback((search)=>{
		return new Promise((resolve, reject) => {
			const filteredCountries = countries.filter((country) => {
				if (country.idd.suffixes[0] === undefined) {
					return false;
				}

				if (!search) return true;

				return new RegExp(search, 'gi').test(country.name.common)
				 || new RegExp(search, 'gi').test(`${country.idd.root}${country.idd.suffixes[0]}`);
			})
			resolve(filteredCountries);
		});
	}, []);

	return (
		<FormSelect
			field={{
				name: `${name}PaisCodigo`,
				placeholder:
					"+00",
				isSearchable: true,
				isClearable: false,
				defaultOptions: true,
				loadOptions: loadOptions,
				getOptionLabel: (option) =>
					`${option.flag} ${option.idd.root}${option.idd.suffixes[0]}`,
				loadingMessage: () =>
					"Carregando...",
				noOptionsMessage: (input) =>
					input.inputValue === ""
						? "+00"
						: "Nenhum resultado encontrado",
				value: selecionado,
				onChange: (value) => {
					if (value) {
						form.setFieldValue(`${name}Pais`, value.cca2);
						form.setFieldValue(
							`${name}PaisCodigo`,
							`${value.idd.root}${value.idd.suffixes[0]}`
						);
					} else {
						form.setFieldValue(`${name}Pais`, null);
						form.setFieldValue(`${name}PaisCodigo`, null);
					}
				},
				className: "noMarginBottom"
			}}
			form={form}
			async
			label="   "
		/>
	);
}

PaisTelefone.propTypes = {
	pais: PropTypes.string,
	codigo: PropTypes.string,
	form: PropTypes.object.isRequired,
};

PaisTelefone.defaultProps = {
	pais: null,
	codigo: null,
	name: "Telefone",
};
