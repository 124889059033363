import styled from 'styled-components';
import FormHelperText from "@material-ui/core/InputLabel";
import Editor from 'components/Editor';
import { Theme } from "assets/jss/doSul-dashboard";

export const CustomEditor = styled(Editor)``

export const HelperTextError = styled(FormHelperText)`
    color: ${Theme.dangerColor[0]}!important;
    font-size: 12px!important;
`