/* Geral / Bibliotecas */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import styled from "styled-components";
import { navigate } from "hookrouter";

/* Ícones */
import Save from "@material-ui/icons/Save";
import Done from "@material-ui/icons/Done";
import Block from "@material-ui/icons/Block";
import Delete from "@material-ui/icons/Delete";

/* Métodos / Ações */
import * as form from "actions/form";
import { fetch, save } from "actions/denuncias";

/* Componentes */
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import FormInput from "components/FormInput";
import Button from "components/CustomButton";
import { Container } from "assets/jss/doSul-dashboard";
import Loading from "components/Loading";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import Launch from "@material-ui/icons/Launch";

/* Validação */
import validationSchema from "./validation";

/* stilos */
const CustomList = styled(List)`
    li{ padding: 0px;}
`;

const Model = {
    IdDenuncia: null,
    membro: null,
    membroDen: null,
    entidadeDen: null,
    empresaDen: null,
    lojaDen: null,
    Titulo: "",
    Texto: "",
    Resposta: "",
    Tipo: "",
    NomeDenunciado: "",
    nomeMembro: "",
    Status: 1
};

export default function LojasForm({
    id, changeStatus, deleteRecord, afterSave, afterChangeStatusSuccess, afterDeleteSuccess
}) {
    const formData = useSelector(state => state.form);
    const permissions = useSelector(state => state.permissions);

    const dispatch = useDispatch();

    const afterFetch = (data) => {
        const model = data.result;
        // etc
        dispatch(form.setModel(model));
        dispatch(form.setLoading(false));
    }; // Sobrescrever se precisar

    useEffect(() => {
        form.start("denuncias", "IdDenuncia", id, Model, fetch, save, changeStatus, deleteRecord, afterSave, afterChangeStatusSuccess, afterDeleteSuccess, afterFetch);
        return form.stop;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // É chamado toda vez que muda o ID
        form.setModel(Model);
        if (id) {
            form.fetchData(id);
        }
    }, [id]);

    const handleSubmit = (values, actions) => {
        formData.save(values, actions, formData.afterSave);
    };

    if (formData.loading) {
        return <Loading />;
    }

    const disabled = permissions && permissions[formData.root] < 2;

    const tipoDenuncia = (tipo) => {
        switch (tipo) {
            case 'membro': return "Denúncia de Membro";
            case 'loja': return "Denúncia de Loja";
            case 'empresa': return "Denúncia de Empresa";
            case 'entidade': return "Denúncia de Entidade";
            case 'publicacao': return "Denúncia de Publicação";
            case 'comentario': return "Denúncia de Comentário";
            default: return "";
        }
    }

    return (
        <Container>
            <Formik
                validationSchema={validationSchema}
                initialValues={formData.model}
                onSubmit={handleSubmit}
                render={FormikProps => (
                    <Form onSubmit={FormikProps.handleSubmit}>
                        <GridContainer>
                            {FormikProps.values.DataHoraDisplay &&
                                <GridItem xs={12} lg={6}>
                                    <CustomList subheader={<li />}>
                                        <li>
                                            <ul>
                                                <ListSubheader>Data/Hora</ListSubheader>
                                                <ListItem>
                                                    <ListItemText primary={`${FormikProps.values.DataHoraDisplay}`} />
                                                </ListItem>
                                                <Divider />
                                            </ul>
                                        </li>
                                    </CustomList>
                                </GridItem>
                            }
                            <GridItem xs={12} lg={6}>
                                <CustomList subheader={<li />}>
                                    <li>
                                        <ul>
                                            <ListSubheader>Membro&nbsp;<small>(Quem fez a denúncia)</small></ListSubheader>
                                            <ListItem>
                                                <ListItemText primary={`${FormikProps.values.nomeMembro}`} />
                                            </ListItem>
                                            <Divider />
                                        </ul>
                                    </li>
                                </CustomList>
                            </GridItem>
                            <GridItem xs={12} lg={6}>
                                <CustomList subheader={<li />}>
                                    <li>
                                        <ul>
                                            <ListSubheader>Tipo de Denúncia</ListSubheader>
                                            <ListItem>
                                                <ListItemText primary={`${tipoDenuncia(FormikProps.values.Tipo)}`} />
                                            </ListItem>
                                            <Divider />
                                        </ul>
                                    </li>
                                </CustomList>
                            </GridItem>
                            <GridItem xs={12}>
                                <CustomList subheader={<li />}>
                                    <li>
                                        {FormikProps.values.Tipo === 'membro' && (
                                            <ul>
                                                <ListSubheader>Membro Denunciado</ListSubheader>
                                                <ListItem>
                                                    <ListItemText primary={<>{FormikProps.values.NomeDenunciado}&nbsp;<Launch fontSize="small" onClick={() => {
                                                        navigate(`/membros/edit/${FormikProps.values.MembroDen}`);
                                                    }} /></>} />
                                                </ListItem>
                                                <Divider />
                                            </ul>
                                        )}
                                        {FormikProps.values.Tipo === 'empresa' && (
                                            <ul>
                                                <ListSubheader>Empresa Denunciada</ListSubheader>
                                                <ListItem>
                                                    <ListItemText primary={<>{FormikProps.values.NomeDenunciado}&nbsp;<Launch fontSize="small" onClick={() => {
                                                        navigate(`/empresas/edit/${FormikProps.values.EmpresaDen}`);
                                                    }} /></>} />
                                                </ListItem>
                                                <Divider />
                                            </ul>
                                        )}
                                        {FormikProps.values.Tipo === 'entidade' && (
                                            <ul>
                                                <ListSubheader>Entidade Denunciada</ListSubheader>
                                                <ListItem>
                                                    <ListItemText primary={<>{FormikProps.values.NomeDenunciado}&nbsp;<Launch fontSize="small" onClick={() => {
                                                        navigate(`/entidades/edit/${FormikProps.values.EntidadeDen}`);
                                                    }} /></>} />
                                                </ListItem>
                                                <Divider />
                                            </ul>
                                        )}
                                        {FormikProps.values.Tipo === 'loja' && (
                                            <ul>
                                                <ListSubheader>Loja Denunciada</ListSubheader>
                                                <ListItem>
                                                    <ListItemText primary={<>{FormikProps.values.NomeDenunciado}&nbsp;<Launch fontSize="small" onClick={() => {
                                                        navigate(`/lojas/edit/${FormikProps.values.LojaDen}`);
                                                    }} /></>} />
                                                </ListItem>
                                                <Divider />
                                            </ul>
                                        )}
                                        {FormikProps.values.Tipo === 'publicacao' && (
                                            <ul>
                                                <ListSubheader>Publicação Denunciada</ListSubheader>
                                                <ListItem>
                                                    <ListItemText primary={<>{FormikProps.values.NomeDenunciado}&nbsp;<Launch fontSize="small" onClick={() => {
                                                        navigate(`/publicacoes/edit/${FormikProps.values.PublicacaoDen}`);
                                                    }} /></>} />
                                                </ListItem>
                                                <Divider />
                                            </ul>
                                        )}
                                        {FormikProps.values.Tipo === 'comentario' && (
                                            <ul>
                                                <ListSubheader>Comentário Denunciado</ListSubheader>
                                                <ListItem>
                                                    <ListItemText primary={<>{FormikProps.values.NomeDenunciado}&nbsp;<Launch fontSize="small" onClick={() => {
                                                        navigate(`/publicacoes/edit/${FormikProps.values.PublicacaoDen}`, false, { denuncia: FormikProps.values.PublicacaoComentarioDen });
                                                    }} /></>} />
                                                </ListItem>
                                                <Divider />
                                            </ul>
                                        )}
                                    </li>
                                </CustomList>
                            </GridItem>
                            <GridItem xs={12}>
                                <CustomList subheader={<li />}>
                                    <li>
                                        <ul>
                                            <ListSubheader>Título da Denúncia</ListSubheader>
                                            <ListItem>
                                                <ListItemText primary={`${FormikProps.values.Titulo}`} />
                                            </ListItem>
                                            <Divider />
                                        </ul>
                                    </li>
                                </CustomList>
                            </GridItem>
                            <GridItem xs={12}>
                                <CustomList subheader={<li />}>
                                    <li>
                                        <ul>
                                            <ListSubheader>Texto da Denúncia</ListSubheader>
                                            <ListItem>
                                                <ListItemText primary={`${FormikProps.values.Texto}`} />
                                            </ListItem>
                                            <Divider />
                                        </ul>
                                    </li>
                                </CustomList>
                            </GridItem>
                            <GridItem xs={12}>
                                <FormInput
                                    field={{
                                        name: "Resposta",
                                        disabled,
                                        multiline: true,
                                        rows: 5
                                    }}
                                    form={FormikProps}
                                    label="Resposta *"
                                />
                            </GridItem>

                            <GridItem xs={12}>
                                <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                                    {!disabled && (
                                        <Button color="success" type="submit" disabled={FormikProps.isSubmitting}>
                                            <Save />
                                            {" "}
                                            Salvar
                                        </Button>
                                    )}
                                    {!disabled && formData.model[formData.idField] && (
                                        <Button color="danger" type="button" right onClick={form.handleDelete}>
                                            <Delete />
                                            {" "}
                                            Excluir
                                        </Button>
                                    )}
                                    {!disabled && formData.model[formData.idField] && formData.model.Status === 0 && (
                                        <Button
                                            color="info"
                                            type="button"
                                            right
                                            onClick={() => {
                                                form.handleChangeStatus(1);
                                            }}
                                        >
                                            <Done />
                                            {" "}
                                            Aceitar denúncia (Bloquear {tipoDenuncia(FormikProps.values.Tipo).replace('Denúncia de ', '')})
                                        </Button>
                                    )}
                                    {!disabled && formData.model[formData.idField] && formData.model.Status === 1 && (
                                        <Button
                                            color="warning"
                                            type="button"
                                            right
                                            onClick={() => {
                                                form.handleChangeStatus(0);
                                            }}
                                        >
                                            <Block />
                                            {" "}
                                            Recusar denúncia (Desbloquear {tipoDenuncia(FormikProps.values.Tipo).replace('Denúncia de ', '')})
                                        </Button>
                                    )}
                                </div>
                            </GridItem>
                        </GridContainer>
                    </Form>
                )}
            />
        </Container>
    );
}

LojasForm.propTypes = {
    id: PropTypes.any,
    afterSave: PropTypes.func,
    changeStatus: PropTypes.func,
    deleteRecord: PropTypes.func,
    afterChangeStatusSuccess: PropTypes.func,
    afterDeleteSuccess: PropTypes.func
};
