// Geral
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

// Ações
import * as list from "actions/list";
import {
    fetchListMembros,
    changeStatusMembros,
    deleteRecordMembros,
    addMembroLoja,
} from "actions/lojas";
import { loadOptionsIrmaos as loadOptionsMembros } from "actions/membros";

// Componentes
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card";
import CardBody from "components/Card/CardBody";
import DynamicTable from "components/DynamicTable";
import Pesquisa from "components/Pesquisa";
import FormSelect from "components/FormSelect";
import { Formik, Form } from "formik";
import { Container } from "assets/jss/doSul-dashboard";
import AddIcon from "@material-ui/icons/Add";
import Button from "components/CustomButton";

// Ícones
import ListButtonsSubList from "components/ListButtonsSubList";

const view = "membroLoja";

export default function LojasListMembros({ id }) {
    useEffect(() => {
        list.start(
            view,
            "IdMembro",
            fetchListMembros,
            changeStatusMembros,
            deleteRecordMembros,
            id
        );
    }, [id]);

    const listData = useSelector((state) => state.list[view]);

    return (
        <Container>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            {listData && (
                                <>
                                    <Formik
                                        onSubmit={(values, { resetForm }) => {
                                            addMembroLoja(values.Membro, () => {
                                                list.requestData(view);
                                            });
                                            resetForm({});
                                        }}
                                        render={(FormikProps) => (
                                            <Form
                                                onSubmit={
                                                    FormikProps.handleSubmit
                                                }
                                            >
                                                <GridContainer
                                                    alignContent="space-between"
                                                    alignItems="flex-end"
                                                >
                                                    <GridItem xs>
                                                        <FormSelect
                                                            field={{
                                                                name: "membro",
                                                                placeholder:
                                                                    "Digite para localizar...",
                                                                isSearchable: true,
                                                                loadOptions: loadOptionsMembros,
                                                                getOptionLabel: (
                                                                    option
                                                                ) =>
                                                                    `${option.Nome}`,
                                                                loadingMessage: () =>
                                                                    "Carregando...",
                                                                noOptionsMessage: (
                                                                    input
                                                                ) =>
                                                                    input.inputValue ===
                                                                    ""
                                                                        ? "Digite para localizar..."
                                                                        : "Nenhum resultado encontrado",
                                                                value: FormikProps
                                                                    .values
                                                                    .Membro
                                                                    ? {
                                                                          IdMembro:
                                                                              FormikProps
                                                                                  .values
                                                                                  .Membro,
                                                                          Nome:
                                                                              FormikProps
                                                                                  .values
                                                                                  .nomeMembro,
                                                                      }
                                                                    : null,
                                                                onChange: (
                                                                    value
                                                                ) => {
                                                                    FormikProps.setFieldValue(
                                                                        "Membro",
                                                                        value.IdMembro
                                                                    );
                                                                    FormikProps.setFieldValue(
                                                                        "nomeMembro",
                                                                        value.Nome
                                                                    );
                                                                },
                                                            }}
                                                            form={FormikProps}
                                                            async
                                                            label="Adicionar membro"
                                                        />
                                                    </GridItem>
                                                    <GridItem xs="auto">
                                                        <Button
                                                            color="success"
                                                            type="submit"
                                                            disabled={
                                                                FormikProps.isSubmitting
                                                            }
                                                        >
                                                            <AddIcon />
                                                        </Button>
                                                    </GridItem>
                                                </GridContainer>
                                            </Form>
                                        )}
                                    />
                                    <Pesquisa view={view} />
                                    <DynamicTable
                                        {...listData.tableProps}
                                        columns={[
                                            {
                                                Header: "Nome",
                                                accessor: "Nome",
                                            },
                                            {
                                                Header: "Administrador",
                                                accessor: "Detalhes",
                                                maxWidth: 150,
                                                style: {
                                                    padding: 0,
                                                },
                                                Cell: (props) => (
                                                    <ListButtonsSubList
                                                        cellProps={props}
                                                        view={view}
                                                        style={{
                                                            display: "inline",
                                                        }}
                                                        root="lojas"
                                                    />
                                                ),
                                            },
                                        ]}
                                        clickable={false}
                                        view={view}
                                        onFetchData={(state, instance) =>
                                            list.requestData(view)
                                        }
                                        onPageSizeChange={(
                                            pageSize,
                                            pageIndex
                                        ) =>
                                            list.onPageSizeChange(
                                                pageSize,
                                                pageIndex,
                                                view
                                            )
                                        }
                                        onPageChange={(pageIndex) =>
                                            list.onPageChange(pageIndex, view)
                                        }
                                        onSortedChange={(
                                            newSorted,
                                            column,
                                            shiftKey
                                        ) =>
                                            list.onSortedChange(
                                                newSorted,
                                                column,
                                                shiftKey,
                                                view
                                            )
                                        }
                                    />
                                </>
                            )}
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </Container>
    );
}
