/* eslint-disable max-len */
/* Geral / Bibliotecas */
import React, { useEffect, useState } from "react";
import moment from 'moment';

import { useSelector, useDispatch } from "react-redux";

/* Métodos / Ações */
import * as form from "actions/form";
import { fetch, loadEstatistica } from "actions/anuncios";

/* Componentes */
import { Container } from "assets/jss/doSul-dashboard";
import Loading from "components/Loading";
import Card from "components/Card";

/* Graficos */
import StatisticForm from "./Graficos/form";
import GenderGraph from "./Graficos/gender";
import LocationGraph from "./Graficos/location";
import AgeGraph from "./Graficos/age";

const Model = {};

export default function EstatisticasForm({ id }) {
    /* Controle das datas */
    let today = moment();
    let removeDays = today.subtract(30, 'days');
    
    const [dateStart, setDateStart] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(removeDays);

    const notifyStatistic = ({ dateStart, dateEnd }) => {
        setDateStart(dateStart);
        setDateEnd(dateEnd);

        GetEstatistica({ id: atob(id), dateStart, dateEnd });
    }
    /* */

    const [allStatistics, setAllStatistics] = useState({
        gender: [],
        location: [],
        age: []
    });
    

    const formData = useSelector(state => state.form);

    const dispatch = useDispatch();

    const afterFetch = (data) => {
        const model = data.result;
        
        dispatch(form.setModel(model));
        dispatch(form.setLoading(false));
    };

    useEffect(() => {
        // form.start("anuncios", "IdAnuncio", id, Model, fetch, GetEstatistica, afterFetch);
        form.start("anuncios", "IdAnuncio", id, Model, fetch, afterFetch);

        /* Chama a funcao ja com o id decodificado */
        GetEstatistica({ id: atob(id) });
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (formData.loading) {
        return <Loading />;
    }
    
    async function GetEstatistica ({ id, dateStart, dateEnd } ) {
        //formData.model
        /* 
            1 - Consulta as estatisticas por genero 
            2 - Consulta as estatisticas por localidade
            3 - Consulta as estatisticas por faixa etária 
        */
        let options = [
            { tipo: 1, opcao: 'gender' },
            { tipo: 2, opcao: 'location' },
            { tipo: 3, opcao: 'age' },
        ];
        
        /* Cria uma variavel cujo escopo seja global */
        var obj = [];

        for(let option of options) {
            /* Destructuring dos itens */
            let { tipo, opcao } = option;
            
            /* Consulta as estatisticas */
            let res = await loadEstatistica({ 
                tipo, 
                concept: id,
                dateStart,
                dateEnd
            });

            /* Armazena os resultados com a chave dinamica */
            obj.push({ [opcao] : res })
            //obj.push({ [opcao] : res[0] })
        }
        
        /* Faz um merge de todos os resultados obtidos anteriormente */
        setAllStatistics({ ...allStatistics, ...Object.assign({}, ...obj) })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    
    /* Spread das propriedades de allStatistics */
    let { gender, location, age } = allStatistics;

    /* */
    return (
        <Container>
            <Card style={{ textAlign: 'center' }}>
                <h3 style={{ margin: '10px' }}>{ formData.model.nomeEmpresa }</h3>

                <StatisticForm notifyStatistic={notifyStatistic} />
            </Card>

            { gender && Object.keys(gender).length > 0 && <GenderGraph title='Estatística por sexo' dataGraph={gender} /> }
            { location && <LocationGraph title='Estatística por localidade' dataGraph={location} /> }
            { age && Object.keys(age).length > 0 && <AgeGraph title='Alcance por Faixa Etária' dataGraph={age} /> }
        </Container>
    );
}
