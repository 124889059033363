import React from "react";
import CountUp from 'react-countup';
import jwt_decode from 'jwt-decode';
import { JWT_TOKEN } from "helpers/consts";
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Idiomas */
import { Trans } from 'react-i18next';

// Ícones
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import StorefrontIcon from '@material-ui/icons/Storefront';
import ShareIcon from '@material-ui/icons/Share';
import PeopleIcon from '@material-ui/icons/People';
import EventNoteIcon from '@material-ui/icons/EventNote';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import SchoolIcon from '@material-ui/icons/School';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

// Componentes
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";

// Ações / Métodos
import { fetchList as fetchAgendaList } from "actions/agenda";
import { fetchList as fetchAnunciosList } from "actions/anuncios";
import { fetchList as fetchComprasList } from "actions/compras";
import { fetchList as fetchDenunciasList } from "actions/denuncias";
import { fetchList as fetchEmpresasList } from "actions/empresas";
import { fetchList as fetchEntidadesList } from "actions/entidades";
import { fetchList as fetchLojasList } from "actions/lojas";
import { fetchList as fetchMembrosList } from "actions/membros";
import { fetchList as fetchPlanosList } from "actions/planos";
import { fetchList as fetchPotenciasList } from "actions/potencias";
import { fetchList as fetchPublicacoesList } from "actions/publicacoes";
import { fetchList as fetchUsuariosList } from "actions/usuarios";

// Estilos
import { Theme } from "assets/jss/doSul-dashboard";
import { CardCategory, CardTitle } from "./styles";

class Dashboard extends React.Component {
    constructor() {
        super()

        this.state = {
            blocos: {
                anuncios: {
                    label: <Trans i18nKey="home.buttons.adverts">Anúncios</Trans>,
                    method: fetchAnunciosList,
                    icon: <PhotoAlbumIcon />,
                    value: 0
                },
                compras: {
                    label: <Trans i18nKey="home.buttons.purchases">Compras</Trans>,
                    method: fetchComprasList,
                    icon: <ShoppingBasketIcon />,
                    value: 0
                },
                empresas: {
                    label: <Trans i18nKey="home.buttons.companies">Empresas</Trans>,
                    method: fetchEmpresasList,
                    icon: <StorefrontIcon />,
                    value: 0
                },
                planos: {
                    label: <Trans i18nKey="home.buttons.plans">Planos</Trans>,
                    method: fetchPlanosList,
                    icon: <AddToPhotosIcon />,
                    value: 0
                },
                publicacoes: {
                    label: <Trans i18nKey="home.buttons.publications">Publicações</Trans>,
                    method: fetchPublicacoesList,
                    icon: <ShareIcon />,
                    value: 0
                },
                membros: {
                    label: <Trans i18nKey="home.buttons.members">Membros</Trans>,
                    method: fetchMembrosList,
                    icon: <PeopleIcon />,
                    value: 0
                },
                agenda: {
                    label: <Trans i18nKey="home.buttons.commitments">Compromissos</Trans>,
                    method: fetchAgendaList,
                    icon: <EventNoteIcon />,
                    value: 0
                },

                denuncias: {
                    label: <Trans i18nKey="home.buttons.complaints">Denúncias</Trans>,
                    method: fetchDenunciasList,
                    icon: <SmsFailedIcon />,
                    value: 0
                },
                lojas: {
                    label: <Trans i18nKey="home.buttons.masonic-stores">Lojas Maçônicas</Trans>,
                    method: fetchLojasList,
                    icon: <AccountBalanceIcon />,
                    value: 0
                },
                potencias: {
                    label: <Trans i18nKey="home.buttons.masonic-powers">Potências</Trans>,
                    method: fetchPotenciasList,
                    icon: <HomeWorkIcon />,
                    value: 0
                },
                entidades: {
                    label: <Trans i18nKey="home.buttons.entities">Entidades</Trans>,
                    method: fetchEntidadesList,
                    icon: <SchoolIcon />,
                    value: 0
                },
                usuarios: {
                    label: <Trans i18nKey="home.buttons.users">Usuários</Trans>,
                    method: fetchUsuariosList,
                    icon: <AccountCircleIcon />,
                    value: 0
                },
            }
        };
    }

    fetch = (method, stateProp) => {
        var promise = new Promise((res, rej) => {
            method({
                pgSize: 1,
                pg: 1
            }, res, rej);
        });

        promise.then((data) => {
            let tmpBlocos = this.state.blocos;
            tmpBlocos[stateProp].value = data.total;

            this.setState({
                blocos: tmpBlocos
            });
        });
        return;
    }

    componentDidMount() {
        for (var key in this.state.blocos) {
            if (!this.state.blocos.hasOwnProperty(key)) continue;

            var obj = this.state.blocos[key];
            if (this.props.permissions && this.props.permissions[key] > 0) {
                this.fetch(obj.method, key);
            }
        }
    }

    componentDidUpdate(nextProps) {
        if (nextProps.permissions !== this.props.permissions) {
            for (var key in this.state.blocos) {
                if (!this.state.blocos.hasOwnProperty(key)) continue;

                var obj = this.state.blocos[key];
                if (this.props.permissions && this.props.permissions[key] > 0) {
                    this.fetch(obj.method, key);
                }
            }
        }
    }

    getNome = () => {
        const token = jwt_decode(localStorage.getItem(JWT_TOKEN));
        return token && token.nome ? token.nome.split(' ')[0] : '';
    }

    montaBlocos = () => {
        const token = jwt_decode(localStorage.getItem(JWT_TOKEN));
        let tmpHtml = [];

        let idx = 0;
        for (var key in this.state.blocos) {
            if (!this.state.blocos.hasOwnProperty(key)) continue;

            var obj = this.state.blocos[key];
            if (this.props.permissions && this.props.permissions[key] > 0) {
                if (token.platform === "dashboard" || (token.platform === "membro" && key !== 'membros')) {
                    tmpHtml.push(<GridItem key={idx} xs={12} sm={6} md={3}>
                        <Card>
                            <CardHeader color="success" stats icon>
                                <CardIcon color="success">
                                    {obj.icon}
                                </CardIcon>
                                <CardCategory>{obj.label}</CardCategory>
                                <CardTitle><CountUp end={obj.value} duration={1.5} /></CardTitle>
                            </CardHeader>
                        </Card>
                    </GridItem>);
                    idx += 1;
                }
            }

        }

        return tmpHtml;
    }

    render() {
        return (
            <div>
                <GridContainer style={{ justifyContent: 'center' }}>
                    <GridItem xs={12}>
                        <h3 style={{ textAlign: 'center', color: Theme.primaryColor[0] }}>
                            {(new Date().getHours() > 0 && new Date().getHours() < 13) &&
                                <>
                                    <Trans i18nKey="home.welcome.good-morning">Bom dia</Trans> 
                                    <strong> { this.getNome() }</strong>!
                                </>
                            }
                            {(new Date().getHours() >= 13 && new Date().getHours() < 19) &&
                                <>
                                    <Trans i18nKey="home.welcome.good-evening">Boa tarde</Trans> 
                                    <strong> { this.getNome() }</strong>!
                                </>
                            }
                            {(new Date().getHours() >= 19) &&
                                <>
                                    <Trans i18nKey="home.welcome.good-night">Boa noite</Trans> 
                                    <strong> { this.getNome() }</strong>!
                                </>
                            }
                        </h3>
                    </GridItem>

                    {this.montaBlocos()}

                </GridContainer>
            </div>
        );
    }
}
Dashboard.propTypes = {
    permissions: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => ({
    permissions: store.permissions
});

export default connect(mapStateToProps)(Dashboard);
