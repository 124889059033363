// Geral
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Favorite from '@material-ui/icons/Favorite';
import ChatBubble from '@material-ui/icons/ChatBubble';

// Ações
import * as list from "actions/list";
import { fetchList, changeStatus, deleteRecord } from "actions/publicacoes";

// Componentes
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card";
import CardBody from "components/Card/CardBody";
import RightPanel from "components/RightPanel";
import DynamicTable from "components/DynamicTable";
import ListButtons from "components/ListButtons";
import Pesquisa from "components/Pesquisa";
import ListHeader from "components/ListHeader";

// Formulário
import Form from "./form";

const view = "publicacoes";

export default function Publicacoes({ params }) {
    useEffect(() => {
        list.start(view, "IdPublicacao", fetchList, changeStatus, deleteRecord);
    }, []);

    const listData = useSelector(state => state.list[view]);

    const form = listData
        ? (
            <Form
                changeStatus={listData.changeStatus}
                deleteRecord={listData.delete}
                afterSave={() => list.afterSaveSuccess(view)}
                afterChangeStatusSuccess={() => list.afterChangeStatusSuccess(view)}
                afterDeleteSuccess={() => list.afterDeleteSuccess(view)}
                id={params ? params.id : null}
            />
        ) : null;

    return (
        <GridContainer>
            {listData && (
                <>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <ListHeader
                                title="Publicacoes"
                                add={false}
                                legend="Publicações do App"
                                view={view}
                            />
                            <CardBody>
                                <Pesquisa view={view} />
                                <DynamicTable view={view}
                                    {...listData.tableProps}
                                    columns={[
                                        {
                                            Header: "Data/Hora",
                                            accessor: "DataHoraDisplay",
                                            maxWidth: 180
                                        },
                                        {
                                            Header: "Tipo",
                                            accessor: "Tipo",
                                            maxWidth: 100,
                                            Cell: props => {
                                                switch (props.original.Tipo) {
                                                    case 'membro': return 'Membro';
                                                    case 'loja': return 'Loja';
                                                    case 'potencia': return 'Potência';
                                                    case 'entidade': return 'Entidade';
                                                    case 'empresa': return 'Empresa';
                                                    default: return '';
                                                }
                                            }
                                        },
                                        {
                                            Header: "Responsável",
                                            accessor: "Responsavel"
                                        },
                                        {
                                            Header: "Informações",
                                            accessor: "Visualizacoes",
                                            Cell: props => (<>
                                                <VisibilityIcon fontSize="small" /> {props.original.Visualizacoes}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <Favorite fontSize="small" /> {props.original.Curtidas}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <ChatBubble fontSize="small" /> {props.original.Comentarios}
                                            </>)
                                        },
                                        {
                                            Header: "Status",
                                            accessor: "Status",
                                            maxWidth: 150,
                                            style: {
                                                padding: 0
                                            },
                                            Cell: props => <ListButtons cellProps={props} view={view} />
                                        }
                                    ]}
                                    onFetchData={(state, instance) => list.requestData(view)}
                                    onPageSizeChange={(pageSize, pageIndex) => list.onPageSizeChange(pageSize, pageIndex, view)}
                                    onPageChange={(pageIndex) => list.onPageChange(pageIndex, view)}
                                    onSortedChange={(newSorted, column, shiftKey) => list.onSortedChange(newSorted, column, shiftKey, view)}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>

                    <RightPanel root={listData.root} isPaneOpen={params && params.action === "new"} title="Nova Publicação" path="new">
                        {form}
                    </RightPanel>
                    <RightPanel root={listData.root} isPaneOpen={params && params.action === "edit"} title="Detalhes de Publicação" path="edit">
                        {form}
                    </RightPanel>
                </>
            )}
        </GridContainer>
    );
}
