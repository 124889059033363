import styled from 'styled-components'

import { Theme } from 'assets/jss/doSul-dashboard'

export const Footer = styled.footer`
    bottom: 0
    border-top: 1px solid ${Theme.grayColor[11]}
    padding: 15px 0
`

export const Left = styled.div`
    float: left!important
    display: block
`

export const Right = styled.p`
    padding: 15px 0
    margin: 0
    font-size: 14px
    float: right!important
`