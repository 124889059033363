import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Tilt from "react-tilt";
import classnames from "classnames";
import IconMail from "@material-ui/icons/Mail";
import IconLock from "@material-ui/icons/Lock";
import { loginUser } from "actions/auth";
// import Loading from '../components/Loading'
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { clearErrors } from "actions/errors";

import logo from "assets/img/logoDark.png";
import logoAdm from "assets/img/logoDarkAdm.png";
import "./styles.css";

const MySwal = withReactContent(Swal);

class Login extends Component {
    constructor(props) {
        super();
        this.state = {
            email: "",
            password: "",
            adm: props.adm,
            loading: true,
            errors: {}
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    componentDidMount() {
        this.props.clearErrors();
        if (this.props.auth.isAuthenticated) {
            this.props.history.push(`${process.env.PUBLIC_URL}/`);
            return;
        }
        this.setState({
            loading: false
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            window.location.href = `${process.env.PUBLIC_URL}/`;
        }
        if (nextProps.errors) {
            let show = false;
            if (nextProps.errors.message) {
                show = true;
            }
            this.setState({
                errors: nextProps.errors,
                loading: false
            });
            if (show) {
                const msg = nextProps.errors.message;
                MySwal.fire({
                    title: "Oops!",
                    type: "error",
                    text: msg,
                    showCancelButton: false,
                    showConfirmButton: true,
                    confirmButtonText: "OK"
                });
                this.props.clearErrors();
            }
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const user = {
            email: this.state.email,
            password: this.state.password,
            adm: this.state.adm
        };
        this.setState({
            loading: true
        });
        this.props.loginUser(user);
    }


    handleInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        const { errors } = this.state;
        return (
            <div className="limiter">
                {/*
                    <Loading active={this.state.loading}></Loading>
                    */}
                <div className="container-login100">
                    <div className="wrap-login100">
                        <Tilt
                            className="Tilt login100-pic js-tilt"
                            options={{ max: 25 }}
                            style={{
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            <img
                                src={this.state.adm ? logoAdm : logo}
                                alt="FraternalHug"
                                style={
                                    { transform: "translateY(-5%)" }
                                }
                            />
                        </Tilt>

                        <form onSubmit={this.handleSubmit} className="login100-form">
                            <span className="login100-form-title">
                                <>
                                    Login
                                    {this.state.adm ? " de Administrador " : " de Membro "}
                                    <a href={`${process.env.PUBLIC_URL}/login${this.state.adm ? "" : "/adm"}`} rel="noopener noreferrer" className="txt2">Trocar</a>
                                </>
                            </span>

                            <div className="wrap-input100">
                                <input
                                    className={classnames("input100", {
                                        "is-invalid": errors.email
                                    })}
                                    type="text"
                                    name="email"
                                    autoComplete="username"
                                    placeholder="E-mail"
                                    onChange={this.handleInputChange}
                                    value={this.state.email}
                                />
                                <span className="focus-input100" />
                                <span className="symbol-input100">
                                    <IconMail />
                                </span>
                            </div>
                            {errors.email && (<div className="invalid-feedback">{errors.email}</div>)}

                            <div className="wrap-input100">
                                <input
                                    className={classnames("input100", {
                                        "is-invalid": errors.password
                                    })}
                                    type="password"
                                    name="password"
                                    autoComplete="current-password"
                                    placeholder="Senha"
                                    onChange={this.handleInputChange}
                                    value={this.state.password}
                                />
                                <span className="focus-input100" />
                                <span className="symbol-input100">
                                    <IconLock />
                                </span>
                            </div>
                            {errors.password && (<div className="invalid-feedback">{errors.password}</div>)}

                            <div className="container-login100-form-btn">
                                <button className="login100-form-btn">
                                    Entrar
                                </button>
                            </div>


                            <div style={{
                                textAlign: "center",
                                paddingTop: "30px"
                            }}
                            >
                                <a href="https://fraternalhug.com" rel="noopener noreferrer" target="_blank" className="txt2">
                                    Preciso de Ajuda
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(mapStateToProps, { loginUser, clearErrors })(Login);
