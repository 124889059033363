import styled from 'styled-components';
import { Theme, hexToRgb } from 'assets/jss/doSul-dashboard';

export const CustomCard = styled.div`
    border: 0;
    margin-bottom: 30px;
    margin-top: 30px;
    border-radius: 6px;
    color: rgba(${hexToRgb(Theme.blackColor)}, 0.87);
    background: ${(props) => props.plain ? "transparent" : Theme.whiteColor};
    width: 100%;
    box-shadow: ${(props) => props.plain ? "none" : "0 1px 4px 0 rgba("+hexToRgb(Theme.blackColor)+", 0.14)"};
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    font-size: .875rem;
    text-align: ${(props) => props.profile ? "center" : "left"}
    ${(props)=>{
        if(props.chart){
            return `
                & p {
                    margin-top: 0px;
                    padding-top: 0px;
                }
            `;
        }
    }}
` 