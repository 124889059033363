import React from "react";
import PropTypes from "prop-types";

// Estilo
import { CustomCardBody } from "./styles"

function CardBody({ ...props }) {
  const { children, ...rest } = props;
  return (
    <CustomCardBody {...rest}>
      {children}
    </CustomCardBody>
  );
}

CardBody.propTypes = {
  plain: PropTypes.bool,
  profile: PropTypes.bool,
  children: PropTypes.node
};

export default CardBody;
