import styled from 'styled-components';
import {KeyboardDatePicker} from '@material-ui/pickers';
import FormHelperText from "@material-ui/core/InputLabel";
import { Theme } from "assets/jss/doSul-dashboard";

export const CustomKeyboardDatePicker = styled(KeyboardDatePicker)`
    width: 100%;

    label, label.MuiFormLabel-root.Mui-focused{
        color: ${Theme.grayColor[3]}!important;
    }
`

export const HelperTextError = styled(FormHelperText)`
    color: ${Theme.dangerColor[0]}!important;
    font-size: 12px!important;
`