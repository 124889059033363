import React, { Component } from "react";
// Estilo
import { Container, A } from 'assets/jss/doSul-dashboard';
import { Footer as FooterStyled, Left, Right } from "./styles";

/* Idiomas */
import { Trans } from 'react-i18next';

export default class Footer extends Component {
    render = () => {
        return (
            <FooterStyled>
                <Container>
                    <Left>
                    </Left>
                    <Right>
                        <span>
                            &copy; {1900 + new Date().getYear()}{" "}
                            <A id="fraternalhug" href="https://fraternalhug.com/" target="_blank">FraternalHug</A> - 
                            <Trans i18nKey="footer.copyright"> todos os direitos reservados</Trans>
                        </span>
                    </Right>
                </Container>
            </FooterStyled>);
    }
}
