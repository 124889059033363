/* Geral / Bibliotecas */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";

/* Ícones */
import Save from "@material-ui/icons/Save";
import Done from "@material-ui/icons/Done";
import Block from "@material-ui/icons/Block";
import Delete from "@material-ui/icons/Delete";

/* Métodos / Ações */
import * as form from "actions/form";
import { fetch, save } from "actions/cupons";
import { loadOptions as loadOptionsEmpresas } from "actions/empresas";

/* Componentes */
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import FormInput from "components/FormInput";
import Button from "components/CustomButton";
import { Container } from "assets/jss/doSul-dashboard";
import Loading from "components/Loading";
import Decimal from "components/Decimal";
import isValid from "date-fns/isValid";
import FNSformat from "date-fns/format";
import ptBRLocale from "date-fns/locale/pt-BR";
import DatePicker from "components/DatePicker";
import FormSelect from "components/FormSelect";

/* Validação */
import validationSchema from "./validation";

const Model = {
    IdCupom: null,
    Codigo: "",
    Titulo: "",
    DataInicio: "",
    DataFim: "",
    Desconto: "",
    Quantidade: "",
    empresa: null,
    Status: 1
};

export default function PlanosForm({
    id, changeStatus, deleteRecord, afterSave, afterChangeStatusSuccess, afterDeleteSuccess
}) {
    const formData = useSelector(state => state.form);
    const permissions = useSelector(state => state.permissions);

    const afterFetch = null; // Sobrescrever se precisar

    useEffect(() => {
        form.start("cupons", "IdCupom", id, Model, fetch, save, changeStatus, deleteRecord, afterSave, afterChangeStatusSuccess, afterDeleteSuccess, afterFetch);
        return form.stop;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // É chamado toda vez que muda o ID
        form.setModel(Model);
        if (id) {
            form.fetchData(id);
        }
    }, [id]);

    if (formData.loading) {
        return <Loading />;
    }

    const disabled = permissions && permissions[formData.root] < 2;

    return (
        <Container>
            <Formik
                validationSchema={validationSchema}
                initialValues={formData.model}
                onSubmit={form.handleSubmit}
                render={FormikProps => (
                    <Form onSubmit={FormikProps.handleSubmit}>
                        <GridContainer>
                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "Codigo",
                                        disabled
                                    }}
                                    form={FormikProps}
                                    label="Código"
                                />
                            </GridItem>
                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "Titulo",
                                        disabled
                                    }}
                                    form={FormikProps}
                                    label="Título *"
                                />
                            </GridItem>
                            <GridItem xs={12} lg={6}>
                                <DatePicker
                                    style={{ width: "100%" }}
                                    field={{
                                        name: "DataInicio",
                                        disabled,
                                        type: "date-local"
                                    }}
                                    value={FormikProps.values.DataInicio ? new Date(`${FormikProps.values.DataInicio} 00:00:00`) : null}
                                    onChange={function (value) {
                                        FormikProps.setFieldValue("DataInicio", isValid(value) ? FNSformat(value, "yyyy-MM-dd", { locale: ptBRLocale }) : null);
                                    }}
                                    form={FormikProps}
                                    label="Data de Início *"
                                />
                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                <DatePicker
                                    style={{ width: "100%" }}
                                    xs={12}
                                    field={{
                                        name: "DataFinal",
                                        disabled,
                                        type: "date-local"
                                    }}
                                    value={FormikProps.values.DataFinal ? new Date(`${FormikProps.values.DataFinal} 00:00:00`) : null}
                                    onChange={function (value) {
                                        FormikProps.setFieldValue("DataFinal", isValid(value) ? FNSformat(value, "yyyy-MM-dd", { locale: ptBRLocale }) : null);
                                    }}
                                    form={FormikProps}
                                    label="Data de Final *"
                                />
                            </GridItem>
                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "Desconto",
                                        disabled,
                                        inputComponent: Decimal,
                                        startAdornment: <span>&nbsp;%</span>
                                    }}
                                    form={FormikProps}
                                    label="Desconto"
                                />
                            </GridItem>
                            <GridItem xs={12} lg={6}>
                                <FormInput
                                    field={{
                                        name: "Quantidade",
                                        disabled,
                                        type: 'number'
                                    }}
                                    form={FormikProps}
                                    label="Quantidade"
                                />
                            </GridItem>
                            <GridItem xs={12}>
                                <FormSelect
                                    field={{
                                        name: "empresa",
                                        placeholder: "Digite para localizar...",
                                        isSearchable: true,
                                        defaultOptions: true,
                                        loadOptions: loadOptionsEmpresas,
                                        getOptionLabel: (option) => `${option.Nome}`,
                                        loadingMessage: () => "Carregando...",
                                        noOptionsMessage: (input) => (input.inputValue === "" ? "Digite para localizar..." : "Nenhum resultado encontrado"),
                                        disabled,
                                        value: FormikProps.values.empresa ? {
                                            IdEmpresa: FormikProps.values.empresa,
                                            Nome: FormikProps.values.nomeEmpresa
                                        } : null,
                                        onChange: (value) => {
                                            if(value == null) {
                                                FormikProps.setFieldValue("empresa", ''); 
                                                FormikProps.setFieldValue("nomeEmpresa", '');
                                            }
                                            else {
                                                if (value.IdEmpresa) { 
                                                    FormikProps.setFieldValue("empresa", btoa(value.IdEmpresa)); 
                                                }
                                                FormikProps.setFieldValue("nomeEmpresa", value.Nome);
                                            }
                                        }
                                    }}
                                    form={FormikProps}
                                    async
                                    label="Empresa"
                                />
                            </GridItem>
                            <GridItem xs={12}>
                                <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                                    {!disabled && (
                                        <Button color="success" type="submit" disabled={FormikProps.isSubmitting}>
                                            <Save />
                                            {" "}
                                            Salvar
                                        </Button>
                                    )}
                                    {!disabled && formData.model[formData.idField] && (
                                        <Button color="danger" type="button" right onClick={form.handleDelete}>
                                            <Delete />
                                            {" "}
                                            Excluir
                                        </Button>
                                    )}
                                    {!disabled && formData.model[formData.idField] && formData.model.Status === 0 && (
                                        <Button
                                            color="info"
                                            type="button"
                                            right
                                            onClick={() => {
                                                form.handleChangeStatus(1);
                                            }}
                                        >
                                            <Done />
                                            {" "}
                                            Ativar
                                        </Button>
                                    )}
                                    {!disabled && formData.model[formData.idField] && formData.model.Status === 1 && (
                                        <Button
                                            color="warning"
                                            type="button"
                                            right
                                            onClick={() => {
                                                form.handleChangeStatus(0);
                                            }}
                                        >
                                            <Block />
                                            {" "}
                                            Bloquear
                                        </Button>
                                    )}
                                </div>
                            </GridItem>
                        </GridContainer>
                    </Form>
                )}
            />
        </Container>
    );
}

PlanosForm.propTypes = {
    id: PropTypes.any,
    afterSave: PropTypes.func,
    changeStatus: PropTypes.func,
    deleteRecord: PropTypes.func,
    afterChangeStatusSuccess: PropTypes.func,
    afterDeleteSuccess: PropTypes.func
};
