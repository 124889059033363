import { defaultFetchList, defaultChangeStatus, defaultDelete, defaultSave, defaultFetch, defaultLoadOptions } from './default';

const model = "ritos";
const idField = "IdRito";

export const fetchList = (reqData, resolve, reject, dispatch) => defaultFetchList(model, reqData, resolve, reject, dispatch)
export const fetch = (id, callback) => defaultFetch(model, id, callback)
export const changeStatus = (id, status, afterChange, afterError) => defaultChangeStatus(model, id, status, afterChange, afterError)
export const deleteRecord = (id, afterDelete, afterError) => defaultDelete(model, id, afterDelete, afterError)
export const save = (data, actions, afterSave, afterError) => defaultSave(model, idField, data, false, actions, afterSave, afterError)

export const loadOptions = (inputValue) => defaultLoadOptions(inputValue, model, [idField, 'Nome']);