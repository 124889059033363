import types from 'types/permissions';

const initialState = {};

export default function(state = initialState, action ) {
    switch(action.type) {
        case types.SET_PERMISSIONS:
            return action.payload;

        case types.GET_PERMISSIONS:
            return action.payload;
            
        default: 
            return state;
    }
};