import styled from "styled-components";
import List from "@material-ui/core/List";
import image from "assets/img/sidebar.jpg";

export const getFromPreferences = (prop, def) => {
    const pref = localStorage.getItem("layout_preferences");
    if (pref) {
        const objPref = JSON.parse(pref);
        if (objPref && objPref[prop]) {
            return objPref[prop];
        }
    }

    return def;
};

export const hexToRgb = input => {
    input += "";
    input = input.replace("#", "");
    const hexRegex = /[0-9A-Fa-f]/g;
    if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
        throw new Error("input is not a valid hex color.");
    }
    if (input.length === 3) {
        const first = input[0];
        const second = input[1];
        const last = input[2];
        input = first + first + second + second + last + last;
    }
    input = input.toUpperCase(input);
    const first = input[0] + input[1];
    const second = input[2] + input[3];
    const last = input[4] + input[5];
    return `${parseInt(first, 16)}, ${parseInt(second, 16)}, ${parseInt(last, 16)}`;
};

export const Theme = {
    primaryColor: [
        getFromPreferences("primaryColor1", "#0076bd"),
        getFromPreferences("primaryColor2", "#0199d2"),
        getFromPreferences("primaryColor3", "#036387")
    ], // (% de CLARO) 42%, 51%, 40%, 47% (Color picker w3schools)
    warningColor: ["#ff9800", "#ffa726", "#fb8c00", "#ffa21a"],
    dangerColor: ["#f44336", "#ef5350", "#e53935", "#f55a4e"],
    successColor: ["#4caf50", "#66bb6a", "#43a047", "#5cb860"],
    infoColor: ["#00acc1", "#26c6da", "#00acc1", "#00d3ee"],
    roseColor: ["#e91e63", "#ec407a", "#d81b60", "#eb3573"],
    grayColor: ["#999", "#777", "#3C4858", "#AAAAAA", "#D2D2D2", "#DDD", "#b4b4b4", "#555555", "#333", "#a9afbb", "#eee", "#e7e7e7"],
    blackColor: "#000",
    whiteColor: "#FFF",

    breakpointSm: "600px",
    breakpointMd: "960px",
    breakpointLg: "1280px",
    breakpointXl: "1920px",

    modalWidth: getFromPreferences("modalWidth", 55),
    menuBackground: getFromPreferences("menuBackground", image)
};

export const defaultBoxShadow = `0 10px 30px -12px rgba(${
    hexToRgb(Theme.blackColor)
    }, 0.42), 0 4px 25px 0px rgba(${
    hexToRgb(Theme.blackColor)
    }, 0.12), 0 8px 10px -5px rgba(${
    hexToRgb(Theme.blackColor)
    }, 0.2)`;
export const primaryBoxShadow = `0 4px 20px 0 rgba(${hexToRgb(Theme.blackColor)},.14), 0 7px 10px -5px rgba(${hexToRgb(Theme.primaryColor[0])},.4)`;

export const Container = styled.div`
    padding-right: 15px;
    padding-left: 15px;
    margin-left: auto;
    margin-right: auto;
`;

export const A = styled.a`
    color: ${Theme.primaryColor[0]};
    text-decoration: none;

    &:hover,
    &:active {
        color: ${Theme.primaryColor[1]};
        text-decoration: none;
    }
`;

export const CustomList = styled(List)`
    color: ${Theme.primaryColor[0]};
    text-decoration: none;

    &:hover,
    &:active {
        color: ${Theme.primaryColor[1]};
        text-decoration: none;
    }
`;
