import styled from 'styled-components';
import { Theme, primaryBoxShadow } from 'assets/jss/doSul-dashboard';

export const CustomCardHeader = styled.div`
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    border-bottom: none;
    background: transparent;
    z-index: 3 !important;
    margin: 0 15px;
    padding: 0;
    position: relative;
    color: ${Theme.whiteColor};

    ${(props) => {
        let ret = '';
        if(props.plain){
            ret += `
                margin-left: 0px !important;
                margin-right: 0px !important;
            `
        }

        if(props.icon){
            ret += `
                background: transparent!important;
                box-shadow: none!important;

                & i,& .material-icons {
                  width: 33px;
                  height: 33px;
                  text-align: center;
                  line-height: 33px;
                }
                & svg {
                  width: 24px;
                  height: 24px;
                  text-align: center;
                  line-height: 33px;
                  margin: 5px 4px 0px;
                }
            `
        } else {
            ret += `
                border-radius: 3px;
                margin-top: -20px;
                padding: 15px;
                color: ${Theme.whiteColor};
                background: linear-gradient(60deg, ${Theme.primaryColor[1]}, ${Theme.primaryColor[2]});
                box-shadow: ${primaryBoxShadow};
            `
        }

        if(props.stats){
            ret += `
                & svg {
                    font-size: 36px;
                    line-height: 56px;
                    text-align: center;
                    width: 36px;
                    height: 36px;
                    margin: 10px 10px 4px;
                }
                & i,& .material-icons {
                    font-size: 36px;
                    line-height: 56px;
                    width: 56px;
                    height: 56px;
                    text-align: center;
                    overflow: unset;
                    margin-bottom: 1px;
                }
                & h1,& h2,& h3,& h4,& h5,& h6 {
                    margin: 0!important
                }
            `
        }

        if(props.stats && props.icon){
            ret += `
                text-align: right;
            `
        }
        return ret;
    }}
`