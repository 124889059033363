import React from "react";

// Estilo
import { CustomTabs } from "./styles";

function Tabs(props) {
    return (
        <CustomTabs
            indicatorColor="primary"
            textColor="primary"
            {...props} />
    );
}

export default Tabs;
